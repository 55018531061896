import useCustomerParents from "api/hooks/useCustomerParents"
import FormInput from "components/Form/FormInput"
import { IInputOption, Input } from "components/GenericComponents/input"
import { FC, useCallback, useMemo } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { CustomerWithChain } from "../../types"
import { linkStoreNameToChain } from "Utils/linkStoreNameToChain"
import { cloneDeep } from "lodash"
import { useCommonEntitiesStore } from "States/commonEntities"
import { NUMBER_REGEX } from "Utils/isValidNumber"
import SelectInput from "components/Form/SelectInput"

interface TenantDetailsFormSectionProps {
	formHandler: UseFormReturn<CustomerWithChain>
	isEdit?: boolean
}

export const TenantDetailsFormSection: FC<TenantDetailsFormSectionProps> = ({
	formHandler,
	isEdit = false,
}) => {
	const { t } = useTrans()
	const { data: chains } = useCustomerParents()
	const { tenantCategories } = useCommonEntitiesStore()

	const customerParents = useMemo(
		() => chains.map(({ id: value, name: option }) => ({ option, value }) as IInputOption),
		[chains]
	)

	const {
		register,
		formState: { errors },
		control,
		setValue,
		watch,
	} = formHandler

	const chain = watch("businessChainId")

	const chainOptions = useMemo(() => {
		const newOptions = cloneDeep(customerParents)

		newOptions.unshift({ value: "", option: "" })

		return newOptions
	}, [customerParents])

	const permissionsOptions = useMemo(
		() => [
			{ label: t("common:yes"), value: "true" },
			{ label: t("common:no"), value: "false" },
		],
		[t]
	)

	const defaultPermission = useMemo(() => permissionsOptions[1].value, [permissionsOptions])

	const categoryOptions = useMemo(
		() => [
			{
				option: "",
				value: "",
			},
			...(tenantCategories || []).map(el => ({
				option: el.name,
				value: el.id,
			})),
		],
		[tenantCategories]
	)

	const onChainUpdate = useCallback(
		(option: IInputOption) => {
			setValue("businessChainId", option.value, { shouldDirty: true })

			const chain = chains.find(el => el.id === option.value)

			if (chain) {
				setValue("categoryId", chain.categoryId, { shouldDirty: true })
			}
		},
		[chains, setValue]
	)

	const onNameChange = useCallback(
		(e: any) => {
			const name = e.target.value
			let chainId = ""
			let category = ""

			if (name) {
				const chainName = linkStoreNameToChain(
					name,
					chains.map(el => el.name)
				)

				const chain = chains.find(el => el.name === chainName)
				if (chain) {
					chainId = chain.id
					category = chain.categoryId || ""
				}
			}

			setValue("businessChainId", chainId, { shouldDirty: true })
			setValue("categoryId", category, { shouldDirty: true })
		},
		[chains, setValue]
	)

	return (
		<div className="md:w-96 mr-3">
			{!isEdit && (
				<h2 className="mb-2 font-dmSans text-base font-medium">{`1. ${t(
					"formLabels:enterTenantInformation"
				)}`}</h2>
			)}
			<div className="mb-3">
				<FormInput
					name="name"
					label={t("formLabels:customerName")}
					register={register}
					error={errors?.name}
					required
					onChange={onNameChange}
				/>
			</div>
			<div className="mb-3">
				<label className="font-dmSans text-sm font-medium text-black empty:hidden">
					{t("formLabels:chain")}
				</label>
				<div className="flex flex-col">
					<Input
						data={chainOptions}
						dropdownStyle={{ maxHeight: 190 }}
						dropdownIcon
						error={!!errors?.businessChainId}
						initialValue={chain}
						onChange={onChainUpdate}
						ignoreOnRenderChange
						placeholder={t("actions:selectProperty", {
							property: t("formLabels:chain").toLowerCase(),
						})}
					/>
				</div>
			</div>
			<div className="mb-3">
				<label className="font-dmSans text-sm font-medium text-black empty:hidden">
					{t("formLabels:category")}
				</label>
				<div className="flex flex-col">
					<Input
						data={categoryOptions}
						dropdownStyle={{ maxHeight: 190 }}
						dropdownIcon
						error={!!errors?.categoryId}
						initialValue={formHandler.getValues("categoryId")}
						onChange={option => setValue("categoryId", option.value, { shouldDirty: true })}
						ignoreOnRenderChange
						placeholder={t("actions:selectProperty", {
							property: t("formLabels:category").toLowerCase(),
						})}
					/>
				</div>
			</div>
			<div className="mb-3">
				<FormInput
					name="area"
					label={t("formLabels:area")}
					register={register}
					error={errors?.area}
					required
					regexPattern={NUMBER_REGEX}
					patternError="errors:mustBeValidNumber"
				/>
			</div>
			<div className="mb-3">
				<SelectInput
					name="throwDelegate"
					label={t("formLabels:cleanerPermissions")}
					control={control}
					options={permissionsOptions}
					placeholder={t("actions:selectProperty", {
						property: t("formLabels:permissions").toLowerCase(),
					})}
					defaultValue={defaultPermission}
					menuPlacement="top"
					required
				/>
			</div>
		</div>
	)
}
