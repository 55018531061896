import { createClient, ClientConfig } from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"
import { useQuery } from "@tanstack/react-query"
import { useApiStore } from "States/api"
import { RequestOptions } from "../types"
import { normalizeSanityResponse } from "./functions"
import * as query from "./queries"
import { SanityQueryType, SanityResponseType } from "./types"
import { useTrans } from "translations"
import i18n from "i18n"
import { SANITY_TOKEN, isStaging } from "Configs/config"
import { useCommonEntitiesStore } from "States/commonEntities"

let clientConfig: ClientConfig = {
	projectId: "knqqkm52",
	dataset: "production",
	apiVersion: "2021-10-21",
	useCdn: true,
}

// Important: Tokens are only added in staging environment to fetch drafts
if (isStaging && SANITY_TOKEN) {
	clientConfig.token = SANITY_TOKEN
	clientConfig.ignoreBrowserTokenWarning = true
}

/** Init sanity client */
const client = createClient(clientConfig)

/** Converts Sanity assets to url */
export const sanityGetUrlFor = imageUrlBuilder(client)

/** Request sanity data with react query function */
export async function requestSanity(
	input: { query: SanityQueryType; locale?: string },
	options?: RequestOptions<SanityResponseType<typeof input.query>>
) {
	type Response = SanityResponseType<typeof input.query>
	const { reactQueryClient } = useApiStore.getState()
	const { languages } = useCommonEntitiesStore.getState()

	const locale = input.locale ?? i18n.language

	if (!reactQueryClient) {
		throw new Error("Could not connect to api client")
	}

	const sanityLanguage = languages?.find(el => el.code === i18n.language)?.sanityCode

	const response = await reactQueryClient.fetchQuery(
		[`sanity_${query[input.query]}_${locale}`],
		async () => {
			const params = sanityLanguage
				? {
						lang: sanityLanguage,
				  }
				: {}
			const fetchedData = await client.fetch<Response>(query[input.query], params)
			return normalizeSanityResponse<Response>(fetchedData, input.query)
		},
		options?.reactQueryOptions
	)

	if (options?.reactQueryRefetch) {
		// Clears cache of specific query key, refreshes places where useQuery is in view or next time
		// visiting a view with useQuery.
		reactQueryClient.invalidateQueries(options.reactQueryRefetch.queryKey)
	}

	return response
}

/** Request sanity data with react query hook */
export function useSanity(
	input: { query: SanityQueryType },
	options?: Parameters<typeof useQuery<SanityResponseType<typeof input.query>, Error>>["2"]
) {
	type Response = SanityResponseType<typeof input.query>
	type NormalizedResponse = SanityResponseType<typeof input.query>
	const { t } = useTrans()

	const reactQuery = useQuery<NormalizedResponse, Error>(
		[`sanity_${query[input.query]}`],
		async () => {
			const fetchedData = await client.fetch<Response>(query[input.query], {
				lang: t("langIso"),
			})

			return normalizeSanityResponse<Response>(fetchedData, input.query)
		},
		options
	)

	return reactQuery
}
