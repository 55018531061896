import React from "react"

export enum NavHeaderTypeEnum {
	General = "general",
	Customers = "customers",
	Infrastructure = "infrastructure",
	Circularity = "circularity",
}

export interface NavSectionInterface {
	type: NavHeaderTypeEnum
}

export interface NavHeaderInterface extends NavSectionInterface {}

export interface NavItemInterface {
	to: string
	name: string
}

export interface navigationInterface {
	[key: string]: {
		icon: React.FC
		links: {
			to: string
			name: string
		}[]
	}
}
