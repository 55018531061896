import React from "react"

export const CalendarIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2080_1077)">
			<path
				d="M11.3333 1.99984H13.9999C14.1767 1.99984 14.3463 2.07008 14.4713 2.1951C14.5963 2.32012 14.6666 2.48969 14.6666 2.6665V13.3332C14.6666 13.51 14.5963 13.6796 14.4713 13.8046C14.3463 13.9296 14.1767 13.9998 13.9999 13.9998H1.99992C1.82311 13.9998 1.65354 13.9296 1.52851 13.8046C1.40349 13.6796 1.33325 13.51 1.33325 13.3332V2.6665C1.33325 2.48969 1.40349 2.32012 1.52851 2.1951C1.65354 2.07008 1.82311 1.99984 1.99992 1.99984H4.66658V0.666504H5.99992V1.99984H9.99992V0.666504H11.3333V1.99984ZM9.99992 3.33317H5.99992V4.6665H4.66658V3.33317H2.66659V5.99984H13.3333V3.33317H11.3333V4.6665H9.99992V3.33317ZM13.3333 7.33317H2.66659V12.6665H13.3333V7.33317Z"
				fill="#948D8D"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2080_1077">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default CalendarIcon
