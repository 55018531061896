import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useModal } from "Contexts"
import { CustomerAccessParent, CustomerWithAccessParents } from "pages/customer/manage/types"
import { FC, useMemo } from "react"
import { AddAccessParentModal } from "./AddAccessParentModal"
import { trpc } from "Utils/trpc"
import { useGlobalAlert } from "States/globalAlert"
import { Table } from "components/Table"
import {
	infrastructureColumnsWithRedundancyPoints,
	infrastructureColumns,
} from "Utils/tableColumns/infrastructureColumns"
import { useTrans } from "translations"
import { useConfig } from "api/hooks/useConfig"
import { TOptions } from "i18next"

interface InfrastructureCardProps {
	customer: CustomerWithAccessParents
	refetch: () => void
}

export const accessParentWithRedundancyPointNames = (
	accessParent: CustomerAccessParent,
	t: (key: string, options?: TOptions) => string
) => {
	const redundancyPointNames = !!accessParent?.redundancyPoints?.length
		? accessParent.redundancyPoints.map(fs => fs.name)
		: [t("hints:noRedundancyPoints")]

	return {
		...accessParent,
		redundancyPointNames,
	}
}

export const InfrastructureCard: FC<InfrastructureCardProps> = ({ customer, refetch }) => {
	const { t } = useTrans()
	const {
		config: { useRedundancyPoints },
	} = useConfig()
	const { setGlobalAlert } = useGlobalAlert()
	const { mutate: updateCustomer } = trpc.customers.update.useMutation()
	const { showModal, hideModal } = useModal()

	const hideModalAndRefetch = () => {
		hideModal()
		refetch()
	}

	const addAccessParent = () => {
		showModal(<AddAccessParentModal {...{ customer }} onConfirm={hideModalAndRefetch} />)
	}

	const removeAccessParent = (accessParentId: string) => {
		updateCustomer(
			{ customer: { id: customer.id, removeAccessParents: [{ id: accessParentId }] } },
			{
				onSuccess: () => {
					setGlobalAlert({
						type: "success",
						message: "systemMessages:changesSaved",
					})
					refetch()
				},
			}
		)
	}

	const columns = useMemo(() => {
		return useRedundancyPoints ? infrastructureColumnsWithRedundancyPoints : infrastructureColumns
	}, [useRedundancyPoints])

	const data = useMemo(
		() =>
			useRedundancyPoints
				? customer.accessParents.map(ap => accessParentWithRedundancyPointNames(ap, t))
				: customer.accessParents,
		[customer.accessParents, t, useRedundancyPoints]
	)

	return (
		<Card
			{...{
				title: "drawerLabels:infrastructure",
			}}
			overflow="auto"
			variant={CardComponentVariant.DRAWER}
		>
			{useRedundancyPoints && (
				<div className="mb-5">
					<p className="text-sm">{t("hints:infrastructureDescription")}</p>
				</div>
			)}
			<Table
				limitedHeight
				columns={columns}
				data={data}
				emptyViewType="noAccessParent"
				emptyViewClassName="bg-grey1 mt-0"
				deleteFunction={removeAccessParent}
			/>
			<div className="py-4 h-20">
				<Button
					className="float-right"
					label="actions:edit"
					color="secondary"
					onClick={addAccessParent}
				/>
			</div>
		</Card>
	)
}
