import { useConfig } from "api/hooks/useConfig"
import React from "react"
import { InfrastructureMWM } from "./summaryMwm"
import { InfrastructureRealEstate } from "./summaryRE"

export const InfrastructureSummary = () => {
	const { isMWM } = useConfig()

	return isMWM ? <InfrastructureMWM /> : <InfrastructureRealEstate />
}
