import { Toggle } from "components/Toggle"
import React, { useCallback } from "react"
import DashboardTileType, {
	MWMDashboardTileType,
	REDashboardTileType,
} from "admin-client-server/src/utils/dashboardTileType"
import { trpc } from "Utils/trpc"
import { remove } from "lodash"
import { Translate, useTrans } from "translations"
import { useConfig } from "api/hooks/useConfig"
import { Button } from "components/button"

interface Props {
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

const getTileTitleKey = (tileType: DashboardTileType) => {
	switch (tileType) {
		case "KEY_NUMBERS":
			return "circularityLabels:keyNumbers"
		case "WASTE_TYPES_IN_USE":
			return "circularityLabels:wasteTypeCategoriesInUse"
		case "RECYCLING_RATE_OVER_TIME":
			return "circularityLabels:recyclingRateOverTime"
		case "DEVELOPMENT_IN_WASTE_TYPES":
			return "circularityLabels:developmentInWasteTypes"
		case "WASTE_CONTRIBUTION":
			return "statisticsLabels:wasteContributions"
		case "WASTE_TYPE_DISTRIBUTION":
			return "circularityLabels:wasteTypeDistribution"
		case "WASTE_PER_TENANT":
			return "circularityLabels:comparisonWasteTypeKG"
		case "COMPARISON_WASTE_TYPE_DISTRIBUTION":
			return "circularityLabels:comparisonWasteTypeDist"
		case "INFRASTRUCTURE_SUMMARY":
			return "statisticsLabels:infrastructureSummary"
		default:
			throw new Error(`Unknown tile type: ${tileType}`)
	}
}

export const Dashboard: React.FC<Props> = ({ setHasUnsavedChanges }) => {
	const { t } = useTrans()
	const { isMWM } = useConfig()
	const { data, refetch } = trpc.config.getDashboardTiles.useQuery()
	const [tiles, setTiles] = React.useState<{ type: DashboardTileType; visible: boolean }[]>([])
	const { mutate, isLoading } = trpc.config.updateDashboardTiles.useMutation({
		onSuccess: () => {
			refetch()
			setHasUnsavedChanges(false)
		},
	})

	const findTile = useCallback(
		(tileType: DashboardTileType) => {
			return tiles.find(t => t.type === tileType) ?? data?.find(tile => tile.tileType === tileType)
		},
		[tiles, data]
	)

	const onChange = useCallback(
		(tileType: DashboardTileType, checked: boolean) => {
			setTiles(prev => [
				...remove(prev, ({ type }) => type !== tileType),
				{
					type: tileType,
					visible: checked,
				},
			])

			setHasUnsavedChanges(true)
		},
		[setTiles, setHasUnsavedChanges]
	)

	const onSave = useCallback(() => {
		mutate(
			tiles.map(({ type, visible }) => ({
				tileType: type,
				visible,
			}))
		)
	}, [tiles, mutate])

	const values = isMWM
		? (Object.values(MWMDashboardTileType) as DashboardTileType[])
		: (Object.values(REDashboardTileType) as DashboardTileType[])

	return (
		<div>
			<div className="mb-4 ">
				<ul className="list-disc list-inside ml-2">
					<Translate
						i18nKey="configLabels:dashboardConfigDescription"
						children={[<li />, <li />, <li />]}
					/>
				</ul>
			</div>
			{values.map(tileType => {
				return (
					<Row
						key={tileType}
						title={t(getTileTitleKey(tileType))}
						onChange={checked => onChange(tileType, checked)}
						isChecked={findTile(tileType)?.visible ?? true}
					/>
				)
			})}
			<hr className="-mx-4 my-4" />
			<Button label={t("actions:save")} onClick={onSave} loading={isLoading} />
		</div>
	)
}

const Row = ({
	title,
	isChecked,
	onChange,
}: {
	title: string
	isChecked: boolean
	onChange: (checked: boolean) => void
}) => {
	return (
		<div className="flex items-center py-2">
			<Toggle checked={isChecked} onChange={onChange} disabled={false} />
			<span className="text-sm ml-2">{title}</span>
		</div>
	)
}
