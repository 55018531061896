import { CustomersResponse } from "Utils/api/datawarehouse/responseTypes"
import { DeltaTrend } from "Utils/tableColumns/functions"

export const mixedWastePerformance = (
	currentPeriod: CustomersResponse,
	firstPeriod: CustomersResponse
) =>
	currentPeriod.customers.map(t => {
		const tenantLastPeriod = firstPeriod.customers.find(el => el.customerName === t.customerName)
		const previousAmount = tenantLastPeriod?.totalResidualWasteWeight.quantity ?? 0
		const diff = t.totalResidualWasteWeight.quantity - previousAmount
		const trend =
			diff < 0 ? DeltaTrend.Positive : diff === 0 ? DeltaTrend.Neutral : DeltaTrend.Negative

		return {
			id: t.customerName,
			name: t.customerName,
			rate: t.totalResidualWasteWeight.quantity,
			diff,
			unit: t.totalResidualWasteWeight.unit,
			trend,
		}
	})

export const percentagePerformance = (
	currentPeriod: CustomersResponse,
	firstPeriod: CustomersResponse
) =>
	currentPeriod.customers.map(t => {
		const tenantLastPeriod = firstPeriod.customers.find(el => el.customerName === t.customerName)
		const previousAmount = (tenantLastPeriod?.sortingDegree ?? 0) * 100
		const currentSortingDegree = t.sortingDegree * 100
		const diff = currentSortingDegree - previousAmount
		const trend =
			diff < 0 ? DeltaTrend.Negative : diff === 0 ? DeltaTrend.Neutral : DeltaTrend.Positive

		return {
			id: t.customerName,
			name: t.customerName,
			rate: t.sortingDegree * 100,
			diff,
			unit: "percent",
			trend,
		}
	})
