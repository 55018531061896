import { ComponentProps } from "react"
import { Button } from "./button"
import { twMerge } from "tailwind-merge"
import { MinusIcon } from "Icons/Minus"

type RemoveButtonButtonProps = Omit<ComponentProps<typeof Button>, "label" | "color">

export const RemoveButton: React.FC<RemoveButtonButtonProps> = props => {
	return (
		<Button
			{...props}
			label={<MinusIcon height={16} width={16} fill={props.disabled ? "#948D8D" : "#000"} />}
			className={twMerge(
				"bg-grey1 hover:bg-grey2 min-w-0 w-8 h-8 px-0 flex justify-center items-center",
				props.className
			)}
		/>
	)
}
