import { SVGAttributes } from "react"

export const DropdownArrowIcon = (props: SVGAttributes<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="black"
		>
			<path d="M1.11713 4.11713L6 9L10.8829 4.11713" />
		</svg>
	)
}
