import { SVGAttributes } from "react"

export const CheckIcon = (props: SVGAttributes<SVGSVGElement>) => {
	return (
		<svg width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
			<path
				d="M5.11464 9.24206L10.948 2.65381L11.8454 3.66738L5.11464 11.2692L1.07617 6.70814L1.9736 5.69457L5.11464 9.24206Z"
				fill="currentColor"
			/>
		</svg>
	)
}
