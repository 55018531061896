import ModalContainer from "components/modalContainer"
import { FC } from "react"
import create from "zustand"
import { combine } from "zustand/middleware"

export const useConfirmationDialog = create(
	combine(
		{
			show: false,
			title: "",
			text: "",
			onAccept: () => {},
		},
		set => ({
			openConfirmationDialog: (title: string, onAccept: () => void, text: string = "") =>
				set({ show: true, title, onAccept, text }),
			closeConfirmationDialog: () => set({ show: false }),
		})
	)
)

export const ConfirmationDialog: FC<{}> = () => {
	const { show, title, text, onAccept, closeConfirmationDialog } = useConfirmationDialog()
	if (!show) return null
	return (
		<div id="modal">
			<ModalContainer
				onCancel={closeConfirmationDialog}
				onConfirm={() => {
					closeConfirmationDialog()
					onAccept()
				}}
				onConfirmType="primary"
				title={title}
				onConfirmText="actions:confirm"
				className="w-1/2"
			>
				{text}
			</ModalContainer>
		</div>
	)
}
