import { noop } from "lodash"
import React from "react"
import { ImportStep, Template } from "./types"
import { UseImportState } from "./importState"

export type IImportContext = {
	steps: ImportStep[]
	activeStep: ImportStep
	setActiveStep: (step: ImportStep) => void

	templates: Template[]

	addToSystem: (context: IImportContext) => void

	reset: () => void
	onCancel: () => void
} & UseImportState

export const ImportContext = React.createContext({
	steps: [],
	activeStep: {
		key: "",
		helpSectionKey: "",
		view: () => <></>,
	},
	setActiveStep: noop,

	selectedTemplate: undefined,
	setSelectedTemplate: noop,
	templates: [],

	selectedParentIds: {},
	setSelectedParentIds: noop,

	uploadedFileName: "",
	setUploadedFileName: noop,

	uploadedFileStructure: [],
	setUploadedFileStructure: noop,

	mappingState: {},
	setMappingState: noop,

	validationState: {},
	setValidationState: noop,

	addToSystem: noop,
	loadingState: {},
	setLoadingState: noop,

	reset: noop,
	fullReset: noop,
	onCancel: noop,
} as IImportContext)
