import { MappingSection } from "components/ImportComponents/types"
import { downloadForCustomers } from "./downloadFunctions"
import { getContactsColumns, getCustomerColumns, getStationsColumns } from "./columnFunctions"
import { CONTACT_KEY, CUSTOMER_KEY, STATION_KEY } from "./constants"
import { SectionProps } from "pages/infrastructure/import/templates"
import { FetchedTenantTypes } from "Utils/api/sanity/types"
import { MINIMAL_ACCESS_PARENT } from "admin-client-server/src/coreApi/models/Common"

export const getCustomerSection = ({ columns }: SectionProps): MappingSection => ({
	key: CUSTOMER_KEY,
	title: "entities:customers",
	isOptional: false,
	sheetSelectLabel: "importLabels:selectCustomerSheet",
	columns: columns || [],
	columnSelectLabel: "importLabels:selectCustomerProperties",
})

export const getContactSection = ({ columns }: SectionProps): MappingSection => ({
	key: CONTACT_KEY,
	title: "drawerLabels:contactPersons",
	isOptional: false,
	sheetSelectLabel: "importLabels:selectContactsSheet",
	columns: columns || [],
	columnSelectLabel: "importLabels:selectContactsProperties",
	hideCount: true,
})

export const getStationsSection = ({ columns }: SectionProps): MappingSection => ({
	key: STATION_KEY,
	title: "entities:accessParents",
	isOptional: false,
	sheetSelectLabel: "importLabels:selectStationsSheet",
	columns: columns || [],
	columnSelectLabel: "importLabels:selectStationsProperties",
	hideCount: true,
})

export const getTemplates = ({
	chains,
	tenantCategories,
	isMWM,
	allAccessParents,
}: {
	chains?: any[]
	tenantCategories?: FetchedTenantTypes
	isMWM: boolean
	allAccessParents?: MINIMAL_ACCESS_PARENT[]
}) => {
	const customerColumns = getCustomerColumns({
		chains,
		tenantCategories,
		isMWM,
	})
	const customerSection = getCustomerSection({
		columns: customerColumns,
	})

	const contactsColumns = getContactsColumns()
	const contactsSection = getContactSection({
		columns: contactsColumns,
	})

	const stationsColumns = getStationsColumns(allAccessParents)
	const stationsSection = getStationsSection({
		columns: stationsColumns,
	})

	const customerSections: MappingSection[] = [
		customerSection,
		isMWM ? stationsSection : contactsSection,
	]

	return [
		{
			key: "customers",
			title: "importLabels:customerTemplateCustomers",
			downloadFile: downloadForCustomers,
			mappingSections: customerSections,
			addedText: "importLabels:customersAdded",
			findResultsText: "importLabels:customersFindResults",
			findResultsLink: "/customer/manage",
			checkCanBeUsed: () => true,
		},
	]
}
