import { DocumentNode } from "@apollo/client"
import { postFunc } from "../Utils/api"
import { generateGQLrequest } from "../Utils/gqlRequestGenerator"
import { addAccessPointMutation } from "../Utils/gqlRequestTypes/addAccessPointMutation"

export const addAccessPoints = ({
	accessPoints,
	refetchQueries,
	onStartCallBack,
	onFailCallBack,
}: {
	accessPoints: addAccessPointMutation[]
	refetchQueries?: {
		query: DocumentNode
		variables?: {
			[key: string]: any
		}
	}[]
	onStartCallBack?: (val?: any) => void
	onFailCallBack?: () => void
}) => {
	const ADD_ACCESS_POINTS = generateGQLrequest({
		type: "addAccessPointsMutation",
		input: {
			wait: true,
			payload: {
				accessPoints,
			},
		},
		payloadKeys: [`modifiedPoints { id, name, externalId: externalKey(key: "ImportID") }`],
	})
	postFunc(
		ADD_ACCESS_POINTS,
		(res: any) => {
			onStartCallBack && onStartCallBack(res?.data?.addAccessPointsMutation)
		},
		{ refetchQueries: refetchQueries || [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
}
