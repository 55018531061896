import { capitalize } from "lodash"
import { translate } from "translations/functions/hook"

const errorText: { [k: string]: string } = {
	required: "errors:requiredFields",
	minLength: "errors:minLength",
	maxLength: "errors:maxLength",
	min: "errors:min",
	max: "errors:max",
}

const getName = (val?: string) => capitalize(val?.replace(/\(.*\)/, ""))

export const getInputErrorMessage = ({
	errorType,
	label,
	patternError,
	minLength,
}: {
	errorType: string
	label?: string
	patternError?: string
	minLength?: number
}) => {
	switch (errorType) {
		case "required": {
			return translate(errorText.required, {
				name: getName(label),
			})
		}

		case "pattern":
		case "validate": {
			return translate(patternError || "", {
				name: getName(label),
			})
		}

		case "minLength": {
			return translate(errorText.minLength, {
				name: getName(label),
				minLength,
			})
		}

		case "maxLength": {
			return translate(errorText.maxLength, {
				name: getName(label),
			})
		}

		case "min": {
			return translate(errorText.min, {
				name: getName(label),
			})
		}

		case "max": {
			return translate(errorText.max, {
				name: getName(label),
			})
		}

		default:
			return ""
	}
}
