import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import React from "react"
import { WasteTypesInUse } from "../.."
import { useTrans } from "translations"

export const WasteTypesInUseModal: React.FC = () => {
	const { hideModal } = useModal()
	const { t } = useTrans()

	return (
		<ModalContainer
			title="circularityLabels:wasteTypeCategoriesInUse"
			onCancel={hideModal}
			disableConfirmButton={true}
			onCancelText={t("actions:close")}
		>
			<WasteTypesInUse limitNumberOfTypes={false} />
		</ModalContainer>
	)
}
