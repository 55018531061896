import { Page } from "components/Page"
import { Card } from "components/pageCards/card"
import React from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { sanityGetUrlFor } from "Utils/api/sanity/request"
import { getFormattedData, InputInterface } from "Utils/getParsedUpgradesData"

export const Upgrades: React.FC = () => {
	const { t } = useTrans()
	const { upgrades } = useCommonEntitiesStore()
	const formattedData = getFormattedData(upgrades as InputInterface[])

	return (
		<Page title="sidebarLabels:upgradesAndPowerUps">
			{formattedData.map(({ category, description, items }, index) => (
				<Card title={category} translateTitle={false} key={category}>
					<p className="mb-4 font-dmSans text-sm text-grey6">{description}</p>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
						{!!items?.length &&
							items.map(({ name, description, manufacturer, iconColor, vendor }) => (
								<div
									className="border border-grey1 p-4"
									data-testid={`upgrade-${index}`}
									key={name}
								>
									<div className="mb-2 flex h-[160px] items-center">
										<img
											src={
												iconColor
													? sanityGetUrlFor.image(iconColor).url()
													: "https://via.placeholder.com/210x160"
											}
											alt=""
										/>
									</div>
									<p className="font-dmSans text-2xs text-black empty:hidden">
										{manufacturer?.name}
									</p>
									<h6 className="mb-2 font-dmSans text-base text-black">{name}</h6>
									<p className="mb-4 font-dmSans text-xs leading-4 text-black">{description}</p>
									<p className="font-dmSans text-2xs text-black">
										{t("genericLabels:contactVendor")} {vendor?.name}:
									</p>
									<a
										href={`mailto:${vendor?.email}`}
										className="font-dmSans text-xs text-black underline empty:hidden"
									>
										{vendor?.email}
									</a>
								</div>
							))}
					</div>
				</Card>
			))}
		</Page>
	)
}

export default Upgrades
