import {
	CONTACT_PEOPLE_KEY,
	IDENTITIES_KEY,
	POINTS_HISTORY_KEY,
	PROPERTIES_KEY,
	SMART_INTEGRATIONS_KEY,
} from "constants/general"

export const CUSTOMER_HISTORY_TYPE = "CUSTOMER_HISTORY"
export const ACCESS_POINT_HISTORY_TYPE = "ACCESS_POINT_HISTORY"

export const ADDED_KEY = "N"
export const DELETED_KEY = "D"
export const EDITED_KEY = "E"

export const TRANSLATIONS: { [k: string]: any } = {
	[IDENTITIES_KEY]: {
		[ADDED_KEY]: "historyLabels:addedIdentity",
		[DELETED_KEY]: "historyLabels:deletedIdentity",
	},
	[PROPERTIES_KEY]: {
		[ADDED_KEY]: "historyLabels:addedProperty",
		[EDITED_KEY]: "historyLabels:changedProperty",
		[DELETED_KEY]: "historyLabels:deletedProperty",
	},
	[POINTS_HISTORY_KEY]: {
		[ADDED_KEY]: "historyLabels:addedToPoint",
		[DELETED_KEY]: "historyLabels:deletedFromPoint",
	},
	[SMART_INTEGRATIONS_KEY]: {
		[ADDED_KEY]: "historyLabels:addedSmartIntegration",
		[EDITED_KEY]: "historyLabels:changedSmartIntegration",
	},
	[CONTACT_PEOPLE_KEY]: {
		[ADDED_KEY]: "historyLabels:addedContacts",
		[EDITED_KEY]: "historyLabels:editedContacts",
		[DELETED_KEY]: "historyLabels:deletedContacts",
	},
}
