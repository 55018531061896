import { isLocalhost } from "Configs/config"
import moment from "moment-timezone"
import { timezoneStore } from "./hooks/useConfig"

export type ExportType = "csv" | "excel" | "zip"

export const fileExtensionForType = (type: ExportType) => {
	switch (type) {
		case "csv":
			return "csv"
		case "excel":
			return "xlsx"
		case "zip":
			return "zip"
		default:
			throw new Error("Invalid type")
	}
}

const ENTITY_FILE_NAME = {
	customers: "exportLabels:customersFileName",
	events: "exportLabels:eventsFileName",
}

export const downloadExportFile = async ({
	type,
	entity,
	queryParams,
	terminalId,
	getTokenSilently,
	language,
	t,
	showPeriodInTitle,
	selectedEventIds,
}: {
	type: ExportType
	entity: "customers" | "events"
	queryParams: { [key: string]: any }
	terminalId?: string
	getTokenSilently: () => Promise<string | undefined>
	language: string
	t: (key: string) => string
	showPeriodInTitle?: boolean
	selectedEventIds?: string[]
}) => {
	const { timezone } = timezoneStore.getState()
	const serverPort = process.env.REACT_APP_SERVER_PORT ?? "4000"
	const host = isLocalhost ? `http://localhost:${serverPort}` : ""
	const token = await getTokenSilently()

	const response = await fetch(`${host}/api/files/${entity}/${type}`, {
		method: "POST",
		headers: {
			language,
			authorization: token as string,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ ...queryParams, terminalId, selectedEventIds }),
	})

	if (!response.ok) {
		throw new Error("Network response was not ok")
	}

	const blob = await response.blob()
	const url = window.URL.createObjectURL(blob)

	let filename = ""
	const entityFileName = ENTITY_FILE_NAME[entity]
	if (showPeriodInTitle) {
		const { startTime, endTime } = queryParams

		const startDate = moment(startTime).tz(timezone).format("YYYYMMDD")

		const endDateTimestamp = endTime || moment().tz(timezone).toISOString()
		const endDate = moment(endDateTimestamp).tz(timezone).format("YYYYMMDD")
		filename = `${t(entityFileName)}_${startDate}-${endDate}.${fileExtensionForType(type)}`
	} else {
		filename = `${t(entityFileName)}_${moment().tz(timezone).format("YYYYMMDD")}.${fileExtensionForType(type)}`
	}

	const a = document.createElement("a")
	a.href = url
	a.download = filename
	document.body.appendChild(a)
	a.click()
	a.remove()
	window.URL.revokeObjectURL(url)
}
