import { useModal } from "Contexts"
import { Button } from "components/button"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { CardInfo } from "components/pageCards/cardInfo"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { FC } from "react"
import { useTrans } from "translations"
import { MultiListDrawer } from "../../multiListDrawer"
import { PreFilteringCard } from "./drawerCards/PreFilteringCard"
import { trpc } from "Utils/trpc"
import { InvalidDeletePointModal } from "pages/infrastructure/modals/invalidDeletePointModal"
import { DeletePointModal } from "pages/infrastructure/modals/deletePointModal"
import { useGlobalAlert } from "States/globalAlert"
import { useNavigate } from "react-router"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useTerminalsState } from "States/Terminals"
import { EditDepotModal } from "pages/infrastructure/modals/editDepotModal"

export const DepotDrawer: FC<{
	accessParentId: string
	depotId: string
}> = ({ accessParentId, depotId }) => {
	const { t } = useTrans()
	const { showModal } = useModal()

	const {
		currentTerminal: { id: terminalId },
	} = useTerminalsState()

	const { isLoading, isError, getDepot, refetch } = useAccessPoints({ accessParentId })
	const depot = getDepot(depotId)

	const { data: customers } = trpc.customers.getAll.useQuery({ pointIds: [depotId], limit: 1 })

	const navigate = useNavigate()
	const { setGlobalAlert } = useGlobalAlert()

	const openEditModal = () => {
		if (!depot) return
		showModal(
			<EditDepotModal
				depot={depot}
				onSuccess={() => {
					refetch()
				}}
			/>
		)
	}

	const openDeletionModal = () => {
		if (customers?.items.length || depot?.containers?.length) {
			showModal(
				<InvalidDeletePointModal
					{...{
						modalTitle: "actions:deleteGroup",
						invalidDeleteHint: "hints:deleteGroupWarning",
					}}
				/>
			)
		} else {
			showModal(
				<DeletePointModal
					{...{
						accessParentId,
						pointId: depot?.id || "",
						pointName: depot?.name,
						modalTitle: "actions:deleteGroup",
						onSuccess: () => {
							setGlobalAlert({
								type: "success",
								message: "systemMessages:groupDeleted",
							})

							const url = getInfrastructureUrl({
								terminalId,
								accessParentId,
							})
							navigate(url)
						},
					}}
				/>
			)
		}
	}

	return (
		<MultiListDrawer {...{ isLoading, isError }}>
			{depot?.name && (
				<h4 className="font-signifier text-3xl font-light text-black">{depot?.name}</h4>
			)}
			<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:groupDetails">
				<div className="flex-col gap-4">
					<CardInfo heading={t("importLabels:depotName")} value={depot?.name} />
					<CardInfo heading={t("formLabels:status")} value={t(`status:${depot?.status}`)} />
					<CardInfo heading={t("drawerLabels:carrotAccessParentID")} value={depot?.id} />
				</div>
				<Button
					label={"actions:edit"}
					onClick={openEditModal}
					color="secondary"
					className="float-right mt-10"
				/>
			</Card>
			<PreFilteringCard
				entityKey="depot"
				eventFilter={encodeURIComponent(JSON.stringify({ depotId }))}
			/>
			<Card title={"drawerLabels:admin"} variant={CardComponentVariant.DRAWER}>
				<div className="mb-6 text-sm">{t("hints:deleteGroupWarning")}</div>
				<Button
					label={"actions:deleteGroup"}
					onClick={openDeletionModal}
					color="black"
					className="float-right"
				/>
			</Card>
		</MultiListDrawer>
	)
}
