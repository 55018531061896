import React, { useEffect, useMemo, useState } from "react"
import { useTrans } from "translations"
import { IImportContext, ImportContext } from "./ImportContext"
import { ImportStep, Template } from "./types"
import { MassImportButtons } from "./components/MassImportButtons"
import { StepViewer } from "./components/StepViewer"
import { useImportState } from "./importState"
import { useCommonEntitiesStore } from "States/commonEntities"
import { HelpSection } from "./HelpSection"

type Props = {
	steps: ImportStep[]
	templates: Template[]
	addToSystem: (context: IImportContext) => void
	onCancel: () => void
}

export const ImportView: React.FC<Props> = ({ steps, templates, addToSystem, onCancel }: Props) => {
	const { t } = useTrans()
	const [activeStep, setActiveStep] = useState<ImportStep>(steps[0])
	const importState = useImportState()
	const { helpSections } = useCommonEntitiesStore()

	const stepKeys = useMemo(() => steps.map(step => step.key), [steps])

	const title = useMemo(
		() => <div className="text-2xl font-signifier">{t(`importLabels:${activeStep.key}`)}</div>,
		[activeStep, t]
	)

	useEffect(() => {
		// reset on opening import view
		importState.fullReset()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [steps])

	const description = useMemo(() => {
		if (activeStep.hideDescription) {
			return null
		}

		if (helpSections) {
			return <HelpSection activeStep={activeStep} helpSections={helpSections} />
		}
	}, [activeStep, helpSections])

	const activeStepView = useMemo(() => activeStep.view(), [activeStep])

	return (
		<ImportContext.Provider
			value={{
				steps,
				activeStep,
				setActiveStep,
				templates,
				addToSystem,
				onCancel,
				...importState,
			}}
		>
			<div className="flex w-full flex-col">
				<div className="px-5 pt-4 pb-3 relative w-full h-fit flex-row items-baseline gap-4 bg-white">
					<StepViewer stepKeys={stepKeys} activeStepKey={activeStep.key} />
				</div>
				<div
					className="w-full bg-white px-5 pt-8 pb-2 relative mt-[2px] overflow-scroll"
					style={{ height: "calc(100% - 100px)" }}
				>
					{title}
					{description}
					{activeStepView}
				</div>
				{!activeStep.hideButtons && <MassImportButtons />}
			</div>
		</ImportContext.Provider>
	)
}
