import { DocumentNode } from "@apollo/client"
import { postFunc } from "../Utils/api"
import { DELETE_CUSTOMER } from "./mutations/deleteCustomer"

export const deleteCustomer = ({
	id,
	refetchQuery,
	onStartCallBack,
	onFailCallBack,
}: {
	id: string
	refetchQuery?: DocumentNode
	onStartCallBack?: () => void
	onFailCallBack?: () => void
}) =>
	postFunc(
		DELETE_CUSTOMER,
		() => {
			onStartCallBack && onStartCallBack()
		},
		{ variables: { Id: id }, refetchQueries: refetchQuery ? [{ query: refetchQuery }] : [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
