import React from "react"

export const ArrowUp: React.FC<{ className?: string }> = ({ className }) => (
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" className={className}>
		<path
			d="M6.49848 3.91422V10H5.49848V3.91422L2.81648 6.5962L2.10938 5.8891L5.99848 2L9.88754 5.8891L9.18044 6.5962L6.49848 3.91422Z"
			fill="currentColor"
		/>
	</svg>
)

export default ArrowUp
