import { Card, CardComponentVariant } from "components/pageCards/card"
import React from "react"
import { useTrans } from "translations"

type Props = {
	fillLevel?: string
}

export const ContainerFillLevel: React.FC<Props> = ({ fillLevel }) => {
	const { t } = useTrans()

	const fillPercentage = fillLevel ? parseInt(fillLevel) : 0

	return (
		<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:fillLevel">
			<div className="mb-4 font-dmSans text-sm">{t("hints:fillLevelDescription")}</div>
			<div className="w-full relative pb-12">
				<div className="h-9 w-full bg-grey1">
					<div
						className="h-9 absolute bg-grey3 flex items-center"
						style={{ width: `${fillPercentage}%` }}
					>
						{fillPercentage > 0 && <div className="absolute right-2">{fillPercentage}%</div>}
					</div>
				</div>
				<div className="absolute left-0">0%</div>
				<div className="absolute right-0">100%</div>
			</div>
		</Card>
	)
}
