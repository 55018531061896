import latinize from "latinize"
import { snakeCase, toUpper } from "lodash"
import { nanoid } from "nanoid"
import { v4 } from "uuid"

export type IUIDGeneratorTypes = "nanoid" | "uuid"

export const uniqueIdGenerator = (type: "nanoid" | "uuid", length?: number) => {
	if (type === "nanoid") return nanoid(length)
	if (type === "uuid") return v4().toUpperCase()
}

export const terminalIdGenerator = (name: string) => latinize(toUpper(snakeCase(name)))
