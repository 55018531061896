import jwtDecode, { JwtPayload } from "jwt-decode"

interface DecodedToken extends JwtPayload {
	"https://auth0.carrot.tech/email": string
	"https://auth0.carrot.tech/name": string
}

export function getTokenData(): DecodedToken | undefined {
	let cookieToken = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1")
	if (cookieToken) {
		return jwtDecode(cookieToken) as DecodedToken
	}
	return undefined
}
