import { useApiStore } from "../../States/api"
import { ReactQueryCacheKey, RequestOptionsWithInit } from "./types"

/**
 * General http requests function returning json
 * @description Uses fetch as a base and react query as a wrapper.
 * */
export const request = async <Response>(
	cacheKey: ReactQueryCacheKey[],
	input: RequestInfo,
	{ init, reactQueryOptions, reactQueryRefetch }: RequestOptionsWithInit<Response>
) => {
	const { reactQueryClient } = useApiStore.getState()

	if (!reactQueryClient) {
		throw new Error("Could not connect to api client")
	}

	const isGetRequest = typeof input === "object" && (!("method" in input) || input.method === "get")
	const preparedInput = isGetRequest ? { ...input, method: "get" } : input

	const response = await reactQueryClient.fetchQuery(
		cacheKey,
		() => fetchRequest<Response>(preparedInput, init),
		reactQueryOptions
	)

	if (reactQueryRefetch) {
		// Clears cache of specific query key, refreshes places where useQuery is in view or next time
		// visiting a view with useQuery.
		reactQueryClient.invalidateQueries(reactQueryRefetch.queryKey)
	}

	return response
}

/** Fetch function with error handling */
export const fetchRequest = async <Response>(input: RequestInfo, init?: RequestInit) => {
	const response = await fetch(input, init)

	if (!response.ok) {
		const bodyErrorText = await response.text()
		throw new Error(`Status: ${response.status}, Body: ${bodyErrorText}`)
	}

	return response.json() as Response
}
