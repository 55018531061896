import { DropdownArrowIcon } from "Icons/DropdownArrow"
import { Card, CardComponentFilterLocation, ICardComponentProps } from "../card"
import { FilterSorts } from "../filterSort/FilterSorts"
import { DataList, DataListProps } from "./DataList"
import { ComponentProps, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTrans } from "translations"
import { twMerge } from "tailwind-merge"
import InfoPopup from "components/InfoPopup/InfoPopup"
import { AddButton } from "components/AddButton"

export type LinkWithListCardProps<DataType extends Record<string, any>[] = Record<string, any>[]> =
	{
		linkHeaderTitle: string
		listHeaderTitle: string
		linkHeaderTooltipHint?: string
		listHeaderTooltipHint?: string
		linkButtonLabel: string
		onLinkButtonClick?: () => void
		listButton?: ComponentProps<typeof AddButton>
	} & DataListProps<DataType> &
		ICardComponentProps

export const LinkWithListCard: React.FC<LinkWithListCardProps> = ({
	filters,
	linkHeaderTitle,
	listHeaderTitle,
	linkHeaderTooltipHint,
	listHeaderTooltipHint,
	linkButtonLabel,
	onLinkButtonClick,
	listButton,
	...props
}) => {
	const { t } = useTrans()

	const containerRef = useRef<HTMLDivElement | null>(null)
	const [windowSize, setWindowSize] = useState([0, 0])

	useLayoutEffect(() => {
		function onResize() {
			setWindowSize([window.innerWidth, window.innerHeight])
		}
		window.addEventListener("resize", onResize)
		onResize()
		return () => window.removeEventListener("resize", onResize)
	}, [])

	const containerHeight = useMemo(() => {
		const { height = 0 } = containerRef.current?.getBoundingClientRect() || {}
		return height
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerRef, windowSize])

	return (
		<Card {...props} hasXContentPadding={false} hasYContentPadding={false} overflow="auto">
			<div ref={containerRef} className="px-4 pt-4">
				<HeaderWithTooltip title={linkHeaderTitle} tooltipHint={linkHeaderTooltipHint} />
				<button onClick={onLinkButtonClick} className="w-full">
					<div className="flex w-full justify-between items-center gap-6 bg-grey2 px-6 py-4">
						<p className="whitespace-nowrap font-bold">{t(linkButtonLabel)}</p>
						<div className="w-2.5">
							<DropdownArrowIcon className="h-2.5 w-2.5 -rotate-90" />
						</div>
					</div>
				</button>

				<HeaderWithTooltip
					title={listHeaderTitle}
					tooltipHint={listHeaderTooltipHint}
					className="mt-10"
				/>
				<div className="flex justify-between items-center pb-1">
					{filters && (
						<FilterSorts filterSorts={filters} filterLocation={CardComponentFilterLocation.LEFT} />
					)}
					{listButton && <AddButton {...listButton} />}
				</div>
			</div>

			<DataList {...props} heightOffset={containerHeight} />
		</Card>
	)
}

type HeaderWithTooltipProps = {
	title: string
	tooltipHint?: string
	className?: string
}

const HeaderWithTooltip: React.FC<HeaderWithTooltipProps> = ({ title, tooltipHint, className }) => {
	const { t } = useTrans()
	return (
		<div className={twMerge("flex items-center mb-4", className)}>
			<h3 className="text-xl">{t(title)}</h3>
			{tooltipHint && (
				<InfoPopup id={title} text={t(tooltipHint)} width="350px" size={13} className="ml-1" />
			)}
		</div>
	)
}
