import { AddButton } from "components/AddButton"
import { CardComponentFilterLocation } from "components/pageCards/card"
import { FilterSort } from "components/pageCards/filterSort/types"
import { ContainerDrawer } from "components/pageCards/multiListTable/drawers/lfm/containerDrawer"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { ContainerInfo } from "components/pageCards/multiListTable/multiSelectContext"
import { TOptions } from "i18next"
import { WasteTypeConfig } from "pages/configuration/useConfigService"
import { AccessParentContainerData } from "pages/infrastructure/functions"

type Props = {
	addContainer: () => void
	isConfigLoading: boolean
	containers: AccessParentContainerData[]
	isLoadingAccessParentChildren: boolean
	selectedContainers: ContainerInfo[]
	wasteTypeConfig: WasteTypeConfig[]
	t: (key: string, options?: TOptions | undefined) => string
	onRowClick: (container: AccessParentContainerData) => void
	onMultipleSelect: (selectedContainers: ContainerInfo[]) => void
	accessParentId: string
	containerId: string
	selectedDepotId: string | undefined
	filters: FilterSort[]
	setContainersFilterState: (activeOptions: any) => void
}

export default function getContainerSection({
	addContainer,
	isConfigLoading,
	containers,
	isLoadingAccessParentChildren,
	selectedContainers,
	wasteTypeConfig,
	t,
	onRowClick,
	onMultipleSelect,
	accessParentId,
	containerId,
	selectedDepotId,
	filters,
	setContainersFilterState,
}: Props): MultiListSectionConfig<typeof containers> {
	return {
		type: "list",
		title: "entities:containers",
		customButton: <AddButton onClick={addContainer} disabled={isConfigLoading} />,
		data: containers,
		isLoading: isLoadingAccessParentChildren,
		getInitiallySelectedRows: entries =>
			entries.filter(({ id }: any) => selectedContainers.find(el => el.id === id)),
		entryRender: ({ wasteCode, wasteType, name, status, fillLevel }) => {
			const wasteTypeName = wasteType || wasteCode
			return {
				labelTop: name || wasteTypeName,
				labelBottom: `${
					wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteCode)?.name || wasteType
				} (${wasteCode})`,
				labelTopRight: fillLevel ? `${t("drawerLabels:fullness")}: ${fillLevel}%` : "",
				labelBottomRight: `${t("formLabels:status")}: ${t("status:" + status)}`,
			}
		},
		onRowClick,
		drawer: {
			content: <ContainerDrawer {...{ accessParentId, containerId, depotId: selectedDepotId }} />,
		},
		emptyViewType: "noContainers",
		withMultiSelect: true,
		onMultipleSelect,
		labelSingle: "entities:container",
		labelMultiple: "entities:containers",
		filters,
		onFilterOptionChange: setContainersFilterState,
		filterLocation: CardComponentFilterLocation.INSIDE,
		collapsed: true,
	}
}
