import { useModal } from "Contexts"
import { useGlobalAlert } from "States/globalAlert"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import ModalContainer from "components/modalContainer"
import { MWMCustomerFormValues } from "../../types"
import { useForm } from "react-hook-form"
import { useCallback, useMemo, useRef, useState } from "react"
import useCustomers from "api/hooks/useCustomers"
import ErrorNotice from "components/ErrorNotice"
import { isEmpty } from "lodash"
import { CreateCustomerForm } from "./CustomerForm"
import { useNavigate } from "react-router-dom"

export const CreateCustomerModal = () => {
	const navigate = useNavigate()
	const { hideModal } = useModal()
	const { sendNewCustomerSlackNotification } = useSlackNotifications()
	const { setGlobalAlert } = useGlobalAlert()
	const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)
	const { refetchCustomers, createCustomer, isSavingCustomer, isLoadingCustomers, isError } =
		useCustomers()
	const containerRef = useRef<HTMLDivElement>(null)

	const formHandler = useForm<MWMCustomerFormValues>({
		defaultValues: { accessParents: [{}] },
	})

	const {
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = formHandler

	const setupCustomer = useCallback(
		(formData: MWMCustomerFormValues) => {
			const { accessParents, ...customer } = formData
			const accessParentIds = accessParents?.filter(el => el.id).map(el => ({ id: el.id })) ?? [] // Filter out empty accessParents

			createCustomer(
				{ customer: { ...customer, accessParents: accessParentIds } },
				{
					onSuccess: async createdCustomer => {
						await refetchCustomers()
						hideModal()
						setGlobalAlert({
							type: "success",
							message: "systemMessages:customerAdded",
						})
						sendNewCustomerSlackNotification(createdCustomer.name, createdCustomer.id)
						navigate(`/customer/manage/${createdCustomer.id}`)
					},
					onError: () => {
						setGlobalAlert({
							type: "error",
							message: "errors:failedSave",
						})
					},
				}
			)
		},
		[
			createCustomer,
			hideModal,
			refetchCustomers,
			setGlobalAlert,
			sendNewCustomerSlackNotification,
			navigate,
		]
	)

	const onSubmit = handleSubmit(customerWithAccessParents => {
		setupCustomer(customerWithAccessParents)
	})
	const submitDisabled = useMemo(
		() => isError || (isSubmitAttempted && (!isEmpty(errors) || !isValid)),
		[isError, isSubmitAttempted, errors, isValid]
	)

	return (
		<ModalContainer
			title="actions:createCustomer"
			onConfirm={() => {
				setIsSubmitAttempted(true)
				onSubmit()
			}}
			onCancel={hideModal}
			onConfirmLoading={isSavingCustomer || isLoadingCustomers}
			onConfirmDisabled={submitDisabled}
			className="w-11/12 lg:w-3/5"
			contentContainerRef={containerRef}
			showDiscardModal={isDirty}
		>
			{isError ? (
				<ErrorNotice />
			) : (
				<div className="flex-col gap-6 h-full">
					<CreateCustomerForm formHandler={formHandler} />
				</div>
			)}
		</ModalContainer>
	)
}
