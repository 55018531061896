import { FetchedHelpSections } from "Utils/api/sanity/types"
import { mapImportSection } from "Utils/helpSections"
import { useConfig } from "api/hooks/useConfig"
import { ImportStep } from "./types"
import Collapsible from "react-collapsible"
import { PortableText, PortableTextProps } from "@portabletext/react"
import React from "react"
import { ArrowIcon } from "Icons/Arrow"
import classNames from "classnames"

const Trigger: React.FC<{ title: string; open?: boolean }> = ({ title, open = false }) => {
	return (
		<div className="flex justify-between text-grey6">
			{title}{" "}
			<div className={classNames("m-2 transform text-grey6", open && "rotate-180")}>
				<ArrowIcon />
			</div>
		</div>
	)
}

const Content: React.FC<PortableTextProps> = props => {
	return (
		<PortableText
			{...props}
			components={{
				marks: {
					link: ({ children, value }) => {
						return (
							<a href={value.href} className="underline">
								{children}
							</a>
						)
					},
				},
				list: {
					bullet: ({ children }) => {
						return <ul className="list-disc list-inside ml-4">{children}</ul>
					},
					number: ({ children }) => {
						return <ol className="list-decimal list-inside ml-4">{children}</ol>
					},
				},
			}}
		/>
	)
}

export const HelpSection: React.FC<{
	activeStep: ImportStep
	helpSections: FetchedHelpSections | null
}> = ({ activeStep, helpSections }) => {
	const { isMWM, config } = useConfig()

	if (!helpSections) return null

	const doc = helpSections.find(section => {
		return (
			mapImportSection({
				isMWM,
				section,
				useWasteSuctionSystem: config.useWasteSuctionSystem,
			}) === activeStep.helpSectionKey
		)
	})

	if (doc) {
		return (
			<div className="w-2/3 my-4 mb-12">
				{doc.description?.length && (
					<div className="my-4">
						<Content value={doc.description} />
					</div>
				)}
				{doc.sections?.map((section, index) => (
					<Collapsible
						className="border-b border-grey3 py-2"
						openedClassName="pt-2"
						key={index}
						trigger={<Trigger title={section.title} />}
						triggerWhenOpen={<Trigger open title={section.title} />}
					>
						<div className="py-2 border-b border-grey3">
							<Content value={section.content} />
						</div>
						{section.subSections?.map((subSection, subIndex) => (
							<Collapsible
								className="ml-4 border-b border-grey3 py-2"
								openedClassName="ml-4 pt-2"
								key={subIndex}
								trigger={<Trigger title={subSection.title} />}
								triggerWhenOpen={<Trigger open title={subSection.title} />}
							>
								<div className="py-2 border-b border-grey3">
									<Content value={subSection.content} />
								</div>
							</Collapsible>
						))}
					</Collapsible>
				))}
			</div>
		)
	}

	return <></>
}
