import { RealEstateCategory, VALID_BUILDING_CATEGORIES } from "api/types"
import FormInput from "components/Form/FormInput"
import SearchInput from "components/Form/SearchInput"
import { IInputOption } from "components/GenericComponents/input"
import { FC, useMemo } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { EMAIL_REGEX } from "Utils/isValidEmail"
import { NUMBER_REGEX } from "Utils/isValidNumber"

export type BuildingFormData = {
	name: string
	category: RealEstateCategory
	contactEmail: string
	area: string
}

interface TerminalFormProps {
	formHandler: UseFormReturn<BuildingFormData>
}

export const BuildingForm: FC<TerminalFormProps> = ({ formHandler }) => {
	const { t } = useTrans()
	const {
		register,
		control,
		setValue,
		formState: { errors },
	} = formHandler

	const buildingCategoryInputOptions: IInputOption[] = useMemo(
		() =>
			VALID_BUILDING_CATEGORIES.map(key => ({
				option: `entities:${key}`,
				value: key,
				translate: true,
			})),
		[]
	)

	return (
		<form>
			<div className="mb-6">
				<FormInput
					name="name"
					label={t("importLabels:terminalName")}
					register={register}
					error={errors?.name}
					required
					showErrorInTooltip={false}
				/>
			</div>
			<div className="mb-6">
				<SearchInput
					label={t("formLabels:buildingCategory")}
					placeholder={t("actions:selectProperty", {
						property: t("formLabels:category").toLowerCase(),
					})}
					required
					name="category"
					data={buildingCategoryInputOptions}
					initialValue={formHandler.getValues("category")}
					noInitialOption={!formHandler.getValues("category")}
					control={control}
					setValue={setValue}
					rules={{ required: true, minLength: 1 }}
					errorObj={errors.category}
					register={register}
				/>
			</div>
			<div className="mb-6">
				<FormInput
					name="contactEmail"
					label={t("formLabels:contactEmail")}
					register={register}
					error={errors?.contactEmail}
					regexPattern={EMAIL_REGEX}
					required
					patternError="errors:mustBeValidEmail"
					showErrorInTooltip={false}
				/>
			</div>
			<div className="mb-6">
				<FormInput
					name="area"
					label={t("formLabels:area")}
					register={register}
					error={errors?.area}
					regexPattern={NUMBER_REGEX}
					patternError="errors:mustBeValidNumber"
					showErrorInTooltip={false}
				/>
			</div>
		</form>
	)
}
