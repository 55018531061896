import { WasteTypeHandling } from "admin-client-server/src/coreApi/models/RealEstate"
import { updatePoint } from "api/mutations/updatePoint"
import { Input } from "components/GenericComponents/input"
import ModalContainer from "components/modalContainer"
import { Option } from "components/pageCards/filterSort/types"
import { useModal } from "Contexts"
import { DownstreamInterface } from "pages/circularity/downstream/downstream.interface"
import { useMemo, useRef, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"

export const DownstreamHandlingModal = ({
	onSave,
	data,
}: {
	onSave: () => void
	data: DownstreamInterface[]
}) => {
	const { hideModal } = useModal()
	const { t } = useTrans()
	const { downstreamHandling: downstreamHandlingOption } = useCommonEntitiesStore()

	const { currentTerminal } = useTerminalsState()
	const modalContainerRef = useRef<HTMLDivElement | null>(null)
	const [input, setInput] = useState<{ [key: string]: WasteTypeHandling }>(
		data.reduce(
			(acc, d) => ({ ...acc, [d.wasteCode]: { code: d.wasteCode, method: d.method } }),
			{}
		)
	)

	const handlingOptions: Option[] = useMemo(
		() =>
			downstreamHandlingOption
				? downstreamHandlingOption.map(({ name, id }) => ({
						option: name,
						value: id,
				  }))
				: [],
		[downstreamHandlingOption]
	)

	const onConfirm = () => {
		updatePoint(
			{
				accessPoint: {
					id: currentTerminal.id,
				},
				propertiesUpdate: {
					mode: "MERGE",
					props: [
						{ key: "downstreamHandlingJSON", value: JSON.stringify(Object.values(input) ?? []) },
					],
				},
			},
			() => {
				onSave()
				hideModal()
			}
		)
	}

	return (
		<ModalContainer
			title="genericLabels:setupWasteTypeHandling"
			onConfirm={onConfirm}
			onCancel={hideModal}
			className="max-h-full w-[800px]"
			contentContainerRef={modalContainerRef}
		>
			<div className="min-w-[600px]">
				<p className="text-xs">{t("hints:downstreamHandlingHint1")}</p>
				<p className="text-xs">{t("hints:downstreamHandlingHint2")}</p>
				<div className="mt-6 flex flex-col gap-y-1 pl-4 pr-7">
					<div className="flex justify-between gap-3">
						<div className="text-sm font-medium">{t("formLabels:wasteTypeWithCode")}</div>
						<div className="w-[284px] text-sm font-medium">
							{t("circularityLabels:handlingMethod")}
						</div>
					</div>

					{!!handlingOptions.length &&
						data.map(({ method, wasteCode, wasteType }, index) => {
							return (
								<div
									className="flex h-12 w-full items-center justify-between gap-3"
									key={`DownstreamModalCol1_entry_${index}`}
								>
									<div className="w-auto">{`${wasteType} (${wasteCode})`}</div>
									<div className="w-[284px]">
										<Input
											data={handlingOptions}
											dropdownIcon
											initialValue={method ? method : undefined}
											noInitialOption={!method}
											onChange={opt =>
												setInput({ ...input, [wasteCode]: { code: wasteCode, method: opt.value } })
											}
											placeholder={t("actions:addHandling")}
											parentElementRef={modalContainerRef}
										/>
									</div>
								</div>
							)
						})}
				</div>
			</div>
		</ModalContainer>
	)
}
