import classNames from "classnames"
import { UseFormReturn, useFieldArray } from "react-hook-form"
import { GridFormCell } from "./cell"
import { Button } from "components/button"
import { useTrans } from "translations"
import Magnifier from "Icons/Magnifier"
import { useCallback, useEffect, useMemo, useState } from "react"
import { get, merge } from "lodash"
import { Autocomplete, AutocompleteOption, AutocompleteValue, FormColumn } from "./types"
import InfoPopup from "components/InfoPopup/InfoPopup"
import { RemoveButton } from "components/RemoveButton"
export { FormFieldType } from "./types"
export type { FormColumn } from "./types"

type Props<T> = {
	columns: FormColumn[]
	enabled?: boolean
	enableDeleteColumn?: boolean
	enableAddRowButton?: boolean
	allowDeletingLastRow?: boolean
	formHandler: UseFormReturn<any>
	searchable?: boolean
	autocomplete?: Autocomplete<T>
	emptyMessage?: string
	withColSpacing?: boolean
	selectInputMenuPortalTarget?: HTMLElement | null
}

function createDefaultObject(columns: FormColumn[]): Record<string, string> {
	const obj: Record<string, string> = {}
	columns.forEach(column => {
		obj[column.name] = column.defaultValue ?? ""
	})
	return obj
}

export function GridForm<T extends { [key: string]: string }[]>({
	autocomplete,
	columns,
	enabled = true,
	formHandler,
	enableDeleteColumn = false,
	enableAddRowButton = false,
	allowDeletingLastRow = true,
	searchable = false,
	withColSpacing = false,
	emptyMessage,
	selectInputMenuPortalTarget,
}: Props<T>) {
	const { t } = useTrans()
	const {
		control,
		getValues,
		formState: { defaultValues },
		trigger,
		watch,
	} = formHandler
	const [search, setSearch] = useState("")
	const [autocompleteField, setAutocompleteField] = useState<string | undefined>(undefined)
	const [autocompleteOption, setAutoCompleteOption] = useState<AutocompleteOption | undefined>(
		undefined
	)

	const formRootName = useMemo(() => Object.keys(defaultValues ?? {})[0], [defaultValues])

	const { fields, remove, append } = useFieldArray({
		control,
		name: formRootName,
		keyName: "gridFormUUID",
	})

	const { results: autocompleteResults } = autocomplete ?? {}

	useEffect(() => {
		if (autocompleteResults && autocompleteField) {
			setAutoCompleteOption({
				key: autocompleteField,
				values: autocompleteResults,
			})
		}
	}, [autocompleteField, autocompleteResults])

	useEffect(() => {
		if (autocomplete) {
			const watchFields = watch((values, { name, type }) => {
				if (type === "blur") {
					autocomplete.onType(undefined)
					return
				}
				const fieldName = name?.split(".")?.slice(-1)[0]
				if (fieldName) {
					setAutocompleteField(name)
					autocomplete.onType(fieldName, get(values, name))
				} else {
					setAutocompleteField(undefined)
				}
			})
			return () => watchFields.unsubscribe()
		}
	}, [autocomplete, watch])

	// Trigger validation on all fields if fields with uniqueness constraint changes
	// to update any duplicate errors in other fields
	useEffect(() => {
		const uniquenessColumns = columns.filter(column => column.unique).map(column => column.name)
		if (uniquenessColumns) {
			const watchFields = watch((_, { name, type }) => {
				const colName = name?.split(".")[2]
				if (!colName) {
					return
				}
				if (uniquenessColumns.includes(colName)) {
					trigger()
				}
			})
			return () => watchFields.unsubscribe()
		}
	}, [columns, watch, trigger])

	const rows = useMemo(
		() =>
			fields.filter(row => {
				return Object.values(row).some(value => {
					return value?.toLowerCase().includes(search?.toLowerCase())
				})
			}),
		[fields, search]
	)

	// Create an empty object with the same keys as the columns.
	// This is used to append an empty row to the form if the user has deleted default values previously.
	const defaultObject = useMemo(() => createDefaultObject(columns), [columns])

	const addRow = () => append(defaultObject, { shouldFocus: false })

	const onAutocompleteOptionSelected = useCallback(
		(row: number, option: AutocompleteValue) => {
			const values = getValues()[formRootName][row]
			formHandler.setValue(`${formRootName}.${row}`, merge(values, option))
			setAutoCompleteOption(undefined)
			trigger()
		},
		[formHandler, formRootName, getValues, trigger]
	)

	return (
		<>
			{searchable && (
				<div className="relative w-full">
					<p className="text-sm font-bold mb-1">{t("hints:searchForAccessTokens")}</p>
					<Magnifier className="absolute mt-2.5 ml-2 text-grey6" width={12} height={12} />
					<input
						onChange={e => setSearch(e.target.value)}
						type="text"
						placeholder={t("genericLabels:searchbarPlaceholder")}
						className={classNames(
							"rounded-none h-8 w-64 py-[7.5px] box-border border border-black pl-6 pr-3 font-dmSans text-sm placeholder:text-grey6 disabled:border-grey3 disabled:text-grey6",
							"focus:outline focus:outline-2 focus:outline-offset-[-2px]"
						)}
					/>
				</div>
			)}
			{enableAddRowButton && (
				<Button
					label="actions:addRow"
					onClick={addRow}
					type="button"
					color="secondary"
					className="float-right"
				/>
			)}
			<table className="w-full table-auto border-separate border-spacing-y-3 min-h-[150px]">
				<thead>
					<tr>
						{columns.map(
							({ label, required, headerTooltipText, headerTooltipComponent }, colIndex) => (
								<th
									align="left"
									key={colIndex}
									style={{ width: `${(enableDeleteColumn ? 95 : 100) / columns.length}%` }}
								>
									<span
										className={classNames(
											colIndex === 0 ? "mr-1" : "m-1",
											withColSpacing && colIndex > 0 && "pl-8",
											"font-dmSans text-sm font-medium text-black empty:hidden"
										)}
									>
										{label} {required && "*"}
										{headerTooltipText && (
											<InfoPopup
												id={label}
												text={headerTooltipText}
												width="250px"
												size={14}
												className="inline-flex mb-1"
											/>
										)}
										{!headerTooltipText && headerTooltipComponent && (
											<InfoPopup id={label} size={14} width="250px" className="inline-flex mb-1">
												{headerTooltipComponent}
											</InfoPopup>
										)}
									</span>
								</th>
							)
						)}
						{enableDeleteColumn && <th></th>}
					</tr>
				</thead>
				<tbody>
					{!fields.length && emptyMessage && (
						<tr>
							<td
								colSpan={enableDeleteColumn ? columns.length + 1 : columns.length}
								className="border-t border-grey3"
							>
								<p className="text-center bg-grey1 py-8 text-sm">{emptyMessage}</p>
							</td>
						</tr>
					)}
					{fields
						.map((row: T[number], rowIndex) => (
							<tr key={row.id} className={classNames(!rows.includes(row as any) && "hidden")}>
								{columns.map((column, colIndex) => (
									<td
										key={colIndex}
										className={classNames(
											withColSpacing && colIndex > 0 && "pl-8",
											"border-t border-grey3 pt-3"
										)}
									>
										<GridFormCell
											enabled={enabled}
											colIndex={colIndex}
											column={column}
											formHandler={formHandler}
											rowIndex={rowIndex}
											value={getValues()[formRootName][rowIndex][column.name]}
											regexPattern={column.regexPattern}
											required={column.required}
											patternError={column.patternError}
											validationFunction={column.validationFunction}
											autocompleteOptions={
												autocompleteOption?.key === `${formRootName}.${rowIndex}.${column.name}`
													? autocompleteOption.values
													: undefined
											}
											onAutocompleteOptionSelected={onAutocompleteOptionSelected}
											selectInputMenuPortalTarget={selectInputMenuPortalTarget}
										/>
									</td>
								))}
								{enableDeleteColumn && (
									<td className="border-t border-gray pt-3" style={{ width: "5%" }}>
										<RemoveButton
											onClick={() => remove(rowIndex)}
											type="button"
											disabled={fields.length === 1 && !allowDeletingLastRow}
											className="ml-2"
										/>
									</td>
								)}
							</tr>
						))
						.slice()
						.reverse()}
				</tbody>
			</table>
		</>
	)
}
