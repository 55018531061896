import { LoadingIndicator } from "Icons/loadingIndicator"
import { FC } from "react"
import { useTrans } from "translations"
import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { CLIPPED_BUTTON_STYLE } from "constants/general"

export const Button: FC<{
	label: string | JSX.Element
	color?: "primary" | "secondary" | "white" | "black"
	className?: string
	wide?: boolean // TODO
	loading?: boolean
	disabled?: boolean
	onClick?: () => any
	type?: "button" | "submit" | "reset"
	link?: string
}> = ({
	color = "primary",
	disabled,
	loading,
	wide,
	label,
	className,
	onClick = () => null,
	type,
	link,
}) => {
	const { t } = useTrans()

	const buttonContent = (
		<button
			className={twMerge([
				"h-10 min-w-[164px] flex-none whitespace-nowrap px-3 text-sm disabled:bg-grey1 disabled:text-grey5",
				color === "primary" && "bg-lime hover:bg-someLimeish active:bg-saturatedLime",
				color === "secondary" && "bg-grey3 hover:bg-grey2 active:bg-grey4",
				color === "white" && "bg-white hover:bg-neutral-100 active:bg-neutral-200",
				color === "black" &&
					"bg-black fill-white stroke-white text-white hover:bg-neutral-900 active:bg-black",
				className,
				CLIPPED_BUTTON_STYLE,
			])}
			onClick={() => !loading && onClick()}
			disabled={disabled}
			type={type}
		>
			{!loading ? (typeof label === "string" ? t(label) : label) : null}

			{loading && (
				<div className="flex justify-center">
					<LoadingIndicator />
				</div>
			)}
		</button>
	)

	return link ? (
		<Link to={link} target="_blank">
			{buttonContent}
		</Link>
	) : (
		buttonContent
	)
}
