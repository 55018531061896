import Magnifier from "Icons/Magnifier"

export type SearchProps = {
	placeholder: string
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function Search({ placeholder, onChange }: SearchProps) {
	return (
		<div className="m-5 mb-0">
			<Magnifier className="absolute mt-2.5 ml-2 text-grey6" width={18} height={18} />
			<input
				type="text"
				onChange={onChange}
				placeholder={placeholder}
				className="h-8 min-w-[300px] border-black h-[40px] pl-8 text-sm leading-10 font-dmSans"
			/>
		</div>
	)
}
