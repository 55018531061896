import React from "react"

export const ArrowDown: React.FC<{ className?: string }> = ({ className }) => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path d="M1.11713 4.11713L6 9L10.8829 4.11713" stroke="currentColor" />
	</svg>
)

export default ArrowDown
