import { FC } from "react"
import { useTrans } from "translations"
import { ContainerBulkMove } from "./drawerCards/ContainerBulkMove"

export const MultipleContainerDrawer: FC = () => {
	const { t } = useTrans()
	return (
		<>
			<h2 className="mb-1 text-3xl">{t("drawerLabels:multipleContainersSelected")}</h2>
			<ContainerBulkMove />
		</>
	)
}
