import { IImportContext } from "components/ImportComponents/ImportContext"
import {
	CONTACT_KEY,
	CUSTOMER_REFERENCE_KEY,
	STATION_KEY,
	CUSTOMER_KEY,
	CUSTOMER_NAME_KEY,
} from "./constants"
import { useApiStore } from "States/api"
import { UPSERT_CUSTOMERS } from "admin-client-server/src/coreApi/queries/Common"
import { uniqueIdGenerator } from "Utils/uniqueIdGenerator"
import {
	CUSTOMER_ADDRESS_KEY,
	CUSTOMER_POSTAL_CODE_KEY,
	CUSTOMER_POSTAL_REGION_KEY,
	EXTERNAL_ID_KEY,
} from "constants/general"
import { ERROR_STATE, FINALIZED_STATE } from "pages/infrastructure/import/addToSystem"
import { FetchedTenantTypes } from "Utils/api/sanity/types"

const CLEANER_PERMISSION_MAPPING: { [k: string]: string } = {
	yes: "true",
	no: "false",
}

type AddToSystemProps = {
	context: IImportContext
	accessParentIds: string[]
	tenantCategories: FetchedTenantTypes | null
	refetchCustomers: () => Promise<any>
	isMWM: boolean
}

const getCustomersToUpload = ({
	context: { validationState },
	accessParentIds,
	tenantCategories,
	isMWM,
}: AddToSystemProps) => {
	const customerPropertyData = validationState[CUSTOMER_KEY]!

	if (isMWM) {
		const customerReferenceRows = customerPropertyData[CUSTOMER_REFERENCE_KEY]!
		const stationsPropertyData = validationState[STATION_KEY]!
		const { customerReference, stationId } = stationsPropertyData
		const { customerType, geoLocationName, postalCode, postal } = customerPropertyData

		return customerReferenceRows.map((referenceCell, i: number) => {
			const stationIds: string[] = customerReference.reduce(
				(acc: any, customerReferenceCell, i) => {
					if (customerReferenceCell.value === referenceCell.value) {
						acc.push(stationId[i].value)
					}

					return acc
				},
				[]
			)

			const props = [
				{ key: CUSTOMER_ADDRESS_KEY, value: geoLocationName?.[i]?.value?.toString() || "" },
				{ key: CUSTOMER_POSTAL_CODE_KEY, value: postalCode?.[i]?.value?.toString() || "" },
				{ key: CUSTOMER_POSTAL_REGION_KEY, value: postal?.[i]?.value?.toString() || "" },
			]

			const customer = {
				id: uniqueIdGenerator("nanoid", 5),
				externalKeyName: EXTERNAL_ID_KEY,
				updateFields: {
					name: referenceCell.value,
					customerType: customerType[i].value.toUpperCase(),
				},
				propertiesUpdate: {
					mode: "SET",
					props,
				},
				newPoints: stationIds.map(id => ({ id })),
			}

			return customer
		})
	} else {
		const customerNameRows = customerPropertyData[CUSTOMER_NAME_KEY]
		const contactsPropertyData = validationState[CONTACT_KEY]!

		const { chain, category, area, cleanerPermissions } = customerPropertyData
		const { customerName, contactPersonName, contactPersonEmail, contactPersonPhone } =
			contactsPropertyData

		return customerNameRows.map((nameCell, i: number) => {
			const contacts = customerName.reduce((acc: any, customerNameCell, i) => {
				if (customerNameCell.value === nameCell.value) {
					acc.push({
						name: contactPersonName[i].value,
						email: contactPersonEmail[i].value,
						phone: contactPersonPhone?.[i].value?.toString() || "",
					})
				}

				return acc
			}, [])

			const firstContact = contacts[0]
			const props: any = [
				{ key: "categoryId", value: category[i].value?.toString() },
				{ key: "area", value: area[i].value.toString() },
				{
					key: "throwDelegate",
					value: CLEANER_PERMISSION_MAPPING[cleanerPermissions[i].value] || "false",
				},
				{ key: "contactPerson", value: firstContact?.name },
				{ key: "contactEmail", value: firstContact?.email },
				{ key: "contactMobile", value: firstContact?.phone },
			]

			if (contacts.length > 1) {
				props.push({
					key: "contactPeople",
					value: JSON.stringify(contacts.slice(1)),
				})
			}

			const customer = {
				id: uniqueIdGenerator("nanoid", 5),
				externalKeyName: EXTERNAL_ID_KEY,
				updateFields: {
					name: nameCell.value,
					customerType: "BUSINESS",
				},
				propertiesUpdate: {
					mode: "SET",
					props,
				},
				...(chain[i].value ? { parent: { id: chain[i].value } } : {}),
				newPoints: accessParentIds.map(id => ({ id })),
			}

			return customer
		})
	}
}

const addCustomers = async (props: AddToSystemProps) => {
	const { setLoadingState, fullReset } = props.context
	const { graphqlClient } = useApiStore.getState()

	const customers = getCustomersToUpload(props)

	setLoadingState({
		importing: "importLabels:importingCustomers",
		finalized: false,
	})
	graphqlClient!
		.request(UPSERT_CUSTOMERS, {
			input: {
				wait: true,
				payload: {
					customers,
				},
			},
		})
		.then(() => {
			props.refetchCustomers().then(() => {
				setLoadingState(FINALIZED_STATE)
				fullReset()
			})
		})
		.catch(() => {
			setLoadingState(ERROR_STATE)
		})
}

export const addToSystem = (props: AddToSystemProps) => {
	const { selectedTemplate } = props.context

	switch (selectedTemplate?.key) {
		case "customers":
			addCustomers(props)
			break

		default:
			break
	}
}
