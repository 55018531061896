import { IconError } from "Icons/Error"
import { ValidationCell } from "components/ImportComponents/types"
import React, { useMemo } from "react"
import { Tooltip } from "react-tooltip"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { v4 } from "uuid"

type Props = {
	cell: ValidationCell
	sectionKey: string
	columnKey: string
	cellIndex: number
	onValueChange: (val: string) => void
	className?: string
	disabled?: boolean
}

export const CellInput: React.FC<Props> = ({
	cell,
	sectionKey,
	columnKey,
	cellIndex,
	onValueChange,
	className = "py-4 px-2.5 border-b border-grey2",
	disabled = false,
}) => {
	const { t } = useTrans()
	const tooltipUUID = useMemo(() => v4(), [])
	const [showTooltip, setShowTooltip] = React.useState(false)

	return (
		<div className={twMerge("relative flex items-center", className)}>
			<input
				id={`${sectionKey}-${columnKey}-${cellIndex}`}
				type="text"
				className={twMerge(
					"px-2.5 py-2 w-full border-black h-10",
					"focus:outline focus:outline-2 focus:outline-offset-[-2px]",
					cell.error && "border-carrotRed bg-lightPeach focus:outline-carrotRed",
					disabled && "disabled:border-grey3 disabled:text-grey6"
				)}
				autoComplete="off"
				value={cell.value}
				onChange={e => onValueChange(e.target.value)}
				onFocus={() => setShowTooltip(true)}
				onBlur={() => setShowTooltip(false)}
				disabled={disabled}
			/>

			{cell.error && (
				<div
					className="absolute right-7 stroke-none"
					data-tooltip-id={tooltipUUID}
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
				>
					<IconError
						width={12}
						height={12}
						data-tooltip-id={tooltipUUID}
						data-tooltip-content={t(cell.error, cell.errorOptions)}
					/>
					<Tooltip
						id={tooltipUUID}
						content={t(cell.error, cell.errorOptions)}
						opacity={1}
						style={{ width: 250, backgroundColor: "#FFAD9E", color: "#000" }}
						className="z-[10]"
						isOpen={showTooltip}
					/>
				</div>
			)}
		</div>
	)
}
