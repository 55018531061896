import React from "react"

export const Pointer: React.FC<{ className?: string }> = ({ className }) => (
	<svg
		width="8"
		height="15"
		viewBox="0 0 8 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M3.64645 14.3536C3.84171 14.5488 4.15829 14.5488 4.35355 14.3536L7.53553 11.1716C7.7308 10.9763 7.7308 10.6597 7.53553 10.4645C7.34027 10.2692 7.02369 10.2692 6.82843 10.4645L4 13.2929L1.17157 10.4645C0.97631 10.2692 0.659728 10.2692 0.464466 10.4645C0.269203 10.6597 0.269203 10.9763 0.464466 11.1716L3.64645 14.3536ZM3.5 -2.18557e-08L3.5 14L4.5 14L4.5 2.18557e-08L3.5 -2.18557e-08Z"
			fill="currentColor"
		/>
	</svg>
)

export default Pointer
