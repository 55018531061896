// Zustand context for translation

import create from "zustand"

type Context = {
	context: string | undefined
	setContext: (context: string | undefined) => void
}

export const translationContext = create<Context>(set => ({
	context: undefined,
	setContext: (context: string | undefined) => set({ context }),
}))
