import { textView, ColumnType, smartCapabilitiesView } from "./functions"

export const smartCapabilitiesColumns: ColumnType[] = [
	{
		label: "formLabels:smartCapability",
		name: "capabilityTypeTranslation",
		getView: textView,
	},
	{
		label: "entities:manufacturer",
		name: "manufacturer",
		getView: textView,
	},
	{
		label: "formLabels:connection",
		name: "status",
		getView: smartCapabilitiesView,
	},
]
