import { get, isNil, isPlainObject, PropertyPath, set } from "lodash"

export type NestedKeyOf<ObjectType extends object> = {
	[Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
		? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
		: `${Key}`
}[keyof ObjectType & (string | number)]

/**
 * sets deep value within object, does not copy
 * if root path it returns copied object without setting directly instead
 */
export const setDeepValue = <Obj extends {}>(
	object: Obj,
	path: PropertyPath,
	value: any,
	replace: boolean = false
): Obj => {
	const isRootPath = path === "" || isNil(path)
	const targetObject = isRootPath ? object : get(object, path, {})
	const parsedValue =
		isPlainObject(value) && isPlainObject(targetObject) && !replace
			? { ...targetObject, ...value }
			: value
	return isRootPath ? parsedValue : set(object, path, parsedValue)
}
