import UnitDiff from "components/UnitDiff"
import { FC } from "react"
import { useTrans } from "translations"
import { formatNumberForTable, formatNumberWithDecimalPlaces } from "Utils/formatFunctions"
import { TCostForecastData } from "../functions/useCostForecast"
import { DeltaTrend } from "Utils/tableColumns/functions"

const calculateTrend = (value: number, futureValue: number) => {
	const calcDir = value < 0 ? -1 : 1
	const diff =
		value === 0
			? 0
			: formatNumberWithDecimalPlaces((futureValue / (value * calcDir) - calcDir) * 100, 1)
	const trend =
		diff === 0 ? DeltaTrend.Neutral : diff > 0 ? DeltaTrend.Negative : DeltaTrend.Positive
	return { diff, trend }
}

const parseValue = (value: string) => {
	return parseFloat((value || "0").replaceAll(" ", ""))
}

export const ContentSummary: FC<{ data: TCostForecastData; modified?: boolean }> = ({
	data,
	modified,
}) => {
	const { t } = useTrans()

	const parsedData = data?.map(({ currentAmount, currentPrice, futureAmount, futurePrice }) => ({
		currentAmount,
		currentPrice: parseValue(currentPrice?.toString()),
		futureAmount: parseValue(futureAmount?.toString()),
		futurePrice: parseValue(futurePrice?.toString()),
	}))

	const currentAnnualAmount =
		parsedData?.reduce((prev, { currentAmount }) => prev + currentAmount, 0) || 0
	const currentAnnualCost =
		parsedData?.reduce(
			(prev, { currentAmount, currentPrice }) =>
				formatNumberWithDecimalPlaces(currentAmount, currentAmount < 1 ? 1 : 0) * currentPrice +
				prev,
			0
		) || 0
	const futureAnnualAmount =
		parsedData?.reduce((prev, { futureAmount }) => futureAmount + prev, 0) || 0
	const futureAnnualCost =
		parsedData?.reduce(
			(prev, { futureAmount, futurePrice }) => futureAmount * futurePrice + prev,
			0
		) || 0

	const { diff: futureCostDiff, trend: futureCostTrend } = calculateTrend(
		currentAnnualCost,
		futureAnnualCost
	)

	const { diff: futureAmountDiff, trend: futureAmmountTrend } = calculateTrend(
		currentAnnualAmount,
		futureAnnualAmount
	)

	return (
		<div className="grid grid-cols-3 gap-6 p-3">
			<div className="text-sm text-grey6">
				<p>{t("hints:insertAmountsAndPrices")}</p>
				<br />
				<p>{t("hints:positiveNegativePricesInfo")}</p>
			</div>
			<div className="flex-col gap-1 bg-grey2 p-6">
				<div>
					<p className="text-xs">{t("circularityLabels:results")}</p>
					<p className="font-bold">{t("circularityLabels:currentCosts")}</p>
					<p className="text-sm text-grey6">{t("circularityLabels:currentCostsInfo")}</p>
				</div>
				<div>
					<div className="flex items-center justify-between py-1.5">
						<p className="text-sm">{t("circularityLabels:currentAnnualCost")}:</p>
						{data && modified && (
							<p className="font-medium">
								{t("circularityLabels:localCurrency")} {formatNumberForTable(currentAnnualCost)}
							</p>
						)}
						{(!data || !modified) && <p className="font-medium">-</p>}
					</div>
					<div className="flex items-center justify-between py-1.5">
						<p className="text-sm">{t("circularityLabels:currentAnnualAmount")}:</p>
						{data && modified && (
							<p className="font-medium">
								{formatNumberForTable(currentAnnualAmount, { suffix: " kg" })}
							</p>
						)}
						{(!data || !modified) && <p className="font-medium">-</p>}
					</div>
				</div>
			</div>
			<div className="flex-col gap-1 bg-grey2 p-6">
				<div>
					<p className="text-xs">{t("circularityLabels:results")}</p>
					<p className="font-bold">{t("statisticsLabels:futureCosts")}</p>
					<p className="text-sm text-grey6">{t("statisticsLabels:futureCostsInfo")}</p>
				</div>
				<div>
					<div className="flex items-center justify-between py-1.5">
						<p className="text-sm">{t("statisticsLabels:futureAnnualCost")}:</p>
						<div className="flex items-baseline gap-2">
							{data && modified && (
								<>
									<p className="font-medium">
										{t("circularityLabels:localCurrency")} {formatNumberForTable(futureAnnualCost)}{" "}
									</p>
									<UnitDiff diff={futureCostDiff} unit={"percent"} trend={futureCostTrend} />
								</>
							)}

							{(!data || !modified) && <p className="font-medium">-</p>}
						</div>
					</div>
					<div className="flex items-center justify-between py-1.5">
						<p className="text-sm">{t("statisticsLabels:futureAnnualAmount")}:</p>
						<div className="flex items-baseline gap-2">
							{data && modified && (
								<>
									<p className="font-medium">
										{formatNumberForTable(futureAnnualAmount, { suffix: " kg" })}
									</p>
									<UnitDiff diff={futureAmountDiff} unit={"percent"} trend={futureAmmountTrend} />
								</>
							)}
							{(!data || !modified) && <p className="font-medium">-</p>}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
