import { useGlobalAlert } from "States/globalAlert"
import { IInputOption, Input } from "components/GenericComponents/input"
import { ImportContext } from "components/ImportComponents/ImportContext"
import React, { useCallback, useContext, useMemo } from "react"
import { useTrans } from "translations"

type Props = {
	sectionKey: string
	sheetSelectLabel: string
}

export const SheetSelect: React.FC<Props> = ({ sectionKey, sheetSelectLabel }: Props) => {
	const { t } = useTrans()

	const {
		uploadedFileStructure,
		mappingState,
		setMappingState,
		validationState,
		setValidationState,
	} = useContext(ImportContext)
	const { setGlobalAlert } = useGlobalAlert()

	const sheetId = useMemo(() => mappingState?.[sectionKey]?.sheetId, [mappingState, sectionKey])

	const sheetOptions = useMemo(
		() =>
			uploadedFileStructure.map(sheet => ({
				option: sheet.name,
				value: sheet.id,
			})),
		[uploadedFileStructure]
	)

	const checkIfSheetHasData = useCallback(
		(sheetId: string) => {
			const sheet = uploadedFileStructure.find(s => s.id === sheetId)
			const columnWithData = sheet?.columns.find(c => c.values.length > 0)

			return !!columnWithData
		},
		[uploadedFileStructure]
	)

	const onSheetIdChange = useCallback(
		(option: IInputOption) => {
			const sheetHasData = checkIfSheetHasData(option.value)

			if (!sheetHasData) {
				setGlobalAlert({
					type: "error",
					message: "errors:sheetNoData",
					instructions: "errors:sheetNoDataInstructions",
				})
			} else {
				const newState = {
					...mappingState,
					[sectionKey]: {
						...mappingState[sectionKey],
						sheetId: option.value,
						columnSelections: {},
					},
				}

				const newValidationState = {
					...validationState,
					[sectionKey]: null,
				}

				const duplicateSelectKey = Object.keys(mappingState)
					.filter(s => s !== sectionKey && mappingState[s]?.sheetId)
					.find(s => mappingState[s].sheetId === option.value)

				if (duplicateSelectKey) {
					newState[duplicateSelectKey].sheetId = ""
					newValidationState[duplicateSelectKey] = null
				}

				setMappingState(newState)
				setValidationState(newValidationState)
			}
		},
		[
			mappingState,
			setMappingState,
			sectionKey,
			validationState,
			setValidationState,
			checkIfSheetHasData,
			setGlobalAlert,
		]
	)

	return (
		<div>
			<div className="text black font-dmSans text-sm font-medium">{t(sheetSelectLabel)} *</div>
			<div className="mb-6 bg-white">
				<Input
					placeholder={t("importLabels:selectSheet")}
					data={sheetOptions}
					dropdownStyle={{ maxHeight: 190 }}
					dropdownIcon
					initialValue={sheetId}
					onChange={onSheetIdChange}
					ignoreOnRenderChange
					noInitialOption={!sheetId}
				/>
			</div>
		</div>
	)
}
