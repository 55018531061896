import React from "react"

export const Paperclip: React.FC<{ className?: string }> = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M14.8287 7.75789L9.1718 13.4148C8.78127 13.8053 8.78127 14.4384 9.1718 14.829C9.56232 15.2195 10.1955 15.2195 10.586 14.829L16.2429 9.1721C17.4144 8.00053 17.4144 6.10104 16.2429 4.92946C15.0713 3.75789 13.1718 3.75789 12.0002 4.92946L6.34337 10.5863C4.39075 12.5389 4.39075 15.7048 6.34337 17.6574C8.29599 19.61 11.4618 19.61 13.4144 17.6574L19.0713 12.0005L20.4855 13.4148L14.8287 19.0716C12.095 21.8053 7.66283 21.8053 4.92916 19.0716C2.19549 16.3379 2.19549 11.9058 4.92916 9.1721L10.586 3.51525C12.5386 1.56263 15.7045 1.56263 17.6571 3.51525C19.6097 5.46787 19.6097 8.6337 17.6571 10.5863L12.0002 16.2432C10.8287 17.4148 8.92916 17.4148 7.75759 16.2432C6.58601 15.0716 6.58601 13.1721 7.75759 12.0005L13.4144 6.34368L14.8287 7.75789Z"
			fill="#948D8D"
		/>
	</svg>
)

export default Paperclip
