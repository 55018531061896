import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { CardInfo } from "components/pageCards/cardInfo"
import { CardInfoContainer } from "components/pageCards/cardInfoContainer"
import { MultiListDrawer } from "components/pageCards/multiListTable/multiListDrawer"
import { useModal } from "Contexts"
import { FC, useCallback, useMemo } from "react"
import { Translate, useTrans } from "translations"
import { EditAllocationModal } from "./editAllocationModal"
import { ColumnType, listView, textView } from "Utils/tableColumns/functions"
import { Table } from "components/Table"
import { EditAllocationStationsModal } from "./editAllocationStationsModal"
import { trpc } from "Utils/trpc"
import { accessParentWithRedundancyPointNames } from "pages/customer/components/CustomerDrawer/InfrastructureCard"

export type Allocation = {
	id: string
	geoLocationName: string
	geoLocationCode: string
	assignHousehold: string
	assignBusiness: string
	points: {
		id: string
		name: string
		priority: number
	}[]
}

type Props = { allocation?: Allocation; refetch: () => void }

const accessParentColumns: ColumnType[] = [
	{
		label: "sidebarLabels:nameOfAccessParent",
		name: "name",
		getView: textView,
	},
	{
		label: "entities:redundancyPoints",
		name: "redundancyPointNames",
		getView: listView,
		sortable: false,
		headerTooltipComponent: (
			<Translate
				i18nKey="hints:redundancyPointDescription"
				components={[
					// Anchor content is added by the translation
					// eslint-disable-next-line jsx-a11y/anchor-has-content
					<a href="mailto:support@carrot.tech" className="underline text-white" />,
				]}
			/>
		),
	},
]

export const AllocationDrawer: FC<Props> = ({ allocation, refetch }) => {
	const { t } = useTrans()
	const { showModal } = useModal()

	const allocationPointIds = useMemo(() => allocation?.points.map(p => p.id) ?? [], [allocation])

	const {
		data: pointsWithRedundancyPoints,
		isFetching,
		isError,
	} = trpc.accessParents.getWithIds.useQuery(
		{
			accessParentIds: allocationPointIds,
			withRedundancyPoints: true,
		},
		{ enabled: !!allocationPointIds.length }
	)

	const editAllocation = useCallback(() => {
		if (!allocation) return
		showModal(<EditAllocationModal allocation={allocation} onSuccess={refetch} />)
	}, [allocation, refetch, showModal])

	const editAccessParents = useCallback(() => {
		if (!allocation) return
		showModal(
			<EditAllocationStationsModal
				allocationId={allocation.id}
				pointsWithRedundancyPoints={pointsWithRedundancyPoints}
				onSuccess={refetch}
			/>
		)
	}, [allocation, pointsWithRedundancyPoints, refetch, showModal])

	if (!allocation) return null

	const [municipality, streetCode, houseNumber] = allocation.geoLocationCode.split(".")
	const allocationStationsCardData =
		pointsWithRedundancyPoints?.map(p => accessParentWithRedundancyPointNames(p, t)) ?? []

	return (
		<MultiListDrawer isLoading={isFetching} isError={isError}>
			<h4 className="font-signifier text-3xl font-light text-black">
				{allocation.geoLocationName}
			</h4>
			<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:allocation">
				<CardInfoContainer>
					<CardInfo heading={t("formLabels:name")} value={allocation.geoLocationName} />
					<CardInfo heading={t("formLabels:municipalityNumber")} value={municipality} />
					<CardInfo heading={t("formLabels:streetCode")} value={streetCode} />
					<CardInfo heading={t("formLabels:houseNumber")} value={houseNumber} />
				</CardInfoContainer>
				<Button
					className="float-right mt-10"
					label={"actions:editAllocation"}
					color="secondary"
					onClick={editAllocation}
				/>
			</Card>
			<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:allocationStations">
				<div className="mb-5">
					<p className="text-sm">{t("hints:allocationStationsDescription")}</p>
				</div>
				<Table
					limitedHeight
					sortingHeader="name"
					columns={accessParentColumns}
					data={allocationStationsCardData}
					emptyViewType="noAccessParent"
					emptyViewClassName="bg-grey1 mt-0"
				/>
				<div className="py-4 h-20">
					<Button
						className="float-right"
						label="actions:edit"
						color="secondary"
						onClick={editAccessParents}
					/>
				</div>
			</Card>
		</MultiListDrawer>
	)
}
