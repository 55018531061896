export const getInfrastructureUrl = ({
	terminalId,
	accessParentId,
	containerId,
}: {
	terminalId?: string
	accessParentId?: string
	containerId?: string
}) => {
	let baseUrl = "/infrastructure/manage/"

	if (terminalId) {
		baseUrl += `${terminalId}/`
	}

	if (accessParentId) {
		baseUrl += `${accessParentId}/`
	}

	if (containerId) {
		baseUrl += containerId
	}

	return baseUrl
}
