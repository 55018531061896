import { NormalizedCustomerIdentities } from "admin-client-server/src/coreApi/models/RealEstate"
import { AccessTokenColumn, AccessTokenRow, IdentityStatus } from "./types"
import { TOptions } from "i18next"
import { v4 } from "uuid"

export const getTableRows = (identities: NormalizedCustomerIdentities) =>
	identities.map(identity => ({
		id: identity.id || "",
		iso: {
			value: identity.iso,
			error: "",
		},
		em: {
			value: identity.em,
			error: "",
		},
		printed: {
			value: identity.printed,
			error: "",
		},
		status: {
			value: identity.status,
			error: "",
		},
		customerId: identity.customerId,
	}))

export const getTableColumns = (
	t: (key: string, options?: TOptions | undefined) => string
): AccessTokenColumn[] =>
	[
		{
			label: t("formLabels:label"),
			name: "printed",
			patternError: "errors:mustBeUnique",
			unique: true,
			canBeDisabled: true,
			isAutocomplete: true,
		},
		{
			label: t("formLabels:ISO"),
			name: "iso",
			required: true,
			patternError: "errors:mustBeUnique",
			unique: true,
			canBeDisabled: true,
			isAutocomplete: true,
		},
		{
			label: t("formLabels:EM"),
			name: "em",
			patternError: "errors:mustBeUnique",
			unique: true,
			canBeDisabled: true,
			isAutocomplete: true,
		},
		{
			label: t("formLabels:status"),
			name: "status",
			isDropdown: true,
			defaultValue: IdentityStatus.ACTIVE,
			options: [
				{ label: t("status:ACTIVE"), value: IdentityStatus.ACTIVE },
				{ label: t("status:DISABLED"), value: IdentityStatus.DISABLED },
			],
			required: true,
		},
	] as AccessTokenColumn[]

export const getAutocompleteRow = (rowId: string, option: any): AccessTokenRow => ({
	id: rowId,
	customerId: "",
	iso: {
		value: option.iso,
		error: "",
	},
	em: {
		value: option.em,
		error: "",
	},
	printed: {
		value: option.printed,
		error: "",
	},
	status: {
		value: option.status,
		error: "",
	},
	identityId: option.id,
	isNew: true,
})

export const getDefaultRow = () => ({
	id: v4(),
	iso: {
		value: "",
		error: "",
	},
	em: {
		value: "",
		error: "",
	},
	printed: {
		value: "",
		error: "",
	},
	status: {
		value: IdentityStatus.ACTIVE,
		error: "",
	},
	isNew: true,
	customerId: "",
})
