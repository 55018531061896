import { FC, createContext, useContext, useState } from "react"
import { useMatch } from "react-router-dom"

export type ContainerInfo = {
	id: string
	name: string
	externalId?: string
}

interface MultiSelectContextType {
	selectedIds: string[]
	setSelectedIds: (customerIds: string[]) => void
	selectedEventIds: string[]
	setSelectedEventIds: (ids: string[]) => void
	selectedContainers: ContainerInfo[]
	setSelectedContainers: (customers: ContainerInfo[]) => void
}

const MultiSelectContext = createContext<MultiSelectContextType | undefined>(undefined)
export const useMultiSelectContext = (): MultiSelectContextType => {
	const context = useContext(MultiSelectContext)
	if (!context) {
		throw new Error("Error using MultiSelectContext")
	}
	return context
}

export const MultiSelectProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const customerRouteMatch = useMatch("/customer/manage/:id")
	const selectedCustomerId = customerRouteMatch?.params.id

	const [selectedIds, setSelectedIds] = useState<string[]>(
		selectedCustomerId ? [selectedCustomerId] : []
	)
	const [selectedEventIds, setSelectedEventIds] = useState<string[]>([])
	const [selectedContainers, setSelectedContainers] = useState<ContainerInfo[]>([])

	return (
		<MultiSelectContext.Provider
			value={{
				selectedIds,
				setSelectedIds,
				selectedEventIds,
				setSelectedEventIds,
				selectedContainers,
				setSelectedContainers,
			}}
		>
			{children}
		</MultiSelectContext.Provider>
	)
}
