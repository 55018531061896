import { ComponentProps } from "react"
import { Button } from "./button"
import { twMerge } from "tailwind-merge"
import { PlusIcon } from "Icons/Plus"

type AddButtonProps = Omit<ComponentProps<typeof Button>, "label" | "color">

export const AddButton: React.FC<AddButtonProps> = props => {
	return (
		<Button
			{...props}
			label={<PlusIcon height={16} width={16} />}
			color="secondary"
			className={twMerge("min-w-0 w-8 h-8 px-0 flex justify-center items-center", props.className)}
		/>
	)
}
