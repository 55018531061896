import { CheckIcon } from "Icons/Check"
import { CrossIcon } from "Icons/cross"
import classNames from "classnames"
import React from "react"
import Switch from "react-switch"

type Props = {
	label?: string
	checked: boolean
	disabled?: boolean
	onChange: (checked: boolean) => void
}

export const Toggle: React.FC<Props> = ({ label, checked, onChange, disabled }: Props) => {
	return (
		<div className="flex items-center">
			<Switch
				checked={checked}
				onChange={onChange}
				offColor="#948D8D"
				onColor="#467432"
				disabled={disabled}
				width={44}
				height={26}
				handleDiameter={22}
				className="react-switch"
				uncheckedIcon={false}
				checkedIcon={false}
				uncheckedHandleIcon={
					<div className="text-grey5 flex items-center justify-center h-full">
						<CrossIcon width={8} height={8} />
					</div>
				}
				checkedHandleIcon={
					<div className="text-carrotGreen flex items-center justify-center h-full">
						<CheckIcon />
					</div>
				}
				activeBoxShadow=""
			/>
			{label && <label className={classNames("mx-2", disabled && "text-grey6")}>{label}</label>}
		</div>
	)
}
