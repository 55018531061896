import { Input } from "components/GenericComponents/input"
import { ImportContext } from "components/ImportComponents/ImportContext"
import { TemplateColumn } from "components/ImportComponents/types"
import React, { useContext, useMemo } from "react"
import { useTrans } from "translations"

type Props = {
	sectionKey: string
	columnSelectLabel: string
	columns: TemplateColumn[]
	updateColumnSelection: (columnKey: string, value: string) => void
	columnOptions: { option: string; value: string }[]
}

export const ColumnSelect: React.FC<Props> = ({
	sectionKey,
	columnSelectLabel,
	columns,
	updateColumnSelection,
	columnOptions,
}) => {
	const { t } = useTrans()
	const { mappingState } = useContext(ImportContext)

	const columnsData = useMemo(
		() =>
			columns
				.filter(col => !col.isGenerated)
				.map(col => {
					const selectedValue = mappingState?.[sectionKey].columnSelections[col.key]
					const hasError =
						(col.isRequired || col.isRequiredFunction?.(mappingState)) && !selectedValue

					return {
						...col,
						selectedValue,
						hasError,
					}
				}),
		[columns, mappingState, sectionKey]
	)

	const columnsView = useMemo(
		() =>
			columnsData.map(({ key, title, isRequired, isRequiredFunction, selectedValue, hasError }) => (
				<div key={key} className="text-sm flex p-2.5 bg-white mt-px items-center">
					<div className="w-1/2">
						{t(title)}
						{(isRequired || isRequiredFunction?.(mappingState)) && " *"}
					</div>
					<div className="w-5" />
					<div className="w-1/2">
						<Input
							placeholder={t(
								isRequired ? "importLabels:selectColumnRequired" : "importLabels:selectColumn"
							)}
							data={columnOptions}
							dropdownStyle={{ maxHeight: 190 }}
							dropdownIcon
							initialValue={selectedValue}
							onChange={option => updateColumnSelection(key, option.value)}
							ignoreOnRenderChange
							noInitialOption={!selectedValue}
							errorInTooltip
							error={hasError}
							errorMessage={hasError ? t("importLabels:unableToMapColumn") : ""}
						/>
					</div>
				</div>
			)),
		[columnOptions, t, updateColumnSelection, columnsData, mappingState]
	)

	return (
		<div>
			<div className="text-sm flex p-2.5 bg-white font-medium ">
				<div className="w-1/2">{t(columnSelectLabel)}</div>
				<div className="w-5" />
				<div className="w-1/2">{t("importLabels:selectCorrespondingColumn")}</div>
			</div>
			{columnsView}
		</div>
	)
}
