import { IdentityKey } from "admin-client-server/src/utils/identitiesUtils"

export enum IdentityStatus {
	ACTIVE = "ACTIVE",
	DISABLED = "DISABLED",
}

export enum IdentityType {
	ISOEM = "ISOEM",
	ISO = "ISO",
	JWT = "JWT",
	PUBLIC_KEY = "PUBLIC_KEY",
}

export type AccessToken = {
	printed: string
	iso: string
	em?: string
	status: IdentityStatus
	canEditKeys?: boolean
}

export type AccessTokenCell = {
	value: any
	error: string
}

export type AccessTokenRow = {
	id: string
	iso: AccessTokenCell
	em: AccessTokenCell
	printed: AccessTokenCell
	status: AccessTokenCell
	customerId: string
	isNew?: boolean
	identityId?: string
}

export enum TypeEnum {
	Iso = "iso",
	IsoEm = "iso_em",
}

export type FieldType = IdentityKey | "status"

export type AccessTokenColumn = {
	label: string
	name: FieldType
	required?: boolean
	patternError?: string
	unique?: boolean
	isAutocomplete?: boolean
	defaultValue?: any
	options?: { label: string; value: string }[]
	canBeDisabled?: boolean
}
