import { sum } from "lodash"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { TenantType } from "."
import { getSortedWasteTypes } from "./exportExcel"
import { useCommonEntitiesStore } from "States/commonEntities"
import { formatNumberForTable, formatWeight } from "Utils/formatFunctions"
import { Header } from "components/TableWithSelect"
import { useConfigService } from "pages/configuration/useConfigService"

export const StickyFooterComponent = ({
	tableRef,
	headers,
	filteredWasteTypes,
	tenants,
	currentTerminalName,
}: {
	tableRef: HTMLTableElement
	headers: Header[]
	filteredWasteTypes: string[]
	tenants: TenantType[]
	currentTerminalName: string
}) => {
	const { t } = useTrans()
	const { wasteTypes } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()

	const allTypesSums = getSortedWasteTypes(
		filteredWasteTypes,
		wasteTypeConfig,
		wasteTypes || []
	).map(code => sum(tenants.map(el => formatWeight(el[code]) || 0)))
	const allTypesTotal = sum(allTypesSums)

	const totalRow = [...allTypesSums, allTypesTotal]

	const totalCellWidth = (headers[0].defaultSize || 100) + (headers[1].defaultSize! || 100)

	return (
		<div className="h-12 w-fit sticky bottom-0 flex flex-row border border-x-0 border-y-1 border-black font-medium text-sm py-3 bg-grey1">
			<div
				className="pl-2 sticky left-0 bg-grey1 h-full flex items-center"
				style={{ width: `${totalCellWidth}px` }}
			>
				{t("genericLabels:total")} {currentTerminalName} (kg)
			</div>
			{totalRow.map((val, i, arr) => (
				<div
					key={i}
					className={twMerge(
						"w-[300px] justify-end text-end last:sticky last:right-0 last:bg-grey1 h-full bg-grey1 flex items-center",
						i === arr.length - 1 && "pr-4"
					)}
				>
					{formatNumberForTable(val)}
				</div>
			))}
		</div>
	)
}
