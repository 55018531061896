import { deletePoint } from "api/deletePoint"
import { useTerminals } from "api/hooks/useTerminals"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"

interface DeleteTerminalModalProps {
	terminalId: string
	terminalName: string
}

export const DeleteTerminalModal: FC<DeleteTerminalModalProps> = ({ terminalId, terminalName }) => {
	const { hideModal } = useModal()
	const { t } = useTrans()
	const navigate = useNavigate()
	const { refetchTerminals, isLoadingTerminals, terminals } = useTerminals()
	const { setGlobalAlert } = useGlobalAlert()
	const { setCurrentTerminal } = useTerminalsState()

	const onConfirm = useCallback(() => {
		deletePoint({
			id: terminalId,
			onStartCallBack: () => {
				setGlobalAlert({
					type: "success",
					message: "systemMessages:terminalDeleted",
				})
				hideModal()
				refetchTerminals().then(() => {
					const terminalId = terminals[0]?.id || ""
					setCurrentTerminal(terminals[0])
					navigate(`/infrastructure/manage${terminalId && `/${terminalId}`}`)
				})
			},
			onFailCallBack: error => {
				console.error(error)
				setGlobalAlert({
					type: "error",
					message: "errors:unknown",
				})
			},
		})
	}, [
		refetchTerminals,
		terminalId,
		navigate,
		setGlobalAlert,
		hideModal,
		setCurrentTerminal,
		terminals,
	])

	return (
		<ModalContainer
			title="actions:deleteTerminal"
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isLoadingTerminals}
			onConfirmText="actions:confirm"
		>
			<div className="pt-3">
				<p>{t("hints:areYouSureDelete", { name: terminalName })}</p>
			</div>
		</ModalContainer>
	)
}
