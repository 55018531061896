export const MinusIcon = ({ fill = "#000", width = 24, height = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height={height}
		width={width}
		viewBox="0 0 16 16"
		fill={fill}
	>
		<rect x="16" y="7.19995" width="1.6" height="16" transform="rotate(90 16 7.19995)" />
	</svg>
)
