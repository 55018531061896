import React, { useMemo } from "react"
import { useTrans } from "translations"

type Props = {
	nrErrors: number
}

export const ErrorTag: React.FC<Props> = ({ nrErrors }) => {
	const { t } = useTrans()

	const text = useMemo(() => {
		if (nrErrors === 1) {
			return t("errors:error").toLowerCase()
		}

		return t("errors:errors")
	}, [nrErrors, t])

	return (
		<div className="px-3 py-0.5 bg-lightPeach text-carrotRed font-medium text-xs rounded-full whitespace-nowrap">
			{nrErrors} {text}
		</div>
	)
}
