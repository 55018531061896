import classNames from "classnames"
import { FC, ReactElement, useCallback } from "react"
import { TableCheckbox } from "../dataTable/components/checkbox"

export type MultiListSectionEntryProps = {
	labelTop: string
	labelTopRight?: string | number
	labelBottom?: string | number
	labelBottomRight?: string | number
	labelBottomRightColor?: "green" | "orange" | "red"
	icon?: string | ReactElement | null
	/** Is handled by DataListCard by default */
	isSelected?: boolean
	/** lighter version of selected. When entry is selected but drawer is open for other entry */
	isSelectedSecondary?: boolean
	withMultiSelect?: boolean
	onClick?: (event: React.MouseEvent, checkboxEvent?: boolean) => void
}

export const MultiListSectionEntry: FC<MultiListSectionEntryProps> = ({
	labelTop,
	labelBottom,
	labelTopRight,
	labelBottomRight,
	labelBottomRightColor,
	icon: Icon,
	isSelected,
	isSelectedSecondary,
	withMultiSelect = false,
	onClick,
}) => {
	const onCheckboxClick = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault()
			e.stopPropagation()

			if (onClick) {
				onClick(e, true)
			}
		},
		[onClick]
	)

	return (
		<div
			className={classNames(
				"group h-20 flex cursor-pointer items-center border-b hover:bg-hoverColor px-3",
				isSelected &&
					(isSelectedSecondary ? "bg-hoverColor" : "bg-selectedColor hover:bg-selectedColor")
			)}
			{...{ onClick }}
		>
			{withMultiSelect && <TableCheckbox isChecked={isSelected} onChange={onCheckboxClick} />}
			<div className={classNames("w-full flex-1 py-0.5 text-sm", withMultiSelect && "pl-2")}>
				<div className="flex justify-between gap-3 py-1 px-3">
					<div className="font-bold">{labelTop}</div>
					<div className="text-right">{labelTopRight}</div>
				</div>
				<div className="flex justify-between gap-3 py-1 px-3">
					<div className="whitespace-pre-wrap">{labelBottom}</div>
					<div
						className={classNames(
							"text-right",
							labelBottomRightColor === "green" && "text-carrotGreen",
							labelBottomRightColor === "orange" && "text-carrot",
							labelBottomRightColor === "red" && "text-carrotRed"
						)}
					>
						{labelBottomRight}
					</div>
				</div>
			</div>
		</div>
	)
}
