import FormInput from "components/Form/FormInput"
import { FC } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"

export type AllocationFormData = {
	name: string
	municipalityNumber: string
	streetCode: string
	houseNumber: string
}

type Props = {
	title: string
	formHandler: UseFormReturn<AllocationFormData>
	isEdit?: boolean
}

export const AllocationForm: FC<Props> = ({ title, formHandler, isEdit }) => {
	const { t } = useTrans()
	const {
		register,
		formState: { errors },
	} = formHandler

	return (
		<form className="mb-8">
			{!isEdit && <p className="text-sm font-dmSans mb-6">{t("hints:createAllocation")}</p>}
			<div className="my-4 w-3/4">
				<FormInput
					name="name"
					label={t("formLabels:allocationName")}
					register={register}
					required
					error={errors.name}
				/>
			</div>
			<div className="my-4 w-3/4">
				<FormInput
					name="municipalityNumber"
					label={t("formLabels:municipalityNumber")}
					register={register}
					required
					error={errors.municipalityNumber}
				/>
			</div>
			<div className="my-4 w-3/4">
				<FormInput
					name="streetCode"
					label={t("formLabels:streetCode")}
					register={register}
					required
					error={errors.streetCode}
				/>
			</div>
			<div className="my-4 w-3/4">
				<FormInput
					name="houseNumber"
					label={t("formLabels:houseNumber")}
					register={register}
					required
					error={errors.houseNumber}
				/>
			</div>
		</form>
	)
}
