import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useState } from "react"
import { useTrans } from "translations"
import { deletePoint } from "api/deletePoint"
import { useAccessPoints } from "../manage/useAccessPoints"

export const DeleteContainerModal: React.FC<{
	containerId: string
	accessParentId: string
	containerName: string | undefined
	onSuccess?: () => void
}> = ({ accessParentId, containerId, containerName, onSuccess }) => {
	const [isLoading, setLoading] = useState(false)

	const { t } = useTrans()
	const { hideModal } = useModal()
	const { refetchAll } = useAccessPoints({ accessParentId })

	const onFailCallBack = () => {
		setLoading(false)
	}

	const onSuccessCallback = () => {
		refetchAll()
		onSuccess?.()
		hideModal()
	}

	const onConfirm = () => {
		setLoading(true)

		deletePoint({
			id: containerId,
			onStartCallBack: onSuccessCallback,
			onFailCallBack: onFailCallBack,
		})
	}

	return (
		<ModalContainer
			title={"actions:deleteContainer"}
			onConfirmText={"actions:confirm"}
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={isLoading}
		>
			<div className="pt-3">
				<p>
					{t("hints:areYouSureDelete", {
						name: containerName || t("entities:container").toLowerCase(),
					})}
				</p>
			</div>
		</ModalContainer>
	)
}
