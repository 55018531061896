import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"

export const LanguageSelection = () => {
	const { t, language, changeLanguage } = useTrans()
	const { languages } = useCommonEntitiesStore()

	return (
		<form>
			<h2 className="mx-4 mt-4 font-dmSans text-sm font-semibold">{t("configLabels:language")}</h2>
			{languages?.map(({ code }) => (
				<div key={code} className="m-4 flex items-center">
					<input
						name="language"
						id={`language-${code}`}
						value={code}
						type="radio"
						className="h-3 w-3"
						checked={language === code}
						onChange={() => changeLanguage(code)}
					/>
					<label htmlFor={`language-${code}`} className="mt-px ml-2 text-sm">
						{t(`languages:${code}`)}
					</label>
				</div>
			))}
		</form>
	)
}
