import React, { SVGAttributes } from "react"

interface TrashIconProps extends SVGAttributes<SVGSVGElement> {
	disabled?: boolean
}

export const TrashIcon = (props: TrashIconProps) => (
	<div className="bg-grey1 rounded-full p-2">
		<svg
			width={props.width ?? 16}
			height={props.height ?? 16}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ opacity: props.disabled ? 0.5 : 1 }}
		>
			<path
				d="M1.2 3.6H10.8V11.4C10.8 11.7314 10.5314 12 10.2 12H1.8C1.46863 12 1.2 11.7314 1.2 11.4V3.6ZM3 1.8V0.6C3 0.268632 3.26863 0 3.6 0H8.4C8.73138 0 9 0.268632 9 0.6V1.8H12V3.6H0V1.8H3ZM4.2 1.2V1.8H7.8V1.2H4.2Z"
				fill="black"
			/>
		</svg>
	</div>
)

TrashIcon.defaultProps = {
	disabled: false,
}

export default TrashIcon
