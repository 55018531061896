import { useModal } from "Contexts"
import HistoryModal from "Modals/HistoryModal/HistoryModal"
import { ACCESS_POINT_HISTORY_TYPE } from "Modals/HistoryModal/constants"
import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { FC } from "react"
import { useTrans } from "translations"

export const PointChangeHistory: FC<{ id: string; name: string; entityKey: string }> = ({
	id,
	name,
	entityKey,
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()

	const showHistoryModal = () =>
		showModal(<HistoryModal key={`historyModal_${id}`} id={id} type={ACCESS_POINT_HISTORY_TYPE} />)

	return (
		<Card title={"drawerLabels:changeHistory"} variant={CardComponentVariant.DRAWER}>
			<p className="mb-10 font-dmSans text-sm text-black">
				{t("hints:historyLinkDescriptionEntity", {
					entity: t(`entities:${entityKey}`).toLowerCase(),
				})}
			</p>
			<Button
				label={"actions:seeChangeHistory"}
				color="secondary"
				className="float-right"
				onClick={showHistoryModal}
			/>
		</Card>
	)
}
