import { IconError } from "Icons/Error"
import { useMemo, useState } from "react"
import { FieldError } from "react-hook-form"
import { Tooltip } from "react-tooltip"
import { v4 } from "uuid"
import { getInputErrorMessage } from "Utils/getInputErrorMessage"
import { twMerge } from "tailwind-merge"

type Props = {
	label?: string
	patternError?: string
	minLength?: number
	showErrorInTooltip?: boolean
	error: FieldError | undefined
	className?: string
}

const ErrorTooltip = (props: Props) => {
	const { label, patternError, minLength, error, showErrorInTooltip = true, className } = props

	const [tooltipActive, setTooltipActive] = useState(false)
	const tooltipUUID = useMemo(() => v4(), [])

	const errorMessage = useMemo(() => {
		if (!error) {
			return ""
		}
		return getInputErrorMessage({
			errorType: error.type,
			label,
			patternError,
			minLength,
		}).trim()
	}, [error, label, patternError, minLength])

	if (!error) {
		return null
	}

	return showErrorInTooltip ? (
		<div
			className={twMerge("absolute z-50 right-4 top-[13px]", className)}
			data-tooltip-id={tooltipUUID}
			onMouseEnter={() => setTooltipActive(true)}
			onMouseLeave={() => setTooltipActive(false)}
		>
			<IconError width={12} height={12} />
			<Tooltip
				id={tooltipUUID}
				content={errorMessage}
				opacity={1}
				style={{ maxWidth: 350, backgroundColor: "#FFAD9E", color: "#000" }}
				isOpen={tooltipActive}
			/>
		</div>
	) : (
		<div className={twMerge("text-sm text-carrotRed font-normal", className)}>{errorMessage}</div>
	)
}

export default ErrorTooltip
