export const Customers = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_23_2242)">
			<path
				d="M1.5 16.5C1.5 13.232 6.5 10.5 7.5 10.5C8.5 10.5 13.5 13.232 13.5 16.5H1.5ZM7.5 9.75C5.01375 9.75 3 7.73625 3 5.25C3 2.76375 5.01375 0.75 7.5 0.75C9.98625 0.75 12 2.76375 12 5.25C12 7.73625 9.98625 9.75 7.5 9.75ZM13.0223 11.4247C14.1695 11.7196 15.1945 12.3689 15.9512 13.2801C16.708 14.1914 17.1581 15.3181 17.2372 16.5H15C15 14.5425 14.25 12.7605 13.0223 11.4247ZM11.505 9.71775C12.1335 9.15561 12.6361 8.467 12.9799 7.69706C13.3237 6.92712 13.501 6.09322 13.5 5.25C13.5016 4.2251 13.2394 3.21703 12.7388 2.32275C13.5882 2.49344 14.3524 2.95303 14.9013 3.6234C15.4503 4.29377 15.7502 5.13354 15.75 6C15.7502 6.53435 15.6362 7.06257 15.4156 7.54926C15.195 8.03594 14.8729 8.46985 14.4709 8.82191C14.0689 9.17396 13.5963 9.43602 13.0848 9.59052C12.5733 9.74503 12.0347 9.7884 11.505 9.71775Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_23_2242">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
)
