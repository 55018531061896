import { UseFormReturn } from "react-hook-form"
import { AutocompleteValue, FormColumn, FormFieldType } from "./types"
import FormInput from "components/Form/FormInput"
import { useTrans } from "translations"
import { useCallback, useMemo } from "react"
import classNames from "classnames"
import PhoneInput from "components/Form/PhoneInput"
import SelectInput from "components/Form/SelectInput"
import { xor } from "lodash"

type Props = {
	column: FormColumn
	enabled?: boolean // Overrides column.enabled
	rowIndex: number
	colIndex: number
	formHandler: UseFormReturn<any>
	regexPattern?: RegExp
	patternError?: string
	required?: boolean
	value: string
	validationFunction?: (value: string) => boolean
	autocompleteOptions?: AutocompleteValue[]
	onAutocompleteOptionSelected?: (row: number, option: AutocompleteValue) => void
	selectInputMenuPortalTarget?: HTMLElement | null
}

const uniques = (arr: any[]) => xor(...arr.map(a => [a]))
const duplicates = (arr: any[]) => xor(arr, uniques(arr))

export function GridFormCell({
	colIndex,
	column: {
		enabled,
		fieldType,
		name,
		options,
		defaultValue,
		unique,
		placeholder,
		readOnlyContent,
		onSelectChange,
	},
	enabled: enabledOverride,
	formHandler,
	regexPattern,
	patternError,
	required,
	rowIndex,
	value,
	validationFunction,
	autocompleteOptions,
	onAutocompleteOptionSelected,
	selectInputMenuPortalTarget,
}: Props) {
	const { t } = useTrans()
	const {
		control,
		register,
		formState: { errors },
		formState: { defaultValues },
	} = formHandler

	const formRootName = useMemo(() => Object.keys(defaultValues ?? {})[0], [defaultValues])

	const enabledValue = useMemo(() => {
		return typeof enabled === "function" ? enabled(rowIndex) : enabled
	}, [enabled, rowIndex])

	const fieldName = useMemo(
		() => `${formRootName}.${rowIndex}.${name}`,
		[formRootName, rowIndex, name]
	)

	const showAutocompleteOptions = useMemo(
		() => autocompleteOptions && onAutocompleteOptionSelected && autocompleteOptions?.length > 0,
		[autocompleteOptions, onAutocompleteOptionSelected]
	)

	const isInvalidDuplicate = useCallback(
		(value: string) => {
			if (!unique) return false
			const allValues = formHandler.getValues()[formRootName].map((row: any) => row[name])
			return duplicates(allValues).includes(value)
		},
		[unique, formHandler, formRootName, name]
	)

	const validationAndUnique = useMemo(() => {
		if (!validationFunction) return (value: string) => !isInvalidDuplicate(value)
		return (value: string) => validationFunction(value) && !isInvalidDuplicate(value)
	}, [validationFunction, isInvalidDuplicate])

	const input = useMemo(() => {
		if (!fieldType || fieldType === FormFieldType.TEXT) {
			return (
				<FormInput
					name={fieldName}
					disabled={!enabledOverride || !enabledValue}
					defaultValue={defaultValue}
					value={value}
					error={(errors?.[formRootName] as any)?.[rowIndex]?.[name]}
					register={register}
					regexPattern={regexPattern}
					patternError={patternError}
					required={required}
					validationFunction={validationAndUnique}
					showErrorInTooltip
				/>
			)
		}

		if (fieldType === FormFieldType.PHONE) {
			return (
				<PhoneInput
					name={fieldName}
					control={control}
					regexPattern={regexPattern}
					validationFunction={validationAndUnique}
					patternError={patternError}
				/>
			)
		}

		if (fieldType === FormFieldType.READ_ONLY && readOnlyContent) {
			return readOnlyContent(rowIndex)
		}

		return (
			<SelectInput
				name={fieldName}
				control={control}
				defaultValue={options?.find((o: any) => o.value === defaultValue)}
				isDisabled={!enabledOverride || !enabledValue}
				options={options as any}
				placeholder={placeholder ?? t("formLabels:chooseAlternative")}
				menuPortalTarget={selectInputMenuPortalTarget}
				validationFunction={validationAndUnique}
				onSelectChange={onSelectChange}
			/>
		)
	}, [
		fieldType,
		fieldName,
		enabledOverride,
		enabledValue,
		defaultValue,
		value,
		errors,
		formRootName,
		rowIndex,
		name,
		register,
		regexPattern,
		patternError,
		required,
		control,
		options,
		t,
		validationAndUnique,
		placeholder,
		readOnlyContent,
		selectInputMenuPortalTarget,
		onSelectChange,
	])

	return (
		<div className={classNames("relative", colIndex === 0 ? "mr-1" : "m-1")}>
			{input}
			{showAutocompleteOptions && (
				<div className="absolute z-10 bg-white w-full border-black border-2 border-t-0">
					{autocompleteOptions!.map((option, index) => (
						<div
							className="border-b py-1 px-2 font-dmSans text-sm hover:bg-grey1 cursor-pointer"
							onClick={() => onAutocompleteOptionSelected!(rowIndex, option)}
						>
							{option[name]}
						</div>
					))}
				</div>
			)}
		</div>
	)
}
