import { useCallback, useEffect, useRef, useState } from "react"
import { useGlobalAlert } from "../../States/globalAlert"
import { AlertLine } from "./AlertLine"
import { Transition } from "@headlessui/react"
import { useLocation } from "react-router-dom"

export const GlobalAlert = () => {
	const { globalAlert, setGlobalAlert } = useGlobalAlert()
	const [showTransition, setShowTransition] = useState(false)

	const location = useLocation()
	const prevLocation = useRef(location)

	const animClose = useCallback(() => {
		setShowTransition(false)
		setGlobalAlert(null)
	}, [setShowTransition, setGlobalAlert])

	useEffect(() => {
		if (globalAlert) {
			let timer1: NodeJS.Timeout
			let timer2: NodeJS.Timeout
			let timer3: NodeJS.Timeout

			// Slide in animation
			timer1 = setTimeout(() => setShowTransition(true), 500)
			// If success - slide out animation after 5 sec
			if (!globalAlert.shouldNotDisappear) {
				timer2 = setTimeout(() => {
					setShowTransition(false)
					timer3 = setTimeout(() => setGlobalAlert(null), 500)
				}, 10000)
			}

			// Clear timeouts when closing popup
			return () => {
				if (timer1) clearInterval(timer1)
				if (timer2) clearInterval(timer2)
				if (timer3) clearInterval(timer3)
			}
		}
	}, [globalAlert, setGlobalAlert])

	useEffect(() => {
		// a bit of a hacky solution but it checks the changes in path
		// using only the first two path segments (excluding params)
		// because we need to hide alert when main page changes
		const [firstPath, secondPath] = location.pathname.slice(1).split("/")
		const [prevFirstPath, prevSecondPath] = prevLocation.current.pathname.slice(1).split("/")

		if (firstPath !== prevFirstPath || secondPath !== prevSecondPath) {
			animClose()
		}
		prevLocation.current = location
	}, [location, animClose])

	return (
		globalAlert && (
			<div className="fixed z-[100001] left-[calc(50%-300px)] bottom-5 w-[600px]">
				<Transition
					show={showTransition}
					enter="transition ease duration-1000 transform"
					enterFrom="opacity-100"
					enterTo="opacity-100"
					leave="transition ease duration-1000 transform"
					leaveFrom="opacity-100"
					leaveTo="opacity-100"
				>
					<AlertLine close={animClose} {...globalAlert} />
				</Transition>
			</div>
		)
	)
}
