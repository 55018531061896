import { useConfig } from "api/hooks/useConfig"
import { NavSection } from "components/Sidebar/components/NavSection"
import { NavHeaderTypeEnum } from "components/Sidebar/Sidebar.interface"
import { CrossIcon } from "Icons/cross"
import Logo from "Icons/Logo"
import React from "react"
import { Link } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { useTrans } from "translations"
import { isCarrotAdmin } from "Utils/authUtils"

interface SidebarProps {
	showSidebar: boolean
	toggleMenu: () => void
}

export const Sidebar: React.FC<SidebarProps> = ({ showSidebar, toggleMenu }) => {
	const { t } = useTrans()
	const { config } = useConfig()

	if (!config) return null

	const faqKey = config.useExternalCRM ? "hints:faq_BIR" : "hints:faq"

	return (
		<aside
			className={`fixed inset-y-0 left-0 z-10 flex w-64 ${
				!showSidebar && "-translate-x-full"
			} transform flex-col bg-lime transition duration-200 ease-in-out md:translate-x-0`}
		>
			<div className="bg z-20 flex h-16 w-full items-center bg-butteryLime px-5">
				<Link to="/">
					<div className="flex h-16 items-center">
						<Logo />
					</div>
				</Link>
				<button onClick={toggleMenu} className="visible absolute right-4 md:hidden">
					<CrossIcon className="h-4 w-4" />
				</button>
			</div>
			<nav className="overflow-auto py-8">
				<ul>
					<NavSection type={NavHeaderTypeEnum.General} />
					<NavSection type={NavHeaderTypeEnum.Customers} />
					<NavSection type={NavHeaderTypeEnum.Infrastructure} />
					{isCarrotAdmin() && <NavSection type={NavHeaderTypeEnum.Circularity} />}
				</ul>
			</nav>
			<div className="mt-auto">
				<NavLink
					to={t(faqKey)}
					className="flex h-10 items-center transition-colors hover:bg-butteryLime justify-center underline"
					target="_blank"
				>
					{t("sidebarLabels:faq")}
				</NavLink>
				<p className="mt-2 text-center	text-xs">Carrot Insight v{process.env.REACT_APP_VERSION}</p>
				<p className="mt-2 pb-12 text-center text-xs">
					<a href="mailto:support@carrot.tech" className="underline">
						support@carrot.tech
					</a>
				</p>
			</div>
		</aside>
	)
}

export default Sidebar
