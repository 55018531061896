import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"

type Props = {
	title: string
	hint: string
}

export const AlertModal: React.FC<Props> = ({ title, hint }) => {
	const { hideModal } = useModal()

	return (
		<ModalContainer
			title={title}
			onConfirm={hideModal}
			onCancel={hideModal}
			onConfirmText="common:OK"
			disableCancelButton
		>
			<div className="pt-3 whitespace-pre">
				<p>{hint}</p>
			</div>
		</ModalContainer>
	)
}
