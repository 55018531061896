import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useModal } from "Contexts"
import { EditTenantModal } from "pages/customer/manage/components/realEstate/EditTenantModal"
import { FC } from "react"
import { CardInfo } from "../../../../components/pageCards/cardInfo"
import { useTrans } from "translations"
import {
	BusinessChain,
	Customer,
	CustomerAccessParent,
} from "admin-client-server/src/coreApi/models/RealEstate"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useConfig } from "api/hooks/useConfig"
import { EditCustomerModal } from "pages/customer/manage/components/mwm/EditCustomerModal"
import { AccessToken } from "./accessTokens/types"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/router"

export type CustomerDetails = Customer & {
	businessChain: BusinessChain
	accessParents: CustomerAccessParent[]
	identities: AccessToken[]
	companyCode?: string
}

interface GeneralCustomerInfoCardProps {
	customer?: CustomerWithDetailsAndCode
	onUpdate?: () => Promise<void>
}

export const GeneralCustomerInfoCard: FC<GeneralCustomerInfoCardProps> = ({
	customer,
	onUpdate,
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const { tenantCategories } = useCommonEntitiesStore()
	const { isRealEstate, isMWM, config } = useConfig()

	if (!customer) return null

	const showEditModal = () => {
		if (isRealEstate) {
			showModal(<EditTenantModal customer={customer} onUpdate={onUpdate} />)
		} else {
			showModal(<EditCustomerModal customer={customer} onUpdate={onUpdate} />)
		}
	}

	const throwDelegateValue = customer.throwDelegate === "true" ? t("common:yes") : t("common:no")

	const category = tenantCategories?.find(c => c.id === customer.categoryId)

	const showEditButton = isRealEstate || !config?.useExternalCRM

	return (
		<Card title={"drawerLabels:customerDetails"} variant={CardComponentVariant.DRAWER}>
			<div className="grid grid-cols-1 gap-y-9 gap-x-2 lg:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2">
				<CardInfo
					heading={isMWM ? t("entities:customerReference") : t("formLabels:customerName")}
					value={customer.name}
				/>
				{isMWM && (
					<>
						<CardInfo
							heading={t("statisticsLabels:customerType")}
							value={t(`entities:customerType${customer?.type.toUpperCase()}`)}
						/>
						<CardInfo heading={t("formLabels:address")} value={customer.address} />
						{config?.useExternalCRM ? (
							<CardInfo heading={t("drawerLabels:crmId")} value={customer.crmId} />
						) : (
							<>
								<CardInfo heading={t("formLabels:postalCode")} value={customer.postalCode} />
								<CardInfo heading={t("formLabels:postalRegion")} value={customer.postalRegion} />
							</>
						)}
					</>
				)}
				{isRealEstate && (
					<>
						<CardInfo heading={t("entities:chain")} value={customer?.businessChain?.name} />
						<CardInfo
							heading={t("formLabels:category")}
							value={category?.name || t("genericLabels:unassigned")}
						/>
						<CardInfo heading={t("formLabels:area")} value={customer.area} />
						{customer.companyCode && (
							<CardInfo heading={t("drawerLabels:companyCode")} value={customer.companyCode} />
						)}
						<CardInfo heading={t("formLabels:cleanerPermissions")} value={throwDelegateValue} />
					</>
				)}
			</div>
			<div className="mt-8 mb-2">
				<CardInfo heading={t("drawerLabels:carrotCustomerID")} value={customer.id} />
			</div>
			{showEditButton && (
				<Button
					label={"actions:edit"}
					onClick={showEditModal}
					color="secondary"
					className="float-right mt-10"
				/>
			)}
		</Card>
	)
}
