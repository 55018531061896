export type BUSINESS = "BUSINESS"
export type HOUSEHOLD = "HOUSEHOLD"

export type CUSTOMER_TYPE =
	| "HB"
	| "HA"
	| "BA"
	| "BAVD"
	| "BUSINESS"
	| "BUSINESS_PARENT"
	| "HOUSEHOLD"
	| "HOUSEHOLD_PARENT"

export type ACCESS_POINT_TYPE =
	| "TERMINAL"
	| "ACCESS_PARENT"
	| "GROUP"
	| "ACCESS_POINT"
	| "CONTAINER_ROOT"
	| "CONTAINER"

export type AccessPointStatus = "ACTIVE" | "INACTIVE" | "DEPRECATED" | "PLANNED"

export const ACTIVE = "ACTIVE"
export const INACTIVE = "INACTIVE"
export const DEPRECATED = "DEPRECATED"
export const PLANNED = "PLANNED"

export const ACCESS_POINT_STATUSES: AccessPointStatus[] = [ACTIVE, INACTIVE, DEPRECATED, PLANNED]

export type UPDATE_MODE = "SET" | "MERGE"

export type KeyValInput = {
	key: string
	value?: string
}

export type BASIC_REQUEST_RETURN = {
	primaryKey?: true | undefined
	commandProcessError?: true | undefined
}

export type Input<PAYLOAD> = {
	payload: PAYLOAD
	wait?: boolean
	failOnError?: boolean
	clientMutationId?: string
}

export type geoLocationInput = {
	decimalDegrees?: string
}
