import { RealEstateCategory } from "api/types"
import FormInput from "components/Form/FormInput"
import { FC } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"

export type TerminalFormData = {
	name: string
	category: RealEstateCategory
	contactEmail: string
	address?: string
	area: string
}

interface TerminalFormProps {
	formHandler: UseFormReturn<TerminalFormData>
}

export const TerminalForm: FC<TerminalFormProps> = ({ formHandler }) => {
	const { t } = useTrans()
	const {
		register,
		formState: { errors },
	} = formHandler

	return (
		<div className="mb-6">
			<FormInput
				name="name"
				label={t("formLabels:name")}
				register={register}
				error={errors?.name}
				required
				showErrorInTooltip={false}
			/>
		</div>
	)
}
