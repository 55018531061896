import ArrowDown from "Icons/ArrowDown"
import { CrossIcon } from "Icons/cross"
import { useCallback, useMemo, useState } from "react"
import { useTrans } from "translations"
import { FilterSortSelect } from "./FilterSortSelect"
import { FilterSortTimeFrame } from "./FilterSortTimeFrame"
import { ActiveOptions, useFilterSort } from "./FilterSortContext"
import { FilterSort, FilterSortMenuType } from "./types"

type FilterSortMenuProps = {
	close: () => void
	filterSorts: FilterSort[]
	initialFilterSortOpenId?: string
	collapsable?: boolean
}

export const FilterSortMenu: React.FC<FilterSortMenuProps> = ({
	filterSorts,
	close,
	initialFilterSortOpenId,
	collapsable,
}) => {
	const { t } = useTrans()
	const { reset, resetAll, activeOptions } = useFilterSort()
	const [filterSortOpen, setFilterSortOpen] = useState<string | undefined>(initialFilterSortOpenId)

	const openFilterSort = useMemo(
		() => filterSorts.find(filterSort => filterSort.id === filterSortOpen),
		[filterSortOpen, filterSorts]
	)
	const showBackButton = useMemo(() => filterSortOpen && collapsable, [collapsable, filterSortOpen])

	const onBack = useCallback(() => {
		setFilterSortOpen(undefined)
	}, [])

	const renderContentByMenuType = useCallback(
		(filter: FilterSort) => {
			switch (filter.menuType) {
				case FilterSortMenuType.Select:
					return <FilterSortSelect filterSort={filter} close={close} />
				case FilterSortMenuType.TimeFrame:
					return <FilterSortTimeFrame filterSort={filter} close={close} />
				default:
					return null
			}
		},
		[close]
	)

	const handleResetClick = useCallback(
		(id?: string) => {
			id ? reset(id) : resetAll()
			close()
		},
		[close, reset, resetAll]
	)

	const showResetAllButton = useMemo(() => {
		return (
			(openFilterSort &&
				openFilterSort.type === "filter" &&
				openFilterSort.menuType === FilterSortMenuType.Select) ||
			collapsable
		)
	}, [collapsable, openFilterSort])

	return (
		<div className="mt-2 min-w-[256px] border shadow-md bg-white">
			<div className="p-3">
				<div className="flex justify-between gap-2">
					<div className="flex gap-3">
						{showBackButton && (
							<button onClick={onBack}>
								<ArrowDown className="rotate-90 stroke-2" />
							</button>
						)}
						<div className="whitespace-nowrap font-medium text-sm">
							{openFilterSort ? t(openFilterSort.title) : t("filterLabels:filters")}
						</div>
					</div>
					<button className="self-center" onClick={close}>
						<CrossIcon />
					</button>
				</div>
				<div className="pt-[14px]">
					{openFilterSort ? (
						renderContentByMenuType(openFilterSort)
					) : (
						<div className="divide-y text-sm leading-tight">
							{filterSorts.map(filterSort => (
								<FilterSortMenuItem
									key={filterSort.id}
									{...filterSort}
									activeOptions={activeOptions}
									onClick={() => setFilterSortOpen(filterSort.id)}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			{showResetAllButton && (
				<>
					<hr />
					<div className="px-5 py-3">
						<button
							onClick={() => handleResetClick(openFilterSort?.id)}
							className="text-grey6 text-sm hover:underline"
						>
							{openFilterSort ? t("filterLabels:resetFilter") : t("filterLabels:resetFilters")}
						</button>
					</div>
				</>
			)}
		</div>
	)
}

type FilterSortMenuItemProps = FilterSort & {
	onClick: () => void
	activeOptions: ActiveOptions<any>
}

const FilterSortMenuItem: React.FC<FilterSortMenuItemProps> = ({
	title,
	onClick,
	activeOptions,
	id,
}) => {
	const { t } = useTrans()

	const hasActiveOptions = useMemo(() => !!activeOptions?.[id]?.length, [activeOptions, id])

	const subtitle = useMemo(
		() =>
			hasActiveOptions
				? activeOptions[id]
						.map(({ option, translate }) =>
							option === "interval.custom"
								? t("genericLabels:custom")
								: option && translate
								? t(option)
								: option
						)
						.join(", ")
				: t("actions:selectProperty", { property: t(title).toLowerCase() }),
		[hasActiveOptions, activeOptions, id, t, title]
	)

	return (
		<button className="w-full flex justify-between p-3 hover:bg-grey1" onClick={onClick}>
			<div className="text-left">
				{t(title)}
				<div className="text-grey6">{subtitle}</div>
			</div>
			<ArrowDown className="-rotate-90 self-center" />
		</button>
	)
}
