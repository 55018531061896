export const IconWarning = ({ width = 20, height = 20 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM9 9V15H11V9H9ZM9 5V7H11V5H9Z"
			fill="#E87205"
		/>
	</svg>
)
