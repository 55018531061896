import { Page } from "components/Page"
import { ComparisonWastePerTenant } from "./components/comparisonWastePerTenant"
import { ComparisonWasteTypeDistribution } from "./components/comparisonWasteTypeDistribution"
import DevelopmentInWasteTypes from "./components/DevelopmentInWasteTypes"
import { OverviewKeyNumbers } from "./components/overviewKeyNumbers"
import { RecyclingRateOverTime } from "./components/recyclingRateOverTime"
import { WasteTypeDistribution } from "./components/wasteTypeDistribution"
import { WasteTypesInUseCard } from "./components/WasteTypesInUse"

export const CircularityOverview = () => {
	return (
		<Page title="sidebarLabels:circularityOverview">
			<div className="grid grid-cols-1 gap-2 xl:grid-cols-2">
				<OverviewKeyNumbers />
				<WasteTypesInUseCard />
				<RecyclingRateOverTime />
				<ComparisonWasteTypeDistribution />
				<ComparisonWastePerTenant />
				<WasteTypeDistribution />
				<DevelopmentInWasteTypes />
			</div>
		</Page>
	)
}

export default CircularityOverview
