import { Card, CardComponentVariant } from "components/pageCards/card"
import { Table } from "components/Table"
import { FC, useMemo } from "react"
import { useTrans } from "translations/functions/hook"
import { accessManagementColumns } from "Utils/tableColumns/accessManagementColumns"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/router"
import { trpc } from "Utils/trpc"
import { NormalizedCustomerIdentities } from "admin-client-server/src/coreApi/models/RealEstate"
import { Button } from "components/button"
import { AccessTokensModal } from "./accessTokens/AccessTokensModal"
import { useModal } from "Contexts"
import { useConfig } from "api/hooks/useConfig"

interface AccessManagementCardProps {
	customer: CustomerWithDetailsAndCode
	refetch: () => Promise<any>
}

const ACCESS_TOKENS_LIMIT = 50

export const AccessManagementCard: FC<AccessManagementCardProps> = ({ customer }) => {
	const { showModal } = useModal()
	const { config } = useConfig()
	const { t } = useTrans()

	const { data: identitiesPages, refetch } = trpc.identities.get.useQuery(
		{
			customerIds: [customer.id],
			limit: ACCESS_TOKENS_LIMIT + 1,
		},
		{
			cacheTime: 0,
		}
	)

	const identities = useMemo(
		() => (identitiesPages?.items || []) as NormalizedCustomerIdentities,
		[identitiesPages]
	)

	const identitiesWithTranslatedStatus = identities.slice(0, ACCESS_TOKENS_LIMIT).map(el => ({
		...el,
		status: t(`status:${el.status}`),
	}))

	const showPreview = useMemo(() => identities.length > ACCESS_TOKENS_LIMIT, [identities])

	const manageAccess = () => {
		showModal(
			<AccessTokensModal customerId={customer.id} customerName={customer.name} refetch={refetch} />
		)
	}

	return (
		<Card
			{...{
				title: "drawerLabels:accessManagement",
			}}
			overflow="auto"
			variant={CardComponentVariant.DRAWER}
		>
			<p className="text-sm font-dmSans mb-2">{t("hints:accessTokenCard")}</p>
			<Table
				limitedHeight
				columns={accessManagementColumns}
				data={identitiesWithTranslatedStatus}
				emptyViewType="noAccessTokens"
				emptyViewClassName="bg-grey1 mt-0"
				showPreview={showPreview}
				previewHintKey="hints:previewAccessTokens"
			/>
			{(!config.useExternalCRM || !customer.crmId) && (
				<div className="py-4 h-20">
					<Button
						className="float-right ml-8"
						label="actions:edit"
						color="secondary"
						onClick={manageAccess}
					/>
				</div>
			)}
		</Card>
	)
}
