import { trpc } from "Utils/trpc"
import { useEffect, useMemo } from "react"
import { useTrans } from "translations"
import { combine } from "zustand/middleware"
import create from "zustand"
import { useTerminalsState } from "States/Terminals"
import { uniq } from "lodash"
import { useGlobalAlert } from "States/globalAlert"

export type WasteTypeConfig = {
	wasteTypeCode: string
	color: string | null
	name?: string
	handledBySmartInfra?: boolean
}

export type WasteTypeData = {
	wasteTypeCode: string
	names: {
		displayName: string
		locale: string
	}[]
	color: string | null
}

export const wasteTypeConfigStore = create<{
	wasteTypeConfig: WasteTypeConfig[]
	setWasteTypeConfig: (wasteTypeConfig: WasteTypeConfig[]) => void
}>(
	combine(
		{
			wasteTypeConfig: [],
		} as any,
		set =>
			({
				setWasteTypeConfig: (wasteTypeConfig: WasteTypeConfig[]) => set({ wasteTypeConfig }),
			}) as any
	)
)

type UseConfigServiceOptions = {
	terminalIdParam?: string
	queriesEnabled?: boolean
}

export const useConfigService = ({
	terminalIdParam,
	queriesEnabled,
}: UseConfigServiceOptions = {}) => {
	const { language } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()
	const { currentTerminal } = useTerminalsState()
	const terminalId = terminalIdParam || currentTerminal?.id
	const { data: clientWasteTypeConfig, refetch: refetchClientWasteTypeConfig } =
		trpc.config.getClientWasteTypeConfig.useQuery(undefined, {
			enabled: queriesEnabled && !!terminalId,
		})
	const { data: terminalWasteTypeConfig = [], refetch: refetchTerminalWasteTypeConfig } =
		trpc.config.getTerminalWasteTypeConfig.useQuery(
			{ terminalId },
			{
				enabled: queriesEnabled && !!terminalId,
				select: data =>
					data.map(el => ({
						...el,
						name: el.names.find(n => n.locale === language)?.displayName,
					})),
			}
		)

	const { setWasteTypeConfig } = wasteTypeConfigStore()
	const { data: terminalConfig, refetch: refetchTerminalConfig } =
		trpc.config.getTerminalConfig.useQuery(
			{ terminalId },
			{ enabled: queriesEnabled && !!terminalId }
		)

	const { mutate: updateTerminalConfig } = trpc.config.updateTerminalConfig.useMutation({
		onSuccess: () => {
			refetchTerminalConfig()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
		},
		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const localizedClientConfig: WasteTypeConfig[] = useMemo(
		() =>
			clientWasteTypeConfig?.map(el => ({
				wasteTypeCode: el.wasteTypeCode,
				color: el.color,
				name: el.names.find(n => n.locale === language)?.displayName,
			})) || [],
		[clientWasteTypeConfig, language]
	)

	const unifiedLocalizedConfig: WasteTypeConfig[] = useMemo(() => {
		const wasteTypesWithConfig = uniq([
			...localizedClientConfig.map(el => el.wasteTypeCode),
			...terminalWasteTypeConfig.map(el => el.wasteTypeCode),
		])

		const unifiedConfig = wasteTypesWithConfig.map(wasteTypeCode => {
			const clientConfig = localizedClientConfig.find(el => el.wasteTypeCode === wasteTypeCode)
			const twtc = terminalWasteTypeConfig.find(el => el.wasteTypeCode === wasteTypeCode)

			return {
				wasteTypeCode,
				color: twtc?.color || clientConfig?.color || "",
				name: twtc?.name || clientConfig?.name,
				handledBySmartInfra: twtc?.handledBySmartInfra,
			}
		})

		return unifiedConfig
	}, [localizedClientConfig, terminalWasteTypeConfig])

	useEffect(() => {
		setWasteTypeConfig(unifiedLocalizedConfig)
	}, [unifiedLocalizedConfig, setWasteTypeConfig])

	return {
		wasteTypeClientConfig: localizedClientConfig,
		wasteTypeTerminalConfig: terminalWasteTypeConfig,
		wasteTypeConfig: unifiedLocalizedConfig,
		clientWasteTypeConfig: clientWasteTypeConfig || [],
		terminalWasteTypeConfig,
		refetchClientWasteTypeConfig,
		refetchTerminalWasteTypeConfig,
		terminalConfig,
		refetchTerminalConfig,
		updateTerminalConfig,
	}
}
