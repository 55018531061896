import { cloneDeep, orderBy } from "lodash"
import { ActiveOptions } from "./FilterSortContext"
import { Option } from "./types"

export const OPTION_ALL = "all"
export const SORTING_KEY = "sorting"

// applies a filter state to an array of objects
// the filter keys must corespond to the property key on the object
export const applyFilters = <T>(data: T[], filterState: ActiveOptions<string>) => {
	let organizedData = cloneDeep(data)
	Object.keys(filterState)
		.filter(key => key !== SORTING_KEY)
		.forEach(key => {
			const activeOptions = filterState[key]
			const values = activeOptions?.map(({ value }) => value) || []

			if (!values.includes(OPTION_ALL)) {
				organizedData = organizedData.filter((el: any) => values.includes(el[key]))
			}
		})

	if (filterState[SORTING_KEY]?.length > 0) {
		const [prop, direction] = filterState[SORTING_KEY][0].value.split("|")

		organizedData = orderBy(organizedData, [prop], [direction as "asc" | "desc"])
	}

	return organizedData
}

export const hasNonAllOptionActive = (options?: (Partial<Option> & { value: string })[]) => {
	return options?.some(option => option.value !== OPTION_ALL)
}
