export const Union = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.6875 0.5625V17.4375H7.3125V0.5625H10.6875ZM5.625 7.47276V17.4375H3.09375L2.25 16.5938V12.4551V7.47276H5.625ZM15.75 10.8014V5.07261L14.9062 4.22886H12.375V17.4375H15.75V10.8014Z"
			fill="currentColor"
		/>
	</svg>
)
