import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useTrans } from "translations"

export const ConfirmModal: React.FC<{
	pointName: string | undefined
	onDelete?: () => void
}> = ({ pointName, onDelete }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()

	const onConfirm = () => {
		hideModal()
		onDelete?.()
	}

	return (
		<ModalContainer
			title={"actions:delete"}
			onConfirmText={"actions:confirm"}
			onConfirm={onConfirm}
			onCancel={hideModal}
		>
			<div className="pt-3">
				<p>{t("hints:areYouSureDelete", { name: pointName })}</p>
			</div>
		</ModalContainer>
	)
}
