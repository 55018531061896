import { FC } from "react"

export const Meatballs: FC = () => (
	<svg width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="2" cy="2.5" r="2" fill="#323232" />
		<circle cx="2" cy="9.5" r="2" fill="#323232" />
		<circle cx="2" cy="16.5" r="2" fill="#323232" />
	</svg>
)

export default Meatballs
