import Checkbox from "Icons/Checkbox"
import PartialCheckbox from "Icons/PartialCheckbox"
import { FC, useMemo } from "react"
import { twMerge } from "tailwind-merge"

const CLICKABLE_EXPAND_STYLE = {
	minWidth: 25,
	minHeight: 25,
	paddingTop: 4,
	paddingLeft: 4,
	marginTop: -4,
	marginLeft: -4,
}

export const TableCheckbox: FC<{
	isHeader?: boolean
	isChecked?: boolean
	isPartialChecked?: boolean
	onChange?: (e: React.MouseEvent) => any
	isVisibleByDefault?: boolean
	stickyLeft?: boolean
}> = props => {
	const {
		isChecked,
		isPartialChecked,
		isVisibleByDefault = true,
		onChange,
		isHeader,
		stickyLeft,
	} = props

	const handleClick = (e: React.MouseEvent) => onChange && onChange(e)

	const checkboxView = useMemo(() => {
		if (isPartialChecked && isHeader) {
			return <PartialCheckbox />
		}

		if (isChecked) {
			return <Checkbox />
		}

		return (
			<div
				className={twMerge(
					"peer h-[18px] w-[18px] cursor-pointer border border-black p-0.5",
					"group-hover:visible",
					!isVisibleByDefault && !isChecked && "invisible"
				)}
			/>
		)
	}, [isChecked, isPartialChecked, isVisibleByDefault, isHeader])

	return (
		<div
			className={twMerge(isHeader && "p-2.5 sticky top-0", stickyLeft && "sticky left-0")}
			style={!isHeader ? CLICKABLE_EXPAND_STYLE : {}} // added to expand a bit the clickable area
			onClick={handleClick}
		>
			{checkboxView}
		</div>
	)
}
