import {
	createTimeFilters,
	FilterTimeFrameValue,
	getTenantCategoryOptions,
} from "components/pageCards/filterSort/filterCreators"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import moment from "moment-timezone"
import { useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { OPTION_ALL, hasNonAllOptionActive } from "components/pageCards/filterSort/constants"
import { timezoneStore } from "api/hooks/useConfig"

const defaultTimeframe = FilterTimeFrameValue.LAST_30_DAYS

export const usePerformance = () => {
	const [{ timeframe: [selectedTimeframe] = [], tenantCategory }, setActiveOptions] = useState<
		ActiveOptions<"timeframe" | "tenantCategory">
	>({} as any)

	const { timezone } = timezoneStore()
	const { startTimeISO, endTimeISO } = useMemo(() => {
		return getTimeFrame((selectedTimeframe?.value as FilterTimeFrameValue) ?? defaultTimeframe)
	}, [selectedTimeframe])

	// The start of the comparison period (an equal length period ending when the current period starts)
	const firstPeriodStartTimeISO = useMemo(() => {
		return moment(startTimeISO)
			.subtract(moment(endTimeISO).diff(moment(startTimeISO), "days"), "days")
			.tz(timezone)
			.toISOString()
	}, [startTimeISO, endTimeISO, timezone])

	const { currentTerminal } = useTerminalsState()
	const { tenantCategories } = useCommonEntitiesStore()

	const filters: FilterSort[] = [
		createTimeFilters({ id: "timeframe", defaultValue: defaultTimeframe }, [
			FilterTimeFrameValue.LAST_30_DAYS,
			FilterTimeFrameValue.LAST_6_FULL_MONTHS,
			FilterTimeFrameValue.LAST_FULL_YEAR,
		]),
		{
			options: getTenantCategoryOptions(tenantCategories),
			title: "statisticsLabels:tenantCategory",
			id: "tenantCategory",
			type: "filter",
			menuType: FilterSortMenuType.Select,
			defaultValue: OPTION_ALL,
		},
	]

	const tenantCategoryValue = useMemo(() => {
		return hasNonAllOptionActive(tenantCategory) ? tenantCategory[0].value : undefined
	}, [tenantCategory])

	const {
		data: firstPeriod,
		isLoading: isFirstPeriodLoading,
		isError: isFirstPeriodError,
	} = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: {
			startTimeISO: firstPeriodStartTimeISO,
			endTimeISO: startTimeISO,
			commercialCustomerCategory: tenantCategoryValue,
		},
	})

	const {
		data: currentPeriod,
		isLoading: isCurrentPeriodLoading,
		isError: isCurrentPeriodError,
	} = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: { startTimeISO, endTimeISO, commercialCustomerCategory: tenantCategoryValue },
	})

	const isLoading = isFirstPeriodLoading || isCurrentPeriodLoading
	const isError = isFirstPeriodError || isCurrentPeriodError

	return { filters, firstPeriod, currentPeriod, isLoading, isError, setActiveOptions }
}
