import { FetchedWasteTypes } from "./api/sanity/types"

export const getWasteTypeLabels = (fractionId: string, wasteTypes: FetchedWasteTypes) => {
	const wasteType = wasteTypes?.find(wt => wt.id === fractionId)
	const primaryLabel = wasteType?.name || `Unknown id ${fractionId}`
	const englishLabel = wasteType?.englishName || `Unknown id ${fractionId}`

	return {
		primaryLabel,
		englishLabel: primaryLabel !== englishLabel ? englishLabel : "",
	}
}
