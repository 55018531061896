import { useConfig } from "api/hooks/useConfig"
import { updateAccessPoints } from "api/updateAccessPoints"
import { Button } from "components/button"
import { IInputOption, Input } from "components/GenericComponents/input"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { FC, useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { trpc } from "Utils/trpc"

export const ContainerBulkMove: FC<{}> = () => {
	const { t } = useTrans()
	const navigate = useNavigate()
	const { currentTerminal } = useTerminalsState()
	const { isMWM } = useConfig()

	const { data: accessParents } = trpc.accessParents.getAll.useQuery({
		terminalId: isMWM ? undefined : currentTerminal?.id,
	})
	const { selectedContainers, setSelectedContainers } = useMultiSelectContext()
	const { accessParentId: urlAccessParentId, terminalId: urlTerminalId } = useParams<{
		accessParentId?: string
		terminalId?: string
	}>()
	const [newAccessParentId, setNewAccessParentId] = useState<string | undefined>()
	const { setGlobalAlert } = useGlobalAlert()
	const [isSaving, setIsSaving] = useState(false)
	const { refetchAll } = useAccessPoints({ accessParentId: urlAccessParentId! })
	const trpcUtils = trpc.useUtils()
	const { refetch: movedToAccessParentRefresh } = useAccessPoints({
		accessParentId: newAccessParentId!,
	})

	const options: IInputOption[] = useMemo(
		() =>
			accessParents?.map(el => ({
				value: el.id || "",
				option: el.name,
				externalId: el.externalId || "",
			})) ?? [],
		[accessParents]
	)

	const navigateToAccessParent = useCallback(
		(id: string) => {
			const url = getInfrastructureUrl({
				terminalId: urlTerminalId,
				accessParentId: id,
			})

			navigate(url)
		},
		[urlTerminalId, navigate]
	)

	const onAccessParentChange = useCallback((option: IInputOption) => {
		setNewAccessParentId(option.value)
	}, [])

	const moveContainers = useCallback(() => {
		if (newAccessParentId && newAccessParentId !== urlAccessParentId) {
			setIsSaving(true)
			updateAccessPoints({
				accessPoints: selectedContainers.map(({ name, id }) => {
					return {
						accessPoint: {
							id,
						},
						updateFields: {
							name,
							type: "ACCESS_POINT",
						},
						propertiesUpdate: {
							mode: "MERGE",
						},
						parent: {
							id: newAccessParentId,
						},
					}
				}),
				onStartCallBack: async () => {
					await trpcUtils.accessPoints.invalidate()
					await refetchAll()
					await movedToAccessParentRefresh()
					setIsSaving(false)
					setGlobalAlert({
						type: "success",
						message: `systemMessages:${
							selectedContainers.length === 1 ? "containerMoved" : "containersMoved"
						}`,
					})
					setSelectedContainers([])
					navigateToAccessParent(urlAccessParentId || "")
				},
				onFailCallBack: () => {
					setIsSaving(false)
					setGlobalAlert({
						type: "error",
						message: `systemMessages:${
							selectedContainers.length === 1 ? "containerMoveFailed" : "containersMoveFailed"
						}`,
					})
				},
			})
		}
	}, [
		selectedContainers,
		movedToAccessParentRefresh,
		setSelectedContainers,
		newAccessParentId,
		urlAccessParentId,
		navigateToAccessParent,
		setGlobalAlert,
		refetchAll,
		trpcUtils,
	])

	const actionName = useMemo(
		() => (selectedContainers.length > 1 ? "actions:moveContainers" : "actions:moveContainer"),
		[selectedContainers]
	)

	return (
		<Card title={actionName} variant={CardComponentVariant.DRAWER}>
			<p className="text black mb-4 font-dmSans text-sm">
				{t("hints:pickEntityToMove", { entity: t("entities:accessParent").toLowerCase() })}
			</p>
			<div className="flex flex-col">
				<Input
					data={options || []}
					dropdownStyle={{ maxHeight: 190 }}
					dropdownIcon
					initialValue={urlAccessParentId || ""}
					onChange={onAccessParentChange}
					ignoreOnRenderChange
				/>
			</div>
			<Button
				className="float-right mt-8"
				label={actionName}
				loading={isSaving}
				color="secondary"
				onClick={moveContainers}
			/>
		</Card>
	)
}
