import { cloneDeep } from "lodash"
import { MutableRefObject, useEffect, useRef, useState } from "react"
import { setDeepValue } from "Utils/object"
import create from "zustand"
import { combine } from "zustand/middleware"
import { MultiListSectionConfig } from "./multiListSection"

export type useMultiListSectionState = {
	isOpen: boolean
	ref?: MutableRefObject<HTMLDivElement | null>
}

export type useMultiListInitialState = {
	selectedDrawerIndex?: number
	isDrawerOpen?: boolean
	selectedSectionIndex?: number
}

const getInitialSectionState = (
	section: MultiListSectionConfig,
	index: number,
	initialState?: useMultiListInitialState
): useMultiListSectionState => {
	const { selectedSectionIndex = 0 } = initialState || {}
	return {
		isOpen: index === selectedSectionIndex ? true : false,
	}
}

const CLOSED_SECTION_WIDTH = 72
const GAP_WIDTH = 12
const MIN_SPACE_FOR_SECTION_TO_OPEN = 450
const MIN_SPACE_FOR_SECTION = 400
const DRAWER_MAX_WIDTH = 672

/** Returns a state with zustand store function call
 * to use zustand as component state instead of global*/
export const useMultiListState = (
	sectionsConfig: MultiListSectionConfig[],
	initialState?: useMultiListInitialState
) => {
	const ref = useRef<null | HTMLDivElement>(null)

	const [state] = useState(() => {
		const { isDrawerOpen = true, selectedDrawerIndex = 0 } = initialState || {}
		return create(
			combine(
				{
					ref,
					containerWidth: 0,
					sections: sectionsConfig.reduce(
						(prev, section, index) => ({
							...prev,
							[index]: getInitialSectionState(section, index, initialState),
						}),
						{}
					) as { [section: number]: useMultiListSectionState },
					drawer: {
						selectedDrawerIndex,
						isOpen: isDrawerOpen,
					},
				},
				(set, get) => ({
					set: <K extends keyof ReturnType<typeof get>>(key: K, value: ReturnType<typeof get>[K]) =>
						set({ [key]: value } as any), // any is fine as long as input type is correct
					setSection: (
						index: number,
						value:
							| ((section: useMultiListSectionState) => Partial<useMultiListSectionState>)
							| Partial<useMultiListSectionState>
					) =>
						set(({ sections }) => {
							return {
								sections: setDeepValue(
									cloneDeep(sections),
									index,
									typeof value === "function" ? value(sections[index]) : value
								),
							}
						}),
					setDrawer: (value: Partial<ReturnType<typeof get>["drawer"]>) =>
						set(({ drawer }) => ({
							drawer: setDeepValue(cloneDeep(drawer), "", value),
						})),
					computeSpaces: (conditions?: { sectionsToOpen?: number; drawerWillOpen?: boolean }) => {
						const { drawer, sections } = get()
						const { width: containerWidth = 0 } = ref.current?.getBoundingClientRect() || {}

						const sectionsToOpen = conditions?.sectionsToOpen ?? 0
						const _sections = Object.values(sections)
						const _openedSections = _sections.filter(s => s.isOpen).length + sectionsToOpen
						const _closedSections = _sections.filter(s => !s.isOpen).length - sectionsToOpen

						const openedSections = _openedSections || 1 // Calcalate widths as if there were atleast 1 open
						const closedSections = _closedSections - (_openedSections === 0 ? 1 : 0)

						const closedSectionsWidth = closedSections * (CLOSED_SECTION_WIDTH + GAP_WIDTH)
						const drawerUnparsedWidth =
							conditions?.drawerWillOpen ?? drawer.isOpen
								? (containerWidth - closedSectionsWidth - openedSections * GAP_WIDTH) /
								  (openedSections + 1)
								: 0
						const drawerWidth =
							drawerUnparsedWidth > DRAWER_MAX_WIDTH ? DRAWER_MAX_WIDTH : drawerUnparsedWidth
						const openSectionWidth =
							(containerWidth - closedSections * (CLOSED_SECTION_WIDTH + GAP_WIDTH) - drawerWidth) /
								openedSections -
							GAP_WIDTH
						const firstOpenSection = Object.values(sections).findIndex(({ isOpen }) => isOpen)

						return {
							openedSections: _openedSections,
							closedSections: _closedSections,
							closedSectionsWidth,
							openSectionWidth,
							drawerWidth,
							firstOpenSection,
						}
					},
					onSectionClick: (index: number) => {
						const { sections, drawer } = get()
						const { openedSections, openSectionWidth } = computeSpaces()
						const { openSectionWidth: openNextSectionWidth, firstOpenSection } = computeSpaces({
							sectionsToOpen: 1,
						})
						const thisSection = sections?.[index]
						const isDrawerOpen = drawer.isOpen

						if (
							(isDrawerOpen && openSectionWidth < MIN_SPACE_FOR_SECTION) ||
							drawer.selectedDrawerIndex < index
						)
							setDrawer({ isOpen: false })
						if (thisSection?.isOpen && openedSections > 1) setSection(index, { isOpen: false })
						if (!thisSection?.isOpen) {
							if (openNextSectionWidth < MIN_SPACE_FOR_SECTION_TO_OPEN)
								setSection(firstOpenSection, { isOpen: false })
							setSection(index, { isOpen: true })
						}
					},
					onSectionEntryClick: (sectionIndex: number) => {
						const { sections } = get()
						const isDrawer = !!sectionsConfig[sectionIndex].drawer
						const isPrevSectionOpen = !!sections[sectionIndex - 1]?.isOpen
						const isNextSection = !!sections[sectionIndex + 1]
						const isNextSectionOpen = !!sections[sectionIndex + 1]?.isOpen
						const { openSectionWidth } = computeSpaces({
							sectionsToOpen: !isNextSection || isNextSectionOpen ? 0 : 1,
							drawerWillOpen: true,
						})
						const closeCurrentSection = openSectionWidth < MIN_SPACE_FOR_SECTION
						const openNextSectionWidth = computeSpaces({
							sectionsToOpen: 0,
							drawerWillOpen: true,
						}).openSectionWidth
						const openNextSection = openNextSectionWidth > MIN_SPACE_FOR_SECTION
						// If little space close this section
						if (closeCurrentSection && isPrevSectionOpen) {
							setSection(sectionIndex - 1, { isOpen: false })
						} else if (closeCurrentSection) setSection(sectionIndex, { isOpen: false })
						if (isNextSection && openNextSection) setSection(sectionIndex + 1, { isOpen: true })
						if (isDrawer) setDrawer({ isOpen: true, selectedDrawerIndex: sectionIndex })
					},
					onLinkClick: (sectionIndex: number) => {
						const { sections } = get()
						const isNextSection = !!sections[sectionIndex + 1]
						const isNextSectionOpen = !!sections[sectionIndex + 1]?.isOpen
						const { openSectionWidth } = computeSpaces({
							sectionsToOpen: !isNextSection || isNextSectionOpen ? 0 : 1,
							drawerWillOpen: true,
						})
						const closeCurrentSection = openSectionWidth < MIN_SPACE_FOR_SECTION
						if (closeCurrentSection) {
							setSection(sectionIndex, { isOpen: false })
						}
						if (isNextSection) {
							setSection(sectionIndex + 1, { isOpen: true })
						}
						setDrawer({ isOpen: false, selectedDrawerIndex: sectionIndex })
					},
					onTableRowClick: (sectionIndex: number, selectedRowsCount: number) => {
						const hasOneOrMoreSelectedRows = selectedRowsCount > 0
						// We want to keep the table open when multiselecting rows
						const hasMultipleSelectedRows = selectedRowsCount > 1
						const { openSectionWidth } = computeSpaces({
							sectionsToOpen: 1,
							drawerWillOpen: hasOneOrMoreSelectedRows,
						})
						const closeCurrentSection =
							!hasMultipleSelectedRows && openSectionWidth < MIN_SPACE_FOR_SECTION

						if (closeCurrentSection) setSection(sectionIndex, { isOpen: false })
					},
				})
			)
		)
	})

	const { set, setDrawer, setSection, computeSpaces, drawer } = state()

	useEffect(() => {
		// Update drawer state when initialState changes
		if (initialState?.selectedDrawerIndex !== undefined)
			setDrawer({ selectedDrawerIndex: initialState?.selectedDrawerIndex })
	}, [initialState?.selectedDrawerIndex, setDrawer])

	const onResize = () => {
		const { width = 0 } = ref.current?.getBoundingClientRect() || {}
		const { openedSections, openSectionWidth, firstOpenSection } = computeSpaces()
		// open or close sections if there is little space left when resizing
		if (openSectionWidth < MIN_SPACE_FOR_SECTION && firstOpenSection >= 0)
			setSection(firstOpenSection, { isOpen: false })
		else if (openSectionWidth > MIN_SPACE_FOR_SECTION && openedSections === 0) {
			// open section whos drawer is open
			setSection(drawer.selectedDrawerIndex, { isOpen: true })
		}
		set("containerWidth", width)
	}

	const onInitialize = () => {
		onResize()
		// Open sections based on space available
		sectionsConfig.forEach((_, index) => {
			if (index === 0) return
			const { openSectionWidth } = computeSpaces({ sectionsToOpen: index })
			if (openSectionWidth > MIN_SPACE_FOR_SECTION) setSection(index, { isOpen: true })
		})
	}

	useEffect(() => {
		onInitialize()
		// Add event listener for getting container width on window resize
		window?.addEventListener("resize", onResize)
		return () => window?.removeEventListener("resize", onResize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return state()
}
