import { orderBy } from "lodash"
import { useState } from "react"

export const useSorting = (defaultHeader: string = "name") => {
	const [activeSort, setActiveSort] = useState<{ [key: string]: "asc" | "desc" }>({
		[defaultHeader]: "asc",
	})

	const field = Object.entries(activeSort).map(([key]) => key)[0]
	const direction = activeSort[field]

	const changeSorting = (name: string) => {
		setActiveSort(prevState => {
			const direction = prevState[name] === "desc" ? "asc" : "desc"

			return {
				[name]: direction,
			}
		})
	}

	return { field, direction, changeSorting }
}

export default useSorting

/** Orders array of objects by the given field.
 * Uses a special case (grouping <1000 values at the end) if sorting by the "wasteCode" field.
 * */
export function orderByWithWasteCodeSpecialCase<T extends { wasteCode: any }>(
	data: T[],
	field: string,
	direction: "asc" | "desc"
): T[] {
	if (field === "wasteCode") {
		return orderFieldByWithWasteCodeSpecialCase(data, field, direction)
	}
	return orderBy(data, field, direction)
}

/** Orders array of objects by the given field, but grouping <1000 values at the end. */
export function orderFieldByWithWasteCodeSpecialCase(
	data: any[],
	field: string,
	direction: "asc" | "desc" = "asc"
) {
	const standardCodes = data.filter(wt => Number(wt[field]) > 999)
	const otherCodes = data.filter(wt => Number(wt[field]) < 1000)
	return orderBy(standardCodes, field, direction).concat(orderBy(otherCodes, field, direction))
}

/** Orders an array of waste codes ascending or descending with all special (<1000) codes sorted at the end. */
export function orderArrayByWithWasteCodeSpecialCase(
	data: string[],
	direction: "asc" | "desc" = "asc"
): string[] {
	const standardCodes = data.filter(wt => Number(wt) > 999)
	const otherCodes = data.filter(wt => Number(wt) < 1000)
	switch (direction) {
		case "asc":
			return orderBy(standardCodes).concat(orderBy(otherCodes))
		case "desc":
			return orderBy(standardCodes.reverse()).concat(orderBy(otherCodes).reverse())
	}
}
