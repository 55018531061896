import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { Terminal } from "States/Terminals"
import { useGeneralConfig } from "../States/generalConfig"
import { IAuthJwt } from "../types"
import { monad } from "Utils/fs"

// Empty string ("") if not finding jwt token cookie
export const getTokenFromCookie = () =>
	document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1")

export const parseJwtToken = (token: string): IAuthJwt | null => {
	try {
		if (typeof token === "string") {
			return JSON.parse(atob(token.split(".")[1]))
		} else {
			console.error("Please make sure the token specified is a string")
			return null
		}
	} catch (e) {
		console.error("Could not parse the token. Please try again.")
		return null
	}
}

export const getJwtObj = () => {
	return monad(getTokenFromCookie()).map(token => (token ? parseJwtToken(token) : null))[0]
}

export type ICommonJwt = {
	exp: number
	iat: number
	iss: string
	permissions?: string[]
	sub: string
} | null

export const CARROT_ADMIN = "wiq-admin"
export const ADMIN = "adminclient-admin"

export const getCommonJwt = (): ICommonJwt =>
	[useGeneralConfig.getState()].map(({ authJwt }) => authJwt)[0]

export const getPermissions = () => getCommonJwt()?.permissions || []

export const hasPermissions = (permissions: string[]) =>
	permissions.every(p => getPermissions().includes(p))

export const isCarrotAdmin = () =>
	getCommonJwt()?.iss === "waste-iq" || getPermissions().includes(CARROT_ADMIN)

export const isAdmin = () => isCarrotAdmin() || getPermissions().includes(ADMIN)

export const hasGeneralBuildingAccess = () => !!getPermissions().find(p => p.startsWith("access:"))

export const hasAccessThroughTerminal = () =>
	!!getPermissions().find(p => p === "accessThroughTerminalList:limit")

export const hasTerminalPermissions = () =>
	!!getPermissions().find(p => p.startsWith("terminalList:"))

export const hasAllTerminals = () => hasGeneralBuildingAccess() && !hasAccessThroughTerminal()

export const hasSpecificTerminals = () =>
	hasGeneralBuildingAccess() && hasAccessThroughTerminal() && hasTerminalPermissions()

export const hasAnyTerminalAccess = () =>
	isAdmin() || isCarrotAdmin() || hasAllTerminals() || hasSpecificTerminals()

export const getPermittedTerminals = (terminals: Terminal[] = [], isMWM: boolean) => {
	let permittedList: Terminal[] = []

	if (isCarrotAdmin() || isMWM || hasAllTerminals()) {
		permittedList = terminals
	} else if (hasSpecificTerminals()) {
		permittedList = terminals?.filter(el => hasPermissions([`terminalList:${el.accessToken}`]))
	}

	const orderedList = permittedList.sort(sortAlphabeticallyByProperty)

	return orderedList
}
