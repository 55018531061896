import QuestionMark from "Icons/QuestionMark"
import { PlacesType, Tooltip } from "react-tooltip"
import { createPortal } from "react-dom"

type Props = {
	id: string
	text?: string
	children?: React.ReactNode
	width: string
	size?: number
	className?: string
	place?: PlacesType
}

const Portal = ({ children }: { children: React.ReactNode }) => {
	return createPortal(<>{children}</>, document.body)
}

const InfoPopup = ({ id, text, children, width, size, className, place }: Props) => {
	return (
		<div className={className}>
			<QuestionMark id={id} data-tooltip-id={id} width={size} height={size} />
			<Portal>
				{text && (
					<Tooltip id={id} content={text} place={place} className="z-[10000]" style={{ width }} />
				)}
				{!text && children && (
					<Tooltip id={id} place={place} className="z-[10000]" style={{ width }} clickable>
						{children}
					</Tooltip>
				)}
			</Portal>
		</div>
	)
}

export default InfoPopup
