import { match } from "ts-pattern"
import { sanityGetUrlFor } from "./request"
import { FetchedWasteTypes, SanityQueryType, SanityResponseType } from "./types"

const normalizeWasteTypesResponse = (
	fetchedWasteTypes: FetchedWasteTypes
): SanityResponseType<"wasteTypes"> =>
	fetchedWasteTypes.map(wt => ({
		...wt,
		iconColor: wt.iconColor ? sanityGetUrlFor.image(wt.iconColor).url() : null,
		iconGrayscale: wt.iconGrayscale ? sanityGetUrlFor.image(wt.iconGrayscale).url() : null,
	}))

function filterDrafts(sanityData: SanityResponseType<SanityQueryType>) {
	let draftIds = new Set(
		sanityData.filter(obj => obj._id.startsWith("drafts.")).map(obj => obj._id.split(".")[1])
	)

	// Filter to give drafts precedence over published versions of the same document
	return sanityData.filter(obj => !draftIds.has(obj._id) || obj._id.startsWith("drafts."))
}

export function normalizeSanityResponse<Response extends SanityResponseType<SanityQueryType>>(
	sanityData: Response,
	queryType: SanityQueryType
): SanityResponseType<SanityQueryType> {
	const filteredData = filterDrafts(sanityData)

	return match(queryType)
		.with("wasteTypes", () => normalizeWasteTypesResponse(filteredData as FetchedWasteTypes))
		.otherwise(() => filteredData as SanityResponseType<SanityQueryType>)
}

export function findClassificationSystemForWasteCode(
	wasteTypes: FetchedWasteTypes | null,
	wasteCode: string
): string {
	const currentWasteType = wasteTypes?.find(el => el.id === wasteCode)
	return currentWasteType?.classificationSystem?.id || ""
}
