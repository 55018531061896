import { FC } from "react"
import { useTrans } from "translations"

export const ErrorNotice: FC<{ errorMessage?: string }> = ({
	errorMessage = "errors:failToFetch",
}) => {
	const { t } = useTrans()
	return (
		<h3 className="font-base flex h-full min-h-[420px] items-center justify-center whitespace-pre-wrap font-dmSans font-bold text-carrotRed">
			{t(errorMessage)}
		</h3>
	)
}

export default ErrorNotice
