import { CustomersResponse } from "Utils/api/datawarehouse/responseTypes"
import { removeMoreThanTwoDecimals } from "Utils/formatFunctions"

export const COLORS = ["#E9D7C9", "#72723B", "#DDCAB9", "#E7EB5D", "#643E3E"]

export const getNewColor = (colors: string[]): [string, string[]] => {
	if (colors.length) {
		return [colors.pop()!, colors.length === 0 ? [...COLORS] : colors]
	}

	return [colors.pop()!, [...COLORS]]
}

type WasteDistributionPieData = {
	id: string
	label: string
	value: number
	color: string
}[]

export const pieWasteContribution = (
	d: CustomersResponse,
	wasteTypeCodeFilter: string = "all",
	translationTextOther: string
): WasteDistributionPieData => {
	const totalWasteWeight =
		wasteTypeCodeFilter === "all"
			? d.customers.reduce((acc, el) => acc + el.totalWasteWeight.quantity, 0)
			: d.customers.reduce(
					(acc, el) =>
						acc + (el.wasteTypes.find(el => el.code === wasteTypeCodeFilter)?.weight.quantity ?? 0),
					0
			  )
	let curColors = [...COLORS]
	const data = d.customers
		.reduce(
			(acc: WasteDistributionPieData, el) => {
				if (
					wasteTypeCodeFilter !== "all" &&
					!el.wasteTypes.some(el => el.code === wasteTypeCodeFilter)
				) {
					return acc
				}

				const weightValue =
					wasteTypeCodeFilter === "all"
						? el.totalWasteWeight.quantity
						: el.wasteTypes.find(el => el.code === wasteTypeCodeFilter)?.weight.quantity ?? 0
				const [other, ...rest] = acc

				const wasteShare = weightValue / totalWasteWeight
				if (wasteShare < 0.03)
					return [
						{ ...other, value: removeMoreThanTwoDecimals(other.value + weightValue) },
						...rest,
					]
				const [color, newColors] = getNewColor(curColors)
				curColors = newColors
				return [
					...acc,
					{
						id: el.customerName,
						label: el.customerName,
						value: weightValue,
						color,
					},
				]
			},
			[{ id: "Other", label: translationTextOther, value: 0, color: "#B6BB22" }]
		)
		.filter(el => el.value !== 0)

	const [other, ...rest] = data
	if (!other) return []
	return [...rest, other]
}
