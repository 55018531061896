import { Page } from "components/Page"
import { useTrans } from "translations"
import { useMemo } from "react"
import QRCodeDisplayCard from "./components/QRCodeDisplayCard"
import useCustomers from "api/hooks/useCustomers"
import ErrorNotice from "components/ErrorNotice"
import { AlertLine } from "components/AlertLine/AlertLine"
import { detect } from "detect-browser"

const browser = detect()

export const PrintQRCodesPage = () => {
	const { t } = useTrans()
	const { isError: isErrorLoadingCustomers } = useCustomers()

	const breadcrumbs = useMemo(
		() => [{ url: "/infrastructure/manage", name: t("sidebarLabels:manageInfrastructure") }],
		[t]
	)

	const showAlert = !["chrome", "edge-chromium"].includes(browser?.name?.toLowerCase() || "")

	return (
		<Page title={t("actions:printQRCodes")} breadcrumbsPaths={breadcrumbs}>
			<div className="bg-white px-8 py-6">
				{showAlert && (
					<div className="mb-10">
						<AlertLine
							type="warning"
							message="genericLabels:qrBetaLabel"
							instructions="genericLabels:qrBetaMessage"
							canClose={false}
						/>
					</div>
				)}
				<p className="text-3xl font-medium mb-2">{t("genericLabels:qrTitle")}</p>
				<p className="text-xl font-normal mb-4">{t("genericLabels:qrUsageInfo")}</p>
				{!isErrorLoadingCustomers ? (
					<div className="flex justify-between">
						<QRCodeDisplayCard selectedTemplate="STORE" />
						<QRCodeDisplayCard selectedTemplate="WEIGHT" />
					</div>
				) : (
					<ErrorNotice />
				)}
			</div>
		</Page>
	)
}

export default PrintQRCodesPage
