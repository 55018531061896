import { gql } from "@apollo/client"

export const DELETE_POINT = gql`
	mutation DELETE_POINT($Id: String!) {
		deleteAccessPointMutation(input: { wait: true, payload: { id: $Id } }) {
			primaryKey
			commandProcessError
		}
	}
`
