import { useModal } from "Contexts"
import { useGlobalAlert } from "States/globalAlert"
import useCustomers from "api/hooks/useCustomers"
import { FC } from "react"
import { MWMCustomerFormValues } from "../../types"
import { useForm } from "react-hook-form"
import ModalContainer from "components/modalContainer"
import { isEmpty, pick } from "lodash"
import { CustomerDetailsForm } from "./CustomerDetailsForm"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/router"

type EditCustomerProps = {
	customer: CustomerWithDetailsAndCode
	onUpdate?: () => Promise<void>
}

export const EditCustomerModal: FC<EditCustomerProps> = ({ customer, onUpdate }) => {
	const { hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const { updateCustomer, isUpdatingCustomer } = useCustomers()

	const formHandler = useForm<MWMCustomerFormValues>({
		defaultValues: pick(customer, ["id", "name", "type", "address", "postalCode", "postalRegion"]),
	})

	const {
		handleSubmit,
		formState: { errors, isDirty },
	} = formHandler

	const onSave = handleSubmit(updatedCustomer => {
		updateCustomer(
			{ customer: updatedCustomer },
			{
				onSuccess: async () => {
					setGlobalAlert({
						type: "success",
						message: "systemMessages:changesSaved",
					})
					await onUpdate?.()

					hideModal()
				},
			}
		)
	})

	return (
		<ModalContainer
			title="drawerLabels:customerDetails"
			onConfirm={onSave}
			onCancel={hideModal}
			onConfirmLoading={isUpdatingCustomer}
			onConfirmDisabled={!isEmpty(errors)}
			className="w-[450px]"
			showDiscardModal={isDirty}
		>
			<CustomerDetailsForm formHandler={formHandler} isEdit={true} />
		</ModalContainer>
	)
}
