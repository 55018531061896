import { get } from "lodash"
import { ImportStep, MappingState, Template, TemplateColumn, ValidationState } from "./types"

export const getActiveStepIndex = (steps: ImportStep[], activeStep: ImportStep) =>
	steps.findIndex(step => step.key === activeStep.key)

export const getWidthOfText = (text: string, font: string) => {
	const canvas = document.createElement("canvas")
	const context: any = canvas.getContext("2d")
	context.font = font
	const metrics = context.measureText(text)
	return metrics.width
}

export const getLinkingValues = ({
	templateColumn,
	sectionKey,
	selectedTemplate,
	validationState,
}: {
	templateColumn?: TemplateColumn
	sectionKey: string
	selectedTemplate?: Template
	validationState: ValidationState
}) => {
	let linkingValues: string[] = []
	const linkedSection = selectedTemplate?.mappingSections
		.filter(s => s.key !== sectionKey)
		.find(s => s.columns?.find(c => c.hasLinkedChild && c.key === templateColumn?.key))
	const linkedColumn = linkedSection?.columns?.find(c => c.key === templateColumn?.key)

	if (linkedSection && linkedColumn) {
		linkingValues = validationState[linkedSection.key]?.[linkedColumn.key].map(c => c.value) || []
	}

	return linkingValues
}

export const getFilteredValues = ({
	validationState,
	column,
	sectionKey,
	cellIndex,
}: {
	validationState: ValidationState
	column?: TemplateColumn
	sectionKey: string
	cellIndex: number
}) => {
	let filteredValues: string[] = []
	if (column?.filterBy) {
		const filterByValue: any = get(
			validationState,
			`${sectionKey}.${column.filterBy}.${cellIndex}.value`,
			""
		)

		if (filterByValue) {
			filteredValues =
				column.dropdownOptions
					?.filter(el => el.filterParamId === filterByValue)
					.map(el => el.value) || []
		}
	}

	return filteredValues
}

export const getDisplayedColumns = (columns: TemplateColumn[], mappingState: MappingState) =>
	columns?.filter(c => !c.displayFunction || c.displayFunction(mappingState))
