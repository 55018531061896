import { Card, CardComponentVariant } from "components/pageCards/card"
import { FC } from "react"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/router"
import { CommunicationSection } from "./communication/CommunicationSection"

interface CustomerCommunicationCardProps {
	customer: CustomerWithDetailsAndCode
}

export const CustomerCommunicationCard: FC<CustomerCommunicationCardProps> = ({ customer }) => {
	const templates: ("mobile" | "tablet")[] = ["tablet", "mobile"]

	return (
		<Card title={"drawerLabels:communication"} variant={CardComponentVariant.DRAWER}>
			{templates.map(template => (
				<CommunicationSection customer={customer} type={template} />
			))}
		</Card>
	)
}
