import { AccessParentContainerData } from "../functions"
import { useMemo } from "react"
import ModalContainer from "components/modalContainer"
import {
	SmartInfrastructureStatus,
	SmartIntegration,
	SmartIntegrationWithTranslation,
} from "Utils/smartInfrastructure"
import { useForm } from "react-hook-form"
import { useModal } from "Contexts"
import { trpc } from "Utils/trpc"
import { useTerminalsState } from "States/Terminals"
import { FormFieldType, GridForm } from "components/GridForm"
import { useTrans } from "translations"
import { capitalize } from "lodash"
import { useAccessPoints } from "../manage/useAccessPoints"

type Props = {
	container: AccessParentContainerData
	accessParentId: string
}

type SmartIntegrationsForm = {
	smartIntegrations: SmartIntegration[]
}

export const EditSmartFunctionalityModal = ({ container, accessParentId }: Props) => {
	const { hideModal } = useModal()
	const { currentTerminal } = useTerminalsState()
	const { t } = useTrans()
	const { refetch } = useAccessPoints({ accessParentId })

	const smartIntegrations = useMemo(
		() =>
			JSON.parse(container?.smartIntegrations ?? "[]").map((si: SmartIntegration) => ({
				...si,
				capabilityTypeTranslation: t(`entities:smart-tech-${si.capabilityType}`),
			})) as SmartIntegrationWithTranslation[],
		[container, t]
	)

	const { mutate, isLoading } = trpc.upsertSmartInfrastructure.useMutation({
		onSuccess: () => {
			hideModal()
			refetch()
		},
	})

	const formHandler = useForm<SmartIntegrationsForm>({
		defaultValues: { smartIntegrations },
	})

	const {
		handleSubmit,
		formState: { isDirty },
	} = formHandler

	const onSubmit = handleSubmit(formData => {
		const url = new URL(window.location.href)
		url.searchParams.set("terminal-id", currentTerminal.id)
		mutate({
			containerId: container.id,
			sendEmail: false,
			url: url.toString(),
			smartIntegrations: formData.smartIntegrations,
		})
	})

	const SmartInfrastructureStatuses = [
		{
			label: t(`status:smartInfrastructure${capitalize(SmartInfrastructureStatus.REQUESTED)}`),
			value: SmartInfrastructureStatus.REQUESTED,
		},
		{
			label: t(`status:smartInfrastructure${capitalize(SmartInfrastructureStatus.ACTIVE)}`),
			value: SmartInfrastructureStatus.ACTIVE,
		},
	]

	const columns = [
		{ label: t("formLabels:smartCapability"), name: "capabilityTypeTranslation", enabled: false },
		{ label: t("entities:manufacturer"), name: "manufacturer", enabled: false },
		{
			label: t("formLabels:connection"),
			name: "status",
			enabled: true,
			fieldType: FormFieldType.SELECT,
			options: SmartInfrastructureStatuses,
		},
	]

	return (
		<ModalContainer
			title="actions:addSmartCapabilities"
			className={"w-3/4 sm:w-3/4 lg:w-1/2"}
			onCancel={hideModal}
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoading}
			showDiscardModal={isDirty}
		>
			<div className="mb-5 min-h-[200px]">
				<GridForm<Omit<SmartIntegration, "fields">[]>
					formHandler={formHandler}
					columns={columns}
					enableDeleteColumn
				/>
			</div>
		</ModalContainer>
	)
}
