import { useTerminalsState } from "States/Terminals"
import { trpc } from "Utils/trpc"
import { useConfig } from "./useConfig"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"

export const useSlackNotifications = () => {
	const { currentTerminal } = useTerminalsState()
	const { isRealEstate } = useConfig()
	const terminalName = currentTerminal.name
	const terminalId = currentTerminal.id
	const sendNewCustomerNotificationMutation = trpc.sendNewCustomerSlackNotification.useMutation()
	const sendNewAccessParentSlackNotificationMutation = trpc.sendNewAccessParentSlackNotification.useMutation()
	const sendNewContainerNotificationMutation = trpc.sendNewContainerSlackNotification.useMutation()

	function createUrlForCustomerPage(path: string) {
		const baseUrl = window.location.origin
		if (!terminalId) {
			throw new Error("Failed to create url. Terminal id is undefined.")
		}

		const url = `${baseUrl}${path}?terminal-id=${terminalId}`
		return url
	}

	function сreateUrlForInfrastructurePage(pageRoute: string) {
		const baseUrl = window.location.origin
		if (!terminalId) {
			throw new Error("Failed to create url. Terminal id is undefined.")
		}

		const terminalFiltering = isRealEstate ? `?terminal-id=${terminalId}` : ""
		const url = `${baseUrl}${pageRoute}${terminalFiltering}`

		return url
	}

	const sendNewCustomerSlackNotification = async (customerName: string, customerId: string) => {
		const manageCustomerUrl = createUrlForCustomerPage(`/customer/manage/${customerId}`)

		await sendNewCustomerNotificationMutation.mutate({
			customerName,
			terminalName: currentTerminal.name,
			manageCustomerUrl,
		})
	}

	const sendNewAccessParentSlackNotification = async (
		accessParentName: string,
		accessParentId: string
	) => {
		const pageRoute = getInfrastructureUrl({ terminalId, accessParentId })
		const manageAccessParentUrl = сreateUrlForInfrastructurePage(pageRoute)

		await sendNewAccessParentSlackNotificationMutation.mutate({
			accessParentName,
			terminalName,
			manageAccessParentUrl,
		})
	}

	const sendNewContainerSlackNotification = async (
		containerName: string,
		accessParentId: string,
		containerId: string
	) => {
		const pageRoute = getInfrastructureUrl({
			terminalId,
			accessParentId,
			containerId,
		})
		const manageContainerUrl = сreateUrlForInfrastructurePage(pageRoute)

		await sendNewContainerNotificationMutation.mutate({
			containerName,
			terminalName,
			manageContainerUrl,
		})
	}

	return {
		sendNewCustomerSlackNotification,
		sendNewAccessParentSlackNotification,
		sendNewContainerSlackNotification,
	}
}
