export const CUSTOMER_KEY = "CUSTOMER"
export const CONTACT_KEY = "CONTACT"
export const STATION_KEY = "STATION"

export const CUSTOMER_NAME_KEY = "customerName"
export const CHAIN_KEY = "chain"
export const CATEGORY_KEY = "category"
export const CUSTOMER_REFERENCE_KEY = "customerReference"

export const ACTIVE_STATUS_VALUE = "active"
export const DISABLED_STATUS_VALUE = "disabled"
