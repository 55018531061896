import { TOptions } from "i18next"
import { useTranslation as i18nextHook } from "react-i18next"
import { translationContext } from "../../States/translationContext"
import i18n from "i18n"

let isTranslationLoaded = false

i18n.on("initialized", () => {
	console.log("Translations initialized")
	isTranslationLoaded = true
})
export const useTrans = () => {
	const { context } = translationContext()
	const { t, i18n } = i18nextHook()
	const translate = (key: string, options?: TOptions) => {
		return t(key, { context, ...options })
	}
	const changeLanguage = (language: string) => {
		i18n.changeLanguage(language)
		window.localStorage.setItem("language.preference", language)
	}

	return {
		t: translate,
		language: i18n.language,
		changeLanguage,
		isTranslationLoaded,
	}
}

export const translate = (key: string, options?: TOptions) => {
	const { context } = translationContext.getState()
	return i18n.t(key, { context, ...options })
}
