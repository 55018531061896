import { Header, flexRender } from "@tanstack/react-table"
import ArrowUp from "Icons/ArrowUp"
import classNames from "classnames"
import React, { useCallback, useRef } from "react"
import { twMerge } from "tailwind-merge"
import { ExpandedColumnDef } from ".."
import { v4 } from "uuid"
import { Tooltip } from "react-tooltip"

type ColumnHeaderProps = {
	header: Header<any, unknown>
}

export const ColumnHeader: React.FC<ColumnHeaderProps> = ({ header }) => {
	const isCheckbox = header.id === "checkbox"
	const ref = useRef<HTMLDivElement | null>(null)

	const sort = useCallback(() => {
		if (!header.column.columnDef.enableSorting) return
		const sortStatus = header.column.getIsSorted() !== "desc"
		header.column.toggleSorting(sortStatus)
	}, [header])

	const { rightAlign, stickyLeft, stickyRight } = header.column.columnDef as ExpandedColumnDef

	const headerTitle = flexRender(header.column.columnDef.header, header.getContext())

	const tooltipUUID = v4()

	if (isCheckbox)
		return (
			<th
				className={twMerge("w-8 sticky top-0 bg-white", stickyLeft && "left-0 z-[9]")}
				style={{
					width: header.getSize(),
				}}
			>
				{headerTitle}
			</th>
		)

	return (
		<th
			className={twMerge(
				"group relative text-sm bg-white sticky top-0 ",
				stickyLeft && "left-8 z-[9]",
				stickyRight && "right-0 top-0 bg-grey1 z-[9]"
			)}
			style={{
				width: header.getSize(),
			}}
		>
			<div
				className={twMerge(
					"p-2.5 font-medium",
					header.column.getCanSort() && "cursor-pointer",
					rightAlign && "flex justify-end text-end"
				)}
				onClick={sort}
			>
				<div
					className="flex items-center whitespace-nowrap"
					{...{ ref }}
					data-tooltip-id={tooltipUUID}
				>
					{headerTitle}
					<Tooltip id={tooltipUUID} children={headerTitle} className="z-[20]" />

					{header.column.getCanSort() && (
						<div className="ml-2 w-fit">
							<ArrowUp
								className={classNames(
									header.column.getIsSorted() ? "text-black" : "text-grey5",
									header.column.getIsSorted() === "desc" && "rotate-180"
								)}
							/>
						</div>
					)}
				</div>
			</div>
		</th>
	)
}
