import React, { useMemo } from "react"
import { Translate, useTrans } from "translations"
import { EMPTY_DATA, EmptyDataType, emptyViewTypes } from "./emptyViewTypes"

type Props = {
	type: EmptyDataType
	error?: Error | null
	className?: string
}

export const EmptyView: React.FC<Props> = ({ type, error, className }) => {
	const { t } = useTrans()

	const data = useMemo(() => emptyViewTypes[type] || EMPTY_DATA, [type])

	return (
		<div
			className={`h-full text-sm font-normal flex flex-col justify-center items-center ${className}`}
		>
			{data.icon}
			<div className="font-medium">{t(data.label)}</div>
			{data.description && (
				<div className="whitespace-pre text-center [text-wrap:balance]">
					<Translate i18nKey={data.description} components={data.descriptionComponents || []} />
				</div>
			)}
			{error && (
				<div className="my-4 font-dmsans text-carrotRed">
					{t("errors:error")}: {error.message}
				</div>
			)}
		</div>
	)
}
