import { SmartContainerFieldValidation } from "Utils/api/sanity/types"

export type SmartIntegration = {
	manufacturer: string
	capabilityType: string
	status: SmartInfrastructureStatus
	fields: { key: string; value: string }[]
}

export type SmartIntegrationWithTranslation = SmartIntegration & {
	capabilityTypeTranslation: string
}

// Copied from /server as a workaround for loader issue with enums
// https://github.com/facebook/create-react-app/issues/8987
export enum SmartInfrastructureStatus {
	REQUESTED = "requested",
	ACTIVE = "active",
}

export const validationPatterns: { [key: string]: RegExp } = {
	uint32: /^\d+$/, // Uses min length as well
	uuid: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
	"non-empty-string": /^(?!\s*$).+/,
	"15-digits": /^\d{15}$/,
}

export const validationMin: { [key in SmartContainerFieldValidation]: number | undefined } = {
	uint32: 0,
	uuid: undefined,
	"non-empty-string": undefined,
	"15-digits": undefined,
}

export const validationMax: { [key in SmartContainerFieldValidation]: number | undefined } = {
	uint32: 4294967295,
	uuid: undefined,
	"non-empty-string": undefined,
	"15-digits": undefined,
}

export const validIntegrationCombinations: { [key: string]: string[] } = {
	integrated: [],
	"access-control": ["sensor"],
	sensor: ["access-control"],
}
