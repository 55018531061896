import { Input } from "components/GenericComponents/input"
import InfoPopup from "components/InfoPopup/InfoPopup"
import React, { ComponentProps } from "react"
import {
	Control,
	Controller,
	FieldError,
	RegisterOptions,
	UseFormRegister,
	UseFormSetValue,
	UseFormTrigger,
} from "react-hook-form"
import { getInputErrorMessage } from "Utils/getInputErrorMessage"

export const SearchInput: React.FC<
	ComponentProps<typeof Input> & {
		id?: string
		label?: string
		name: string
		entityName?: string /** Used for error messages if label is not set */
		setValue: UseFormSetValue<any>
		control: Control<any>
		required?: boolean
		locked?: boolean
		rules?: Omit<
			RegisterOptions<any, any>,
			"valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
		>
		register?: UseFormRegister<any>
		trigger?: UseFormTrigger<any>
		errorObj?: FieldError
		tooltip?: Omit<ComponentProps<typeof InfoPopup>, "width">
	}
> = props => {
	const {
		id,
		label,
		required,
		name,
		rules,
		control,
		setValue,
		trigger,
		locked,
		onFocus,
		onBlur,
		onChange,
		initialValue,
		errorObj,
		entityName,
		tooltip,
	} = props

	const errorMessage = getInputErrorMessage({
		errorType: errorObj?.type || "",
		label: label ?? entityName,
	})

	return (
		<div className="flex flex-col">
			<label htmlFor={id} className="font-dmSans text-sm font-medium text-black empty:hidden">
				{label}
				{label && required && " *"}
				{tooltip && (
					<InfoPopup
						width="350px"
						size={15}
						className="inline-flex align-bottom mb-1 ml-1"
						{...tooltip}
					/>
				)}
			</label>
			<Controller
				name={name}
				control={control}
				rules={{ ...rules, required }}
				render={({ field: { value } }) => (
					<Input
						dropdownStyle={{ maxHeight: 190 }}
						dropdownIcon
						disabled={locked}
						error={!!errorObj} // handle errors here unless overridden
						errorMessage={errorMessage}
						{...props}
						initialValue={value || initialValue || undefined}
						noInitialOption={!(value || initialValue)}
						onFocus={onFocus}
						onChange={option => {
							const { value } = option
							value && setValue(name, value, { shouldValidate: true, shouldDirty: true })
							onChange?.(option)
						}}
						onBlur={() => {
							onBlur?.()
							trigger?.(name)
						}}
					/>
				)}
			/>
		</div>
	)
}

export default SearchInput
