import { DocumentNode, FetchResult } from "@apollo/client"
import { isEmpty } from "lodash"
import { useApiStore } from "../States/api"
import { useGlobalAlert } from "../States/globalAlert"
import { translate } from "translations/functions/hook"

/** @deprecated Replaced with queryGraphQL function  */
export function postFunc<Return>(
	query: DocumentNode,
	func: (value: FetchResult<Return, Record<string, any>, Record<string, any>>) => void,
	params?: {
		variables?: {
			[key: string]: any
		}
		refetchQueries?: {
			query: DocumentNode
			variables?: {
				[key: string]: any
			}
		}[]
		fetchPolicy?: "no-cache"
	},
	errorFunc?: (err: any) => void
) {
	const { apolloClient } = useApiStore.getState()

	if (apolloClient) {
		return handleMutationErrorWithGlobalAlert<Return>(
			apolloClient.mutate<Return>({
				mutation: query,
				...params,
				errorPolicy: "all",
			}),
			func,
			errorFunc
		)
	} else {
		throw new Error("Problem connecting to api client")
	}
}

/** @deprecated Custom global alert will be handled outside fetch/post functions */
export function handleMutationErrorWithGlobalAlert<Return>(
	mutationReturn: Promise<FetchResult<Return, Record<string, any>, Record<string, any>>>,
	func?: (value: FetchResult<Return, Record<string, any>, Record<string, any>>) => void,
	errorFunc?: (err: any) => void
) {
	const { setGlobalAlert } = useGlobalAlert.getState()

	return mutationReturn
		.then(data => {
			const statusMsg: string = getMutationErrorFromApollo(data)
			if (statusMsg?.length) {
				if (errorFunc) {
					errorFunc(translate("errors:failed") + ". " + statusMsg)
				}
				setGlobalAlert({
					type: "error",
					message: "errors:somethingWentWrong",
					messageVariables: {
						email: "support@carrot.tech",
					},
					instructions: "errors:technicalError",
					instructionVariables: {
						error: statusMsg,
					},
				})

				return undefined
			}
			return func ? func(data) : undefined
		})
		.catch(err => {
			if (errorFunc) {
				errorFunc(err)
			}
			setGlobalAlert({
				type: "error",
				message: "errors:somethingWentWrong",
				messageVariables: {
					email: "support@carrot.tech",
				},
				instructions: "errors:technicalError",
				instructionVariables: {
					error: err.message,
				},
			})
			console.error(err)
		})
}

export function getMutationErrorFromApollo(
	mutationReturn: FetchResult<unknown, Record<string, any>, Record<string, any>>
) {
	const errorMsg = mutationReturn.errors?.reduce(
		(prev, curr) => (curr.message ? prev + curr.message + ". " : ""),
		""
	)

	if (errorMsg?.length) {
		return errorMsg
	}

	const apiErrMsg: string =
		typeof mutationReturn.data === "object" && !isEmpty(mutationReturn.data)
			? Object.values(mutationReturn.data as unknown as object)?.reduce(
					(prev, curr) => prev + (curr.commandProcessError ? curr.commandProcessError + ". " : ""),
					""
			  ) || ""
			: ""

	return apiErrMsg
}
