import { ApolloClient, NormalizedCacheObject } from "@apollo/client/core"
import { QueryClient } from "@tanstack/react-query"
import { GraphQLClient } from "graphql-request"
import create from "zustand"

export type UseApiStore = {
	apolloClient: ApolloClient<NormalizedCacheObject> | null
	setApolloClient: (_client: ApolloClient<NormalizedCacheObject>) => void
	graphqlClient: GraphQLClient | null
	setGraphqlClient: (_client: GraphQLClient) => void
	reactQueryClient: QueryClient | null
	setReactQueryClient: (_reactQueryClient: QueryClient) => void
}

export const useApiStore = create<UseApiStore>(set => ({
	apolloClient: null,
	setApolloClient: _client => set(() => ({ apolloClient: _client })),
	graphqlClient: null,
	setGraphqlClient: _client => set(() => ({ graphqlClient: _client })),
	reactQueryClient: null,
	setReactQueryClient: _reactQueryClient => set(() => ({ reactQueryClient: _reactQueryClient })),
}))
