import React from "react"

export const User = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4 22.9897C4 20.868 6.34315 17.3329 6.34315 17.3329L12 14.9897L17.6569 17.3329C17.6569 17.3329 20 20.868 20 22.9897H4ZM12 13.9897C8.685 13.9897 6 11.3047 6 7.98975C6 4.67475 8.685 1.98975 12 1.98975C15.315 1.98975 18 4.67475 18 7.98975C18 11.3047 15.315 13.9897 12 13.9897Z"
			fill="black"
		/>
	</svg>
)

export default User
