import classNames from "classnames"
import LoadingTable from "components/LoadingTable"
import { FC, useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { Customer } from "Utils/api/datawarehouse/responseTypes"
import { formatNumberForTable, TFormatNumberSuffix } from "Utils/formatFunctions"
import { Card } from "./card"
import { getTenantCategoryOptions } from "./filterSort/filterCreators"
import { getTimeFrame } from "./filterSort/filterTimeFrame"
import ArrowUp from "Icons/ArrowUp"
import { EmptyView } from "components/EmptyView/EmptyView"
import { ActiveOptions } from "./filterSort/FilterSortContext"
import { FilterSort, FilterSortMenuType } from "./filterSort/types"
import { FilterTimeFrameValue, createTimeFilters } from "./filterSort/filterCreators"
import { OPTION_ALL, hasNonAllOptionActive } from "./filterSort/constants"
import { FetchedTenantTypes } from "Utils/api/sanity/types"
import { useConfigService } from "pages/configuration/useConfigService"
import { Tooltip } from "react-tooltip"
import { v4 } from "uuid"

const printWasteType = (customer: Customer, wasteType: string) => {
	const wtValue = customer.wasteTypes.find(el => el.code === wasteType)?.weight.quantity
	const wtUnit = customer.wasteTypes.find(el => el.code === wasteType)?.weight.unit

	if (!wtValue) return ""

	return formatNumberForTable(wtValue, { suffix: ` ${wtUnit}` as TFormatNumberSuffix })
}

const shouldUtilizeWasteType = (
	customer: Customer,
	wasteType: string,
	tenantCategories: FetchedTenantTypes
): boolean => {
	let applicableCategories = tenantCategories.filter(
		tc => tc.id === customer.commercialCustomerCategory
	)
	if (applicableCategories.length === 0) {
		applicableCategories = [tenantCategories.find(el => el.id === "6")!]
	}
	const expectedWasteTypes = Array.from(
		new Set(
			applicableCategories.reduce((acc: string[], c) => {
				return [...acc, ...(c.expectedWasteTypes || []).map(t => t.id)]
			}, [])
		)
	)

	return expectedWasteTypes.some(el => el === wasteType)
}

const getUtilizationColor = (
	customer: Customer,
	wasteType: string,
	expectedWasteTypes: FetchedTenantTypes
) => {
	if (customer.wasteTypes.some(wt => wasteType === wt.code)) {
		return "#F3F6C7"
	}

	if (shouldUtilizeWasteType(customer, wasteType, expectedWasteTypes)) {
		return "#FFE5D4"
	}

	return ""
}

const sortByWasteType =
	(wasteType: string, dir: number, expectedWasteTypes: FetchedTenantTypes) =>
	(c1: Customer, c2: Customer): number => {
		const t1WasteType = c1.wasteTypes.find(el => el.code === wasteType)
		const t2WasteType = c2.wasteTypes.find(el => el.code === wasteType)
		const t1ShouldUtilize = shouldUtilizeWasteType(c1, wasteType, expectedWasteTypes)
		const t2ShouldUtilize = shouldUtilizeWasteType(c2, wasteType, expectedWasteTypes)

		if (!t1WasteType && !t2WasteType) {
			if (t1ShouldUtilize && !t2ShouldUtilize) {
				return -1 * dir
			}
			if (!t1ShouldUtilize && t2ShouldUtilize) {
				return 1 * dir
			}
			return 0 * dir
		}

		if (t1WasteType && !t2WasteType) {
			return -1 * dir
		}

		if (!t1WasteType && t2WasteType) {
			return 1 * dir
		}

		if (t1WasteType && t2WasteType) {
			return t1WasteType.weight.quantity > t2WasteType.weight.quantity ? -1 * dir : 1 * dir
		}

		return 0 * dir
	}

const sortTenantsByName = (c1: Customer, c2: Customer) =>
	c1.customerName > c2.customerName ? 1 : -1

const defaultTimeframe = FilterTimeFrameValue.LAST_30_DAYS

export const TenantWasteTypesCard: FC<{ realEstateId: string }> = ({ realEstateId }) => {
	const [sortColumn, setSortColumn] = useState(-1) // -1 for customer column
	const [sortAscending, setSortAscending] = useState(true)

	const [{ timeframe: [selectedTimeframe] = [], tenantCategory }, setActiveOptions] = useState<
		ActiveOptions<"timeframe" | "tenantCategory">
	>({} as any)

	const [customers, setCustomers] = useState<Customer[]>([])
	const [uniqueWasteTypes, setUniqueWasteTypes] = useState<string[]>([])

	const { tenantCategories } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()

	const { startTimeISO, endTimeISO } = useMemo(() => {
		return getTimeFrame((selectedTimeframe?.value as FilterTimeFrameValue) ?? defaultTimeframe)
	}, [selectedTimeframe])

	const { data, isLoading } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: realEstateId },
		filters: {
			startTimeISO,
			endTimeISO,
			commercialCustomerCategory: hasNonAllOptionActive(tenantCategory)
				? tenantCategory[0].value
				: undefined,
		},
	})

	useEffect(() => {
		if (data) {
			setCustomers(data.customers.sort(sortTenantsByName))

			const wasteTypes = data.customers.reduce(
				(acc: string[], t) => [...acc, ...t.wasteTypes.map(wt => wt.code)],
				[]
			)
			setUniqueWasteTypes(Array.from(new Set(wasteTypes)).sort((a, b) => (a > b ? -1 : 1)))
		}
	}, [data])

	const { t } = useTrans()
	const { wasteTypes: sanityData } = useCommonEntitiesStore()

	if (!sanityData) return <></> // FIXME: Loading and error handling

	const filters: FilterSort[] = [
		createTimeFilters({ id: "timeframe", defaultValue: defaultTimeframe as FilterTimeFrameValue }),
		{
			options: getTenantCategoryOptions(tenantCategories),
			title: "statisticsLabels:tenantCategory",
			id: "tenantCategory",
			type: "filter",
			menuType: FilterSortMenuType.Select,
			defaultValue: OPTION_ALL,
		},
	]

	const handleColHeaderClick = (colIndex: number, wt?: string) => {
		const isCurrentCol = sortColumn === colIndex
		const sortAsc = isCurrentCol ? !sortAscending : true
		if (colIndex === -1) {
			customers.sort((a, b) =>
				a.customerName > b.customerName ? (sortAsc ? 1 : -1) : sortAsc ? -1 : 1
			)
		}

		if (wt) {
			customers.sort(sortByWasteType(wt, sortAsc ? 1 : -1, tenantCategories || []))
		}

		setSortColumn(colIndex)
		setSortAscending(sortAsc)
	}

	return (
		<Card filters={filters} onFilterOptionChange={setActiveOptions} overflow="auto">
			{!uniqueWasteTypes.length ? (
				<EmptyView type="noData" />
			) : (
				<div className="h-full flex-col">
					<Legend />
					<div className="overflow-auto">
						{isLoading ? (
							<LoadingTable rows={25} />
						) : (
							<table className="relative w-full table-auto">
								<thead>
									<tr className="sticky top-0 z-9 h-[48px] cursor-pointer select-none whitespace-nowrap border-b border-grey2 bg-white text-center align-top font-medium">
										<th
											className="sticky left-0 border-b border-grey2 bg-white px-2 pt-1 text-sm sm:px-5"
											onClick={() => handleColHeaderClick(-1)}
										>
											<div className="flex h-6 items-center gap-1.5">
												{t("entities:customer")}
												<ArrowUp
													className={classNames([
														{ "stroke-grey4": sortColumn !== -1 },
														{ "rotate-180": sortColumn !== -1 && !sortAscending },
													])}
												/>
											</div>
										</th>
										{uniqueWasteTypes.map((wt, colIndex) => {
											const isSortedCol = sortColumn === colIndex

											const customWT = wasteTypeConfig.find(el => el.wasteTypeCode === wt)

											const title =
												customWT?.name || sanityData.find(el => el.id === wt)?.name || wt

											const tooltipUUID = v4()
											return (
												<th
													className="h-10 px-1.5 pt-1 pb-3"
													key={`wasteTypesTableHeaderCell_${colIndex}`}
													onClick={() => handleColHeaderClick(colIndex, wt)}
													data-tooltip-id={tooltipUUID}
												>
													<div className="flex items-center justify-center gap-1.5">
														{customWT?.color ? (
															<div
																className="h-6 w-6 rounded-full"
																style={{ backgroundColor: customWT.color }}
															/>
														) : (
															<img
																src={sanityData.find(el => el.id === wt)?.iconGrayscale as string}
																className="left-0 top-0 right-0 h-6 w-6"
																alt={`${sanityData.find(el => el.id === wt)?.name ?? wt}-icon`}
															/>
														)}

														<ArrowUp
															className={classNames([
																{ "stroke-grey4": !isSortedCol },
																{ "rotate-180": !isSortedCol || !sortAscending },
															])}
														/>
													</div>

													<Tooltip id={tooltipUUID} content={title} className="z-[10]" />
												</th>
											)
										})}
									</tr>
								</thead>
								<tbody>
									{customers.map((t, i) => (
										<tr
											className="group border-b border-grey3 text-center text-xs h-[48px] hover:bg-hoverColor"
											key={`wasteTypesTableRow_${i}`}
										>
											<td className="sticky left-0 max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap bg-white px-2 py-1 text-left text-sm sm:w-auto sm:max-w-none sm:px-5 group-hover:bg-hoverColor">
												{t.customerName}
											</td>
											{uniqueWasteTypes.map((wt, colIndex) => (
												<td
													className="border border-grey3 py-1 px-1.5 group-hover:bg-hoverColor"
													key={`wasteTypesTableRow_${i}_cell_${colIndex}`}
													style={{
														backgroundColor: getUtilizationColor(t, wt, tenantCategories || []),
													}}
												>
													{printWasteType(t, wt)}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			)}
		</Card>
	)
}

export interface ITenantWasteTypesCardEntry {}

const Legend = () => {
	const { t } = useTrans()
	return (
		<div className="mb-4 mt-3 flex gap-3 self-end text-xs">
			<div className="flex items-center gap-1.5">
				<div className="h-6 w-12 bg-lightPeach" />
				{t("errors:notUtilized")}
			</div>
			<div className="flex items-center gap-1.5">
				<div className="h-6 w-12 bg-lightLichen" />
				{t("genericLabels:utilized")}
			</div>
			<div className="flex items-center gap-1.5">
				<div className="h-6 w-12 border" />
				{t("errors:notExpected")}
			</div>
		</div>
	)
}
