import { Loading } from "App"
import { useAuth0 } from "Contexts"
import { useEffect } from "react"

export const Logout = () => {
	const { logout } = useAuth0()!

	useEffect(() => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}, [logout])

	return <Loading />
}
