import groupBy from "lodash/groupBy"

export interface InputInterface {
	category: {
		name: string
		description: string
	}
	name: string
	description: string
	vendor: {
		name: string
		email: string
	}
	manufacturer: {
		name: string
	}
	iconColor: any
}

interface OutputInterface {
	category: string
	description: string
	items: {
		name: string
		description: string
		manufacturer: {
			name: string
		}
		vendor: {
			name: string
			email: string
		}
		iconColor: any
	}[]
}

export const getFormattedData = (data?: InputInterface[]): OutputInterface[] => {
	if (!!data?.length) {
		const grouped = groupBy(data, "category.name")
		const keys = Object.keys(grouped)
		return keys.map(key => ({
			category: key,
			description: grouped[key][0]?.category?.description,
			items: grouped[key],
		}))
	}
	return []
}
