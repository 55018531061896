import { textView, ColumnType, methodView } from "./functions"

export const downstreamColumns: ColumnType[] = [
	{
		label: "entities:wasteTypes",
		name: "wasteType",
		getView: textView,
	},
	{
		label: "circularityLabels:wasteCode",
		name: "wasteCode",
		getView: textView,
	},
	{
		label: "circularityLabels:handlingMethod",
		name: "method",
		getView: methodView,
	},
]
