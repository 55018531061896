import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useState } from "react"
import { useTrans } from "translations"
import { useAccessPoints } from "../manage/useAccessPoints"
import { deletePoint } from "api/deletePoint"

export const DeletePointModal: React.FC<{
	accessParentId: string
	pointId: string
	pointName: string | undefined
	onSuccess?: () => void
	modalTitle: string
}> = ({ accessParentId, pointId, pointName, onSuccess, modalTitle }) => {
	const [isLoading, setLoading] = useState(false)

	const { t } = useTrans()
	const { hideModal } = useModal()
	const { refetchAll } = useAccessPoints({ accessParentId })

	const onFailCallBack = () => {
		setLoading(false)
	}

	const onSuccessCallback = () => {
		hideModal()
		refetchAll()
		onSuccess?.()
	}

	const onConfirm = () => {
		setLoading(true)

		deletePoint({
			id: pointId,
			onStartCallBack: onSuccessCallback,
			onFailCallBack: onFailCallBack,
		})
	}

	return (
		<ModalContainer
			title={modalTitle}
			onConfirmText={"actions:confirm"}
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={isLoading}
		>
			<div className="pt-3">
				<p>{t("hints:areYouSureDelete", { name: pointName })}</p>
			</div>
		</ModalContainer>
	)
}
