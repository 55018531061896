import Paperclip from "Icons/Paperclip"
import { Button } from "components/button"
import React, { useContext } from "react"
import { useTrans } from "translations"
import { ImportContext } from "../ImportContext"
import { useConfig } from "api/hooks/useConfig"

export const DownloadTemplates: React.FC = () => {
	const { t } = useTrans()
	const { templates } = useContext(ImportContext)
	const { isMWM, config } = useConfig()

	return (
		<div className="w-2/3 border border-grey3">
			{templates.map(template => (
				<div
					key={template.key}
					className="border-b border-grey3 last:border-b-0 relative flex p-4 items-center justify-between"
				>
					<div className="flex items-center">
						<Paperclip />
						<div className="ml-2">{t(template.title)}.xlsx</div>
					</div>
					<Button
						label="importLabels:download"
						onClick={() =>
							template.downloadFile(template.title, isMWM, !!config.useWasteSuctionSystem)
						}
						type="button"
						color="primary"
					/>
				</div>
			))}
		</div>
	)
}
