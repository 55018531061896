import React from "react"

export const IconSuccess = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1087_3366)">
			<circle cx="10" cy="10" r="9" fill="#467432" stroke="#467432" stroke-width="2" />
			<path
				d="M14.9719 5.76232L14.9015 5.69245L14.831 5.76232L8.36796 12.1742L5.17158 9.00321L5.10115 8.93334L5.03073 9.00321L4.09754 9.92899L4.02598 9.99998L4.09754 10.071L7.36422 13.3119L7.43465 13.2409L7.36422 13.3119L8.29741 14.2376L8.36783 14.3075L8.43826 14.2376L11.7051 10.9968L12.6383 10.071L15.9051 6.83009L15.9766 6.75909L15.9051 6.6881L14.9719 5.76232Z"
				fill="white"
				stroke="white"
				stroke-width="0.2"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1087_3366">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
)
