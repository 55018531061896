export enum FilterSortMenuType {
	Select,
	TimeFrame,
}

export type Option = {
	option: string
	value: string
	translate?: boolean
}

export type FilterSort = {
	type: "filter" | "sort"
	/** Determines which component should render */
	menuType: FilterSortMenuType
	/** Used to identify filters */
	id: string
	options: Option[]
	title: string
	/** Allows multiple options to be selectable at once */
	isMultiselect?: boolean
	/** Use OPTION_ALL as "empty" option, as this value gets treated differently */
	defaultValue?: string
	isLoadingOptions?: boolean
	/** The minimum number of options needed before displaying search bar in f.ex. FilterSelect */
	minOptionsForSearch?: number
	/** Determines if the options should be searchable. Default: true */
	searchEnabled?: boolean
	/** Used for FilterTimeFrame */
	initialStartDate?: Date
	initialEndDate?: Date
	unsetsFilters?: string[]
}
