import React, { useEffect } from "react"
import { DayPicker, getDefaultClassNames } from "react-day-picker"
import CalendarIcon from "Icons/Calendar"
import { Popover } from "@headlessui/react"
import moment from "moment"
import classNames from "classnames"

export const DatePicker: React.FC<{
	onChange: (date?: Date) => void
	value: Date
	heading: string
	minDate?: Date
}> = ({ onChange, value, heading, minDate }) => {
	useEffect(() => {
		if (minDate && value < minDate) {
			onChange(minDate)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minDate])

	const defaultClassNames = getDefaultClassNames()

	return (
		<div>
			<p className="mb-0.5 font-dmSans text-2xs font-bold text-grey6">{heading}</p>
			<Popover className="relative">
				{({ close }) => (
					<>
						<Popover.Button className="w-full bg-grey1 flex flex-row text-sm">
							<div className="m-1.5">
								<CalendarIcon />
							</div>
							<div className="m-1">{moment(value).format("DD/MM/YYYY")}</div>
						</Popover.Button>
						<Popover.Panel className="absolute bg-white p-2 shadow">
							<DayPicker
								mode="single"
								selected={value}
								weekStartsOn={1}
								onSelect={date => {
									onChange(date)
									close()
								}}
								disabled={{ before: minDate, after: new Date() }}
								startMonth={minDate}
								endMonth={new Date()}
								required={false}
								classNames={{
									caption_label: classNames(defaultClassNames.caption_label, "font-dmSans "),
									weekday: "font-dmSans text-sm font-normal",
									disabled: "text-grey3",
									today: "border-lime",
									selected: `bg-lime border-lime`,
									chevron: "fill-black",
								}}
								defaultMonth={value}
							/>
						</Popover.Panel>
					</>
				)}
			</Popover>
		</div>
	)
}

export default DatePicker
