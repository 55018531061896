import React from "react"
import classNames from "classnames"
import { FormInputInterface } from "components/Form/FormInput/FormInput.interface"
import ErrorTooltip from "../ErrorTooltip"

export const FormInput: React.FC<FormInputInterface> = ({
	name,
	id,
	label,
	register,
	required,
	error,
	locked,
	onChange,
	minLength,
	regexPattern,
	min,
	max,
	patternError,
	disabled,
	validationFunction,
	showErrorInTooltip,
}) => {
	return (
		<div className="flex flex-col">
			<label htmlFor={id} className="font-dmSans text-sm font-medium text-black empty:hidden">
				{label}
				{label && required && " *"}
			</label>
			<div className="relative flex-col">
				{!locked ? (
					<input
						disabled={disabled}
						type="text"
						id={id}
						className={classNames(
							"rounded-none h-10 box-border border border-black px-3 font-dmSans text-sm placeholder:text-grey4 disabled:border-grey3 disabled:text-grey6",
							"focus:outline focus:outline-2 focus:outline-offset-[-2px]",
							error && "border-1 border-carrotRed bg-lightPeach focus:outline-carrotRed"
						)}
						autoComplete="off"
						{...register(name, {
							required,
							onChange,
							pattern: regexPattern,
							validate: validationFunction,
							min,
							max,
							minLength,
						})}
					/>
				) : (
					<div className="flex py-1 items-center text-grey4">-</div>
				)}
				<ErrorTooltip
					error={error}
					label={label}
					minLength={minLength}
					patternError={patternError}
					showErrorInTooltip={showErrorInTooltip}
				/>
			</div>
		</div>
	)
}

export default FormInput
