import React, { useContext, useState, useCallback, useRef } from "react"

import "./Modal.css"

export type IModalContext = {
	showModal: (_: any) => void
	hideModal: () => void
	ref: React.MutableRefObject<HTMLDivElement | null>
}

const ModalContext = React.createContext({
	showModal: (_: any) => {},
	hideModal: () => {},
	ref: { current: null },
} as IModalContext)

export function useModal() {
	return useContext(ModalContext)
}

export const ModalProvider = ({ children }: any) => {
	const [modalContent, setModalContent] = useState(null)
	const ref = useRef<HTMLDivElement>(null)

	const hideModal = useCallback(() => {
		setModalContent(null)
	}, [setModalContent])
	const value = {
		showModal: setModalContent,
		hideModal,
		ref,
	}

	const style = {
		visibility: modalContent ? "visible" : "hidden",
	} as React.CSSProperties

	return (
		<ModalContext.Provider value={value}>
			{children}
			<div ref={ref} id="modal" style={style}>
				{modalContent}
			</div>
		</ModalContext.Provider>
	)
}
