import {
	changeView,
	percentageView,
	titleView,
	weightView,
	ColumnType,
	textView,
	RIGHT_ALIGN_CLASS,
} from "./functions"

export const tenantDetailsColumns: ColumnType[] = [
	{
		label: "entities:customer",
		name: "name",
		getView: titleView,
	},
	{
		label: "circularityLabels:recyclingRate",
		name: "recyclingRate",
		getView: value => percentageView(value, " %", RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:changeSincePrevPeriod",
		name: "percentageChange",
		getView: value => changeView(value, "percent", RIGHT_ALIGN_CLASS),
	},
	{
		label: "entities:wasteTypes",
		name: "wasteTypes",
		getView: value => textView(value, RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:registeredNoThrows",
		name: "throws",
		getView: value => textView(value, RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:regResidualWasteWeight",
		name: "residualWaste",
		getView: value => weightView(value, RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:regTotalWeight",
		name: "totalWeight",
		getView: value => weightView(value, RIGHT_ALIGN_CLASS),
	},
]

export const expandedTenantDetailsColumns: ColumnType[] = [
	{
		label: "genericLabels:wasteType",
		name: "wasteType",
		getView: textView,
	},
	{
		label: "circularityLabels:wasteCode",
		name: "wasteCode",
		getView: value => textView(value, RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:registeredNoThrows",
		name: "throws",
		getView: value => textView(value, RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:amount",
		name: "totalWeight",
		getView: value => weightView(value, RIGHT_ALIGN_CLASS),
	},
]
