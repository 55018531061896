import { Page } from "components/Page"
import { Card } from "components/pageCards/card"
import { useModal } from "Contexts"
import { DownstreamHandlingModal } from "Modals/downstreamHandlingModal/downstreamHandlingModal"
import { useTerminalsState } from "States/Terminals"
import { DownstreamInterface } from "./downstream.interface"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useMemo } from "react"
import { trpc } from "Utils/trpc"
import { Table } from "components/Table"
import { downstreamColumns } from "Utils/tableColumns/downstreamColumns"
import { useConfigService } from "pages/configuration/useConfigService"

export const DownstreamHandling = () => {
	const { currentTerminal } = useTerminalsState()
	const { wasteTypes } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()

	const { showModal } = useModal()

	const { data: wasteTypesTotal } = trpc.getWasteTypes.useQuery({
		id: currentTerminal.id,
	})

	const { data: wasteHandling, refetch } = trpc.getDownstreamHandling.useQuery({
		id: currentTerminal.id,
	})

	const downstreamHandlingData = useMemo(
		() =>
			wasteTypesTotal?.map(wasteType => {
				return {
					wasteCode: wasteType.code,
					wasteType:
						wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteType.code)?.name ||
						wasteTypes?.find(w => w.id === wasteType.code)?.name ||
						wasteType.code,
					method: wasteHandling?.find(w => w.code === wasteType.code)?.method,
				} as DownstreamInterface
			}) ?? [],
		[wasteHandling, wasteTypes, wasteTypesTotal, wasteTypeConfig]
	)

	return (
		<Page title="sidebarLabels:downstreamHandling">
			<Card
				title="circularityLabels:handlingPerWasteType"
				button={{
					label: "actions:edit",
					onClick: () =>
						showModal(
							<DownstreamHandlingModal data={downstreamHandlingData} onSave={() => refetch()} />
						),
					color: "secondary",
				}}
			>
				<Table
					columns={downstreamColumns}
					data={downstreamHandlingData}
					sortingHeader="wasteType"
				/>
			</Card>
		</Page>
	)
}

export default DownstreamHandling
