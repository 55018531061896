import FormInput from "components/Form/FormInput"
import { FC } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { MWMCustomerFormValues } from "../../types"
import SelectInput from "components/Form/SelectInput"
import { TypeEnum } from "api/hooks/useCustomers/useCustomers"

type CustomerDetailsFormProps = {
	formHandler: UseFormReturn<MWMCustomerFormValues>
	isEdit?: boolean
}

export const CustomerDetailsForm: FC<CustomerDetailsFormProps> = ({
	isEdit = false,
	formHandler,
}) => {
	const { t } = useTrans()

	const {
		register,
		control,
		formState: { errors },
	} = formHandler

	const typeOptions = [
		{ label: t("entities:customerTypeHOUSEHOLD"), value: TypeEnum.Household.toUpperCase() },
		{ label: t("entities:customerTypeBUSINESS"), value: TypeEnum.Business.toUpperCase() },
	]

	return (
		<div className="md:w-96 mr-3 flex-1">
			{!isEdit && (
				<h2 className="mb-2 font-dmSans text-base font-medium">{`1. ${t(
					"formLabels:enterCustomerInformation"
				)}`}</h2>
			)}
			<div className="my-2">
				<FormInput
					name="name"
					label={t("formLabels:customerReference")}
					register={register}
					error={errors?.name}
					required
				/>
			</div>
			<div className="my-2">
				<SelectInput
					name="type"
					label={t("formLabels:type")}
					control={control}
					options={typeOptions}
					required
					isDisabled={isEdit}
				/>
			</div>
			<div className="my-2">
				<FormInput
					name="address"
					label={t("formLabels:address")}
					register={register}
					error={errors?.address}
				/>
			</div>
			<div className="my-2">
				<FormInput
					name="postalCode"
					label={t("formLabels:postalCode")}
					register={register}
					error={errors?.postalCode}
				/>
			</div>
			<div className="my-2">
				<FormInput
					name="postalRegion"
					label={t("formLabels:postalRegion")}
					register={register}
					error={errors?.postalRegion}
				/>
			</div>
		</div>
	)
}
