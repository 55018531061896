import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

export const LoadingTable: React.FC<{ rows: number }> = ({ rows }) => (
	<div>
		<Skeleton height={24} borderRadius={0} count={rows} />
	</div>
)

export default LoadingTable
