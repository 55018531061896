import { useTerminalsState } from "States/Terminals"
import { trpc } from "Utils/trpc"
import { useCallback, useMemo, useState } from "react"
import { useConfig } from "../useConfig"
import { SortingState } from "@tanstack/react-table"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { OPTION_ALL } from "components/pageCards/filterSort/constants"
import { flatten } from "lodash"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { ListCustomersDtoItemsInner } from "admin-client-server/src/api"

export enum TypeEnum {
	Business = "business",
	BusinessParent = "business_parent",
	Household = "household",
	HouseholdParent = "household_parent",
}

export type SimplifiedCustomer = {
	id: string
	name: string
	category: {
		id: string
	}
	type: TypeEnum
	parent: {
		id: string
		name?: string
	}
}

const limit = 500

export type CustomerList = SimplifiedCustomer[]

const mapCustomerId = (customer: ListCustomersDtoItemsInner) => {
	// Cannot be a switch statement because TS won't recognize the type narrowing
	if (customer.type === TypeEnum.Business) {
		return customer.business.id
	} else if (customer.type === TypeEnum.Household) {
		return customer.household.id
	} else if (customer.type === TypeEnum.BusinessParent) {
		return customer.businessParent.id
	} else if (customer.type === TypeEnum.HouseholdParent) {
		return customer.householdParent.id
	} else {
		throw new Error("Unknown customer type")
	}
}

export const useCustomers = () => {
	const [tenantsFilterState, setTenantsFilterState] = useState<
		ActiveOptions<"category" | "type" | "accessParentId">
	>({} as ActiveOptions<any>)

	const [sortingState, setSortingState] = useState<SortingState>()
	const [allSelected, setAllSelected] = useState(false)
	const { selectedIds, setSelectedIds } = useMultiSelectContext()
	const [nameFilter, setNameFilter] = useState<string | undefined>(undefined)

	const { currentTerminal } = useTerminalsState()

	const { isRealEstate } = useConfig()

	const typeValues = useMemo(() => {
		const values = tenantsFilterState.type
			?.filter(el => el.value !== OPTION_ALL)
			.map(({ value }) => value)
		return values?.length ? values : undefined
	}, [tenantsFilterState])

	const accessParentValues = useMemo(() => {
		const filterValues = tenantsFilterState.accessParentId
			?.filter(el => el.value !== OPTION_ALL)
			.map(({ value }) => value)

		if (filterValues?.length) {
			return filterValues
		}

		if (isRealEstate) {
			return [currentTerminal.id]
		}

		return undefined
	}, [tenantsFilterState, isRealEstate, currentTerminal])

	const categoryIds = useMemo(() => {
		const values: string[] = flatten(
			tenantsFilterState.category?.filter(el => el.value !== OPTION_ALL).map(({ value }) => value)
		)

		return values?.length ? values : undefined
	}, [tenantsFilterState])

	const {
		data: customerPages,
		isFetching: isLoadingCustomers,
		refetch: refetchCustomers,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		isError,
	} = trpc.customers.getAll.useInfiniteQuery(
		{
			nameRegex: nameFilter,
			pointIds: accessParentValues,
			sortDirection: sortingState?.[0]?.desc ?? false ? "desc" : "asc",
			sortFields: (sortingState?.map(ss => ss.id) as ("name" | "createdAt" | "updatedAt")[]) ?? [
				"name",
			],
			categoryIds,
			types: typeValues,
			limit,
		},
		{
			getNextPageParam: lastPage => lastPage.nextCursor,
			onSuccess(data) {
				if (data.pages.length === 1) {
					return
				}
				const items = data.pages.flatMap(page => page.items)
				if (allSelected) {
					setSelectedIds(items.map(customer => mapCustomerId(customer)))
				}
			},
		}
	)

	const { data: minimalCustomerList = [], isLoading: isLoadingMinimalCustomers } =
		trpc.customers.getAllMinimal.useQuery({
			terminalId: isRealEstate ? currentTerminal.id : undefined,
		})

	const getCrmId = useCallback(
		(id: string) => minimalCustomerList.find(el => el.id === id)?.crmId,
		[minimalCustomerList]
	)

	const customers = useMemo(
		() =>
			customerPages?.pages?.flatMap(
				page =>
					page.items?.map(customerInner => {
						if (customerInner.type === TypeEnum.Business) {
							return {
								...customerInner.business,
								parent: { id: customerInner.business.parentCustomer?.id },
								type: customerInner.type,
								crmId: getCrmId(customerInner.business.id),
							}
						} else if (customerInner.type === TypeEnum.Household) {
							return {
								...customerInner.household,
								parent: { id: customerInner.household.parentCustomer?.id },
								type: customerInner.type,
								crmId: getCrmId(customerInner.household.id),
							}
						} else if (customerInner.type === TypeEnum.BusinessParent) {
							return {
								...customerInner.businessParent,
								type: customerInner.type,
								crmId: getCrmId(customerInner.businessParent.id),
							}
						} else if (customerInner.type === TypeEnum.HouseholdParent) {
							return {
								...customerInner.householdParent,
								type: customerInner.type,
								crmId: getCrmId(customerInner.householdParent.id),
							}
						} else {
							throw new Error("Unknown customer type")
						}
					}) as CustomerList
			),
		[customerPages, getCrmId]
	)

	const { mutate: createCustomer, isLoading: isSavingCustomer } =
		trpc.customers.create.useMutation()

	const {
		mutate: updateCustomer,
		isLoading: isUpdatingCustomer,
		status: updateStatus,
	} = trpc.customers.update.useMutation()

	return {
		data: customers || [],
		isLoadingCustomers,
		isError,
		refetchCustomers,
		createCustomer,
		isSavingCustomer,
		updateCustomer,
		isUpdatingCustomer,
		updateStatus,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		sortingState,
		setSortingState,
		setTenantsFilterState,
		tenantsFilterState,
		selectedIds,
		setSelectedIds,
		allSelected,
		setAllSelected,
		nameFilter,
		setNameFilter,
		minimalCustomerList,
		isLoadingMinimalCustomers,
	}
}
