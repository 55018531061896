import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { useTerminalsState } from "States/Terminals"
import { WasteRoomForm, WasteRoomFormData } from "../manage/wasteRoomForm"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { addAccessPoint } from "api/addAccessPoint"
import { uniqueIdGenerator } from "Utils/uniqueIdGenerator"
import { ACCESS_POINT_NAME_KEY, EXTERNAL_ID_KEY, EXTERNAL_KEYS_KEY } from "constants/general"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useGlobalAlert } from "States/globalAlert"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"

export const AddWasteRoomModal: React.FC = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { isLoading: isLoadingWasteRooms, refetch } = useAccessParentsWithPoints()
	const { setSelectedContainers } = useMultiSelectContext()
	const { setGlobalAlert } = useGlobalAlert()

	const {
		currentTerminal: { id: terminalId },
	} = useTerminalsState()
	const { sendNewAccessParentSlackNotification } = useSlackNotifications()

	const formHandler = useForm<WasteRoomFormData>()

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onSubmit = handleSubmit(roomFormData => {
		const { name } = roomFormData

		addAccessPoint({
			accessPoint: {
				[ACCESS_POINT_NAME_KEY]: name,
				type: "ACCESS_PARENT",
				parent: {
					id: terminalId,
				},
				[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: uniqueIdGenerator("uuid") }],
			},
			onStartCallBack: res => {
				refetch()
					.then(() => {
						setGlobalAlert({
							type: "success",
							message: "systemMessages:accessParentAdded",
						})
						sendNewAccessParentSlackNotification(name, res.modifiedPoint.id)
						setSelectedContainers([])

						const url = getInfrastructureUrl({
							terminalId,
							accessParentId: res.modifiedPoint.id,
						})
						navigate(url)
					})
					.then(hideModal)
			},
		})
	})

	return (
		<ModalContainer
			title="actions:newAccessParent"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingWasteRooms}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<WasteRoomForm {...{ formHandler, onSubmit }} />
		</ModalContainer>
	)
}
