import i18n from "i18n"
import { getCurrentLocale } from "translations/functions/locale"

/**
 * Formats number with thousand seperator
 * @param n
 * @returns formated string
 */
export const formatNumber = (n: number | string) => {
	return Number(n).toLocaleString(i18n.language)
}

/**
 * Rounds/formats number to specified decimal places
 * @param number
 * @param decimalPlaces
 * @returns rounded number with specific number of decimal places
 */
export const formatNumberWithDecimalPlaces = (number: number, decimalPlaces: number) =>
	Math.round((number + Number.EPSILON) * 10 ** decimalPlaces) / 10 ** decimalPlaces

export type TFormatNumberSuffix = "" | " %" | " kg"

export const roundNumberForTable = (number: number, numDecimals: number = 1): number => {
	const shouldUseDecimals = numDecimals > 0
	return formatNumberWithDecimalPlaces(number, shouldUseDecimals ? numDecimals : 0)
}

/**
 * @param number
 * @param suffix
 * @param numDecimals
 * @param maxLength max length of the number when displayed as a string. Above this length the number will be displayed in exponential notation
 * @returns formated string
 */
export const formatNumberForTable = (
	number: number | string | undefined,
	options?: Partial<{
		suffix: TFormatNumberSuffix
		numDecimals: number
		maxLength: number
		locale: string
	}>
) => {
	const endsWithPeriod = typeof number === "string" && number.slice(-1) === "."
	const _number = endsWithPeriod ? Number(number.slice(0, -1)) : Number(number)
	const { maxLength = 8, numDecimals = 1, suffix = "", locale = getCurrentLocale() } = options || {}

	const shouldIncludeEndingPeriod = endsWithPeriod
	const localeDecimalSeparator = new Intl.NumberFormat(locale).format(1.1).charAt(1)

	if (isNaN(_number)) return "0"

	const parsedNumber = roundNumberForTable(_number, numDecimals)

	const shouldBeExponential = parsedNumber.toString().length > maxLength
	const formatedValue = parsedNumber.toLocaleString(locale, {
		notation: shouldBeExponential ? "scientific" : "standard",
		minimumFractionDigits: shouldBeExponential ? 1 : undefined,
		maximumFractionDigits: shouldBeExponential ? 1 : undefined,
	})
	const isPercentValue = suffix.includes(" %")

	if (isPercentValue && numDecimals) {
		const isZeroOrFull = !_number || _number === 100
		const fullNumber = Number(_number).toLocaleString(locale, {
			minimumFractionDigits: isZeroOrFull ? 0 : 1,
			maximumFractionDigits: isZeroOrFull ? 0 : 1,
		})
		const maxLengthAccountingForNegative = _number < 0 ? maxLength + 1 : maxLength
		const shouldBeExponential = fullNumber.length > maxLengthAccountingForNegative
		return (
			_number.toLocaleString(locale, {
				minimumFractionDigits: isZeroOrFull ? 0 : 1,
				maximumFractionDigits: isZeroOrFull ? 0 : 1,
				notation: shouldBeExponential ? "scientific" : "standard",
			}) + suffix
		)
	}

	return formatedValue + suffix + (shouldIncludeEndingPeriod ? localeDecimalSeparator : "")
}

export const removeMoreThanTwoDecimals = (num: number) => Math.round(num * 100) / 100
export const removeDecimals = (num: number) => Number.parseInt(num.toFixed(0))

export const formatWeight = (weight: number | string) => +Number(weight).toFixed(1)
