import FormInput from "components/Form/FormInput"
import { BaseSyntheticEvent, FC } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"

export type WasteRoomFormData = {
	name: string
}

export const WasteRoomForm: FC<{
	formHandler: UseFormReturn<WasteRoomFormData>
	onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}> = ({ formHandler, onSubmit }) => {
	const { t } = useTrans()
	const {
		register,
		formState: { errors },
	} = formHandler

	return (
		<form {...{ onSubmit }}>
			<div className="mb-6">
				<FormInput
					name="name"
					label={t("importLabels:accessParentName")}
					register={register}
					error={errors?.name}
					required
				/>
			</div>
		</form>
	)
}
