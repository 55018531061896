import { isEmpty } from "lodash"
import { ActiveOptions } from "./FilterSortContext"
import { FilterSort } from "./types"

export const stringifySearchParamOptions = (searchParams: Record<string, string>) => {
	if (isEmpty(searchParams)) return ""

	return `?filters=${encodeURIComponent(JSON.stringify(searchParams))}`
}

export const parseSearchParamsForFilterOptions = (url: string) => {
	const urlParams = new URLSearchParams(url)
	const filters = urlParams.get("filters")
	if (!filters) return []
	return JSON.parse(filters)
}

export const mapSearchParamOptions = (input: Record<string, string>, filters: FilterSort[]) => {
	const activeOptions: ActiveOptions<any> = {}

	filters.forEach(filter => {
		const filterId = filter.id
		const searchParamFilter = input[filterId]

		if (searchParamFilter) {
			let values
			// dont split custom interval
			if (filterId === "timeframe" && !!input[filterId].match(/Z&/g)?.length) {
				values = [{ value: input[filterId], option: "interval.custom" }]
			} else {
				values = input[filterId].split("&").map(value => {
					const option = filter?.options.find(option => option.value === value)
					// if the option is not found, return the value
					return option || { value }
				})
			}
			if (values.length > 0) {
				activeOptions[filterId] = values
			}
		} else {
			const defaultOption = filter.options.find(option => option.value === filter.defaultValue)

			if (defaultOption) {
				activeOptions[filterId] = [defaultOption]
			}
		}
	})

	return activeOptions
}
