import { AddButton } from "components/AddButton"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { AccessParentWithChildren } from "admin-client-server/src/coreApi/models/Common"
import { TOptions } from "i18next"
import { AddStationModal } from "pages/infrastructure/modals/addStationModal"
import { AddWasteRoomModal } from "pages/infrastructure/modals/addWasteRoomModal"
import { AccessParentDrawer } from "components/pageCards/multiListTable/drawers/lfm/accessParentDrawer"
import { FilterSort } from "components/pageCards/filterSort/types"
import { CardComponentFilterLocation } from "components/pageCards/card"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"

type Props = {
	isMWM: boolean
	t: (key: string, options?: TOptions | undefined) => string
	qrLinkDisabled: boolean
	showModal: (_: any) => void
	accessParentsList: (AccessParentWithChildren & {
		fillLevel: any
	})[]
	isLoading: boolean
	accessParentId: string
	getAccessParentListEntryBottomLabel: (entry: AccessParentWithChildren) => string
	onRowClick: (accessParent: AccessParentWithChildren) => void
	filters: FilterSort[]
	setAccessParentsFilterState: (activeOptions: ActiveOptions<any>) => void
}

export default function getAccessParentSection({
	isMWM,
	t,
	qrLinkDisabled,
	showModal,
	accessParentsList,
	isLoading,
	accessParentId,
	getAccessParentListEntryBottomLabel,
	onRowClick,
	filters,
	setAccessParentsFilterState,
}: Props): MultiListSectionConfig<typeof accessParentsList> {
	return {
		type: "list",
		title: "entities:accessParents",
		link: !isMWM
			? {
					label: t("actions:printQRCodes"),
					to: "/infrastructure/manage/qr-codes",
					isDisabled: qrLinkDisabled,
					disabledMessage: t("errors:missingTerminalsOrAccessParents"),
			  }
			: undefined,
		customButton: (
			<AddButton onClick={() => showModal(isMWM ? <AddStationModal /> : <AddWasteRoomModal />)} />
		),
		data: accessParentsList,
		isLoading,
		getInitiallySelectedRows: entries => entries.filter(({ id }) => id === accessParentId),
		entryRender: entry => ({
			labelTop: entry.name,
			labelBottom: getAccessParentListEntryBottomLabel(entry),
			labelTopRight: entry.fillLevel
				? `${t("drawerLabels:fullestContainer")}: ${entry.fillLevel}%`
				: "",
			labelBottomRight: `${t("formLabels:status")}: ${t("status:" + entry.status)}`,
		}),
		onRowClick,
		drawer: {
			content: <AccessParentDrawer {...{ accessParentId }} />,
		},
		emptyViewType: "noAccessParents",
		labelSingle: "entities:accessParent",
		labelMultiple: "entities:accessParents",
		filters,
		onFilterOptionChange: setAccessParentsFilterState,
		filterLocation: CardComponentFilterLocation.INSIDE,
	}
}
