import React from "react"

export type ITableContext = {
	onRowClick: any
	selectedIds: string[]
}

export const TableContext = React.createContext({
	onRowClick: () => {},
	selectedIds: [],
} as ITableContext)
