import { UseFormReturn, useFieldArray } from "react-hook-form"
import { MWMCustomerFormValues } from "../../types"
import { FC, useMemo } from "react"
import { useTrans } from "translations"
import SelectInput from "components/Form/SelectInput"
import { useAccessParents } from "pages/infrastructure/functions"
import { Button } from "components/button"
import { CustomerDetailsForm } from "./CustomerDetailsForm"
import { RemoveButton } from "components/RemoveButton"

interface CustomerDetailsFormSectionProps {
	formHandler: UseFormReturn<MWMCustomerFormValues>
}

export const CreateCustomerForm: FC<CustomerDetailsFormSectionProps> = ({ formHandler }) => {
	const { accessParents } = useAccessParents()
	const { t } = useTrans()
	const { control } = formHandler

	const {
		fields: accessParentFields,
		append,
		remove: removeRow,
	} = useFieldArray({
		control,
		name: "accessParents",
	})

	const addRow = () => append({ id: "" }, { shouldFocus: false })

	const accessParentOptions = useMemo(
		() => accessParents.map(({ id, name }) => ({ label: name, value: id })),
		[accessParents]
	)

	return (
		<form className="flex flex-col gap-4 md:flex-row 2xl:justify-between">
			<CustomerDetailsForm formHandler={formHandler} isEdit={false} />
			<div className="md:ml-3 flex-1">
				<h2 className="mb-2 font-dmSans text-base font-medium">{`2. ${t(
					"entities:accessParents"
				)}`}</h2>
				{accessParentFields.map((_, index) => (
					<div key={index} className="flex my-3">
						<div className="flex-1">
							<SelectInput
								name={`accessParents.${index}.id`}
								control={control}
								options={accessParentOptions}
							/>
						</div>
						<RemoveButton onClick={() => removeRow(index)} className="ml-3" type="button" />
					</div>
				))}
				<Button
					className="mt-2"
					label="actions:addRow"
					onClick={addRow}
					type="button"
					color="secondary"
				/>
			</div>
		</form>
	)
}
