interface QRPageProps {
	children: React.ReactNode
	pageIndex: number
	pageLabel: string
	qrContainerMarginBottom: string
	templateHeight: string
	templateWidth: string
	columns: number
	gridTemplateRows: string
}

const QRPrintPage: React.FC<QRPageProps> = ({
	children,
	pageIndex,
	pageLabel,
	qrContainerMarginBottom,
	templateHeight,
	templateWidth,
	columns,
	gridTemplateRows,
}) => {
	return (
		<div
			key={pageIndex}
			className={`qr-code-container ${qrContainerMarginBottom} print:break-after-page print:h-[${templateHeight}] print:w-[${templateWidth}] print:px-6`}
		>
			<div className={`border-gray relative top-0 left-0`}>
				<div className="invisible flex h-0 items-center justify-between print:visible print:h-[10%] print:pb-[5%] print:pt-[5%]">
					<img src="/carrot-logo.svg" alt="Carrot Logo" className="w-[10%]" />
					<p className="text-md">{pageLabel}</p>
				</div>
				<div
					key={pageIndex}
					className={`h-[85%] grid grid-cols-${columns} gap-x-[2%]`}
					style={{
						width: "100%",
						boxSizing: "border-box",
						gridTemplateRows,
					}}
				>
					{children}
				</div>
			</div>
		</div>
	)
}

export default QRPrintPage
