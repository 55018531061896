import React, { MutableRefObject } from "react"
import Skeleton from "react-loading-skeleton"

type TableSkeletonProps = {
	wrapperRef: MutableRefObject<HTMLDivElement | null>
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({ wrapperRef }) => {
	const tableHeight = wrapperRef.current?.clientHeight ?? 0
	const numberOfRows = Math.floor(tableHeight / 30)
	return <Skeleton count={numberOfRows} height={28} />
}
