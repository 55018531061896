import { EmptyState } from "Icons/EmptyState"
import { NoAccessParents } from "Icons/NoAccessParents"
import { NoContainers } from "Icons/NoContainers"
import { NoEvents } from "Icons/NoEvents"
import { NoHistory } from "Icons/NoHistory"
import { NoPage } from "Icons/NoPage"
import { NoCustomers } from "Icons/NoCustomers"
import { NoTerminals } from "Icons/NoTerminals"

type EmptyViewData = {
	icon?: React.JSX.Element
	label: string
	description?: string
	descriptionComponents?: any[]
}

export const EMPTY_DATA: EmptyViewData = {
	icon: <></>,
	label: "",
	description: "",
}

export type EmptyDataType =
	| "noData"
	| "noDataForInterval"
	| "failToFetch"
	| "noInactiveTenants"
	| "noEvents"
	| "unableToFetchEvents"
	| "noTerminals"
	| "noAccessParents"
	| "noContainers"
	| "noCustomers"
	| "noHistory"
	| "qrCode"
	| "noAccessTokens"
	| "noContactPerson"
	| "noAccessParent"
	| "noTechnologies"
	| "noDepots"
	| "noCustomerResults"
	| "noDataCalculationPeriodUncomplete"

export const emptyViewTypes: { [k in EmptyDataType]: EmptyViewData } = {
	noData: {
		icon: <EmptyState />,
		label: "errors:noData",
		description: "errors:noDataDescription",
	},
	noDataForInterval: {
		icon: <EmptyState />,
		label: "errors:noDataForInterval",
		description: "errors:noDataForIntervalDescription",
	},
	failToFetch: {
		icon: <EmptyState />,
		label: "errors:failToFetch",
		description: "errors:failToFetchDescription",
	},
	noInactiveTenants: {
		icon: <NoCustomers />,
		label: "errors:noInactiveTenants",
	},
	noEvents: {
		icon: <NoEvents />,
		label: "errors:noEventsForInterval",
		description: "errors:noEventsForIntervalDescription",
	},
	unableToFetchEvents: {
		icon: <NoEvents />,
		label: "errors:failToFetchEvents",
		description: "errors:failToFetchEventsDescription",
	},
	noTerminals: {
		icon: <NoTerminals />,
		label: "errors:noTerminals",
		description: "errors:noTerminalsDescription",
	},
	noAccessParents: {
		icon: <NoAccessParents />,
		label: "errors:noAccessParents",
		description: "errors:noAccessParentsDescription",
	},
	noContainers: {
		icon: <NoContainers />,
		label: "errors:noContainers",
		description: "errors:noContainersDescription",
	},
	noCustomers: {
		icon: <NoCustomers />,
		label: "errors:noCustomers",
		description: "errors:noCustomersDescription",
	},
	noHistory: {
		icon: <NoHistory />,
		label: "errors:noHistory",
	},
	qrCode: {
		icon: <NoPage />,
		label: "errors:noQRCodes",
		description: "errors:noQRCodesDescription",
		descriptionComponents: [
			// eslint-disable-next-line jsx-a11y/anchor-has-content
			<a href="mailto:support@carrot.tech" className="underline" />,
		],
	},
	noAccessTokens: {
		label: "errors:noAccessTokens",
	},
	noContactPerson: {
		label: "errors:noContactPerson",
	},
	noAccessParent: {
		label: "errors:noAccessParents",
	},
	noTechnologies: {
		label: "systemMessages:noTechnologies",
	},
	noDepots: {
		label: "errors:noDepots",
		description: "errors:noDepotsDescription",
	},
	noCustomerResults: {
		icon: <NoCustomers />,
		label: "errors:noResults",
	},
	noDataCalculationPeriodUncomplete: {
		icon: <EmptyState />,
		label: "errors:noDataCalculationPeriodUncomplete",
		description: "errors:noDataCalculationPeriodUncompleteDescription",
	},
}
