import { textView, ColumnType } from "./functions"

export const contactsColumns: ColumnType[] = [
	{
		label: "formLabels:name",
		name: "name",
		getView: textView,
	},
	{
		label: "formLabels:email",
		name: "email",
		getView: textView,
	},
	{
		label: "formLabels:phoneNumber",
		name: "phone",
		getView: textView,
	},
]
