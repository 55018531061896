import { Card, CardComponentVariant } from "components/pageCards/card"
import { useModal } from "Contexts"
import { FC } from "react"
import { Button } from "components/button/index"
import { Person } from "."
import { PeopleModal } from "./PeopleModal"
import { useTrans } from "translations"
import { Table } from "components/Table"
import { contactsColumns } from "Utils/tableColumns/contactsColumns"
import { Customer } from "pages/customer/manage/types"

export type CustomerWithPeople = Customer & {
	contactPeople?: string
}

interface PeopleCardProps {
	customer: CustomerWithPeople
	onUpdate: () => void
}

export const PeopleCard: FC<PeopleCardProps> = ({ customer, onUpdate }) => {
	const { t } = useTrans()
	const { showModal } = useModal()

	const mainPerson = customer?.contactEmail
		? {
				name: customer?.contactPerson ?? t("drawerLabels:mainContact"),
				email: customer?.contactEmail,
				phone: customer?.contactMobile,
		  }
		: null
	let people = JSON.parse(customer?.contactPeople ?? "[]") as Person[]
	if (mainPerson) {
		people = [mainPerson, ...people]
	}

	const showEditModal = () => {
		showModal(<PeopleModal customerId={customer.id} people={people} onConfirm={onUpdate} />)
	}

	return (
		<Card title="drawerLabels:contactPersons" variant={CardComponentVariant.DRAWER}>
			<Table
				limitedHeight
				columns={contactsColumns}
				data={people}
				sortable={false}
				emptyViewType="noContactPerson"
				emptyViewClassName="bg-grey1 mt-0"
			/>
			<Button
				label={"actions:edit"}
				onClick={showEditModal}
				color="secondary"
				className="float-right mt-10"
			/>
		</Card>
	)
}
