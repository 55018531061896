import { DocumentNode } from "@apollo/client"
import { postFunc } from "../Utils/api"
import { generateGQLrequest } from "../Utils/gqlRequestGenerator"
import { addAccessPointMutation } from "../Utils/gqlRequestTypes/addAccessPointMutation"

export const addAccessPoint = ({
	accessPoint,
	refetchQueries,
	onStartCallBack,
	onFailCallBack,
}: {
	accessPoint: addAccessPointMutation
	refetchQueries?: {
		query: DocumentNode
		variables?: {
			[key: string]: any
		}
	}[]
	onStartCallBack?: (val?: any) => void
	onFailCallBack?: () => void
}) => {
	const ADD_ACCESS_POINT = generateGQLrequest({
		type: "addAccessPointMutation",
		input: {
			wait: true,
			payload: accessPoint,
		},
		payloadKeys: ["modifiedPoint { id }"],
	})
	postFunc(
		ADD_ACCESS_POINT,
		(res: any) => {
			onStartCallBack && onStartCallBack(res?.data?.addAccessPointMutation)
		},
		{ refetchQueries: refetchQueries || [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
}
