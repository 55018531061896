import { MappingSection, Template } from "components/ImportComponents/types"
import {
	getAccessParentColumns,
	getAccessParentSelection,
	getContainerColumns,
	getDepotColumns,
	getDepotSelection,
	getTerminalColumns,
	getTerminalParentSelection,
} from "./columnFunctions"
import {
	downloadForAccessParents,
	downloadForContainers,
	downloadForContainersOnDepot,
	downloadForDepots,
	downloadForTerminals,
} from "./downloadFunctions"
import {
	SUCCESS_PARAMETERS,
	TemplateProps,
	getAccessParentSection,
	getContainerSection,
	getDepotSection,
	getTerminalSection,
} from "./templates"
import { NoTerminalsModal } from "pages/customer/manage/components/NoTerminalsModal"
import { NoAccessParentsForContainersModal } from "pages/customer/manage/components/NoAccessParentsForContainers"
import { NoDepotsForContainersModal } from "pages/customer/manage/components/NoDepotForContainersModal"

export const getTemplatesWithDepots = ({
	terminals,
	wasteTypeClassificationSystems,
	wasteTypes,
	accessParents,
	depots,
	defaultWTCS,
	isMWM,
	showModal,
}: TemplateProps): Template[] => {
	const terminalColumns = getTerminalColumns({ terminals, isMWM })
	const terminalParentSelection = () => getTerminalParentSelection(terminals)
	const terminalSection = getTerminalSection({
		columns: terminalColumns,
		selectionParams: terminalParentSelection(),
	})
	const preSelectedTerminalSection = {
		...terminalSection,
		isPreSelected: true,
	}

	const accessParentColumns = getAccessParentColumns({ isMWM })
	const accessParentSelection = () => getAccessParentSelection(accessParents)
	const accessParentSection = getAccessParentSection({
		columns: accessParentColumns,
		selectionParams: accessParentSelection(),
	})
	const optionalAccessParentSection = { ...accessParentSection, isOptional: true }
	const preSelectedAccessParentSection = {
		...accessParentSection,
		isPreSelected: true,
	}

	const depotColumns = getDepotColumns()
	const depotSelection = getDepotSelection(depots)
	const depotSection = getDepotSection({
		columns: depotColumns,
		selectionParams: depotSelection,
	})
	const optionalDepotSection = { ...depotSection, isOptional: true }

	const containerColumns = getContainerColumns({
		wasteTypeClassificationSystems,
		wasteTypes,
		defaultWTCS,
		isMWM,
		onDepotOrSection: true,
	})
	const containersSection = getContainerSection({ columns: containerColumns })
	const optionalContainersSection = { ...containersSection, isOptional: true }

	const terminalSections: MappingSection[] = [
		terminalSection,
		optionalAccessParentSection,
		optionalDepotSection,
		optionalContainersSection,
	]

	const accessParentSections: MappingSection[] = [
		preSelectedTerminalSection,
		{
			...accessParentSection,
			columns: accessParentSection.columns?.filter(c => !c.hasLinkedParent),
		},
		optionalDepotSection,
		optionalContainersSection,
	]

	const depotSections: MappingSection[] = [
		preSelectedTerminalSection,
		preSelectedAccessParentSection,
		{
			...depotSection,
			columns: depotSection.columns?.filter(c => !c.hasLinkedParent),
		},
		{
			...optionalContainersSection,
			columns: getContainerColumns({
				wasteTypeClassificationSystems,
				wasteTypes,
				defaultWTCS,
				isMWM,
				onDepot: true,
			}),
		},
	]

	const containerOnSectionSections: MappingSection[] = [
		preSelectedTerminalSection,
		preSelectedAccessParentSection,
		{
			...containersSection,
			columns: getContainerColumns({
				wasteTypeClassificationSystems,
				wasteTypes,
				defaultWTCS,
				isMWM,
			}).filter(c => !c.hasLinkedParent),
		},
	]

	const containerOnDepotSections: MappingSection[] = [
		preSelectedTerminalSection,
		preSelectedAccessParentSection,
		{
			...depotSection,
			isPreSelected: true,
		},
		{
			...containersSection,
			columns: getContainerColumns({
				wasteTypeClassificationSystems,
				wasteTypes,
				defaultWTCS,
				isMWM,
			}).filter(c => !c.hasLinkedParent),
		},
	]

	return [
		{
			key: "terminals",
			title: "importLabels:infraTemplateTerminalsWithDepots",
			downloadFile: downloadForTerminals,
			mappingSections: terminalSections,
			checkCanBeUsed: () => true,
			...SUCCESS_PARAMETERS,
		},
		{
			key: "accessParents",
			title: "importLabels:infraTemplateAccessParentsWithDepots",
			downloadFile: downloadForAccessParents,
			parentSelections: [terminalParentSelection()],
			mappingSections: accessParentSections,
			checkCanBeUsed: () => terminals?.length > 0,
			showCantBeUsed: () => showModal(<NoTerminalsModal />),
			...SUCCESS_PARAMETERS,
		},
		{
			key: "depots",
			title: "importLabels:infraTemplateDepots",
			downloadFile: downloadForDepots,
			parentSelections: [terminalParentSelection(), accessParentSelection()],
			mappingSections: depotSections,
			checkCanBeUsed: () => accessParents?.length! > 0,
			showCantBeUsed: () => showModal(<NoAccessParentsForContainersModal />),
			...SUCCESS_PARAMETERS,
		},
		{
			key: "containers",
			title: "importLabels:infraTemplateContainersOnStations",
			downloadFile: downloadForContainers,
			parentSelections: [terminalParentSelection(), accessParentSelection()],
			mappingSections: containerOnSectionSections,
			checkCanBeUsed: () => accessParents?.length! > 0,
			showCantBeUsed: () => showModal(<NoAccessParentsForContainersModal />),
			...SUCCESS_PARAMETERS,
		},
		{
			key: "containersOnDepots",
			title: "importLabels:infraTemplateContainersOnDepots",
			downloadFile: downloadForContainersOnDepot,
			parentSelections: [terminalParentSelection(), accessParentSelection(), depotSelection],
			mappingSections: containerOnDepotSections,
			checkCanBeUsed: () => depots?.length! > 0,
			showCantBeUsed: () => showModal(<NoDepotsForContainersModal />),
			...SUCCESS_PARAMETERS,
		},
	]
}
