import { FC, SVGAttributes } from "react"

const QuestionMark: FC<SVGAttributes<SVGSVGElement>> = ({
	color = "#000",
	height = 20,
	width = 20,
	...props
}) => (
	<svg width={width} height={height} viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm-1-7v2h2v-2H9Zm2-1.645A3.502 3.502 0 0 0 10 4.5a3.501 3.501 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 10 9.5a1 1 0 0 0-1 1V12h2v-.645Z"
			fill={color}
		/>
	</svg>
)

export default QuestionMark
