import { LoadingState, MappingState, Template, UploadedFileSheet, ValidationState } from "./types"
import create from "zustand"

export type UseImportState = {
	// Templates are the possible file configurations (upload X, Y and Z, upload only Y and Z, etc.)
	selectedTemplate: Template | undefined
	setSelectedTemplate: (t?: Template) => void

	// Parent selection values store the choices of which entities to upload to
	selectedParentIds: { [k: string]: string }
	setSelectedParentIds: (obj: { [k: string]: string }) => void

	uploadedFileName: string
	setUploadedFileName: (s: string) => void

	// Stores the basic structure of the uploaded file in a list of sheets and the columns of each
	uploadedFileStructure: UploadedFileSheet[]
	setUploadedFileStructure: (arr: UploadedFileSheet[]) => void

	// Stores the information of how each sheet and column is mapped
	mappingState: MappingState
	setMappingState: (obj: MappingState) => void

	// Stores the information of how each cell is validated
	validationState: ValidationState
	setValidationState: (obj: ValidationState) => void

	// Stores the current loading state when adding to system
	loadingState: LoadingState
	setLoadingState: (obj: LoadingState) => void

	reset: () => void
	fullReset: () => void
}

export const useImportState = create<UseImportState>(set => ({
	selectedTemplate: undefined,
	setSelectedTemplate: _selectedTemplate => set(() => ({ selectedTemplate: _selectedTemplate })),

	selectedParentIds: {},
	setSelectedParentIds: _selectedParentIds =>
		set(() => ({ selectedParentIds: _selectedParentIds })),

	uploadedFileName: "",
	setUploadedFileName: _uploadedFileName => set(() => ({ uploadedFileName: _uploadedFileName })),

	uploadedFileStructure: [],
	setUploadedFileStructure: _uploadedFileStructure =>
		set(() => ({ uploadedFileStructure: _uploadedFileStructure })),

	mappingState: {},
	setMappingState: _mappingState => set(() => ({ mappingState: _mappingState })),

	validationState: {},
	setValidationState: _validationState => set(() => ({ validationState: _validationState })),

	loadingState: {},
	setLoadingState: _loadingState => set(() => ({ loadingState: _loadingState })),

	reset: () => {
		set(() => ({
			uploadedFileName: "",
			uploadedFileStructure: [],
			mappingState: {},
			validationState: {},
			loadingState: {},
		}))
	},

	fullReset: () => {
		set(() => ({
			selectedTemplate: undefined,
			selectedParentIds: {},
			uploadedFileName: "",
			uploadedFileStructure: [],
			mappingState: {},
			validationState: {},
			loadingState: {},
		}))
	},
}))
