import React, { useMemo } from "react"
import { useTrans } from "translations"

type TableFooterProps = {
	numberTotalRows?: number
	numberRowsSelected?: number
	hasMultipleSelectFunctionality: boolean
	hasExportText: boolean
	labelSingle: string
	labelMultiple: string
	isPaginated?: boolean
	hasLoadedEverything?: boolean
}

export const TableFooter: React.FC<TableFooterProps> = ({
	hasMultipleSelectFunctionality,
	numberTotalRows = 0,
	numberRowsSelected = 0,
	hasExportText,
	labelSingle,
	labelMultiple,
	isPaginated = false,
	hasLoadedEverything = false,
}) => {
	const { t } = useTrans()
	const rowCountLabel = numberTotalRows === 1 ? labelSingle : labelMultiple

	const counter = useMemo(() => {
		if (isPaginated && !hasLoadedEverything) {
			if (numberRowsSelected >= numberTotalRows) {
				return t("genericLabels:allSelected", { entity: t(rowCountLabel).toLowerCase() })
			}
			return `${numberRowsSelected} ${t(labelMultiple).toLowerCase()} ${t(
				"genericLabels:selected"
			)}`
		}
		if (numberRowsSelected === 0 || !hasMultipleSelectFunctionality) {
			return `${numberTotalRows} ${t(rowCountLabel).toLowerCase()}`
		}
		return `${numberRowsSelected}/${numberTotalRows} ${t(labelMultiple).toLowerCase()} ${t(
			"genericLabels:selected"
		)}`
	}, [
		isPaginated,
		numberRowsSelected,
		numberTotalRows,
		hasMultipleSelectFunctionality,
		rowCountLabel,
		t,
		labelMultiple,
		hasLoadedEverything,
	])

	return (
		<div className="flex h-16 items-center justify-between border-t-2 border-grey1 bg-white pl-4 pr-5 text-sm">
			<div className="text-grey6">{counter}</div>
			{hasExportText && (
				<div className="text-grey6">
					{t(
						`actions:${
							hasMultipleSelectFunctionality ? "selectMultipleRows" : "selectRowsToExport"
						}`
					)}
				</div>
			)}
		</div>
	)
}
