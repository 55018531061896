import { ErrorTag } from "components/ErrorTag/ErrorTag"
import React from "react"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"

export type Tab = {
	key?: string
	title: string
	nrErrors?: number
}

type Props = {
	tabs: Tab[]
	activeTab: number
	setActiveTab: (index: number) => void
}

export const Tabs: React.FC<Props> = ({ tabs, activeTab, setActiveTab }) => {
	const { t } = useTrans()

	const activeTabClass = "border-b-4 border-saturatedLime font-medium border-solid"
	const inactiveTabClass =
		"cursor-pointer font-normal hover:border-b-4 hover:border-grey3 hover:bg-grey1 hover:border-solid"

	return (
		<div className="flex text-sm">
			{tabs.map(({ title, nrErrors }, index) => (
				<div
					key={title}
					className={twMerge(
						"py-4 px-6 flex border-b-4 border-transparent",
						index === activeTab ? activeTabClass : inactiveTabClass
					)}
					onClick={() => setActiveTab(index)}
				>
					{t(title)}
					{nrErrors! > 0 && (
						<div className="ml-3">
							<ErrorTag nrErrors={nrErrors!} />
						</div>
					)}
				</div>
			))}
		</div>
	)
}
