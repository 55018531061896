import React from "react"
import UserButton from "UI/Header/components/UserButton"
import { useTrans } from "translations"

interface SearchbarProps {
	toggleMenu: () => void
}

export const Searchbar: React.FC<SearchbarProps> = ({ toggleMenu }) => {
	const { t } = useTrans()
	return (
		<div className="fixed top-0 right-0 z-20 flex h-16 w-full items-center justify-between bg-white px-8 md:w-[calc(100%-theme(space.64))] ">
			<button onClick={toggleMenu} className="text-2xl md:opacity-0">
				{t("menu")}
			</button>
			<UserButton />
		</div>
	)
}

export default Searchbar
