import { Row } from "@tanstack/react-table"
import { Virtualizer } from "@tanstack/react-virtual"
import React from "react"
import { TableRow } from "./TableRow"
import { DataTableDataType } from ".."

type TableBodyProps = {
	rows: Row<DataTableDataType>[]
	virtualizerInstance: Virtualizer<HTMLDivElement, Element>
}

export const TableBody: React.FC<TableBodyProps> = ({ rows, virtualizerInstance }) => {
	const { getVirtualItems, getTotalSize } = virtualizerInstance

	const virtualRows = getVirtualItems()

	const paddingTop = virtualRows?.[0]?.start || 0
	const paddingBottom = getTotalSize() - (virtualRows?.[virtualRows.length - 1]?.end || 0)

	return (
		<tbody>
			{paddingTop > 0 && (
				<tr>
					<td style={{ height: `${paddingTop}px` }} />
				</tr>
			)}
			{virtualRows.map(virtualRow => (
				<TableRow
					rowKey={virtualRow.key as number}
					row={rows[virtualRow.index]}
					key={`${virtualRow.key}_${virtualRow.index}`}
				/>
			))}
			{paddingBottom > 0 && (
				<tr>
					<td style={{ height: `${paddingBottom}px` }} />
				</tr>
			)}
		</tbody>
	)
}
