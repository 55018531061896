/* eslint-disable no-useless-escape */
import { gql } from "@apollo/client"
import { GQL_REQUEST_OPTIONS } from "./gqlRequestTypes"

// JSON stringify puts keys in quotes, where GQL requires keys are quotes
export const objToString = (obj: any, typeList?: string[]) => {
	const unquotedString = JSON.stringify(obj)
		.replace(/"([^"]+)":/g, "\n$1:")
		.replace(/\uFFFF/g, '\\"')
	if (typeList?.length) {
		// NOTE: "(\\w+)" has double \\, because single \ must be escaped in string.
		return unquotedString.replace(RegExp(`(${typeList.join("|")}):"(\\w+)"`, "g"), "$1:$2")
	}
	return unquotedString
}

const DEFAULT_PAYLOAD_KEYS = ["commandProcessError", "primaryKey"]

export const generateGQLrequest = (opts: GQL_REQUEST_OPTIONS & { payloadKeys?: string[] }) => {
	const { type, input, payloadKeys = [] } = opts

	const typesNotStrings = (() => {
		switch (type) {
			case "upsertCustomersMutation":
				return ["mode"]
			case "updateAccessPointsMutation":
			case "addAccessPointMutation":
			case "addAccessPointsMutation":
				return ["mode", "type"]
			default:
				return []
		}
	})()

	return gql(
		`mutation {\n${type} (input: \n
			${objToString(input, typesNotStrings)}\n
		) {
			${[...DEFAULT_PAYLOAD_KEYS, ...payloadKeys].join(", ")}}\n
		}`
	)
}

// ${Object.keys(cmdReturn).join(", ")}}\n
