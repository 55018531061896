import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { getWidthOfText } from "../functions"
import { useMemo } from "react"

const NUMBER_CIRCLE_WIDTH = 26

type Props = {
	stepKeys: string[]
	activeStepKey: string
}

export const StepViewer: React.FC<Props> = ({ stepKeys, activeStepKey }: Props) => {
	const { t } = useTrans()

	const activeStepIndex = useMemo(
		() => stepKeys.findIndex(key => key === activeStepKey),
		[stepKeys, activeStepKey]
	)

	return (
		<div className="flex w-full ml-12 pb-4">
			{stepKeys.map((stepKey, i) => {
				const isActive = i <= activeStepIndex
				const isNotLast = i !== stepKeys.length - 1

				const title = t(`importLabels:${stepKey}`)
				const textLength = getWidthOfText(title, "normal 14px DM Sans")
				const left = -(textLength - NUMBER_CIRCLE_WIDTH) / 2

				return (
					<div key={stepKey} className="relative block flex items-center">
						<div
							className={twMerge(
								"relative border border-black w-[23px] w-[23px] rounded-full flex items-center justify-center m-auto text-sm",
								isActive && "bg-lime border-lime"
							)}
						>
							{i + 1}
						</div>
						<div className="absolute mt-12 text-sm whitespace-nowrap" style={{ left }}>
							{t(`importLabels:${stepKey}`)}
						</div>
						{isNotLast && <hr className="w-36 border-black mx-4" />}
					</div>
				)
			})}
		</div>
	)
}
