import { useModal } from "Contexts"
import { trpc } from "Utils/trpc"
import ModalContainer from "components/modalContainer"
import { isNil, mapValues } from "lodash"
import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTrans } from "translations"
import { Person } from "."
import { EMAIL_REGEX } from "Utils/isValidEmail"
import { useGlobalAlert } from "States/globalAlert"
import { FormFieldType, GridForm } from "components/GridForm"
import { isValidPhoneNumberAllowEmpty } from "Utils/isValidPhoneNumber"
import { addCountryCodeToPhone } from "Utils/phoneCountryCode"

interface PeopleModalProps {
	customerId: string
	people: Person[]
	onConfirm: () => void
}

type PeopleForm = {
	people: Person[]
}

export const PeopleModal: FC<PeopleModalProps> = ({ customerId, people, onConfirm }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()

	const formHandler = useForm<PeopleForm>({
		mode: "onTouched",
		defaultValues: {
			people: people
				.map(person => mapValues(person, value => (isNil(value) ? "" : value)))
				.map(person => ({ ...person, phone: addCountryCodeToPhone(person.phone ?? "") })),
		},
	})

	useEffect(() => {
		formHandler.trigger()
	}, [formHandler])

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = formHandler

	const { mutate, isLoading, isSuccess } = trpc.customers.update.useMutation()

	useEffect(() => {
		if (isSuccess) {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			onConfirm()
			hideModal()
		}
	}, [isSuccess, hideModal, onConfirm, setGlobalAlert])

	const onSubmit = handleSubmit(formData => {
		const people = formData.people
		const mainContact = people[0]
		const otherContacts = people.slice(1)

		const contactPeople = JSON.stringify(otherContacts) ?? undefined

		const customer = {
			id: customerId,
			contactEmail: mainContact.email ?? undefined,
			contactMobile: mainContact.phone ?? undefined,
			contactPerson: mainContact.name ?? undefined,
			contactPeople,
		}

		mutate({
			customer,
		})
	})

	// Disable form inputs and button when loading or after saving
	const columns = [
		{ label: t("formLabels:name"), name: "name", enabled: true, required: true },
		{
			label: t("formLabels:email"),
			name: "email",
			enabled: true,
			regexPattern: EMAIL_REGEX,
			required: true,
			patternError: "errors:mustBeValidEmail",
		},
		{
			label: t("formLabels:phoneNumber"),
			name: "phone",
			enabled: true,
			validationFunction: isValidPhoneNumberAllowEmpty,
			patternError: "errors:mustBeValidPhoneNumber",
			fieldType: FormFieldType.PHONE,
		},
	]

	return (
		<ModalContainer
			title={"drawerLabels:contactPersons"}
			onCancel={hideModal}
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoading}
			showDiscardModal={isDirty}
			onConfirmDisabled={!isValid}
		>
			<div className="mb-5 min-w-[700px]">
				<GridForm<Person[]>
					enabled={!isLoading}
					formHandler={formHandler}
					columns={columns}
					enableDeleteColumn
					enableAddRowButton
					allowDeletingLastRow={false}
				/>
			</div>
		</ModalContainer>
	)
}
