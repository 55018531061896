import { ImportContext } from "components/ImportComponents/ImportContext"
import React, { useCallback, useContext, useMemo } from "react"
import { ValidateColumn } from "./ValidateColumn"
import { useTrans } from "translations"
import { Input } from "components/GenericComponents/input"

type Props = {
	sectionKey: string
}

export const ValidateTable: React.FC<Props> = ({ sectionKey }) => {
	const { t } = useTrans()
	const {
		selectedTemplate,
		selectedParentIds,
		setSelectedParentIds,
		mappingState,
		validationState,
	} = useContext(ImportContext)

	const section = useMemo(
		() => selectedTemplate?.mappingSections.find(s => s.key === sectionKey),
		[selectedTemplate, sectionKey]
	)

	const preSelectOptions = useMemo(
		() =>
			section?.selectionParams?.getOptions(
				selectedParentIds?.[section?.selectionParams?.dependsOn!]
			) || [],
		[section, selectedParentIds]
	)

	const updateParentSelectionValues = useCallback(
		(key: string, value: string) => {
			const newValues = {
				...selectedParentIds,
				[key]: value,
			}

			const dependents = selectedTemplate?.parentSelections?.filter(p => p.dependsOn === key)
			if (dependents?.length) {
				dependents.forEach(d => {
					newValues[d.key] = ""
				})
			}

			setSelectedParentIds(newValues)
		},
		[selectedParentIds, setSelectedParentIds, selectedTemplate]
	)

	const validatingColumns = useMemo(
		() =>
			section?.columns?.filter(col =>
				col.displayFunction
					? validationState[sectionKey]?.[col.key] && col.displayFunction(mappingState)
					: col.isGenerated || validationState[sectionKey]?.[col.key]
			),
		[section, mappingState, validationState, sectionKey]
	)

	return section ? (
		<div className="flex flex-row flex-1 w-full h-full overflow-scroll">
			{section.isPreSelected ? (
				<div className="w-1/2 pt-5">
					<div className="text black font-dmSans text-sm font-medium">
						{t(section.selectionParams?.selectedLabel!)}
					</div>
					<div className="mb-6 bg-white">
						<Input
							placeholder={t(section.selectionParams?.placeholder!)}
							data={preSelectOptions}
							dropdownStyle={{ maxHeight: 190 }}
							dropdownIcon
							initialValue={selectedParentIds[section.key]}
							onChange={option => updateParentSelectionValues(section.key, option.value)}
							ignoreOnRenderChange
							noInitialOption={!selectedParentIds[section.key]}
						/>
					</div>
				</div>
			) : (
				validatingColumns?.map((c, _, cols) => (
					<div key={c.key} style={{ width: `${100 / cols.length}%`, maxWidth: "50%" }}>
						<ValidateColumn sectionKey={sectionKey} column={c} />
					</div>
				))
			)}
		</div>
	) : null
}
