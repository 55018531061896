import { MultiListDrawer } from "components/pageCards/multiListTable/multiListDrawer"
import { ComponentProps, FC } from "react"
import { MultipleCustomerView } from "./MultipleTenantView"
import { SingleCustomerView } from "./SingleCustomerView"
import { ResponsiveDrawer } from "components/ResponsiveDrawer"
import { CustomerList } from "api/hooks/useCustomers/useCustomers"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { SortingState } from "@tanstack/react-table"

type TenantDrawerProps = {
	selectedCustomers: CustomerList
	isActive?: boolean
	areAllSelected?: boolean
	filterState: ActiveOptions<any>
	sortingState?: SortingState
	nameFilter?: string
} & Pick<ComponentProps<typeof ResponsiveDrawer>, "onCloseFn">

export type Person = {
	name?: string
	email: string
	phone?: string
}

export const CustomerDrawer: FC<TenantDrawerProps> = ({
	selectedCustomers,
	isActive,
	areAllSelected,
	onCloseFn,
	filterState,
	sortingState,
	nameFilter,
}) => {
	if (!selectedCustomers.length) return null
	const isMultiple = selectedCustomers.length > 1
	const firstCustomerId = selectedCustomers[0].id

	return (
		<MultiListDrawer {...{ isActive, onCloseFn }}>
			{isMultiple ? (
				<MultipleCustomerView
					selectedCustomers={selectedCustomers}
					areAllSelected={areAllSelected}
					filterState={filterState}
					sortingState={sortingState}
					nameFilter={nameFilter}
				/>
			) : (
				<SingleCustomerView customerId={firstCustomerId} selectedCustomers={selectedCustomers} />
			)}
			<div className="mb-5"></div>
		</MultiListDrawer>
	)
}
