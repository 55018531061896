export const PlusIcon = ({ fill = "#000", width = 24, height = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height={height}
		width={width}
		viewBox="0 0 21 20"
		fill={fill}
	>
		<rect x="9.75" width="2" height="20" fill="black" />
		<rect x="20.75" y="9" width="2" height="20" transform="rotate(90 20.75 9)" fill="black" />
	</svg>
)
