export enum FormFieldType {
	TEXT = "text",
	SELECT = "select",
	PHONE = "phone",
	READ_ONLY = "read-only",
}

export type FormColumn = {
	label: string
	name: string
	enabled?: boolean | ((rowIndex: number) => boolean)
	fieldType?: FormFieldType
	defaultValue?: string
	options?: {
		label: string
		value: string
	}[]
	regexPattern?: RegExp
	patternError?: string
	required?: boolean
	validationFunction?: (value: string) => boolean
	unique?: boolean
	placeholder?: string
	readOnlyContent?: (rowIndex: number) => React.ReactNode
	headerTooltipText?: string
	headerTooltipComponent?: React.ReactNode
	onSelectChange?: (value: any, name: string) => void
}

export type Autocomplete<T> = {
	onType: (key: undefined | string, value?: string) => void
	results: T | undefined
	enableOnColumns: string[]
}

export type AutocompleteValue = { [key: string]: string }

export type AutocompleteOption = {
	key: string
	values: AutocompleteValue[]
}
