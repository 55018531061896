import { Input } from "components/GenericComponents/input"
import { ImportContext } from "components/ImportComponents/ImportContext"
import { MappingSection, MappingState, ValidationState } from "components/ImportComponents/types"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { useTrans } from "translations"
import { ColumnSelect } from "./ColumnSelect"
import { MappingHeader } from "./MappingHeader"
import { SheetSelect } from "./SheetSelect"
import { cloneDeep, get, set } from "lodash"
import {
	getDisplayedColumns,
	getFilteredValues,
	getLinkingValues,
} from "components/ImportComponents/functions"

type Props = {
	section: MappingSection
}

export const MappingColumn: React.FC<Props> = ({ section }: Props) => {
	const { t } = useTrans()
	const {
		selectedTemplate,
		mappingState,
		selectedParentIds,
		setSelectedParentIds,
		uploadedFileStructure,
		validationState,
		setMappingState,
		setValidationState,
	} = useContext(ImportContext)

	const {
		isOptional,
		isPreSelected,
		selectionParams,
		key,
		sheetSelectLabel,
		columns,
		columnSelectLabel,
	} = section

	const selected = useMemo(() => mappingState?.[key]?.selected, [mappingState, key])
	const sheetId = useMemo(() => mappingState?.[key]?.sheetId, [mappingState, key])

	const columnsInSheet = useMemo(
		() => uploadedFileStructure.find(s => s.id === sheetId)?.columns || [],
		[uploadedFileStructure, sheetId]
	)

	const columnOptions = useMemo(
		() =>
			columnsInSheet.map((c, i) => ({
				option: c.name,
				value: i.toString(),
			})),
		[columnsInSheet]
	)

	const selectedColumns = useMemo(() => mappingState?.[key]?.columnSelections, [mappingState, key])

	const getValuesWithoutValidation = useCallback(
		(
			columnKey: string,
			value: string,
			newState: MappingState,
			newValidationState: ValidationState
		) => {
			const templateMappingState = selectedTemplate?.mappingSections.find(el => el.key === key)
			const uploadedColumn = columnsInSheet[parseInt(value)]
			const templateColumn = templateMappingState?.columns?.find(el => el.key === columnKey)

			set(newState, `${key}.columnSelections.${columnKey}`, value)

			const newColumnValues = uploadedColumn.values.map(v => {
				const value =
					templateColumn?.transformValue?.(
						v?.toString() || templateColumn?.defaultValue || "",
						templateColumn.dropdownOptions
					) ??
					(v || templateColumn?.defaultValue)

				return {
					value,
				}
			})

			set(newValidationState, `${key}.${columnKey}`, newColumnValues)

			// update validation for dependents with generated values
			if (templateColumn?.generatingDependents?.length) {
				templateColumn.generatingDependents.forEach(dependent => {
					const dependentColumn = templateMappingState?.columns?.find(el => el.key === dependent)

					if (dependentColumn) {
						const newGeneratedColumnValues = uploadedColumn.values.map(v => {
							const value = dependentColumn.getGeneratedValue?.(v)

							return {
								value,
							}
						})

						set(newValidationState, `${key}.${dependentColumn.key}`, newGeneratedColumnValues)
					}
				})
			}

			const duplicateSelectKey = Object.keys(selectedColumns)
				.filter(s => s !== columnKey)
				.find(s => selectedColumns[s] === value)

			// don't allow the same column select to be used for different columns
			if (duplicateSelectKey) {
				set(newState, `${key}.columnSelections.${duplicateSelectKey}`, "")

				if (validationState[key]) {
					set(validationState, `${key}.${duplicateSelectKey}`, [])
				}
			}

			return {
				newState,
				newValidationState,
			}
		},
		[selectedColumns, key, selectedTemplate, validationState, columnsInSheet]
	)

	const getUpdatedStatesWithValidation = useCallback(
		async (
			columnKey: string,
			value: string,
			newState: MappingState,
			newValidationState: ValidationState
		) => {
			const templateMappingState = selectedTemplate?.mappingSections.find(el => el.key === key)
			const uploadedColumn = columnsInSheet[parseInt(value)]
			const templateColumn = templateMappingState?.columns?.find(el => el.key === columnKey)

			let linkingValues = getLinkingValues({
				templateColumn,
				sectionKey: key,
				selectedTemplate,
				validationState,
			})

			const newColumnValues = uploadedColumn.values.map((v, index) => {
				const filteredValues = getFilteredValues({
					validationState: newValidationState,
					column: templateColumn,
					sectionKey: key,
					cellIndex: index,
				})

				if (filteredValues?.length) {
					linkingValues = filteredValues
				}

				const value =
					templateColumn?.transformValue?.(
						v?.toString() || templateColumn?.defaultValue || "",
						templateColumn.dropdownOptions
					) ??
					(v || templateColumn?.defaultValue) ??
					""

				let error =
					templateColumn?.validate?.({
						value,
						linkingValues:
							templateColumn.hasLinkedParent || templateColumn.filterBy ? linkingValues : [],
					}) || ""

				let errorOptions = {}

				if (templateColumn?.oppositeKey) {
					const oppositeMappingState = selectedTemplate?.mappingSections.find(
						el => el.columns?.some(c => c.key === templateColumn?.oppositeKey && c.hasLinkedChild)
					)

					if (oppositeMappingState) {
						const oppositeNotSelected = !newState[oppositeMappingState.key]?.selected
						if (oppositeNotSelected) {
							if (!value) {
								error = "errors:requiredFields"
							}
						} else {
							const oppositeValue = get(
								newValidationState,
								`${key}.${templateColumn.oppositeKey}.${index}.value`
							)
							if (!value && !oppositeValue) {
								error = "errors:oneMustBeFilled"
							} else if (value && oppositeValue) {
								error = "errors:bothCantBeFilled"
								errorOptions = {
									firstColumnTitle: t(templateColumn.title),
									secondColumnTitle: t(
										oppositeMappingState.columns?.find(c => c.key === templateColumn.oppositeKey)
											?.title || ""
									),
								}
							}
						}
					}
				}

				return {
					value,
					error,
					errorOptions,
				}
			})

			set(newValidationState, `${key}.${columnKey}`, newColumnValues)

			if (templateColumn?.columnValidate) {
				const values = newColumnValues.map(v => v.value)

				const validationResults = await templateColumn.columnValidate(values)

				if (validationResults) {
					set(
						newValidationState,
						`${key}.${columnKey}`,
						newColumnValues.map(v => ({
							...v,
							error: validationResults[v.value] || v.error || "",
						}))
					)
				}
			}

			// update validation for dependents with generated values
			if (templateColumn?.generatingDependents?.length) {
				templateColumn.generatingDependents.forEach(dependent => {
					const dependentColumn = templateMappingState?.columns?.find(el => el.key === dependent)

					if (dependentColumn) {
						const newGeneratedColumnValues = uploadedColumn.values.map(v => {
							const value = dependentColumn.getGeneratedValue?.(v) ?? ""

							return {
								value,
								error: dependentColumn?.validate?.({ value }) || "",
							}
						})

						set(newValidationState, `${key}.${dependentColumn.key}`, newGeneratedColumnValues)
					}
				})
			}

			// update validation for columns in which all values should be unique
			if (templateColumn?.uniqueInColumn) {
				newValidationState[key]?.[columnKey].forEach((cell, i, cells) => {
					const valueMoreThanOnce = cells.filter(c => c.value === cell.value).length > 1

					if (cell.value && valueMoreThanOnce) {
						set(
							newValidationState,
							`${key}.${columnKey}.${i}.error`,
							"errors:duplicateValueInColumn"
						)
					}
				})
			}

			const columnsFilteredByThisColumn = templateMappingState?.columns?.filter(
				el => el.filterBy === templateColumn?.key
			)
			if (columnsFilteredByThisColumn?.length) {
				columnsFilteredByThisColumn.forEach(filteredColumn => {
					newValidationState[key]?.[filteredColumn.key]?.forEach((_, i) => {
						const filteredValues = getFilteredValues({
							validationState,
							column: filteredColumn,
							sectionKey: key,
							cellIndex: i,
						})

						if (filteredValues.length) {
							set(
								newValidationState,
								`${key}.${filteredColumn.key}.${i}.error`,
								filteredColumn.validate?.({
									value,
									linkingValues: filteredValues,
								}) || ""
							)
						}
					})
				})
			}

			return newValidationState
		},
		[key, selectedTemplate, validationState, columnsInSheet, t]
	)

	const updateColumnSelection = useCallback(
		async (columnKey: string, value: string) => {
			const statesWithValues = getValuesWithoutValidation(
				columnKey,
				value,
				cloneDeep(mappingState),
				cloneDeep(validationState)
			)

			const newValidationState = await getUpdatedStatesWithValidation(
				columnKey,
				value,
				statesWithValues.newState,
				statesWithValues.newValidationState
			)

			setMappingState(statesWithValues.newState)
			setValidationState(newValidationState)
		},
		[
			mappingState,
			setMappingState,
			validationState,
			setValidationState,
			getValuesWithoutValidation,
			getUpdatedStatesWithValidation,
		]
	)

	const displayedColumns = useMemo(
		() => getDisplayedColumns(columns || [], mappingState),
		[columns, mappingState]
	)

	const updateColumnSelections = useCallback(() => {
		let newState: MappingState = cloneDeep(mappingState)
		let newValidationState: ValidationState = cloneDeep(validationState)

		if (mappingState?.[key]) {
			displayedColumns?.forEach(col => {
				const selectedValue = get(mappingState, `${key}.columnSelections.${col.key}`, "") as string
				const initialValue =
					selectedValue || columnOptions.find(el => el.option.trim() === t(col.title).trim())?.value

				if (!selectedValue && initialValue) {
					const { newState: updatedState, newValidationState: updatedValidationState } =
						getValuesWithoutValidation(col.key, initialValue, newState, newValidationState)

					newState = updatedState
					newValidationState = updatedValidationState
				}
			})

			displayedColumns?.forEach(async col => {
				const selectedValue = get(mappingState, `${key}.columnSelections.${col.key}`, "") as string
				const initialValue =
					selectedValue || columnOptions.find(el => el.option === t(col.title))?.value

				if (!selectedValue && initialValue) {
					const updatedValidationState = await getUpdatedStatesWithValidation(
						col.key,
						initialValue,
						newState,
						newValidationState
					)

					newValidationState = updatedValidationState
				}
			})
		}

		setMappingState(newState)
		setValidationState(newValidationState)
	}, [
		displayedColumns,
		columnOptions,
		t,
		getValuesWithoutValidation,
		getUpdatedStatesWithValidation,
		mappingState,
		validationState,
		setMappingState,
		setValidationState,
		key,
	])

	useEffect(() => {
		if (columnOptions?.length) {
			updateColumnSelections()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnOptions])

	const updateParentSelectionValues = useCallback(
		(key: string, value: string) => {
			const newValues = {
				...selectedParentIds,
				[key]: value,
			}

			const dependents = selectedTemplate?.parentSelections?.filter(p => p.dependsOn === key)
			if (dependents?.length) {
				dependents.forEach(d => {
					newValues[d.key] = ""
				})
			}

			setSelectedParentIds(newValues)
			updateColumnSelections()
		},
		[selectedParentIds, setSelectedParentIds, selectedTemplate, updateColumnSelections]
	)

	const preSelectOptions = useMemo(
		() => selectionParams?.getOptions(selectedParentIds?.[selectionParams.dependsOn!]) || [],
		[selectionParams, selectedParentIds]
	)

	return (
		<>
			<MappingHeader section={section} />
			{(!isOptional || selected) && (
				<div>
					{isPreSelected ? (
						<div>
							{preSelectOptions.length === 0 && selectionParams?.emptyComponent ? (
								<>{selectionParams.emptyComponent}</>
							) : (
								<>
									<div className="text black font-dmSans text-sm font-medium">
										{t(selectionParams?.selectedLabel!)}
									</div>
									<div className="mb-6 bg-white">
										<Input
											placeholder={t(selectionParams?.placeholder!)}
											data={preSelectOptions}
											dropdownStyle={{ maxHeight: 190 }}
											dropdownIcon
											initialValue={selectedParentIds[key]}
											onChange={option => updateParentSelectionValues(key, option.value)}
											ignoreOnRenderChange
											noInitialOption={!selectedParentIds[key]}
										/>
									</div>
								</>
							)}
						</div>
					) : (
						<div>
							<SheetSelect sectionKey={key} sheetSelectLabel={sheetSelectLabel!} />
							{sheetId && (
								<ColumnSelect
									sectionKey={key}
									columnSelectLabel={columnSelectLabel || ""}
									columns={displayedColumns || []}
									updateColumnSelection={updateColumnSelection}
									columnOptions={columnOptions}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</>
	)
}
