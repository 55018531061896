import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { useTerminalsState } from "States/Terminals"
import { StationForm, StationFormData } from "../manage/stationForm"
import { addAccessPoint } from "api/addAccessPoint"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { ACCESS_POINT_STATUSES } from "Utils/gqlRequestTypes/generic"
import { uniqueIdGenerator } from "Utils/uniqueIdGenerator"
import {
	ACCESS_POINT_COORDINATES,
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_STATUS_KEY,
	ACCESS_POINT_TYPE_KEY,
	CLIENT_KEY,
	EXTERNAL_ID_KEY,
	EXTERNAL_KEYS_KEY,
	MUNICIPALITY_KEY,
	PROPERTIES_KEY,
} from "constants/general"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useGlobalAlert } from "States/globalAlert"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"

export const AddStationModal: React.FC = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { isLoading, refetch } = useAccessParentsWithPoints()
	const { setSelectedContainers } = useMultiSelectContext()
	const { setGlobalAlert } = useGlobalAlert()

	const { sendNewAccessParentSlackNotification } = useSlackNotifications()

	const {
		currentTerminal: { id: terminalId },
	} = useTerminalsState()

	const formHandler = useForm<StationFormData>({
		defaultValues: { status: ACCESS_POINT_STATUSES[0] },
	})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onSubmit = handleSubmit(formData => {
		const { name, status, coordinates, municipality, client } = formData

		addAccessPoint({
			accessPoint: {
				[ACCESS_POINT_NAME_KEY]: name,
				[ACCESS_POINT_TYPE_KEY]: "ACCESS_PARENT",
				[ACCESS_POINT_STATUS_KEY]: status,
				parent: {
					id: terminalId,
				},
				geoLocation: {
					[ACCESS_POINT_COORDINATES]: coordinates,
				},
				[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: uniqueIdGenerator("uuid") }],
				[PROPERTIES_KEY]: [
					{
						key: MUNICIPALITY_KEY,
						value: municipality,
					},
					{
						key: CLIENT_KEY,
						value: client,
					},
				],
			},
			onStartCallBack: res => {
				refetch()
					.then(() => {
						setGlobalAlert({
							type: "success",
							message: "systemMessages:accessParentAdded",
						})
						sendNewAccessParentSlackNotification(name, res.modifiedPoint.id)
						setSelectedContainers([])

						const url = getInfrastructureUrl({
							terminalId,
							accessParentId: res.modifiedPoint.id,
						})
						navigate(url)
					})
					.then(hideModal)
			},
		})
	})

	return (
		<ModalContainer
			title="actions:newAccessParent"
			onConfirmText={"actions:confirm"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<StationForm {...{ formHandler, onSubmit }} />
		</ModalContainer>
	)
}
