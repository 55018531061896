export const IconError = ({ width = 20, height = 20 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 8.74228e-07C15.5228 3.91405e-07 20 4.4772 20 10C20 15.5229 15.5229 20 10 20C4.4772 20 -3.91405e-07 15.5229 -8.74228e-07 10C-1.35705e-06 4.4772 4.4772 1.35705e-06 10 8.74228e-07ZM11 11L11 5L9 5L9 11L11 11ZM11 15L11 13L9 13L9 15L11 15Z"
			fill="#B73A23"
		/>
	</svg>
)
