import { useConfig } from "api/hooks/useConfig"
import { useTerminals } from "api/hooks/useTerminals"
import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { CardInfo } from "components/pageCards/cardInfo"
import { MultiListDrawer } from "components/pageCards/multiListTable/multiListDrawer"
import { ResponsiveDrawer } from "components/ResponsiveDrawer"
import { useModal } from "Contexts"
import { ComponentProps, FC, useEffect } from "react"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { DeleteTerminalModal } from "./DeleteTerminalModal"
import { EditTerminalModal, Terminal } from "./EditTerminalModal"
import { PointChangeHistory } from "components/pageCards/multiListTable/drawers/lfm/drawerCards/PointChangeHistory"
import { PreFilteringCard } from "components/pageCards/multiListTable/drawers/lfm/drawerCards/PreFilteringCard"
import { trpc } from "Utils/trpc"
import { InvalidDeleteTerminalModal } from "./InvalidDeleteTerminalModal"

type TerminalDrawerProps = {
	terminalId: string
	isActive?: boolean
} & Pick<ComponentProps<typeof ResponsiveDrawer>, "onCloseFn">

export const TerminalDrawer: FC<TerminalDrawerProps> = ({ terminalId, isActive, onCloseFn }) => {
	const { t } = useTrans()
	const { terminals: stateTerminals } = useTerminalsState()
	const { showModal } = useModal()
	const { terminals } = useTerminals()
	const { isRealEstate, isMWM } = useConfig()

	const dwhTerminal = stateTerminals.find(t => t.id === terminalId)!

	const { data: terminal, refetch } = trpc.getTerminalById.useQuery({
		id: terminalId,
	})

	const name = terminal?.name ?? dwhTerminal?.name

	const categoryName = dwhTerminal?.commercialRealEstateCategory
		? t(`entities:${dwhTerminal.commercialRealEstateCategory}`)
		: t("common:unknown")

	const openDeletionModal = () => {
		if (!terminal) return

		if (terminal.children.length) {
			showModal(<InvalidDeleteTerminalModal />)
		} else {
			showModal(<DeleteTerminalModal {...{ terminalId: terminal.id, terminalName: name }} />)
		}
	}

	const openEditModal = () => {
		showModal(<EditTerminalModal {...{ terminal: terminal as Terminal }} />)
	}

	// Reload this terminal's data when the list of terminals is updated
	useEffect(() => {
		refetch()
	}, [refetch, terminals])

	if (!terminalId) return null

	return (
		<MultiListDrawer>
			<h4 className="font-signifier text-3xl font-light text-black">{name}</h4>
			<Card
				title="drawerLabels:terminalDetails"
				variant={CardComponentVariant.DRAWER}
				{...onCloseFn}
			>
				<div className="grid grid-cols-1 gap-y-9 gap-x-2 lg:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2">
					<CardInfo heading={t("importLabels:terminalName")} value={name} />
					{isRealEstate && (
						<>
							<CardInfo heading={t("formLabels:buildingCategory")} value={categoryName} />
							<CardInfo heading={t("formLabels:contactEmail")} value={terminal?.operations.email} />
							{terminal && (
								<>
									<CardInfo heading={t("formLabels:area")} value={terminal.area} />
								</>
							)}
						</>
					)}
				</div>
				<Button
					label={"actions:edit"}
					color="secondary"
					className="float-right mt-4"
					onClick={openEditModal}
				/>
			</Card>
			{isMWM && (
				<>
					<PreFilteringCard
						entityKey="terminal"
						eventFilter={encodeURIComponent(JSON.stringify({ filterTerminalId: terminal?.id }))}
					/>
					<PointChangeHistory id={terminalId} name={terminal?.name || ""} entityKey="terminal" />
				</>
			)}
			<Card title={"drawerLabels:admin"} variant={CardComponentVariant.DRAWER}>
				<div className="mb-6 text-sm">{t("hints:deleteTerminalWarning")}</div>
				<Button
					label={"actions:deleteTerminal"}
					color="black"
					className="float-right"
					disabled={!terminal}
					onClick={openDeletionModal}
				/>
			</Card>
		</MultiListDrawer>
	)
}
