import { timezoneStore } from "api/hooks/useConfig"
import { CardFilterTimeFrameIso } from "components/pageCards/filterSort/filterTimeFrame"
import moment from "moment-timezone"
import { translate } from "translations/functions/hook"
import { FetchedWasteTypes } from "./api/sanity/types"

export const getFileName = (titleKey: string, timeframe: CardFilterTimeFrameIso) => {
	const { timezone } = timezoneStore.getState()
	const startDate = moment(timeframe.startTimeISO).tz(timezone).format("YYYYMMDD")
	const endDate = moment(timeframe.endTimeISO || moment().toISOString())
		.tz(timezone)
		.format("YYYYMMDD")

	const title = `${translate(titleKey)}_${startDate}-${endDate}`

	return title
}

export const getWasteTypeName = (sanityWasteTypes: FetchedWasteTypes, wt: string) =>
	sanityWasteTypes?.find(swt => swt.id === wt)?.name || ""
