import { Terminal } from "States/Terminals"
import { FetchedWasteTypeClassificationSystems, FetchedWasteTypes } from "Utils/api/sanity/types"
import {
	MappingState,
	ParentSelection,
	TemplateColumn,
	ValidateFunctionProps,
} from "components/ImportComponents/types"
import { translate } from "translations/functions/hook"
import {
	ACCESS_PARENT_KEY,
	ACCESS_PARENT_NAME_KEY,
	CONTAINER_NAME_KEY,
	DISCONTINUED_VALUE,
	TERMINAL_KEY,
	TERMINAL_NAME_KEY,
	WASTE_TYPE_CLASSIFICATION_KEY,
	DEPOT_KEY,
	DEPOT_NAME_KEY,
} from "./constants"
import { ACCESS_PARENT_WITH_TERMINAL_ID } from "admin-client-server/src/coreApi/models/Common"
import {
	numberValidation,
	buildingCategoryValidation,
	contactEmailValidation,
	linkedValueValidation,
	requiredFieldValidation,
	terminalNameValidation,
	validate,
	valueInArrayValidation,
	coordinatesValidation,
	anythingAllowed,
} from "Utils/importCommon/validationFunctions"
import { IInputOption } from "components/GenericComponents/input"
import {
	MWM_SPACE_NAME,
	REAL_ESTATE_SPACE_NAME,
	dropdownValueTransform,
	filterBySpaceName,
} from "Utils/importCommon/common"
import { ACCESS_POINT_STATUSES, ACTIVE, AccessPointStatus } from "Utils/gqlRequestTypes/generic"
import { VALID_BUILDING_CATEGORIES } from "api/types"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { AlertLine } from "components/AlertLine/AlertLine"

const getStatusOptionValue = (key: AccessPointStatus) =>
	key === "DEPRECATED" ? DISCONTINUED_VALUE : key.toLowerCase()

const sortOptions = (options: IInputOption[]) =>
	options.sort((a, b) => sortAlphabeticallyByProperty(a, b, "option"))

export const getTerminalParentSelection = (terminals: Terminal[]): ParentSelection => ({
	key: TERMINAL_KEY,
	label: "importLabels:selectTerminalLabel",
	selectedLabel: "importLabels:selectedTerminalLabel",
	placeholder: "importLabels:selectTerminal",
	getOptions: () =>
		sortOptions(
			terminals.map(t => ({
				option: t.name,
				value: t.id,
			}))
		),
})

export const getAccessParentSelection = (
	accessParents: ACCESS_PARENT_WITH_TERMINAL_ID[] = []
): ParentSelection => ({
	key: ACCESS_PARENT_KEY,
	label: "importLabels:selectAccessParentLabel",
	selectedLabel: "importLabels:selectedAccessParentLabel",
	placeholder: "importLabels:selectAccessParent",
	getOptions: (terminalId?: string) =>
		sortOptions(
			accessParents
				?.filter(ap => ap.parentId === terminalId)
				.map(t => ({
					option: t.name,
					value: t.id,
				})) || []
		),
	dependsOn: TERMINAL_KEY,
	emptyComponent: (
		<AlertLine
			type="warning"
			message="importLabels:noAccessParents"
			instructions="importLabels:noAccessParentsForTerminal"
			canClose={false}
			instructionComponents={[
				// eslint-disable-next-line jsx-a11y/anchor-has-content
				<a
					href="/infrastructure/manage"
					style={{ textDecoration: "underline" }}
					target="_blank"
					rel="noreferrer"
				/>,
			]}
		/>
	),
})

export const getDepotSelection = (depots?: any[]): ParentSelection => ({
	key: DEPOT_KEY,
	label: "importLabels:selectDepotLabel",
	selectedLabel: "importLabels:selectedDepotLabel",
	placeholder: "importLabels:selectDepot",
	getOptions: (accessParentId?: string) =>
		sortOptions(
			depots
				?.filter(ap => ap.parentId === accessParentId)
				.map(t => ({
					option: t.name,
					value: t.id,
				})) || []
		),
	dependsOn: ACCESS_PARENT_KEY,
	emptyComponent: (
		<AlertLine
			type="warning"
			message="importLabels:noDepots"
			instructions="importLabels:noDepotsForAccessParent"
			canClose={false}
			instructionComponents={[
				// eslint-disable-next-line jsx-a11y/anchor-has-content
				<a
					href="/infrastructure/manage"
					style={{ textDecoration: "underline" }}
					target="_blank"
					rel="noreferrer"
				/>,
			]}
		/>
	),
})

export const getTerminalColumns = ({
	terminals,
	isMWM,
}: {
	terminals?: Terminal[]
	isMWM: boolean
}): TemplateColumn[] =>
	[
		{
			key: TERMINAL_NAME_KEY,
			title: "importLabels:terminalName",
			hasLinkedChild: true,
			isRequired: true,
			uniqueInColumn: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, terminalNameValidation], {
					value,
					terminals,
				}),
		},
		{
			key: "buildingCategory",
			title: "importLabels:buildingCategory",
			isRequired: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, buildingCategoryValidation], { value }),
			isDropdown: true,
			dropdownOptions: VALID_BUILDING_CATEGORIES.map(key => ({
				option: translate(`entities:${key}`),
				value: key,
			})),
			contextName: REAL_ESTATE_SPACE_NAME,
			transformValue: dropdownValueTransform,
		},
		{
			key: "contactEmail",
			title: "importLabels:contactEmail",
			isRequired: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, contactEmailValidation], { value }),
			contextName: REAL_ESTATE_SPACE_NAME,
			transformValue: dropdownValueTransform,
		},
		{
			key: "area",
			title: "importLabels:area",
			validate: ({ value }: ValidateFunctionProps) => validate([numberValidation], { value }),
			contextName: REAL_ESTATE_SPACE_NAME,
			transformValue: dropdownValueTransform,
		},
	].filter(c => filterBySpaceName(c, isMWM))

export const getAccessParentColumns = ({ isMWM }: { isMWM: boolean }): TemplateColumn[] =>
	[
		{
			key: TERMINAL_NAME_KEY,
			title: "importLabels:terminalName",
			hasLinkedParent: true,
			isRequired: true,
			validate: ({ value, linkingValues }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, linkedValueValidation], { value, linkingValues }),
		},
		{
			key: ACCESS_PARENT_NAME_KEY,
			title: "importLabels:accessParentName",
			isRequired: true,
			hasLinkedChild: true,
			validate: ({ value, linkingValues }: ValidateFunctionProps) =>
				validate([requiredFieldValidation], { value, linkingValues }),
		},
		{
			key: "coordinates",
			title: "formLabels:coordinates",
			validate: ({ value }: ValidateFunctionProps) => validate([coordinatesValidation], { value }),
			contextName: MWM_SPACE_NAME,
		},
		{
			key: "municipality",
			title: "formLabels:municipalityNumber",
			validate: anythingAllowed,
			contextName: MWM_SPACE_NAME,
		},
		{
			key: "client",
			title: "formLabels:client",
			validate: anythingAllowed,
			contextName: MWM_SPACE_NAME,
		},
		{
			key: "accessParentStatus",
			title: "formLabels:status",
			isDropdown: true,
			dropdownOptions: ACCESS_POINT_STATUSES.map(key => ({
				option: translate(`status:${key}`),
				value: getStatusOptionValue(key),
			})),
			defaultValue: ACTIVE.toLowerCase(),
			validate: ({ value }: ValidateFunctionProps) =>
				validate([valueInArrayValidation], {
					value,
					arrayOfValues: ACCESS_POINT_STATUSES.map(key => ({ id: getStatusOptionValue(key) })),
				}),
			contextName: MWM_SPACE_NAME,
			transformValue: dropdownValueTransform,
		},
	].filter(c => filterBySpaceName(c, isMWM))

export const getDepotColumns = (): TemplateColumn[] => [
	{
		key: ACCESS_PARENT_NAME_KEY,
		title: "importLabels:accessParentName",
		hasLinkedParent: true,
		isRequired: true,
		validate: ({ value, linkingValues }: ValidateFunctionProps) =>
			validate([requiredFieldValidation, linkedValueValidation], { value, linkingValues }),
	},
	{
		key: DEPOT_NAME_KEY,
		title: "importLabels:depotName",
		isRequired: true,
		hasLinkedChild: true,
		validate: ({ value }: ValidateFunctionProps) => validate([requiredFieldValidation], { value }),
	},
	{
		key: "depotStatus",
		title: "formLabels:status",
		isDropdown: true,
		dropdownOptions: ACCESS_POINT_STATUSES.map(key => ({
			option: translate(`status:${key}`),
			value: getStatusOptionValue(key),
		})),
		defaultValue: ACTIVE.toLowerCase(),
		validate: ({ value }: ValidateFunctionProps) =>
			validate([valueInArrayValidation], {
				value,
				arrayOfValues: ACCESS_POINT_STATUSES.map(key => ({
					id: getStatusOptionValue(key),
				})),
			}),
		contextName: MWM_SPACE_NAME,
		transformValue: dropdownValueTransform,
	},
]

export const getContainerColumns = (
	{
		wasteTypeClassificationSystems,
		wasteTypes,
		defaultWTCS,
		isMWM,
		onDepot,
		onDepotOrSection,
	}: {
		wasteTypeClassificationSystems?: FetchedWasteTypeClassificationSystems | null
		wasteTypes?: FetchedWasteTypes | null
		defaultWTCS?: string | null
		isMWM: boolean
		onDepot?: boolean
		onDepotOrSection?: boolean
	} = { isMWM: false }
): TemplateColumn[] => {
	const columns: TemplateColumn[] = []

	const accessParentColumn = {
		key: ACCESS_PARENT_NAME_KEY,
		title: "importLabels:accessParentName",
		hasLinkedParent: true,
		isRequiredFunction: (mappingState: MappingState) => !mappingState[DEPOT_KEY]?.selected,
		validate: ({ value, linkingValues }: ValidateFunctionProps) =>
			validate([linkedValueValidation], {
				value,
				linkingValues,
			}),
		oppositeKey: DEPOT_NAME_KEY,
	}

	const depotColumn = {
		key: DEPOT_NAME_KEY,
		title: "importLabels:depotName",
		hasLinkedParent: true,
		isRequired: false,
		validate: ({ value, linkingValues }: ValidateFunctionProps) =>
			validate([linkedValueValidation], {
				value,
				linkingValues,
			}),
		displayFunction: (mappingState: MappingState) => mappingState[DEPOT_KEY]?.selected,
		oppositeKey: ACCESS_PARENT_NAME_KEY,
	}

	if (onDepotOrSection) {
		columns.push(accessParentColumn, depotColumn)
	} else if (onDepot) {
		columns.push({
			key: DEPOT_NAME_KEY,
			title: "importLabels:depotName",
			hasLinkedParent: true,
			isRequired: true,
			validate: ({ value, linkingValues }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, linkedValueValidation], { value, linkingValues }),
		})
	} else {
		columns.push({
			key: ACCESS_PARENT_NAME_KEY,
			title: "importLabels:accessParentName",
			hasLinkedParent: true,
			isRequired: true,
			validate: ({ value, linkingValues }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, linkedValueValidation], { value, linkingValues }),
		})
	}

	columns.push(
		{
			key: CONTAINER_NAME_KEY,
			title: "importLabels:containerName",
			isRequired: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation], { value }),
		},
		{
			key: WASTE_TYPE_CLASSIFICATION_KEY,
			title: "importLabels:wasteTypeClassification",
			validate: ({ value }: ValidateFunctionProps) =>
				validate([valueInArrayValidation], {
					value,
					arrayOfValues: wasteTypeClassificationSystems,
				}),
			isDropdown: true,
			dropdownOptions: wasteTypeClassificationSystems?.map(w => ({
				option: w.name,
				value: w.id,
			})),
			defaultValue: defaultWTCS || undefined,
			transformValue: dropdownValueTransform,
		},
		{
			key: "wasteTypeCode",
			title: "importLabels:wasteTypeCode",
			isRequired: true,
			validate: ({ value, linkingValues: validWasteTypes }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, valueInArrayValidation], {
					value,
					arrayOfValues: validWasteTypes?.map(id => ({ id })),
				}),
			isDropdown: true,
			dropdownOptions: wasteTypes?.map(w => ({
				option: `${w.name} (${w.id})`,
				value: w.id,
				filterParamId: w.classificationSystem?.id,
			})),
			filterBy: WASTE_TYPE_CLASSIFICATION_KEY,
			transformValue: dropdownValueTransform,
		},
		{
			key: "containerStatus",
			title: "formLabels:status",
			isDropdown: true,
			dropdownOptions: ACCESS_POINT_STATUSES.map(key => ({
				option: translate(`status:${key}`),
				value: getStatusOptionValue(key),
			})),
			defaultValue: ACTIVE.toLowerCase(),
			validate: ({ value }: ValidateFunctionProps) =>
				validate([valueInArrayValidation], {
					value,
					arrayOfValues: ACCESS_POINT_STATUSES.map(key => ({
						id: getStatusOptionValue(key),
					})),
				}),
			contextName: MWM_SPACE_NAME,
			transformValue: dropdownValueTransform,
		}
	)

	return columns.filter(c => filterBySpaceName(c, isMWM))
}
