import ErrorNotice from "components/ErrorNotice"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { CustomerAccessParentsFormSection } from "pages/customer/manage/components/CustomerAccessParentsFormSection"
import {
	CustomerWithAccessParents,
	CustomerWithAccessParentsAndChain,
} from "pages/customer/manage/types"
import { FC, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useGlobalAlert } from "States/globalAlert"
import { trpc } from "Utils/trpc"
import classNames from "classnames"
import { useConfig } from "api/hooks/useConfig"

interface AddAccessParentModalProps {
	customer: CustomerWithAccessParents
	onConfirm?: () => void
}

const defaultAccessParent: CustomerWithAccessParents["accessParents"][number] = {
	id: "",
}

export const AddAccessParentModal: FC<AddAccessParentModalProps> = ({ customer, onConfirm }) => {
	const { hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const {
		config: { useRedundancyPoints },
	} = useConfig()
	const [isLoading, setIsLoading] = useState(false)

	const customerAccessParents = customer.accessParents || []

	const {
		mutate: updateCustomer,
		isLoading: isUpdatingCustomer,
		isError,
	} = trpc.customers.update.useMutation()

	const formHandler = useForm<CustomerWithAccessParentsAndChain>({
		defaultValues: {
			accessParents: customerAccessParents.length ? customerAccessParents : [defaultAccessParent],
		},
	})

	const {
		handleSubmit,
		formState: { isDirty, defaultValues },
	} = formHandler

	const done = () => {
		if (onConfirm) onConfirm()
		setGlobalAlert({
			type: "success",
			message: "systemMessages:changesSaved",
		})
		hideModal()
	}

	const save = (accessParents: CustomerWithAccessParents["accessParents"]) => {
		// filter out accessParents that are in the initial list
		const newAccessParents = accessParents
			.filter(
				({ id }) => !defaultValues?.accessParents?.some(initialAP => initialAP?.id === id) && id
			)
			.map(({ id }) => ({ id }))

		const removeAccessParents = defaultValues?.accessParents
			?.filter(initialAP => !accessParents.some(({ id }) => id === initialAP?.id) && initialAP?.id)
			.map(APToRemove => ({ id: APToRemove?.id || "" }))

		updateCustomer(
			{ customer: { id: customer.id, newAccessParents, removeAccessParents } },
			{
				onSuccess: done,
			}
		)
	}

	const onSubmit = handleSubmit(formData => {
		save(formData.accessParents)
	})

	const modalContent = useMemo(() => {
		if (isError) {
			return <ErrorNotice />
		}
		return (
			<CustomerAccessParentsFormSection
				{...{
					formHandler,
					useNumberForHint: false,
					isEdit: false,
					setIsLoading,
				}}
			/>
		)
	}, [formHandler, isError])

	return (
		<ModalContainer
			title={"actions:editInfrastructure"}
			onCancel={hideModal}
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoading || isUpdatingCustomer}
			showDiscardModal={isDirty}
			className={classNames(useRedundancyPoints ? "max-w-none" : "max-w-lg")}
		>
			{modalContent}
		</ModalContainer>
	)
}
