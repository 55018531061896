import { useConfig } from "api/hooks/useConfig"
import { AlertLine } from "components/AlertLine/AlertLine"
import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { CardInfo } from "components/pageCards/cardInfo"
import { CardInfoContainer } from "components/pageCards/cardInfoContainer"
import { useModal } from "Contexts"
import { EditContainerModal } from "pages/infrastructure/modals/editContainerModal"
import { FC, useMemo } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { isValidWasteType } from "Utils/isValidWasteType"
import { ContainerDrawerProps } from "../containerDrawer"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { useConfigService } from "pages/configuration/useConfigService"

export const ContainerInformation: FC<ContainerDrawerProps> = ({
	accessParentId,
	containerId,
	depotId,
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const { wasteTypeClassificationSystems, wasteTypes } = useCommonEntitiesStore()
	const { isMWM, isConfigLoading, config } = useConfig()
	const { currentTerminal } = useTerminalsState()
	const { wasteTypeConfig } = useConfigService()

	const { getContainer } = useAccessPoints({ accessParentId })
	const container = getContainer(containerId, depotId)

	const showWasteTypeWarning = useMemo(() => {
		if (!wasteTypes || !container) return false
		return !isValidWasteType(wasteTypes, container.wasteCode)
	}, [wasteTypes, container])

	if (!container) return null
	const { wasteTypeClassificationSystemId, wasteCode, wasteType, name, status } = container

	const defaultWasteTypeClassificationSystemId =
		config?.wasteTypeClassificationSystemId ||
		currentTerminal?.wasteTypeClassificationSystemId ||
		container.wasteTypeClassificationSystemId ||
		""

	const wasteTypeClassificationSystemName = wasteTypeClassificationSystems?.find(
		({ id }) => id === wasteTypeClassificationSystemId
	)?.name

	const wasteTypeName =
		wasteTypeConfig?.find(({ wasteTypeCode }) => wasteTypeCode === wasteCode)?.name || wasteType

	return (
		<>
			<h4 className="font-signifier text-3xl font-light text-black">{name}</h4>
			<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:containerDetails">
				{showWasteTypeWarning && (
					<div className="mb-4">
						<AlertLine
							type="warning"
							message="hints:invalidWasteType"
							instructions="hints:invalidWasteTypeDetails"
							canClose={false}
						/>
					</div>
				)}
				<div className="mb-10">
					<CardInfo heading={t("formLabels:nameOfContainer")} value={name} />
				</div>
				<CardInfoContainer>
					<CardInfo
						heading={t("entities:wasteTypeClassificationSystem")}
						value={wasteTypeClassificationSystemName}
						tooltip={{
							text: t("hints:wasteTypeClassificationSystemDescription"),
							id: "wasteTypeClassificationSystem",
						}}
					/>
					<CardInfo
						heading={t("formLabels:wasteTypeWithCode")}
						value={`${wasteTypeName} (${wasteCode})`}
						tooltip={{
							text: t("hints:wasteTypeWithCodeDescription"),
							id: "wasteTypeWithCode",
						}}
					/>
					{isMWM && <CardInfo heading={t("formLabels:status")} value={t(`status:${status}`)} />}
				</CardInfoContainer>
				<Button
					label={"actions:edit"}
					onClick={() =>
						showModal(
							<EditContainerModal
								{...{ accessParentId, container, defaultWasteTypeClassificationSystemId }}
							/>
						)
					}
					color="secondary"
					className="float-right mt-10"
					disabled={isConfigLoading}
				/>
			</Card>
		</>
	)
}
