import { Card, CardComponentVariant } from "components/pageCards/card"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useTrans } from "translations"

export const PreFilteringCard: FC<{
	entityKey: string
	eventFilter?: string
	customersFilter?: string
}> = ({ entityKey, eventFilter, customersFilter }) => {
	const { t } = useTrans()
	const entity = t(`entities:${entityKey}`)

	return (
		<Card title={"drawerLabels:preFiltering"} variant={CardComponentVariant.DRAWER}>
			<p className="text black mb-4 font-dmSans text-sm">
				{t("hints:goToEntityPrefilteredEvents", {
					entity: entity.toLowerCase(),
				})}
			</p>
			{eventFilter && (
				<div className="flex items-center">
					<Link
						to={{
							pathname: "/events",
							search: `?filters=${eventFilter}`,
						}}
						className="pr-9 font-dmSans text-base font-medium underline w-1/2"
					>
						{t("actions:seeEntityEvents", {
							entity: entity.toLowerCase(),
						})}
					</Link>
					<p className="font-dmSans text-sm text-black">
						{t("hints:entityLatestEvents", { entity: entity.toLowerCase() })}
					</p>
				</div>
			)}
			{customersFilter && (
				<div className="flex items-center mt-2">
					<Link
						to={{
							pathname: "/customer/manage",
							search: `?filters=${eventFilter}`,
						}}
						className="pr-9 font-dmSans text-base font-medium underline w-1/2"
					>
						{t("actions:seeEntityCustomers", {
							entity: entity.toLowerCase(),
						})}
					</Link>
					<p className="font-dmSans text-sm text-black">
						{t("hints:entityCustomers", { entity: entity.toLowerCase() })}
					</p>
				</div>
			)}
		</Card>
	)
}
