import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useTrans } from "translations"

export const InvalidDeletePointModal: React.FC<{
	modalTitle: string
	invalidDeleteHint: string
}> = ({ modalTitle, invalidDeleteHint }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()

	return (
		<ModalContainer
			title={modalTitle}
			onConfirm={hideModal}
			onCancel={hideModal}
			onConfirmText="common:OK"
			disableCancelButton
		>
			<div className="pt-3 whitespace-pre">
				<p>{t(invalidDeleteHint)}</p>
			</div>
		</ModalContainer>
	)
}
