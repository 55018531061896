import { UseFormReturn } from "react-hook-form"
import { CustomerWithChain } from "../../types"
import { TenantDetailsFormSection } from "./TenantDetailsFormSection"
import { TenantContactInfoFormSection } from "./TenantContactInfoFormSection"
import { useConfig } from "api/hooks/useConfig"

export const CreateSingleTenant = ({
	formHandler,
}: {
	formHandler: UseFormReturn<CustomerWithChain>
}) => {
	const { isRealEstate } = useConfig()
	return (
		<form className="flex flex-col gap-4 md:flex-row 2xl:justify-between">
			<TenantDetailsFormSection formHandler={formHandler} />
			{isRealEstate && (
				<div>
					<TenantContactInfoFormSection formHandler={formHandler} />
				</div>
			)}
		</form>
	)
}
