import { FC } from "react"
import ModalContainer from "components/modalContainer"
import ErrorNotice from "components/ErrorNotice"
import { Allocation } from "./allocationDrawer"
import { AllocationForm, AllocationFormData } from "./allocationForm"
import { useTrans } from "translations"
import { useModal } from "Contexts"
import { useForm } from "react-hook-form"
import { trpc } from "Utils/trpc"
import { useGlobalAlert } from "States/globalAlert"

type Props = {
	allocation: Allocation
	onSuccess: () => void
}

export const EditAllocationModal: FC<Props> = ({ allocation, onSuccess }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()

	const [municipalityNumber, streetCode, houseNumber] = allocation.geoLocationCode.split(".")

	const formHandler = useForm<AllocationFormData>({
		defaultValues: {
			name: allocation.geoLocationName,
			municipalityNumber,
			streetCode,
			houseNumber,
		},
	})

	const { isLoading, isError, mutate } = trpc.allocations.update.useMutation()

	const { handleSubmit } = formHandler

	const onConfirm = handleSubmit(fieldData => {
		const geoLocationCode = `${fieldData.municipalityNumber}.${fieldData.streetCode}.${fieldData.houseNumber}`
		const allocationData = {
			id: allocation.id,
			geoLocationCode,
			geoLocationName: fieldData.name,
		}

		mutate(allocationData, {
			onSuccess: () => {
				hideModal()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:changesSaved",
				})
				onSuccess()
			},
			onError: () => {
				setGlobalAlert({
					type: "error",
					message: "errors:failedSave",
				})
			},
		})
	})

	return (
		<ModalContainer
			title="actions:editAllocation"
			onCancel={hideModal}
			onConfirm={onConfirm}
			onConfirmLoading={isLoading}
			className="w-1/2 lg:w-1/3"
		>
			{isError ? (
				<ErrorNotice />
			) : (
				<AllocationForm title={t("drawerLabels:allocation")} formHandler={formHandler} isEdit />
			)}
		</ModalContainer>
	)
}
