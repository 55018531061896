import React from "react"

export const PartialCheckbox: React.FC<{ className?: string }> = ({ className }) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" className={className}>
		<rect x="0.5" y="0.5" width="17" height="17" fill="white" />
		<line x1="3" y1="9" x2="15" y2="9" stroke="black" stroke-width="2" />
		<rect x="0.5" y="0.5" width="17" height="17" stroke="black" />
	</svg>
)

export default PartialCheckbox
