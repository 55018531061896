import { useAuth0 } from "Contexts"
import { MouseEvent } from "react"
import { Translate, useTrans } from "translations"

export const Unauthorized = () => {
	const { t } = useTrans()
	const { logout } = useAuth0()!

	const onLogout = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault()
		logout({ logoutParams: { returnTo: window.location.origin } })
	}

	return (
		<div className="fixed top-0 bottom-0 left-0 right-0 z-30 flex-col items-center justify-center bg-grey1">
			<h1 className="w-[920px] text-center font-signifier text-3xl">
				<Translate
					i18nKey={"errors:unauthorized"}
					// Anchor content is added by the translation
					// eslint-disable-next-line jsx-a11y/anchor-has-content
					components={[<a href="mailto:support@carrot.tech" className="text-fucales underline" />]}
				/>
			</h1>
			<a
				className="mt-6 cursor-pointer text-3xl text-fucales underline"
				href="/"
				onClick={onLogout}
			>
				{t("actions:logout")}
			</a>
		</div>
	)
}

export default Unauthorized
