import classNames from "classnames"
import { useMemo } from "react"
import { MultiListSection, MultiListSectionConfig } from "./multiListSection"
import { useMultiListState } from "./multiListState"

export type MultiListTableProps = {
	sections: MultiListSectionConfig<any>[]
	initialState?: Parameters<typeof useMultiListState>[1]
}

export const MultiListTable: React.FC<MultiListTableProps> = ({ sections, initialState }) => {
	const state = useMultiListState(sections, initialState)
	const {
		ref,
		drawer: { isOpen: isDrawerOpen, selectedDrawerIndex },
	} = state

	const selectedDrawer = useMemo(
		() => sections?.[selectedDrawerIndex]?.drawer,
		[sections, selectedDrawerIndex]
	)

	return (
		<div className={classNames("flex gap-0.5 sm:gap-3")} {...{ ref }}>
			{sections.map((section, index) => (
				<MultiListSection key={`MultiListSection_${index}`} {...{ ...section, state, index }} />
			))}
			{isDrawerOpen && selectedDrawer?.content}
		</div>
	)
}
