import { trpc } from "Utils/trpc"
import { useTerminalsState } from "../../States/Terminals"
import { useEffect, useMemo } from "react"
import { useConfig } from "./useConfig"
import { getPermittedTerminals } from "Utils/authUtils"

/**
 * Use terminals hook, used to manage terminals. The underlying state is managed by useTerminalsState.
 */
export const useTerminals = () => {
	const { isMWM } = useConfig()
	const { setTerminalState } = useTerminalsState()

	const { data, isLoading: isLoadingTerminals, refetch } = trpc.getAllTerminals.useQuery()

	useEffect(() => {
		const filteredTerminals = getPermittedTerminals(data, isMWM)

		setTerminalState({
			terminals: filteredTerminals,
		})
	}, [data, setTerminalState, isMWM])

	const terminals = useMemo(() => getPermittedTerminals(data, isMWM) || [], [data, isMWM])

	const refetchTerminals = async () => {
		await refetch()
		setTerminalState({ terminals })
	}

	const { mutateAsync: updateTerminal, isLoading: isUpdatingTerminal } =
		trpc.accessPoints.update.useMutation()

	return { terminals, refetchTerminals, isLoadingTerminals, updateTerminal, isUpdatingTerminal }
}
