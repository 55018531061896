import { useConfig } from "api/hooks/useConfig"
import { ExportButton } from "components/ExportButton"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTrans } from "translations"
import { CustomerList } from "api/hooks/useCustomers/useCustomers"
import { useTerminalsState } from "States/Terminals"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { SortingState } from "@tanstack/react-table"
import { useGlobalAlert } from "States/globalAlert"
import { Toggle } from "components/Toggle"
import { ExportType, downloadExportFile } from "api/export"
import { useAuth0 } from "Contexts"
import { OPTION_ALL } from "components/pageCards/filterSort/constants"

interface ExportDataCardProps {
	selectedCustomers: CustomerList
	areAllSelected?: boolean // Export all customers even beyond the current page if true
	filterState?: ActiveOptions<any>
	sortingState?: SortingState
	nameFilter?: string
}

export const ExportDataCard: FC<ExportDataCardProps> = ({
	selectedCustomers,
	areAllSelected,
	filterState = {},
	sortingState,
	nameFilter,
}) => {
	const { t, language } = useTrans()
	const { isMWM } = useConfig()
	const { currentTerminal } = useTerminalsState()
	const [isExporting, setIsExporting] = useState<undefined | "csv" | "excel">(undefined)
	const { setGlobalAlert } = useGlobalAlert()
	const selectedIds = useMemo(
		() => selectedCustomers.map(customer => customer.id),
		[selectedCustomers]
	)
	const [includeAccessTokens, setIncludeAccessTokens] = useState(false)
	const [includeContacts, setIncludeContacts] = useState(false)
	const [includeStations, setIncludeStations] = useState(false)

	const { getTokenSilently } = useAuth0()!

	const exportFile = useCallback(async () => {
		try {
			const multipleFiles = includeStations || includeContacts || includeAccessTokens

			let type: ExportType = "csv"
			if (multipleFiles && isExporting === "csv") {
				type = "zip"
			} else if (isExporting === "excel") {
				type = "excel"
			}

			const types = filterState?.type?.filter(t => t.value !== OPTION_ALL)
			const categories = filterState?.category?.filter(t => t.value !== OPTION_ALL)
			const points = filterState?.accessParentId?.filter(t => t.value !== OPTION_ALL)

			await downloadExportFile({
				type,
				entity: "customers",
				queryParams: {
					includeStations,
					includeContacts,
					includeAccessTokens,
					customerIds: areAllSelected ? undefined : selectedIds,
					filterSort: {
						nameRegex: nameFilter,
						sortDirection: sortingState?.[0]?.desc ?? false ? "desc" : "asc",
						sortFields: (sortingState?.map(ss => ss.id) as (
							| "name"
							| "createdAt"
							| "updatedAt"
						)[]) ?? ["name"],
						types: types?.length ? types.map(el => el.value) : undefined,
						categoryIds: categories?.length ? categories.map(el => el.value) : undefined,
						pointIds: points?.length ? points.map(el => el.value) : undefined,
					},
				},
				terminalId: isMWM ? undefined : currentTerminal.id,
				language,
				t,
				getTokenSilently,
			})
		} catch (error) {
			console.error("There was an error downloading the file!", error)
			setGlobalAlert({
				message: t("alerts:exportFailed"),
				type: "error",
			})
		}
		setIsExporting(undefined)
	}, [
		isExporting,
		includeStations,
		includeContacts,
		includeAccessTokens,
		currentTerminal.id,
		getTokenSilently,
		setGlobalAlert,
		areAllSelected,
		selectedIds,
		isMWM,
		language,
		t,
		nameFilter,
		sortingState,
		filterState,
	])

	useEffect(() => {
		if (isExporting) {
			exportFile()
		}
	}, [isExporting, exportFile])

	return (
		<Card title={"drawerLabels:exportData"} variant={CardComponentVariant.DRAWER}>
			<p className="mb-4 font-dmSans text-sm text-black">
				{t("hints:exportCustomerDataDescription")}
			</p>
			<ul>
				{isMWM ? (
					<>
						<li className="mb-2">
							<Toggle
								label={t("drawerLabels:includeStations")}
								checked={includeStations}
								onChange={() => setIncludeStations(!includeStations)}
							/>
						</li>
						<li className="mb-2">
							<Toggle
								label={t("drawerLabels:includeAccessTokens")}
								checked={includeAccessTokens}
								onChange={() => setIncludeAccessTokens(!includeAccessTokens)}
							/>
						</li>
					</>
				) : (
					<>
						<li className="mb-2">
							<Toggle
								label={t("drawerLabels:includeContacts")}
								checked={includeContacts}
								onChange={() => setIncludeContacts(!includeContacts)}
							/>
						</li>
						<li className="mb-2">
							<Toggle
								label={t("drawerLabels:includeAccessTokens")}
								checked={includeAccessTokens}
								onChange={() => setIncludeAccessTokens(!includeAccessTokens)}
							/>
						</li>
					</>
				)}
			</ul>
			<div className="right-8 z-[9] flex justify-end">
				<ExportButton
					isLoading={!!isExporting}
					nrSelected={selectedCustomers.length}
					areAllSelected={areAllSelected}
					exportCsvFunction={() => setIsExporting("csv")}
					exportExcelFunction={() => setIsExporting("excel")}
				/>
			</div>
		</Card>
	)
}
