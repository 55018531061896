import { Page } from "components/Page"
import { Card } from "components/pageCards/card"
import { ContentSummary } from "./components/contentSummary"
import { CostsTable } from "./components/costsTable"
import { useCostForecast } from "./functions/useCostForecast"

export type HandleCostForecastChange = () => void

export const CostForecasts = () => {
	const { costForecastData, handleInputChange, modified } = useCostForecast()

	return (
		<Page title="sidebarLabels:costForecasts" fullHeight>
			<Card
				title="circularityLabels:expenseEstimation"
				overflow="auto"
				hasXContentPadding={false}
				hasYContentPadding={false}
			>
				<div className="my-6 mx-8 h-full min-w-[1280px] flex-col gap-5">
					<ContentSummary data={costForecastData} modified={modified} />
					<CostsTable data={costForecastData} inputChangeHandler={handleInputChange} />
				</div>
			</Card>
		</Page>
	)
}
