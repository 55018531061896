import React from "react"
import classNames from "classnames"
import Pointer from "Icons/Pointer"
import { formatNumberForTable } from "Utils/formatFunctions"
import { DeltaTrend } from "Utils/tableColumns/functions"

export const calculateUnitSpace = (unit: string): string => (unit === "kg" ? " " : "")
const getTrendColor = (trend: DeltaTrend) => {
	if (trend === DeltaTrend.Positive) return "text-carrotGreen"
	if (trend === DeltaTrend.Negative) return "text-carrotRed"
	if (trend === DeltaTrend.Neutral) return "text-grey6"
	return undefined
}

export const UnitDiff: React.FC<{ diff: number; unit: string; trend: DeltaTrend }> = ({
	diff,
	unit,
	trend,
}) => {
	const percentUnits = unit === "percent"
	const suffix = percentUnits ? " %" : " kg"
	return (
		<div
			className={classNames(
				"flex h-4 justify-items-center justify-self-end font-dmSans text-sm",
				getTrendColor(trend)
			)}
		>
			<Pointer className={classNames("mr-1", diff > 0 && "rotate-180", diff === 0 && "hidden")} />
			{formatNumberForTable(diff, { suffix })}
		</div>
	)
}

export default UnitDiff
