import { Input } from "components/GenericComponents/input"
import { ImportContext } from "components/ImportComponents/ImportContext"
import { TemplateColumn, ValidationCell } from "components/ImportComponents/types"
import { cloneDeep, get, set } from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { useTrans } from "translations"
import { CellInput } from "./CellInput"
import { getFilteredValues, getLinkingValues } from "components/ImportComponents/functions"

type Props = {
	cell: ValidationCell
	sectionKey: string
	cellIndex: number
	column: TemplateColumn
}

export const ValidateCell: React.FC<Props> = ({ cell, sectionKey, cellIndex, column }) => {
	const { t } = useTrans()
	const { selectedTemplate, mappingState, validationState, setValidationState } =
		useContext(ImportContext)

	const onValueChange = useCallback(
		async (value: string) => {
			const newState = cloneDeep(validationState)

			if (newState[sectionKey]) {
				const templateMappingState = selectedTemplate?.mappingSections.find(
					el => el.key === sectionKey
				)
				const templateColumn = templateMappingState?.columns?.find(el => el.key === column.key)

				let linkingValues = getLinkingValues({
					templateColumn,
					sectionKey,
					selectedTemplate,
					validationState,
				})

				if (templateColumn?.filterBy) {
					const filteredValues = getFilteredValues({
						validationState,
						column: templateColumn,
						sectionKey,
						cellIndex,
					})

					if (filteredValues?.length) {
						linkingValues = filteredValues
					}
				}

				set(newState, `${sectionKey}.${column.key}.${cellIndex}.value`, value)

				const error =
					column.validate?.({
						value,
						linkingValues:
							templateColumn?.hasLinkedParent || templateColumn?.filterBy ? linkingValues : [],
					}) || ""

				set(newState, `${sectionKey}.${column.key}.${cellIndex}.error`, error)

				if (templateColumn?.oppositeKey) {
					const oppositeMappingState = selectedTemplate?.mappingSections.find(
						el => el.columns?.some(c => c.key === templateColumn?.oppositeKey && c.hasLinkedChild)
					)

					if (oppositeMappingState) {
						const oppositeNotSelected = !mappingState[oppositeMappingState.key]?.selected

						if (oppositeNotSelected) {
							if (!value) {
								set(
									newState,
									`${sectionKey}.${column.key}.${cellIndex}.error`,
									"errors:requiredFields"
								)
							}
						} else {
							const oppositeCell = newState[sectionKey]?.[templateColumn?.oppositeKey]?.[cellIndex]

							if (!value && !oppositeCell?.value) {
								set(
									newState,
									`${sectionKey}.${column.key}.${cellIndex}.error`,
									"errors:oneMustBeFilled"
								)
								set(
									newState,
									`${sectionKey}.${templateColumn?.oppositeKey}.${cellIndex}.error`,
									"errors:oneMustBeFilled"
								)
							} else if (value && oppositeCell?.value) {
								set(
									newState,
									`${sectionKey}.${column.key}.${cellIndex}.error`,
									"errors:bothCantBeFilled"
								)
								set(
									newState,
									`${sectionKey}.${templateColumn?.oppositeKey}.${cellIndex}.error`,
									"errors:bothCantBeFilled"
								)

								const firstColumnTitle = t(templateColumn.title)
								const secondColumnTitle = t(
									oppositeMappingState.columns?.find(c => c.key === templateColumn?.oppositeKey)
										?.title || ""
								)

								set(newState, `${sectionKey}.${column.key}.${cellIndex}.errorOptions`, {
									firstColumnTitle,
									secondColumnTitle,
								})
								set(
									newState,
									`${sectionKey}.${templateColumn?.oppositeKey}.${cellIndex}.errorOptions`,
									{ firstColumnTitle, secondColumnTitle }
								)
							} else if (
								(value && oppositeCell?.error === "errors:oneMustBeFilled") ||
								(!value && oppositeCell?.error === "errors:bothCantBeFilled")
							) {
								set(newState, `${sectionKey}.${templateColumn?.oppositeKey}.${cellIndex}.error`, "")
							}
						}
					}
				}

				if (templateColumn?.columnValidate) {
					const validationResults = await templateColumn.columnValidate([value])

					if (validationResults) {
						newState[sectionKey]![column.key][cellIndex].error =
							validationResults[value] || error || ""
					}
				}

				// validate that the linked columns have correct values
				if (templateColumn?.hasLinkedChild) {
					const columnValues = newState[sectionKey]![column.key].map(el => el.value)

					const linkedSections = selectedTemplate?.mappingSections.filter(
						s => s.columns?.find(c => c.hasLinkedParent && c.key === templateColumn?.key)
					)

					linkedSections?.forEach(linkedSection => {
						const linkedColumn = linkedSection?.columns?.find(c => c.key === templateColumn?.key)

						if (newState[linkedSection.key] && linkedColumn) {
							newState[linkedSection.key]![linkedColumn.key].forEach((c, i) => {
								const currentError = newState[linkedSection!.key]![linkedColumn!.key][i].error
								let errorValue = currentError

								if (!columnValues.includes(c.value)) {
									errorValue = "errors:unrecognizedValue"
								} else if (currentError === "errors:unrecognizedValue" && c.value === value) {
									errorValue = ""
								}

								set(newState, `${linkedSection!.key}.${linkedColumn!.key}.${i}.error`, errorValue)
							})
						}
					})
				}

				// generate values for columns that depend on this one
				if (templateColumn?.generatingDependents?.length) {
					templateColumn.generatingDependents.forEach(dependent => {
						const dependentColumn = templateMappingState?.columns?.find(el => el.key === dependent)

						if (dependentColumn) {
							const newGeneratedValue = dependentColumn.getGeneratedValue?.(value)

							set(newState, `${sectionKey}.${dependentColumn.key}.${cellIndex}`, {
								value: newGeneratedValue,
							})
						}
					})
				}

				if (templateColumn?.uniqueInColumn) {
					newState[sectionKey]?.[column.key].forEach((cell, i, cells) => {
						const valueMoreThanOnce = cells.filter(c => c.value === cell.value).length > 1
						const currentError = newState[sectionKey]![column.key][i].error
						let errorValue = currentError

						if (cell.value && valueMoreThanOnce) {
							errorValue = "errors:duplicateValueInColumn"
						} else if (currentError === "errors:duplicateValueInColumn") {
							errorValue = ""
						}

						set(newState, `${sectionKey}.${column.key}.${i}.error`, errorValue)
					})
				}

				const columnsFilteredByThisColumn = templateMappingState?.columns?.filter(
					el => el.filterBy === column.key
				)
				if (columnsFilteredByThisColumn?.length) {
					columnsFilteredByThisColumn.forEach(filteredColumn => {
						const filteredValues = getFilteredValues({
							validationState,
							column: filteredColumn,
							sectionKey,
							cellIndex,
						})

						if (filteredValues.length) {
							set(
								newState,
								`${sectionKey}.${filteredColumn.key}.${cellIndex}.error`,
								filteredColumn.validate?.({ value, linkingValues: filteredValues }) || ""
							)
						}
					})
				}
			}

			setValidationState(newState)
		},
		[
			cellIndex,
			sectionKey,
			validationState,
			setValidationState,
			column,
			selectedTemplate,
			mappingState,
			t,
		]
	)

	const cellView = useMemo(() => {
		if (column.isDropdown) {
			let dropdownOptions = column.dropdownOptions || []

			if (column.filterBy) {
				const filterByValue: any = get(
					validationState,
					`${sectionKey}.${column.filterBy}.${cellIndex}.value`,
					""
				)

				if (filterByValue) {
					dropdownOptions = dropdownOptions.filter(el => el.filterParamId === filterByValue)
				}
			}

			return (
				<div className="py-4 px-2.5  border-b border-grey2 ">
					<Input
						placeholder={t("importLabels:selectTemplate")}
						data={dropdownOptions}
						dropdownStyle={{ maxHeight: 190 }}
						dropdownIcon
						initialValue={cell.value}
						onChange={option => onValueChange(option.value)}
						ignoreOnRenderChange
						noInitialOption={!cell.value}
						error={!!cell.error}
						errorMessage={t(cell.error)}
						errorInTooltip
					/>
				</div>
			)
		}

		return (
			<CellInput
				cell={cell}
				sectionKey={sectionKey}
				columnKey={column.key}
				cellIndex={cellIndex}
				onValueChange={onValueChange}
			/>
		)
	}, [column, cell, cellIndex, sectionKey, onValueChange, t, validationState])

	return cellView
}
