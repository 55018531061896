import { ImportStep } from "components/ImportComponents/types"
import {
	DOWNLOAD_STEP,
	UPLOAD_STEP,
	MAPPING_STEP,
	VALIDATE_STEP,
	ADDING_STEP,
	DOWNLOAD_KEY,
	UPLOAD_KEY,
	MAP_KEY,
	VALIDATE_KEY,
	ADDING_KEY,
} from "pages/infrastructure/import/importSteps"

export const IMPORT_STEPS: ImportStep[] = [
	{
		...DOWNLOAD_STEP,
		key: `${DOWNLOAD_KEY}-customers`,
		helpSectionKey: `${DOWNLOAD_KEY}-customers`,
	},
	{ ...UPLOAD_STEP, key: UPLOAD_KEY, helpSectionKey: `${UPLOAD_KEY}-customers` },
	{ ...MAPPING_STEP, key: MAP_KEY, helpSectionKey: `${MAP_KEY}-customers` },
	{ ...VALIDATE_STEP, key: VALIDATE_KEY, helpSectionKey: `${VALIDATE_KEY}-customers` },
	{ ...ADDING_STEP, key: ADDING_KEY, helpSectionKey: `${ADDING_KEY}-customers` },
]
