import { useEffect } from "react"

/**
 * Hook that calls back on clicks outside of the passed ref
 * @param ref - React ref to the element to check for clicks outside
 * @param handler - Callback function to call on click outside
 * @param enableIf - If false, the hook will not be active. Typically used to disable the hook when the element is not visible
 */
export default function useClickoutside(ref: any, handler?: () => void, enableIf?: boolean) {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (!handler) return

			if (ref?.current && !ref?.current.contains(event.target)) {
				handler()
			}
		}

		if (enableIf === undefined || enableIf) {
			document.addEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [ref, handler, enableIf])
}
