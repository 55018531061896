import { deleteCustomer } from "api/deleteCustomer"
import useCustomers from "api/hooks/useCustomers"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGlobalAlert } from "States/globalAlert"
import { useTrans } from "translations"

export const DeleteTenantModal: React.FC<{ customerId: string; customerName: string }> = ({
	customerId,
	customerName,
}) => {
	const [isLoading, setLoading] = useState(false)

	const navigate = useNavigate()
	const { t } = useTrans()
	const { hideModal } = useModal()
	const { refetchCustomers } = useCustomers()
	const { setGlobalAlert } = useGlobalAlert()

	const onFailCallBack = () => {
		setLoading(false)
	}

	const onSuccessCallback = () => {
		setGlobalAlert({
			type: "success",
			message: "systemMessages:customerDeleted",
		})

		refetchCustomers()
		hideModal()
	}

	const onConfirm = () => {
		setLoading(true)
		// TODO: Make and use new API with error handling
		deleteCustomer({ id: customerId, onFailCallBack }).then(onSuccessCallback)
		navigate("/customer/manage")
	}

	return (
		<ModalContainer
			title={"actions:deleteCustomer"}
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={isLoading}
			onConfirmText="actions:confirm"
		>
			<div className="pt-3">
				<p>
					{t("hints:deleteTenantConfirmation")} {customerName}?
				</p>
			</div>
		</ModalContainer>
	)
}
