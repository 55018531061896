import { changeView, percentageView, titleView, ColumnType } from "./functions"

const SMALL_TABLE_RIGHT_ALIGN_CLASS = "justify-end pr-3"

export const bestPerformanceColumns: ColumnType[] = [
	{
		label: "entities:customer",
		name: "name",
		getView: titleView,
	},
	{
		label: "circularityLabels:recyclingRate",
		name: "rate",
		getView: value => percentageView(value, " %", SMALL_TABLE_RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:change",
		name: "diff",
		getView: value => changeView(value, "percent", SMALL_TABLE_RIGHT_ALIGN_CLASS),
	},
]

export const worstPerformanceColumns: ColumnType[] = [
	{
		label: "entities:customer",
		name: "name",
		getView: titleView,
	},
	{
		label: "circularityLabels:recyclingRate",
		name: "rate",
		getView: value => percentageView(value, " %", SMALL_TABLE_RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:change",
		name: "diff",
		getView: value => changeView(value, "percent", SMALL_TABLE_RIGHT_ALIGN_CLASS),
	},
]

export const bestMixedWasteColumns: ColumnType[] = [
	{
		label: "entities:customer",
		name: "name",
		getView: titleView,
	},
	{
		label: "statisticsLabels:amount",
		name: "rate",
		getView: value => percentageView(value, " kg", SMALL_TABLE_RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:change",
		name: "diff",
		getView: value => changeView(value, "kg", SMALL_TABLE_RIGHT_ALIGN_CLASS, true),
	},
]

export const worstMixedWasteColumns: ColumnType[] = [
	{
		label: "entities:customer",
		name: "name",
		getView: titleView,
	},
	{
		label: "statisticsLabels:amount",
		name: "rate",
		getView: value => percentageView(value, " kg", SMALL_TABLE_RIGHT_ALIGN_CLASS),
	},
	{
		label: "statisticsLabels:change",
		name: "diff",
		getView: value => changeView(value, "kg", SMALL_TABLE_RIGHT_ALIGN_CLASS, true),
	},
]
