import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort } from "components/pageCards/filterSort/types"
import { AccessParentDrawer } from "components/pageCards/multiListTable/drawers/lfm/accessParentDrawer"
import { DepotDrawer } from "components/pageCards/multiListTable/drawers/lfm/depotDrawer"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { TOptions } from "i18next"
import { AccessParentContainerData } from "pages/infrastructure/functions"
import { AddDepotModal } from "pages/infrastructure/modals/AddDepotModal"
import { Dispatch, SetStateAction } from "react"

type Props = {
	depots: {
		status: string | undefined
		containers: AccessParentContainerData[]
		id: string
		name: string
	}[]
	isLoadingAccessParentChildren: boolean
	selectedDepotId?: string
	t: (key: string, options?: TOptions | undefined) => string
	onRowClick: (depot: { id: string }) => void
	accessParentId: string
	filters: FilterSort[]
	setAccessParentVariantsFilterState: (activeOptions: ActiveOptions<any>) => void
	onLinkButtonClick: () => void
	showModal: (_: any) => void
	setSelectedDepotId: Dispatch<SetStateAction<string | undefined>>
}

export default function getAccessParentVariantsSection({
	depots,
	selectedDepotId,
	isLoadingAccessParentChildren,
	t,
	onRowClick,
	accessParentId,
	filters,
	setAccessParentVariantsFilterState,
	onLinkButtonClick,
	showModal,
	setSelectedDepotId,
}: Props): MultiListSectionConfig<typeof depots> {
	return {
		type: "linkWithList",
		title: "genericLabels:accessParentVariants",
		data: depots,
		isLoading: isLoadingAccessParentChildren,
		getInitiallySelectedRows: entries => entries.filter(({ id }) => id === selectedDepotId),
		entryRender: entry => ({
			labelTop: entry.name,
			labelBottom: `${entry.containers?.length ?? 0} ${
				entry.containers?.length === 1
					? t("entities:container").toLowerCase()
					: t("entities:containers").toLowerCase()
			}`,
			labelBottomRight: `${t("formLabels:status")}: ${t("status:" + entry.status)}`,
			labelTopRight: "",
		}),
		onRowClick,
		drawer: {
			content: selectedDepotId ? (
				<DepotDrawer {...{ accessParentId, depotId: selectedDepotId }} />
			) : (
				<AccessParentDrawer {...{ accessParentId }} />
			),
		},
		emptyViewType: "noDepots",
		labelSingle: "entities:depot",
		labelMultiple: "entities:depots",
		filters,
		onFilterOptionChange: setAccessParentVariantsFilterState,
		linkHeaderTitle: "genericLabels:accessParentWithoutDepots",
		linkHeaderTooltipHint: "hints:accessParentWithoutDepots",
		listHeaderTitle: "genericLabels:accessParentWithDepots",
		listHeaderTooltipHint: "hints:accessParentWithDepots",
		linkButtonLabel: "entities:containers",
		onLinkButtonClick,
		listButton: accessParentId
			? {
					onClick: () =>
						showModal(
							<AddDepotModal parentId={accessParentId} setSelectedDepotId={setSelectedDepotId} />
						),
			  }
			: undefined,
	}
}
