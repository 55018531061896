import { Card, CardComponentVariant } from "components/pageCards/card"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { FC } from "react"
import { useTrans } from "translations"
import { ContainerDrawerProps } from "../containerDrawer"

const supportedHatchTypeCodes = ["LS", "L", "S"]

export const ContainerHatches: FC<ContainerDrawerProps> = ({
	accessParentId,
	containerId,
	depotId,
}) => {
	const { t } = useTrans()

	const { getContainer } = useAccessPoints({ accessParentId })
	const { hatchTypeCode } = getContainer(containerId, depotId) || {}

	if (!hatchTypeCode || !supportedHatchTypeCodes.includes(hatchTypeCode)) {
		return null
	}

	return (
		<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:hatches">
			<div className="mb-6 text-sm">{t("hints:hatchesDescription")}</div>
			<div className="mb-3 text-sm">{t("hints:containerHasFollowingHatchSizes")}</div>
			<div className="text-sm font-medium">{t(`entities:hatchTypeCode${hatchTypeCode}`)}</div>
		</Card>
	)
}
