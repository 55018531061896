import ModalContainer from "components/modalContainer"
import { useEffect } from "react"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { StationForm, StationFormData } from "../manage/stationForm"
import { updatePoint } from "api/mutations/updatePoint"
import { ACCESS_POINT_STATUSES } from "Utils/gqlRequestTypes/generic"
import { useGlobalAlert } from "States/globalAlert"
import {
	ACCESS_POINT_COORDINATES,
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_STATUS_KEY,
	ACCESS_POINT_TYPE_KEY,
	CLIENT_KEY,
	MUNICIPALITY_KEY,
} from "constants/general"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"

export const EditStationModal: React.FC<{
	stationId: string
}> = ({ stationId }) => {
	const { hideModal } = useModal()
	const { getAccessParent, isLoading, refetch } = useAccessParentsWithPoints()
	const { setGlobalAlert } = useGlobalAlert()

	const station = getAccessParent(stationId)

	const formHandler = useForm<StationFormData>({
		defaultValues: { status: ACCESS_POINT_STATUSES[0], ...station, coordinates: station?.pos?.dd },
	})

	useEffect(() => {
		formHandler.trigger()
	}, [formHandler])

	const {
		formState: { errors, isValid },
		handleSubmit,
	} = formHandler

	const onSubmit = handleSubmit(formData => {
		const { name, status, coordinates, municipality, client } = formData

		updatePoint(
			{
				accessPoint: {
					id: stationId,
				},
				updateFields: {
					[ACCESS_POINT_NAME_KEY]: name,
					[ACCESS_POINT_TYPE_KEY]: "ACCESS_PARENT",
					[ACCESS_POINT_STATUS_KEY]: status,
				},
				geoLocationUpdate: {
					mode: "MERGE",
					props: [{ key: ACCESS_POINT_COORDINATES, value: coordinates }],
				},
				propertiesUpdate: {
					mode: "MERGE",
					props: [
						{
							key: MUNICIPALITY_KEY,
							value: municipality,
						},
						{
							key: CLIENT_KEY,
							value: client,
						},
					],
				},
			},
			() => {
				setGlobalAlert({
					type: "success",
					message: "systemMessages:changesSaved",
				})
				refetch().then(hideModal)
			}
		)
	})

	return (
		<ModalContainer
			title="drawerLabels:accessParentDetails"
			onConfirmText={"actions:confirm"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
		>
			<StationForm {...{ formHandler, onSubmit }} />
		</ModalContainer>
	)
}
