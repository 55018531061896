import {
	NavHeaderTypeEnum,
	navigationInterface,
	NavSectionInterface,
} from "components/Sidebar/Sidebar.interface"
import React from "react"
import { NavHeader } from "components/Sidebar/components/NavHeader"
import { NavItem } from "components/Sidebar/components/NavItem"
import { Union } from "Icons/Union"
import { Customers } from "Icons/Customers"
import { Infrastructure } from "Icons/Infrastructure"
import { Circularity } from "Icons/Circularity"
import { useConfig } from "api/hooks/useConfig"

export const getNavigationInterface = (
	isRealEstate: boolean = false,
	useExternalCRM: boolean = false
): navigationInterface => {
	return {
		[NavHeaderTypeEnum.General]: {
			icon: Union,
			links: [
				{
					to: "/",
					name: "sidebarLabels:home",
				},
				isRealEstate && {
					to: "/reporting",
					name: "sidebarLabels:reporting",
				},
				{
					to: "/events",
					name: "sidebarLabels:events",
				},
			].filter(a => !!a) as { to: string; name: string }[],
		},
		[NavHeaderTypeEnum.Customers]: {
			icon: Customers,
			links: [
				isRealEstate && {
					to: "/tenant/dashboard",
					name: "sidebarLabels:tenantFollowUp",
				},
				{
					to: "/customer/manage",
					name: "sidebarLabels:manageCustomers",
				},
				!isRealEstate &&
					useExternalCRM && {
						to: "/allocations",
						name: "sidebarLabels:allocations",
					},
			].filter(a => !!a) as { to: string; name: string }[],
		},
		[NavHeaderTypeEnum.Infrastructure]: {
			icon: Infrastructure,
			links: [
				{
					to: "/infrastructure/manage",
					name: "sidebarLabels:manageInfrastructure",
				},
				isRealEstate && {
					to: "/infrastructure/upgrades",
					name: "sidebarLabels:upgradesAndPowerUps",
				},
			].filter(a => !!a) as { to: string; name: string }[],
		},
		[NavHeaderTypeEnum.Circularity]: {
			icon: Circularity,
			links: [
				{
					to: "/circularity/overview",
					name: "sidebarLabels:circularityOverview",
				},
				{
					to: "/circularity/costs",
					name: "sidebarLabels:costForecasts",
				},
				{
					to: "/circularity/downstream",
					name: "sidebarLabels:downstreamHandling",
				},
			],
		},
	}
}

export const NavSection: React.FC<NavSectionInterface> = ({ type }) => {
	const { isRealEstate, config } = useConfig()

	return (
		<li className="mb-5">
			<ul>
				<li className="mb-1.5">
					<NavHeader type={type} />
				</li>
				{getNavigationInterface(isRealEstate, config?.useExternalCRM)[type].links.map(
					({ to, name }, index) => (
						<li key={index}>
							<NavItem to={to} name={name} />
						</li>
					)
				)}
			</ul>
		</li>
	)
}
