import classNames from "classnames"
import { useModal } from "Contexts"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { useTrans } from "translations"
import { WasteTypesInUseModal } from "../components/WasteTypesInUse"

export type WasteTypeCategory = { id: string; icon: string; name: string }
export type WasteTypeElement = {
	id: string
	icon: string
	name: string
	tooltip?: string
}

export const WasteTypesInUserTable: React.FC<{
	typesInUse: WasteTypeElement[]
	futurePotential: WasteTypeElement[]
	avgTypes: number
	limitNumberOfTypes?: boolean
}> = ({ typesInUse, futurePotential, avgTypes, limitNumberOfTypes = true }) => {
	const { t } = useTrans()
	const anyInUse = typesInUse.length > 0
	const anyFuturePotential = futurePotential.length > 0
	const inUseToShow = limitNumberOfTypes ? (anyFuturePotential ? 8 : 11) : Number.MAX_SAFE_INTEGER
	const futurePotentialToShow = limitNumberOfTypes ? (anyInUse ? 5 : 11) : Number.MAX_SAFE_INTEGER

	const { showModal } = useModal()

	const showAllTypesModal = () => {
		showModal(<WasteTypesInUseModal />)
	}

	return (
		<div className="flex min-h-full gap-4">
			{anyInUse && (
				<div
					className={classNames(
						"bg-lime py-3 px-3 2xl:px-6",
						anyFuturePotential ? "basis-3/5" : "w-full"
					)}
				>
					<span className="text-base font-bold text-black">
						{t("circularityLabels:typesAlreadyInUse", { count: typesInUse.length })}
					</span>
					<p className="mb-4 font-dmSans text-xs text-black">
						{t("circularityLabels:theCarrotAverageIs")} {avgTypes}
					</p>
					<div
						className={classNames(
							"grid grid-flow-row gap-x-2 2xl:gap-x-6",
							anyFuturePotential ? "grid-cols-3" : "grid-cols-4"
						)}
					>
						{typesInUse.slice(0, inUseToShow).map(({ id, icon, name }) => (
							<div className="flex h-[100px] flex-col items-center text-center" key={id}>
								<div
									className="mb-2 h-10 w-10"
									style={{
										backgroundImage: `url(${icon})`,
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center center",
									}}
								/>
								<span className="font-dmSans text-2xs text-black">{name}</span>
							</div>
						))}
						{typesInUse.length > inUseToShow && (
							<div
								className="flex h-[100px] cursor-pointer flex-col items-center text-center"
								onClick={showAllTypesModal}
							>
								<div className="mb-2 aspect-square h-10 w-10 text-4xl">…</div>
								<span className="font-dmSans text-2xs text-black underline">
									{typesInUse.length - inUseToShow} {t("circularityLabels:more").toLowerCase()}
								</span>
							</div>
						)}
					</div>
				</div>
			)}
			{anyFuturePotential && (
				<div
					className={classNames("bg-grey1 py-3 px-3 2xl:px-6", anyInUse ? "basis-2/5" : "w-full")}
				>
					<h6 className="font-dmSans text-base font-bold text-black">
						{t("circularityLabels:futurePotential")}
					</h6>
					<p className="mb-4 font-dmSans text-xs text-black">
						{t("circularityLabels:wasteTypesInUseByOtherCenters")}
					</p>
					<div
						className={classNames(
							"grid w-full grid-flow-row gap-x-2 2xl:gap-x-6",
							anyInUse ? "grid-cols-2" : "grid-cols-4"
						)}
					>
						{futurePotential.slice(0, futurePotentialToShow).map(({ id, icon, name, tooltip }) => (
							<div
								className="flex h-[100px] flex-col items-center text-center"
								key={id}
								data-tip={tooltip && t(tooltip)}
							>
								<div
									className={classNames(
										"mb-2 h-10 w-10",
										tooltip && "outline outline-8 outline-lime"
									)}
									style={{
										backgroundImage: `url(${icon})`,
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center center",
									}}
									data-tooltip-id={id}
								/>
								<span className="font-dmSans text-2xs text-black">{name}</span>
								{tooltip && (
									<ReactTooltip variant="light" className="max-w-xs" id={id} content={t(tooltip)} />
								)}
							</div>
						))}
						{futurePotential.length > futurePotentialToShow && (
							<div
								className="flex h-[100px] cursor-pointer flex-col items-center text-center"
								onClick={showAllTypesModal}
							>
								<div className="mb-2 aspect-square h-10 w-10">
									<div className="mb-2 aspect-square h-10 w-10 text-4xl">…</div>
								</div>
								<span className="font-dmSans text-2xs text-black underline">
									{futurePotential.length - futurePotentialToShow}{" "}
									{t("circularityLabels:more").toLowerCase()}
								</span>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
