import { Card, ICardComponentProps } from "../card"
import { DataList, DataListProps } from "./DataList"

export type DataListCardProps<DataType extends Record<string, any>[] = Record<string, any>[]> =
	DataListProps<DataType> & ICardComponentProps

export const DataListCard: React.FC<DataListCardProps> = props => {
	const {
		data = [],
		isLoading,
		getInitiallySelectedRows,
		entryRender,
		onRowClick,
		onMultipleSelect,
		emptyViewType,
		withMultiSelect = false,
		labelSingle,
		labelMultiple,
		filterLocation,
	} = props

	return (
		<Card {...{ ...props }} hasXContentPadding={false} hasYContentPadding={false} overflow="auto">
			<DataList
				data={data}
				isLoading={isLoading}
				entryRender={entryRender}
				onMultipleSelect={onMultipleSelect}
				emptyViewType={emptyViewType}
				withMultiSelect={withMultiSelect}
				labelSingle={labelSingle}
				labelMultiple={labelMultiple}
				filterLocation={filterLocation}
				getInitiallySelectedRows={getInitiallySelectedRows}
				onRowClick={onRowClick}
			/>
		</Card>
	)
}
