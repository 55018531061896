import { IconError } from "Icons/Error"
import { IconInfo } from "Icons/Info"
import { IconSuccess } from "Icons/Success"
import { IconWarning } from "Icons/Warning"
import React from "react"

export const alertConfig = {
	success: {
		icon: <IconSuccess />,
		className: "border-carrotGreen bg-lightLichen",
	},
	warning: {
		icon: <IconWarning />,
		className: "border-carrotOrange bg-lightOrange",
	},
	error: {
		icon: <IconError />,
		className: "border-carrotRed bg-lightPeach",
	},
	info: {
		icon: <IconInfo />,
		className: "border-carrotOrange bg-lightOrange",
	},
}
