export const NoHistory = ({ className }: { className?: string }) => (
	<svg width="64" height="64" viewBox="0 0 64 64" fill="none" className={className}>
		<path
			d="M17.4348 14L16 15.5V18.5L17.4348 20H47.5652L49 18.5V15.5L47.5652 14H17.4348Z"
			fill="#B2ABAB"
		/>
		<path
			d="M17.4348 24L16 25.5V28.5L17.4348 30H47.5652L49 28.5V25.5L47.5652 24H17.4348Z"
			fill="#B2ABAB"
		/>
		<path
			d="M17.4348 34L16 35.5V38.5L17.4348 40H47.5652L49 38.5V35.5L47.5652 34H17.4348Z"
			fill="#B2ABAB"
		/>
		<path
			d="M17.4348 44L16 45.5V48.5L17.4348 50H47.5652L49 48.5V45.5L47.5652 44H17.4348Z"
			fill="#B2ABAB"
		/>
	</svg>
)
