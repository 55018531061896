import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import Backend from "i18next-locize-backend"
import { locizeProjectId, locizeVersion } from "Configs/config"

const localStorageLanguage = window.localStorage.getItem("language.preference")
const browserLanguage =
	localStorageLanguage || (["nn-NO", "nb-NO", "nb"].includes(navigator.language) ? "nb" : "en")

const locizeOptions = {
	projectId: locizeProjectId,
	version: locizeVersion,
}

i18n
	.use(Backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		lng: browserLanguage,
		fallbackLng: "en",
		backend: locizeOptions,
		react: {
			useSuspense: false,
		},
		ns: [
			"languages",
			"common",
			"entities",
			"actions",
			"hints",
			"errors",
			"periods",
			"eventTypes",
			"status",
			"systemMessages",
			// label categories
			"statisticsLabels",
			"genericLabels",
			"drawerLabels",
			"formLabels",
			"filterLabels",
			"sidebarLabels",
			"configLabels",
			"historyLabels",
			"circularityLabels",
			"exportLabels",
			"importLabels",
			"propertyLabels",
			"rejectReasons",
		],
	})

export default i18n
