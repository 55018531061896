import { Page } from "components/Page"
import { ILinkEntryProps, LinksCard } from "components/pageCards/linksCard"
import { useTrans } from "translations"
import { TranslateFunction } from "translations/types"
import { InactiveTenantsCard } from "./components/inactiveTenantsCard"
import { MixedWastePerformance } from "./components/mixedWastePerformance"
import { PercentagePerformance } from "./components/percentagePerformance"
import { PieWasteContribution } from "./components/pieWasteContribution"
import { TenantNumberCard } from "./components/tenantNumberCard"
import { useTerminalsState } from "States/Terminals"
import { LoadingIndicator } from "Icons/loadingIndicator"

const deepDiveLinks = (t: TranslateFunction): ILinkEntryProps[] => [
	{
		heading: t("drawerLabels:tenantDetails"),
		description: t("hints:tenantDetailsInfo"),
		linkTo: `details`,
	},
	{
		heading: t("statisticsLabels:tenantWasteTypes"),
		description: t("hints:tenantWasteTypesInfo"),
		linkTo: `waste-types`,
	},
]

export const TenantDashboard = () => {
	const { t } = useTrans()
	const { currentTerminal } = useTerminalsState()

	return (
		<Page title="sidebarLabels:tenantFollowUp">
			<div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
				{!currentTerminal?.id ? (
					<div className="flex justify-center">
						<LoadingIndicator />
					</div>
				) : (
					<>
						<PercentagePerformance />
						<MixedWastePerformance />
						<PieWasteContribution />
						<TenantNumberCard />
						<LinksCard title="statisticsLabels:deepDive" links={deepDiveLinks(t)} />
						<InactiveTenantsCard />
					</>
				)}
			</div>
		</Page>
	)
}

export default TenantDashboard
