import { NoPage } from "Icons/NoPage"
import { Translate } from "translations"

export const NotFound = () => {
	return (
		<div className="fixed top-0 bottom-0 left-0 right-0 z-30 flex items-center justify-center bg-grey1">
			<h1 className="w-[920px] text-center font-signifier text-3xl">
				<div className="flex justify-center mb-5">
					<NoPage width={130} />
				</div>
				<div className="font-semibold mb-3">
					<Translate i18nKey={"errors:notFound"} />
				</div>
				<Translate
					i18nKey={"errors:notFoundDescription"}
					components={[
						// Anchor content is added by the translation
						// eslint-disable-next-line jsx-a11y/anchor-has-content
						<a href="mailto:support@carrot.tech" className="underline" />,
					]}
				/>
			</h1>
		</div>
	)
}

export default NotFound
