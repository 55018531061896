import { DocumentNode } from "@apollo/client"
import { postFunc } from "../Utils/api"
import { generateGQLrequest } from "../Utils/gqlRequestGenerator"
import { UpdateAccessPointPayload } from "../Utils/gqlRequestTypes/updateAccessPointsMutation"

export const updateAccessPoints = ({
	// parentId,
	accessPoints,
	refetchQueries,
	onStartCallBack,
	onFailCallBack,
}: {
	// parentId: string
	accessPoints: UpdateAccessPointPayload[]
	refetchQueries?: {
		query: DocumentNode
		variables?: {
			[key: string]: any
		}
	}[]
	onStartCallBack?: () => void
	onFailCallBack?: () => void
}) => {
	const UPDATE_ACCESS_POINTS = generateGQLrequest({
		type: "updateAccessPointsMutation",
		input: {
			wait: true,
			payload: accessPoints,
		},
	})
	postFunc(
		UPDATE_ACCESS_POINTS,
		() => {
			onStartCallBack && onStartCallBack()
		},
		{ refetchQueries: refetchQueries || [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
}
