import { Option } from "components/pageCards/filterSort/types"
import { orderBy } from "lodash"
import uniqWith from "lodash/uniqWith"
import { WasteTypeConfig } from "pages/configuration/useConfigService"
import { CustomersResponse, WasteType } from "Utils/api/datawarehouse/responseTypes"
import { FetchedWasteTypes } from "Utils/api/sanity/types"

export const getWasteTypesOptions = (
	currentPeriod: CustomersResponse | undefined,
	wasteTypeNames: FetchedWasteTypes | null,
	wasteTypeConfig: WasteTypeConfig[]
): Option[] => {
	const datawarehouseWasteTypes =
		uniqWith(
			currentPeriod?.customers.flatMap(t => t.wasteTypes),
			(a: WasteType, b: WasteType) => a.code === b.code
		) || []

	const getWasteTypeName = (wasteTypeId: string) =>
		wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteTypeId)?.name ||
		wasteTypeNames?.find(wtn => wtn.id === wasteTypeId)?.name ||
		""

	const availableWasteTypes = datawarehouseWasteTypes
		.filter(({ code }) => !!getWasteTypeName(code))
		.map(({ code }) => ({
			option: getWasteTypeName(code),
			value: code,
			translate: false,
		}))

	const sortedWasteTypes = orderBy(availableWasteTypes, "option")

	const allWasteTypes = {
		option: "filterLabels:allWasteTypes",
		value: "all",
		translate: true,
	}

	return [allWasteTypes, ...sortedWasteTypes]
}
