import { FC } from "react"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { Button } from "components/button/index"
import { useModal } from "Contexts"
import { DeleteTenantModal } from "pages/customer/manage/components/DeleteTenant"

interface CustomerAdminCardProps {
	customerId: string
	customerName?: string
}

export const CustomerAdminCard: FC<CustomerAdminCardProps> = ({
	customerId,
	customerName = "",
}) => {
	const { showModal } = useModal()

	const openDeletionModal = () => {
		showModal(<DeleteTenantModal {...{ customerId, customerName }} />)
	}

	return (
		<Card title={"drawerLabels:admin"} variant={CardComponentVariant.DRAWER}>
			<Button
				label={"actions:deleteCustomer"}
				color="black"
				className="float-right"
				onClick={openDeletionModal}
			/>
		</Card>
	)
}
