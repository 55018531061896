import { MapSheetsAndColumns } from "components/ImportComponents/components/mappingStep/MapSheetsAndColumns"
import { DownloadTemplates } from "components/ImportComponents/components/DownloadTemplates"
import { UploadFile } from "components/ImportComponents/components/uploadStep/UploadFile"
import { IImportContext } from "components/ImportComponents/ImportContext"
import { ImportStep } from "components/ImportComponents/types"
import { ValidateImportedData } from "components/ImportComponents/components/validatingStep/ValidateImportedData"
import { sum } from "lodash"
import { AddingToSystem } from "components/ImportComponents/components/AddingToSystem"

const validateMapping = ({ mappingState, selectedTemplate, selectedParentIds }: IImportContext) => {
	const mappingSections = selectedTemplate?.mappingSections
	const preselectedColumns = mappingSections?.filter(s => s.isPreSelected)

	const allPreselectedHaveValue = preselectedColumns?.every(c => selectedParentIds[c.key])

	const sectionsWithSheetSelect = mappingSections?.filter(
		s => (!s.isPreSelected && !s.isOptional) || s.isOptional
	)

	const allApplicableHaveSheetSelected = sectionsWithSheetSelect?.every(s => {
		const state = mappingState[s.key]

		return (
			(!s.isOptional && state?.sheetId) ||
			(s.isOptional && !state?.selected) ||
			(s.isOptional && state?.selected && state?.sheetId)
		)
	})

	const allRequiredFieldsSelectedStatuses: boolean[] = []
	mappingSections?.forEach(s => {
		const state = mappingState[s.key]

		if ((state?.selected || !s.isOptional) && state?.columnSelections) {
			s.columns?.forEach(c => {
				if (c.isRequired || c.isRequiredFunction?.(mappingState)) {
					allRequiredFieldsSelectedStatuses.push(Boolean(state?.columnSelections[c.key]))
				}
			})
		}
	})

	const allRequiredFieldsSelected = allRequiredFieldsSelectedStatuses.every(Boolean)

	return (
		Boolean(allPreselectedHaveValue) &&
		Boolean(allApplicableHaveSheetSelected) &&
		Boolean(allRequiredFieldsSelected)
	)
}

const checkValidationErrors = ({
	selectedTemplate,
	mappingState,
	validationState,
}: IImportContext) => {
	const validatingSections = selectedTemplate?.mappingSections?.filter(
		s => !s.isOptional || (s.isOptional && mappingState?.[s.key]?.selected)
	)

	let totalErrors = 0
	validatingSections?.forEach(s => {
		const tabData = validationState?.[s.key]
		const nrErrors = sum(
			Object.keys(tabData || {}).map(key => tabData?.[key].filter(el => el.error).length)
		)

		totalErrors += nrErrors
	})

	return totalErrors === 0
}

export const DOWNLOAD_KEY = "downloadTemplate"
export const UPLOAD_KEY = "uploadFile"
export const MAP_KEY = "mapSheets"
export const VALIDATE_KEY = "validateFile"
export const ADDING_KEY = "addToSystem"

export const DOWNLOAD_STEP = {
	key: `${DOWNLOAD_KEY}-infrastructure`,
	helpSectionKey: `${DOWNLOAD_KEY}-infrastructure`,
	view: () => <DownloadTemplates />,
}

export const UPLOAD_STEP = {
	key: UPLOAD_KEY,
	helpSectionKey: `${UPLOAD_KEY}-infrastructure`,
	view: () => <UploadFile />,
	hasValidation: true,
	validate: ({ uploadedFileName }: IImportContext) => {
		return !!uploadedFileName
	},
}

export const MAPPING_STEP = {
	key: MAP_KEY,
	helpSectionKey: `${MAP_KEY}-infrastructure`,
	view: () => <MapSheetsAndColumns />,
	hasValidation: true,
	validate: validateMapping,
}

export const VALIDATE_STEP = {
	key: VALIDATE_KEY,
	helpSectionKey: `${VALIDATE_KEY}-infrastructure`,
	view: () => <ValidateImportedData />,
	hasValidation: true,
	continueButtonText: "importLabels:addToSystem",
	validate: checkValidationErrors,
	shouldAddToSystem: true,
}

export const ADDING_STEP = {
	key: ADDING_KEY,
	helpSectionKey: `${ADDING_KEY}-infrastructure`,
	view: () => <AddingToSystem />,
	hideDescription: true,
	hideButtons: true,
}

export const IMPORT_STEPS: ImportStep[] = [
	DOWNLOAD_STEP,
	UPLOAD_STEP,
	MAPPING_STEP,
	VALIDATE_STEP,
	ADDING_STEP,
]
