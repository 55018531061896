import { ImportContext } from "components/ImportComponents/ImportContext"
import { MappingSection, MappingState, ValidationState } from "components/ImportComponents/types"
import { Toggle } from "components/Toggle"
import { cloneDeep, get, set } from "lodash"
import React, { useCallback, useContext } from "react"
import { useTrans } from "translations"

type Props = {
	section: MappingSection
}

export const MappingHeader: React.FC<Props> = ({ section }: Props) => {
	const { t } = useTrans()
	const { selectedTemplate, mappingState, setMappingState, validationState, setValidationState } =
		useContext(ImportContext)

	const onSwitchChange = useCallback(
		(key: string) => {
			const templateMappingState = selectedTemplate?.mappingSections.find(el => el.key === key)
			const newSelectedValue = !mappingState[key]?.selected

			const newState: MappingState = cloneDeep(mappingState)
			set(newState, `${key}.selected`, newSelectedValue)

			const newValidationState: ValidationState = cloneDeep(validationState)
			set(newValidationState, `${key}`, null)

			if (!newSelectedValue && templateMappingState?.dependents?.length) {
				templateMappingState.dependents.forEach(dep => {
					set(newState, `${dep}.selected`, false)
					set(newValidationState, dep, null)
				})
			}

			const parentSelected = get(newState, `${templateMappingState?.dependsOn}.selected`, false)
			if (newSelectedValue && !parentSelected) {
				set(newState, `${templateMappingState?.dependsOn}.selected`, true)
			}

			setMappingState(newState)
			setValidationState(newValidationState)
		},
		[mappingState, setMappingState, selectedTemplate, validationState, setValidationState]
	)

	return (
		<div className="flex justify-between mb-6">
			<div className="text-lg font-medium">{t(section.title)}</div>
			{section.isOptional && (
				<div className="flex items-center">
					<div className="mr-2 text-sm font-normal">{t("importLabels:include")}</div>
					<Toggle
						checked={!!mappingState[section.key]?.selected}
						onChange={() => onSwitchChange(section.key)}
					/>
				</div>
			)}
		</div>
	)
}
