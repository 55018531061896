import { textView, ColumnType } from "./functions"

export const inactiveTenantsColumns: ColumnType[] = [
	{
		label: "entities:customer",
		name: "customerName",
		getView: textView,
	},
	{
		label: "statisticsLabels:previousRegistrations",
		name: "previousRegistrations",
		getView: textView,
	},
]
