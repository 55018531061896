import { Button } from "components/button"
import { DiscardModal } from "components/modalContainer"
import { useModal } from "Contexts"
import { DOWNLOAD_KEY } from "pages/infrastructure/import/importSteps"
import React, { useCallback, useContext, useMemo } from "react"
import { useTrans } from "translations"
import { getActiveStepIndex } from "../functions"
import { ImportContext } from "../ImportContext"

export const MassImportButtons: React.FC = () => {
	const { t } = useTrans()
	const context = useContext(ImportContext)
	const { showModal, hideModal } = useModal()
	const {
		steps,
		activeStep,
		setActiveStep,
		addToSystem,
		reset,
		onCancel,
		setSelectedParentIds,
		setSelectedTemplate,
	} = context

	const activeStepIndex = useMemo(() => getActiveStepIndex(steps, activeStep), [steps, activeStep])

	const onCancelActions = useCallback(() => {
		setSelectedTemplate(undefined)
		setSelectedParentIds({})
		reset()
		onCancel()
		hideModal()
	}, [setSelectedTemplate, setSelectedParentIds, reset, onCancel, hideModal])

	const onCancelClick = useCallback(() => {
		if (activeStep.key.includes(DOWNLOAD_KEY)) {
			onCancelActions()
		} else {
			showModal(<DiscardModal onCancel={hideModal} onConfirm={onCancelActions} />)
		}
	}, [activeStep, showModal, onCancelActions, hideModal])

	const onBackButtonClick = useCallback(() => {
		const previousStep = steps[activeStepIndex - 1]

		if (previousStep) {
			setActiveStep(previousStep)
		}
	}, [steps, activeStepIndex, setActiveStep])

	const onContinueClick = useCallback(() => {
		if (activeStep.shouldAddToSystem) {
			addToSystem(context)
		}

		const nextStep = steps[activeStepIndex + 1]

		if (nextStep) {
			setActiveStep(nextStep)
		}
	}, [activeStep, steps, activeStepIndex, setActiveStep, addToSystem, context])

	const disabledContinue = useMemo(
		() => activeStep.hasValidation && !activeStep.validate?.(context),
		[activeStep, context]
	)

	const showBackButton = useMemo(() => activeStepIndex > 0, [activeStepIndex])

	return (
		<div className="w-full h-fit flex-row items-baseline bg-white mt-[2px] justify-between items-center px-6 py-4">
			<Button label="actions:cancel" type="button" color="secondary" onClick={onCancelClick} />
			<div className="gap-2 flex items-center">
				{disabledContinue && (
					<div className="text-grey6 text-sm mr-4">
						{t("importLabels:continueButtonDisabledMessage")}
					</div>
				)}
				{showBackButton && (
					<Button
						label="actions:back"
						type="button"
						color="secondary"
						className="mr-4"
						onClick={onBackButtonClick}
					/>
				)}
				<Button
					label={activeStep.continueButtonText || "actions:continue"}
					type="button"
					color="primary"
					disabled={disabledContinue}
					onClick={onContinueClick}
				/>
			</div>
		</div>
	)
}
