import classNames from "classnames"
import { diff } from "deep-object-diff"
import Fuse from "fuse.js"
import { ArrowIcon } from "Icons/Arrow"
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTrans } from "translations"
import { Spinner } from ".."
import { SearchIcon } from "../../Icons/SearchIcon"
import { useContextMenu } from "../ContextMenu"
import { twMerge } from "tailwind-merge"
import "./styles.css"
import { IconWarning } from "Icons/Warning"
import { Tooltip } from "react-tooltip"
import { v4 } from "uuid"
import { IconError } from "Icons/Error"

function createFuse(options: any) {
	return new Fuse(options, {
		shouldSort: true,
		threshold: 0.3,
		location: 0,
		distance: 200,
		minMatchCharLength: 1,
		keys: ["option"],
	})
}

export type IInputOption = {
	option: string
	value: string
	translate?: boolean
	externalId?: string
}

/** A copy of Select component. Restyled and extended for slush. Should replace Select in the future */
export const Input: FC<{
	className?: string
	inputClassName?: string
	data: IInputOption[]
	placeholder?: string
	onChange?: (option: IInputOption) => any
	onInputChange?: (value: string) => any
	onFocus?: () => any
	onBlur?: () => any
	loading?: boolean
	searchIcon?: boolean
	dropdownIcon?: boolean
	initialValue?: string
	disabled?: boolean
	allowInput?: boolean
	/** Experimental feature */
	useContextDropdown?: boolean
	dropdownStyle?: React.CSSProperties
	error?: boolean
	errorMessage?: string
	errorInTooltip?: boolean
	dontShowNoResults?: boolean
	fontSize?: number | string
	noInitialOption?: boolean
	/** In some cases input is not focused by default */
	focusInputOnRender?: boolean
	/** This will prevent blur on change */
	ignoreBlurOnSelect?: boolean
	/** When true, will prevent onChange on render */
	ignoreOnRenderChange?: boolean
	/** Provide this property to calculate dropdown opening direction */
	parentElementRef?: React.MutableRefObject<HTMLDivElement | null>
	warning?: string
}> = ({
	className,
	inputClassName,
	data,
	placeholder,
	onChange = () => null,
	onInputChange,
	onFocus,
	onBlur,
	loading,
	searchIcon,
	dropdownIcon,
	initialValue,
	disabled,
	allowInput,
	useContextDropdown,
	dropdownStyle,
	error,
	errorMessage,
	errorInTooltip,
	dontShowNoResults,
	fontSize,
	noInitialOption,
	focusInputOnRender,
	ignoreBlurOnSelect,
	ignoreOnRenderChange,
	parentElementRef,
	warning,
}) => {
	// find option based on initialValue else just render initialValue
	const initialOptionFromValue = useMemo(
		() =>
			data.find(({ option, value }) => (value || option) === initialValue) ||
			(initialValue ? { option: initialValue, value: initialValue } : data[0]),
		[data, initialValue]
	)

	const initialOption = useMemo(
		() => (data?.length && !noInitialOption ? initialOptionFromValue : { option: "", value: "" }),
		[data, noInitialOption, initialOptionFromValue]
	)

	const [activeOption, setActiveOption] = useState<IInputOption>(initialOption)
	const [searchValue, setSearchValue] = useState("")
	const [active, setActive] = useState(false)
	const [onSelectBoundries, setOnSelectBoundries] = useState(false)
	const [isDropdownDirUp, setIsDropdownDirUp] = useState(false)
	const [tooltipActive, setTooltipActive] = useState(false)
	const [searchOptions, setSearchOptions] = useState<IInputOption[]>(data)

	const inputRef = useRef<HTMLInputElement | null>(null)
	const dropdownRef = useRef<HTMLDivElement | null>(null)
	const wrapperRef = useRef<HTMLDivElement | null>(null)

	const { closeContextMenu, openContextMenuByPos } = useContextMenu()
	const { t } = useTrans()

	// Automatic dropdown opening direction handler
	const calculateDropdownDirection = useCallback(() => {
		if (!parentElementRef?.current || !dropdownRef.current || !wrapperRef.current) return
		const parentHeight = parentElementRef.current.clientHeight
		const dropdownHeight = dropdownRef.current.clientHeight
		const inputHeight = wrapperRef.current.clientHeight
		const parentPosition = parentElementRef.current.getBoundingClientRect()
		const thisPosition = wrapperRef.current.getBoundingClientRect()
		const inputPosition = thisPosition.y - parentPosition.y + inputHeight
		const isDropdownUp = inputPosition + dropdownHeight > parentHeight
		setIsDropdownDirUp(isDropdownUp)
	}, [parentElementRef])

	useEffect(() => {
		const parentElement = parentElementRef?.current
		parentElement?.addEventListener("scroll", calculateDropdownDirection)
		return () => parentElement?.removeEventListener("scroll", calculateDropdownDirection)
	}, [calculateDropdownDirection, parentElementRef])

	useEffect(() => {
		calculateDropdownDirection()
	}, [active, calculateDropdownDirection])

	const renderOption = useCallback(
		({ option, translate }: IInputOption) => {
			return translate ? t(option) : option
		},
		[t]
	)

	const fuzze = useMemo(() => {
		// Remove translate value so search results would not be double translated
		const preparedData = data.map(({ option, value, translate }) => {
			return { option: translate ? t(option) : option, value }
		})
		return createFuse(preparedData)
	}, [data, t])

	useEffect(() => {
		// required to make input active in some cases it is not by default
		if (focusInputOnRender) inputRef?.current?.focus()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (onInputChange) onInputChange(searchValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue])

	useEffect(() => {
		// update selected value when option list changes
		const isOptionDiff = !!Object.keys(diff(activeOption, initialOption)).length

		if (isOptionDiff) {
			setActiveOption(initialOption)
		}
		// eslint-disable-next-line
	}, [data, initialOption])

	const tooltipUUID = useMemo(() => v4(), [])

	const handleFocus = (e: React.FocusEvent) => {
		const { x, y, height } = e.target.getBoundingClientRect()
		useContextDropdown &&
			e &&
			openContextMenuByPos(
				{ x, y: y + height },
				data.map(o => ({
					label: o.option,
					fn: () => onChange && onChange(o),
				}))
			)
		setActive(true)
		calculateDropdownDirection()
		setOnSelectBoundries(true)
		if (!allowInput) setSearchValue("")
		if (onFocus) onFocus()
		if (!e.relatedTarget) setSearchOptions(data)
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		setSearchValue(value)
		setSearchOptions(fuzze.search(value).map(result => result.item) as IInputOption[])
	}

	const handleBlur = () => {
		if (!onSelectBoundries) {
			if (!allowInput) setSearchValue(activeOption.option)
			setActive(false)
		}
		if (onBlur && ignoreBlurOnSelect && !onSelectBoundries) onBlur()
		if (onBlur && !ignoreBlurOnSelect) onBlur()
	}

	const handleOptionClick = (option: IInputOption) => {
		setSearchValue(allowInput ? renderOption(option) : "")
		if (useContextDropdown) closeContextMenu()
		setActiveOption(option)
		onChange(option)
		setActive(false)
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Tab" && active) setActive(false)
	}

	return (
		<div className="relative" ref={wrapperRef}>
			<div
				className={twMerge([
					"w-full overflow-hidden",
					!className && "border-black stroke-black text-sm",
					errorMessage && "overflow-visible",
					className,
				])}
				onFocus={e => !disabled && handleFocus(e)}
				onBlur={() => handleBlur()}
				onMouseLeave={() => setOnSelectBoundries(false)}
				onKeyDown={onKeyDown}
			>
				<div className={twMerge("h-full flex-row items-center relative")}>
					<input
						className={twMerge([
							"w-full bg-transparent border border-1 border-black pl-3 pr-10 py-1 h-10 focus:outline focus:outline-2 focus:outline-offset-[-2px]",
							error && "border-carrotRed bg-lightPeach focus:outline-carrotRed",
							!inputClassName && "placeholder-grey4",
							inputClassName,
							warning &&
								"border-carrotOrange bg-yellow-100 focus:outline-carrotOrange disabled:border-carrotOrange",
						])}
						value={active || allowInput ? searchValue : renderOption(activeOption)}
						onChange={handleInputChange}
						placeholder={placeholder || renderOption(activeOption)}
						disabled={disabled}
						style={{ fontSize }}
						ref={inputRef}
					/>

					{error && errorInTooltip && (
						<div
							className="absolute right-9 stroke-none"
							data-tooltip-id={tooltipUUID}
							onMouseEnter={() => setTooltipActive(true)}
							onMouseLeave={() => setTooltipActive(false)}
						>
							<IconError
								width={12}
								height={12}
								data-tooltip-id={tooltipUUID}
								data-tooltip-content={errorMessage}
							/>
							<Tooltip
								id={tooltipUUID}
								content={errorMessage}
								opacity={1}
								style={{ width: 250, backgroundColor: "#FFAD9E", color: "#000" }}
								className="z-[10]"
								isOpen={active || tooltipActive}
							/>
						</div>
					)}

					<div
						className="right-3 cursor-pointer absolute"
						onClick={() => (!active ? inputRef?.current?.focus() : setActive(false))}
					>
						{loading ? (
							<Spinner height={20} />
						) : searchIcon ? (
							<SearchIcon height="16" width="16" opacity="0.3" />
						) : dropdownIcon ? (
							<ArrowIcon
								className={twMerge("h-3 w-3 stroke-inherit", active && "transform rotate-180")}
							/>
						) : null}
					</div>
				</div>
				{error && !errorInTooltip && (
					<div className="text-sm text-carrotRed font-normal">{errorMessage}</div>
				)}
				{active && !!data.length && !useContextDropdown && (
					<div
						className={classNames(
							"absolute z-50 mt-1 w-full gap-px overflow-auto border border-grey2 bg-grey2",
							isDropdownDirUp && "bottom-8",
							isDropdownDirUp && error && "bottom-12"
						)}
						style={dropdownStyle}
						onMouseEnter={() => setOnSelectBoundries(true)}
						ref={dropdownRef}
					>
						{!!searchOptions.length &&
							searchOptions.map((option, i) => {
								const isActiveOption =
									activeOption.value && option.value && activeOption.value === option.value
								return (
									<div
										key={`dropdown-option${i}`}
										className={classNames([
											"flex h-10 cursor-pointer items-center overflow-hidden text-ellipsis whitespace-nowrap border-b py-1 px-2 last:border-b-0 hover:bg-grey1",
											{
												"bg-grey1 font-medium": isActiveOption,
												"bg-white": !isActiveOption,
											},
										])}
										title={renderOption(option)}
										onClick={e => {
											handleOptionClick(option)
										}}
									>
										{renderOption(option)}
									</div>
								)
							})}
						{!searchOptions.length && !dontShowNoResults && (
							<div className="overflow-hidden text-ellipsis whitespace-nowrap border-b py-1 px-2 hover:bg-grey1">
								{t("errors:noResults")}
							</div>
						)}
					</div>
				)}
			</div>
			{!error && warning && (
				<div className="absolute right-9 top-[13px]" data-tooltip-id={tooltipUUID}>
					<IconWarning width={12} height={12} />
					<Tooltip
						id={tooltipUUID}
						content={warning}
						opacity={1}
						style={{ width: 400, backgroundColor: "#FFA14A", color: "#000" }}
						isOpen={active || tooltipActive}
					/>
				</div>
			)}
		</div>
	)
}
