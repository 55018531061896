export enum RealEstateCategory {
	Office = "officebuilding",
	ShoppingCenter = "shoppingcenter",
	Pecunia = "officebuilding_pecunia_notrack",
}

export const VALID_BUILDING_CATEGORIES = [
	RealEstateCategory.Office,
	RealEstateCategory.ShoppingCenter,
]
