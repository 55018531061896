import { CountryCode } from "libphonenumber-js"
import { getCountryCallingCode } from "react-phone-number-input"

export function addCountryCodeToPhone(phone: string | number, country: CountryCode = "NO"): string {
	if (phone.toString().length === 0) {
		return ""
	}
	if (phone.toString().startsWith("+")) {
		return phone.toString()
	}
	const callingCode = getCountryCallingCode(country)
	return `+${callingCode}${phone}`
}
