export const TERMINAL_KEY = "TERMINAL"
export const ACCESS_PARENT_KEY = "ACCESS_PARENT"
export const DEPOT_KEY = "DEPOT_KEY"
export const CONTAINER_KEY = "CONTAINER"

export const TERMINAL_NAME_KEY = "terminalName"
export const ACCESS_PARENT_NAME_KEY = "accessParentName"
export const DEPOT_NAME_KEY = "depotName"
export const CONTAINER_NAME_KEY = "containerName"
export const WASTE_TYPE_CLASSIFICATION_KEY = "wasteTypeClassification"
export const DISCONTINUED_VALUE = "discontinued"
