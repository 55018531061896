import { Card, CardComponentVariant } from "components/pageCards/card"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useTrans } from "translations"

export interface CustomerPrefilteringCardProps {
	customerId: string
}

export const CustomerPrefilteringCard: FC<CustomerPrefilteringCardProps> = ({ customerId }) => {
	const { t } = useTrans()

	return (
		<Card title={"drawerLabels:preFiltering"} variant={CardComponentVariant.DRAWER}>
			<p className="text black mb-4 font-dmSans text-sm">{t("hints:goToPrefilteredEvents")}</p>
			<div className="flex items-center">
				<Link
					to={{
						pathname: "/events",
						search: `?filters=${encodeURIComponent(JSON.stringify({ customerId }))}`,
					}}
					className="pr-9 font-dmSans text-base font-medium underline"
				>
					{t("actions:seeTheEvents")}
				</Link>
				<p className="font-dmSans text-sm text-black">{t("hints:tenantsLatestEvents")}</p>
			</div>
		</Card>
	)
}
