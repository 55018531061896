import React, { useState, useEffect } from "react"
import { get } from "lodash"
import InputSelect from "./InputSelect"
import "./SelectAccessPoint.css"

const priorities = [1, 2, 3].map(v => ({ label: v + "", value: v }))

function initPriority(includePriority: any, selected: any) {
	if (includePriority) {
		const pri = get(selected, "priority")
		if (pri) {
			return {
				label: pri + "",
				value: pri,
			}
		}
		return priorities[0]
	}
	return { value: 1 }
}

export type ISelectAccessPoint = {
	selected?: {
		id: string
		name: string
		priority?: number
		[key: string]: any
	}
	accessPoints: {
		id: string
		name: string
		[key: string]: any
	}[]
	includePriority?: boolean
	onSelect: (newValues: object) => void
}

const SelectAccessPoint = ({
	selected,
	accessPoints,
	includePriority,
	onSelect,
}: ISelectAccessPoint) => {
	const [accessPoint, setAccessPoint] = useState<
		| {
				id: string
				name: string
				priority?: number
				[key: string]: any
		  }
		| undefined
	>(selected)
	const [priority, setPriority] = useState<any>(initPriority(includePriority, selected))

	useEffect(() => {
		const name = get(accessPoint, "name")
		if (name && priority) {
			onSelect({
				...accessPoint,
				priority: priority.value,
			})
		}
	}, [accessPoint, priority, onSelect])

	return (
		<div className="select-access-point-container">
			<InputSelect
				placeholder="selectStation"
				label="name"
				defaultValue={selected}
				options={accessPoints}
				onSelect={setAccessPoint}
				optionsWidth={260}
				optionsHeight={90}
			/>
			{includePriority && (
				<InputSelect
					placeholder="priority"
					label="label"
					defaultValue={priority}
					options={priorities}
					onSelect={setPriority}
					optionsWidth={70}
					optionsHeight={90}
				/>
			)}
		</div>
	)
}

export default SelectAccessPoint
