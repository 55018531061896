import { isValidPhoneNumber as libphonenumberValidator } from "libphonenumber-js"

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
	// Check if phone number is valid in any of our supported countries
	return libphonenumberValidator(phoneNumber)
}

export const isValidPhoneNumberAllowEmpty = (phoneNumber: string): boolean => {
	if (!phoneNumber) {
		return true
	}

	return libphonenumberValidator(phoneNumber)
}
