import { FC, useMemo } from "react"
import { MultiListDrawer } from "../../multiListDrawer"
import { useMultiSelectContext } from "../../multiSelectContext"
import { MultipleContainerDrawer } from "./multipleContainerDrawer"
import { SingleContainerDrawer } from "./singleContainerDrawer"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"

export type ContainerDrawerProps = {
	accessParentId: string
	containerId: string
	depotId?: string | null
}

export const ContainerDrawer: FC<ContainerDrawerProps> = ({
	accessParentId,
	containerId,
	depotId,
}) => {
	const { isLoading, isError } = useAccessPoints({
		accessParentId,
	})
	const { selectedContainers } = useMultiSelectContext()

	const isMultiple = useMemo(() => selectedContainers.length > 1, [selectedContainers])

	if (!containerId && !isMultiple) return null

	return (
		<MultiListDrawer {...{ isLoading, isError }}>
			{isMultiple ? (
				<MultipleContainerDrawer />
			) : (
				<SingleContainerDrawer
					accessParentId={accessParentId}
					containerId={containerId}
					depotId={depotId}
				/>
			)}
		</MultiListDrawer>
	)
}
