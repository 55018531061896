import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useTrans } from "translations"

export const InvalidDeleteTerminalModal: React.FC = () => {
	const { t } = useTrans()
	const { hideModal } = useModal()

	return (
		<ModalContainer
			title={"actions:deleteTerminal"}
			onConfirm={hideModal}
			onCancel={hideModal}
			onConfirmText="common:OK"
			disableCancelButton
		>
			<div className="pt-3 whitespace-pre">
				<p>{t("hints:deleteTerminalWarning")}</p>
			</div>
		</ModalContainer>
	)
}
