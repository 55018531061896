import { AccessParentWithChildren } from "admin-client-server/src/coreApi/models/Common"
import { max } from "lodash"

export const accessParentsWithFillLevel = (accessParents: AccessParentWithChildren[]) =>
	accessParents.map(ap => {
		const fillLevels = ap.containers.map(el => el.fillLevel)
		const maxLevel = max(fillLevels)

		return {
			...ap,
			fillLevel: maxLevel,
		}
	})
