import React from "react"
import { useModal } from "../../Contexts"
import ModalContainer from "components/modalContainer"
import { CUSTOMER_HISTORY_TYPE } from "./constants"
import { CustomerHistory } from "./CustomerHistory"
import { PointHistory } from "./PointHistory"
import { HistoryProps } from "./types"

const HistoryModal = ({ id, type }: HistoryProps) => {
	const { hideModal } = useModal()

	return (
		<ModalContainer
			title={"historyLabels:history"}
			className="w-11/12 lg:w-3/5"
			disableConfirmButton
			disableCancelButton
			onCancel={hideModal}
		>
			{type === CUSTOMER_HISTORY_TYPE ? <CustomerHistory id={id} /> : <PointHistory id={id} />}
		</ModalContainer>
	)
}

export default HistoryModal
