import { SVGAttributes } from "react"

export const ArrowIcon = (props: SVGAttributes<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="16"
			height="9"
			viewBox="0 0 16 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="currentColor"
		>
			<path
				d="M1 1L8 8L15 1"
				strokeOpacity="0.65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
