import classNames from "classnames"
import { Controller } from "react-hook-form"
import PhoneNumberInput, { Country } from "react-phone-number-input"
import ErrorTooltip from "../ErrorTooltip"

type ViewProps = Omit<React.ComponentProps<typeof PhoneNumberInput>, "onChange">
type CustomProps = {
	label?: string
	patternError?: string
}

const countries: Country[] = ["NO", "SE", "GB", "DE"]

const PhoneInput = (props: ViewProps & CustomProps) => {
	const {
		id,
		control,
		name,
		label,
		minLength,
		patternError,
		regexPattern,
		required,
		validationFunction,
	} = props

	return (
		<Controller
			name={name}
			control={control}
			rules={{
				pattern: regexPattern,
				validate: validationFunction,
			}}
			render={({ field, fieldState: { error } }) => (
				<div className="flex flex-col">
					{label && (
						<label htmlFor={id} className="font-dmSans text-sm font-medium text-black empty:hidden">
							{label}
							{label && required && " *"}
						</label>
					)}
					<div className="relative flex-col">
						<PhoneNumberInput
							addInternationalOption
							className={classNames(
								"border border-px border-black px-2 font-dmSans text-sm disabled:border-grey3 disabled:text-grey6",
								"focus:outline focus:outline-2 focus:outline-offset-[-2px]",
								error && "border-1 border-carrotRed bg-lightPeach focus:outline-carrotRed"
							)}
							countries={countries}
							countryCallingCodeEditable
							countryOptionsOrder={countries}
							defaultCountry="NO"
							focusInputOnCountrySelection
							withCountryCallingCode
							international
							numberInputProps={{
								className: classNames("h-[38px] border-none", error && "bg-lightPeach"),
							}}
							{...field}
						/>
						<ErrorTooltip
							label={label}
							patternError={patternError}
							minLength={minLength}
							error={error}
						/>
					</div>
				</div>
			)}
		/>
	)
}

export default PhoneInput
