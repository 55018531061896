import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { useCommonEntitiesStore } from "States/commonEntities"
import { ContainerForm, ContainerFormData } from "../manage/containerForm"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { useTerminalsState } from "States/Terminals"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { ACCESS_POINT_STATUSES } from "Utils/gqlRequestTypes/generic"
import { addAccessPoint } from "api/addAccessPoint"
import { uniqueIdGenerator } from "Utils/uniqueIdGenerator"
import {
	ACCESS_POINT_WTCS_KEY,
	ACCESS_POINT_WTCS_ID_KEY,
	EXTERNAL_ID_KEY,
	ACCESS_POINT_FRACTION_KEY,
	ACCESS_POINT_FRACTION_DESC_KEY,
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_TYPE_KEY,
	ACCESS_POINT_STATUS_KEY,
	PROPERTIES_KEY,
	EXTERNAL_KEYS_KEY,
	ACCESS_POINT_WTCS_LEGACY_KEY,
} from "constants/general"
import { findClassificationSystemForWasteCode } from "Utils/api/sanity/functions"
import { useGlobalAlert } from "States/globalAlert"
import { useConfig } from "api/hooks/useConfig"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"
import { useAccessPoints } from "../manage/useAccessPoints"

export const AddContainerModal: React.FC<{
	accessParentId: string
	selectedDepotId?: string | null
}> = ({ accessParentId, selectedDepotId }) => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { getAccessParent } = useAccessParentsWithPoints()
	const { isLoadingAll, refetchAll } = useAccessPoints({ accessParentId })
	const { sendNewContainerSlackNotification } = useSlackNotifications()
	const { wasteTypes } = useCommonEntitiesStore()
	const {
		currentTerminal: { id: terminalId, wasteTypeClassificationSystemId },
	} = useTerminalsState()
	const { setGlobalAlert } = useGlobalAlert()
	const { config } = useConfig()

	const accessParent = getAccessParent(accessParentId)

	const formHandler = useForm<ContainerFormData>({
		defaultValues: {
			status: ACCESS_POINT_STATUSES[0],
			wasteTypeClassificationSystem:
				wasteTypeClassificationSystemId ?? config?.wasteTypeClassificationSystemId ?? undefined,
		},
	})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onConfirmDisabled = useMemo(() => !isEmpty(errors) || !isValid, [errors, isValid])

	if (!accessParentId || !accessParent) return null

	const onSubmitSingle = (containerFormData: ContainerFormData) => {
		const { containerName, wasteTypeClassificationSystem, wasteCode, status } = containerFormData

		const fractionDesc = wasteTypes?.find(({ id }) => id === wasteCode)?.name

		const properties = [
			{ key: ACCESS_POINT_WTCS_ID_KEY, value: wasteTypeClassificationSystem },
			{
				key: ACCESS_POINT_WTCS_LEGACY_KEY,
				value: wasteTypeClassificationSystem,
			},
			{ key: ACCESS_POINT_FRACTION_KEY, value: wasteCode },
			{ key: ACCESS_POINT_FRACTION_DESC_KEY, value: fractionDesc },
			{
				key: ACCESS_POINT_WTCS_KEY,
				value: findClassificationSystemForWasteCode(wasteTypes, wasteCode),
			},
		]

		addAccessPoint({
			accessPoint: {
				[ACCESS_POINT_NAME_KEY]: containerName,
				[ACCESS_POINT_TYPE_KEY]: "ACCESS_POINT",
				[ACCESS_POINT_STATUS_KEY]: status,
				[PROPERTIES_KEY]: properties,
				parent: {
					id: selectedDepotId || accessParentId,
				},
				[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: uniqueIdGenerator("uuid") }],
			},
			onStartCallBack: async res => {
				await refetchAll()

				setGlobalAlert({
					type: "success",
					message: "systemMessages:containerAdded",
				})
				const url = getInfrastructureUrl({
					terminalId,
					accessParentId: accessParent.id,
					containerId: res.modifiedPoint.id,
				})
				navigate(url)

				sendNewContainerSlackNotification(
					containerName,
					selectedDepotId || accessParentId || "",
					res.modifiedPoint.id || ""
				)
				hideModal()
			},
		})
	}

	const onSubmit = handleSubmit(containerFormData => {
		onSubmitSingle(containerFormData)
	})

	return (
		<ModalContainer
			title="actions:newContainer"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingAll}
			onConfirmDisabled={onConfirmDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<div className="flex-col gap-4">
				<ContainerForm {...{ formHandler, onSubmit }} />
			</div>
		</ModalContainer>
	)
}
