import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { TerminalForm, TerminalFormData } from "./TerminalForm"
import { useTerminals } from "api/hooks/useTerminals"
import { addAccessPoint } from "api/addAccessPoint"
import { useNavigate } from "react-router-dom"
import { terminalIdGenerator } from "Utils/uniqueIdGenerator"
import {
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_TYPE_KEY,
	EXTERNAL_ID_KEY,
	EXTERNAL_KEYS_KEY,
} from "constants/general"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useGlobalAlert } from "States/globalAlert"

export const CreateTerminalModal = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { refetchTerminals, isLoadingTerminals } = useTerminals()
	const { setGlobalAlert } = useGlobalAlert()

	const formHandler = useForm<TerminalFormData>({})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onSuccess = (id: string) => {
		refetchTerminals().then(() => {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:terminalAdded",
			})
			hideModal()

			const url = getInfrastructureUrl({
				terminalId: id,
			})
			navigate(url)
		})
	}

	const setupTerminal = (terminalFormData: TerminalFormData) => {
		addAccessPoint({
			accessPoint: {
				[ACCESS_POINT_NAME_KEY]: terminalFormData.name,
				[ACCESS_POINT_TYPE_KEY]: "TERMINAL",
				[EXTERNAL_KEYS_KEY]: [
					{ key: EXTERNAL_ID_KEY, value: terminalIdGenerator(terminalFormData.name) },
				],
			},
			onStartCallBack: res => {
				onSuccess(res.modifiedPoint.id)
			},
		})
	}

	const onSubmit = handleSubmit(terminalFormData => {
		setupTerminal(terminalFormData)
	})

	return (
		<ModalContainer
			title="actions:createTerminal"
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoadingTerminals}
			onCancel={hideModal}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<TerminalForm formHandler={formHandler} />
		</ModalContainer>
	)
}
