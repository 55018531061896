import { ResponsiveBar } from "@nivo/bar"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import omit from "lodash/omit"
import moment from "moment"
import { COLORS, getNewColor } from "pages/customer/dashboard/functions/pieChartData"
import React, { useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { formatNumberForTable } from "Utils/formatFunctions"
import { getUniqKeys } from "Utils/getUniqKeys"
import { DevelopmentInWasteTypesDataInterface } from "../../overview.interface"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { EmptyView } from "components/EmptyView/EmptyView"
import { useConfig } from "api/hooks/useConfig"
import { TerminalAggregatesPeriods, TerminalPeriods } from "Utils/api/datawarehouse/types"
import {
	TerminalAggregatesPeriodsResponse,
	TerminalPeriodsResponse,
	WasteType,
} from "Utils/api/datawarehouse/responseTypes"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import { FilterSort } from "components/pageCards/filterSort/types"
import {
	FilterTimeFrameValue,
	createTimeFilters,
} from "components/pageCards/filterSort/filterCreators"
import { useConfigService } from "pages/configuration/useConfigService"
import { capitalize, uniq } from "lodash"
import { usePdfPreview } from "pages/home/pdfPreview"
import { useHomeLoadingState } from "pages/home/loadingState"

export const getPeriodicity = (currentStartTime: string) => {
	if (currentStartTime === getTimeFrame(FilterTimeFrameValue.LAST_6_FULL_MONTHS).startTimeISO) {
		return "last6CompletedMonths"
	}
	if (currentStartTime === getTimeFrame(FilterTimeFrameValue.LAST_FULL_YEAR).startTimeISO) {
		return "last12CompletedMonths"
	}

	return "last6CompletedMonths"
}

const defaultPeriod = FilterTimeFrameValue.LAST_6_FULL_MONTHS

export const DevelopmentInWasteTypes: React.FC<{}> = () => {
	const [filters, setFilters] = useState<string[]>([])
	const { t, language } = useTrans()
	const { wasteTypes } = useCommonEntitiesStore()
	const { currentTerminal } = useTerminalsState()
	const { isMWM, config } = useConfig()
	const { pdfPreviewOpen } = usePdfPreview()

	const { wasteTypeConfig, terminalConfig } = useConfigService()
	const { setDevelopmentLoading } = useHomeLoadingState()

	const [{ period: [selectedPeriod] = [] }, setActiveOptions] = useState<ActiveOptions<"period">>(
		{} as any
	)

	const timeFilters: FilterSort[] = useMemo(
		() => [
			createTimeFilters({ defaultValue: defaultPeriod }, [
				FilterTimeFrameValue.LAST_6_FULL_MONTHS,
				FilterTimeFrameValue.LAST_FULL_YEAR,
			]),
		],
		[]
	)

	const requestParams = useMemo(() => {
		const periodicity: "last6CompletedMonths" | "last12CompletedMonths" = getPeriodicity(
			getTimeFrame((selectedPeriod?.value as FilterTimeFrameValue) ?? defaultPeriod).startTimeISO
		)

		const filters = {
			periodicity,
		}

		const props: TerminalAggregatesPeriods | TerminalPeriods = isMWM
			? {
					endpoint: "terminal-aggregates-periods",
					filters,
				}
			: {
					endpoint: "terminal/periods",
					terminalId: currentTerminal.id,
					filters,
				}

		return props
	}, [isMWM, selectedPeriod, currentTerminal])

	const { data: _data, isError, isLoading } = useDataWareHouse(requestParams)

	useEffect(() => {
		setDevelopmentLoading(isLoading)
	}, [setDevelopmentLoading, isLoading])

	const data: DevelopmentInWasteTypesDataInterface[] = useMemo(() => {
		if (!_data) return []

		const { periods } = isMWM
			? (_data as TerminalAggregatesPeriodsResponse)
			: (_data as TerminalPeriodsResponse)

		return (
			periods
				?.map(({ terminal, terminalAggregates, startTime }: any) => {
					const data = isMWM ? terminalAggregates : terminal
					const parsedWasteTypes = data.wasteTypes?.reduce(
						(
							prev: any,
							{
								share: shareValue,
								code: wasteCode,
								weight = {} as any,
								occurrences,
								occurrenceBasedShare,
							}: WasteType
						) => {
							const share = isMWM ? occurrenceBasedShare : shareValue
							if (share < 0.05)
								return { ...prev, other: prev.other + (isMWM ? occurrences : weight.quantity) }
							const wasteType = wasteTypes?.find(w => w.id === wasteCode)

							const customName = wasteTypeConfig?.find(wtc => wtc.wasteTypeCode === wasteCode)?.name
							const wasteTypeName = customName || wasteType?.name || wasteCode
							return { ...prev, [wasteTypeName]: isMWM ? occurrences : weight.quantity }
						},
						{ other: 0 }
					)

					const { other = "", ...rest } = parsedWasteTypes || {}
					const month = capitalize(moment(startTime).locale(language).format("MMM"))
					return { month, ...rest, other }
				})
				// translate "other" key
				.map(({ other, ...rest }: any) => ({
					...rest,
					[t("circularityLabels:otherWasteTypes")]: other,
				})) ?? []
		)
	}, [isMWM, _data, wasteTypes, language, t, wasteTypeConfig])

	const wasteCodes = useMemo(() => {
		if (!_data) return []

		const { periods } = isMWM
			? (_data as TerminalAggregatesPeriodsResponse)
			: (_data as TerminalPeriodsResponse)

		const types = uniq(
			periods?.flatMap(({ terminal, terminalAggregates }: any) => {
				const data = isMWM ? terminalAggregates : terminal
				return data.wasteTypes?.map(({ code }: WasteType) => code) ?? []
			})
		)

		return types.map((wasteCode: string) => ({
			id: wasteCode,
			name: wasteTypes?.find(w => w.id === wasteCode)?.name,
		}))
	}, [isMWM, _data, wasteTypes])

	let curColors = [...COLORS]

	const keysWithColor = getUniqKeys(data)
		.filter(item => item !== "month")
		.map(el => {
			const [color, newColors] = getNewColor(curColors)
			curColors = newColors

			if (el === t("circularityLabels:otherWasteTypes"))
				return [el, terminalConfig?.otherColor || config.otherColor || color]

			const wasteCode = wasteCodes?.find(w => w.name === el)?.id
			const customColor = wasteTypeConfig?.find(
				wtc => wtc.name === el || wtc.wasteTypeCode === wasteCode
			)?.color
			return [el, customColor || color]
		})

	const filteredData = data.map(singleBarData => omit(singleBarData, filters))

	const chart = useMemo(() => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center min-h-full h-full">
					<LoadingIndicator />
				</div>
			)
		}

		if (isError) {
			return <EmptyView type="failToFetch" className="min-h-[300px]" />
		}

		if (!_data.metadata.eventsExist) {
			return <EmptyView type="noData" className="min-h-[300px]" />
		} else if (data.length === 0) {
			return <EmptyView type="noDataCalculationPeriodUncomplete" className="min-h-[300px]" />
		}

		return (
			<div className="h-96">
				<div className="grid grid-cols-[1fr_1fr] items-center gap-8">
					<div className="h-[332px]">
						<ResponsiveBar
							data={filteredData}
							keys={keysWithColor.map(el => el[0])}
							indexBy="month"
							isInteractive={true}
							enableGridY={false}
							enableGridX={false}
							axisLeft={null}
							margin={{ top: 0, right: 0, bottom: 25, left: 0 }}
							innerPadding={1}
							labelSkipHeight={10}
							axisBottom={{
								tickSize: 0,
								tickPadding: 8,
								tickRotation: 0,
							}}
							enableLabel={false}
							valueFormat={value =>
								isMWM
									? `${formatNumberForTable(value)} ${t("statisticsLabels:throws").toLowerCase()}`
									: formatNumberForTable(value, { suffix: " kg" })
							}
							colors={({ id }) => {
								const [, color] = keysWithColor.find(el => el[0] === id) ?? []
								return color ?? "#ccc"
							}}
						/>
					</div>
					<div>
						{!pdfPreviewOpen && (
							<h6 className="mb-2.5 font-dmSans font-semibold text-black">
								{t("hints:clickToSelectOrUnselectWasteTypes")}
							</h6>
						)}
						<ul>
							{keysWithColor.reverse().map(([key, color]) => (
								<li key={key} className="mb-2.5 flex items-center last:mb-0">
									<div className="relative h-4 w-4">
										{pdfPreviewOpen ? (
											<div
												className="h-4 w-4 rounded-full absolute"
												style={{ backgroundColor: color }}
											/>
										) : (
											<input
												id={`developmentInWasteTypes-${key}`}
												type="checkbox"
												data-html2canvas-ignore="true"
												className="h-4 w-4 rounded-none text-red-600 accent-[#000] absolute"
												style={{ accentColor: color }}
												onChange={() =>
													setFilters(prevState =>
														prevState.includes(key)
															? prevState.filter(option => option !== key)
															: [...prevState, key]
													)
												}
												defaultChecked
											/>
										)}
									</div>
									<label htmlFor={`developmentInWasteTypes-${key}`} className="ml-2 text-xs">
										{key}
									</label>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		)
	}, [
		filteredData,
		isError,
		isLoading,
		keysWithColor,
		t,
		data.length,
		isMWM,
		pdfPreviewOpen,
		_data,
	])

	return (
		<Card
			title={"circularityLabels:developmentInWasteTypes"}
			filters={timeFilters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{chart}
		</Card>
	)
}

export default DevelopmentInWasteTypes
