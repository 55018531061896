import React, { useContext, useEffect, useMemo, useState } from "react"
import { Translate, useTrans } from "translations"
import { ImportContext } from "../ImportContext"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { CheckCircleIcon } from "Icons/CheckCircle"
import { Button } from "components/button"
import { SuccessTextProps } from "../types"

export const AddingToSystem = () => {
	const { t } = useTrans()
	const context = useContext(ImportContext)

	const { loadingState, validationState, selectedTemplate, addToSystem } = context

	const [counts, setCounts] = useState<any>([])
	const [successText, setSuccessText] = useState<SuccessTextProps>({
		addedText: "",
		findResultsText: "",
		findResultsLink: "",
	})

	// doing it like this need to preserve counts after data wipe on successful upload
	useEffect(() => {
		if (Object.keys(validationState).length) {
			const values = Object.keys(validationState)
				.filter(key => validationState[key])
				.map(key => {
					const section = selectedTemplate?.mappingSections.find(section => section.key === key)

					if (section?.hideCount) return <></>

					const label = section?.title
					const firstColumn = Object.keys(validationState[key]!)[0]
					const count = validationState[key]![firstColumn]?.length

					return (
						<div className="text-base font-normal mb-2 text-center" key={label}>
							<span className="font-medium">{t(label || "")}:</span> {count}
						</div>
					)
				})
			setCounts(values)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validationState])

	useEffect(() => {
		if (selectedTemplate) {
			setSuccessText({
				addedText: selectedTemplate.addedText,
				findResultsText: selectedTemplate.findResultsText,
				findResultsLink: selectedTemplate.findResultsLink,
			})
		}
	}, [selectedTemplate])

	const loadingView = useMemo(() => {
		if (loadingState.importing) {
			return (
				<div className="flex flex-col items-center justify-center h-full">
					<LoadingIndicator />
					<div className="text-2xl font-medium mt-6">{t(loadingState.importing)}</div>
				</div>
			)
		}

		if (loadingState.error) {
			return (
				<div className="flex flex-col items-center justify-center h-full">
					<div className="text-2xl text-carrotRed font-medium">
						{t("importLabels:importFailed")}
					</div>
					<div className="mt-8 text-base">{t("importLabels:tryAgain")}</div>

					<Button
						label="actions:tryAgain"
						type="button"
						color="primary"
						onClick={() => addToSystem(context)}
						className="my-8"
					/>

					<div>
						<Translate
							i18nKey={"importLabels:contactSupport"}
							components={[
								// Anchor content is added by the translation
								// eslint-disable-next-line jsx-a11y/anchor-has-content
								<a href="mailto:support@carrot.tech" className="underline" />,
							]}
						/>
					</div>
				</div>
			)
		}

		return (
			<div className="flex flex-col items-center justify-center h-5/6">
				<CheckCircleIcon width={32} height={32} />
				<div className="mt-4 text-carrotGreen text-2xl font-medium">
					{t("importLabels:wellDone")}
				</div>
				<div className="text-base font-normal mt-8">{t(successText.addedText)}</div>

				<div className="mt-8">{counts}</div>

				<div className="mt-8 text-base font-normal whitespace-pre-wrap max-w-[650px]">
					<Translate
						i18nKey={successText.findResultsText}
						components={[
							// eslint-disable-next-line
							<a href={successText.findResultsLink} className="underline" />,
						]}
					/>
				</div>
			</div>
		)
	}, [loadingState, t, addToSystem, context, counts, successText])

	return loadingView
}
