import { FilterTimeFrameValue } from "components/pageCards/filterSort/filterCreators"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { INumberEntryProps, NumberCard } from "components/pageCards/numberCard"
import { getWastetypesInUse } from "pages/circularity/overview/components/WasteTypesInUse/functions"
import { useAccessParents } from "pages/infrastructure/functions"
import { useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"

export const InfrastructureRealEstate = () => {
	const { currentTerminal } = useTerminalsState()
	const { t } = useTrans()
	const { manageAccessParentPageData: accessParentsData } = useAccessParents()

	const [timeFrame] = useState(getTimeFrame(FilterTimeFrameValue.LAST_30_DAYS))
	const { data: dwhData, isLoading } = useDataWareHouse({
		endpoint: "terminal",
		terminalId: currentTerminal.id,
		filters: timeFrame,
	})
	const { wasteTypes: sanityWasteTypes } = useCommonEntitiesStore()
	const containers = !!accessParentsData?.length
		? accessParentsData.reduce(
				(previousValue, currentValue) => previousValue + currentValue.containers.length,
				0
			)
		: 0

	const wasteTypesInUse = useMemo(
		() =>
			sanityWasteTypes && dwhData
				? getWastetypesInUse(dwhData, accessParentsData, sanityWasteTypes)
				: [],
		[dwhData, sanityWasteTypes, accessParentsData]
	)

	const numbers: INumberEntryProps[] = [
		{ heading: t("entities:accessParents"), data: accessParentsData!.length | 0 },
		{ heading: t("entities:containers"), data: containers | 0 },
		{ heading: t("statisticsLabels:wasteCategories"), data: wasteTypesInUse.length | 0 },
	]
	return (
		<NumberCard
			title="statisticsLabels:infrastructureSummary"
			numbers={numbers}
			isLoading={isLoading}
		/>
	)
}
