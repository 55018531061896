export const tooltip1ByWasteType = [
	"-2",
	"1111",
	"1128",
	"1131",
	"1141",
	"1149",
	"1221",
	"1231",
	"1299",
	"1322",
	"1452",
	"1599",
	// removed tooltip fromt his one
	// "1618",
	"1711",
	"1712",
	"1722",
	"1799",
	"1911",
	"7051",
	"7055",
	"7086",
	"7093",
]

export const tooltip2ByWasteType: string[] = ["1131"]
