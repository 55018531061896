import { trpc } from "Utils/trpc"
import React, { useMemo } from "react"
import { getHistoryEntries } from "./functions"
import { HistoryTable } from "./HistoryTable"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { WithId } from "./types"

export const PointHistory: React.FC<WithId> = ({ id }) => {
	const { data: history, isLoading } = trpc.history.getPointHistory.useQuery(
		{ id },
		{ cacheTime: 0 }
	)
	const historyEntries = useMemo(() => getHistoryEntries(history), [history])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-full h-full">
				<LoadingIndicator />
			</div>
		)
	}

	return <HistoryTable entries={historyEntries} />
}
