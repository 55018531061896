import { Page } from "components/Page"
import { OverviewKeyNumbers } from "pages/circularity/overview/components/overviewKeyNumbers"
import { WasteTypesInUseCard } from "pages/circularity/overview/components/WasteTypesInUse"
import { ComparisonWastePerTenant } from "pages/circularity/overview/components/comparisonWastePerTenant"
import DevelopmentInWasteTypes from "pages/circularity/overview/components/DevelopmentInWasteTypes"
import { RecyclingRateOverTime } from "pages/circularity/overview/components/recyclingRateOverTime"
import { WasteTypeDistribution } from "pages/circularity/overview/components/wasteTypeDistribution"
import { PieWasteContribution } from "pages/customer/dashboard/components/pieWasteContribution"
import { ComparisonWasteTypeDistribution } from "pages/circularity/overview/components/comparisonWasteTypeDistribution"
import { InfrastructureSummary } from "pages/circularity/overview/components/InfrastructureSummary"
import { useConfig } from "api/hooks/useConfig"
import { useTerminalsState } from "States/Terminals"
import NoTerminals from "pages/error/NoTerminals"
import { useCallback, useMemo } from "react"
import { trpc } from "Utils/trpc"
import DashboardTileType from "admin-client-server/src/utils/dashboardTileType"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useTrans } from "translations"
//@ts-ignore
import html2pdf from "html2pdf.js"
import moment from "moment"
import { PdfPreview, usePdfPreview } from "./pdfPreview"
import { useHomeLoadingState } from "./loadingState"
import { useModal } from "Contexts"

export const Home = () => {
	const { t } = useTrans()
	const { currentTerminal } = useTerminalsState()
	const { isRealEstate } = useConfig()
	const { setPdfPreviewOpen } = usePdfPreview()
	const { data: tileConfig, isLoading } = trpc.config.getDashboardTiles.useQuery()
	const { showModal, hideModal } = useModal()

	const {
		keyNumbersLoading,
		wasteTypesInUseLoading,
		recyclingRateLoading,
		developmentLoading,
		wasteContributionLoading,
		wasteDistributionLoading,
		comparisonWasteLoading,
		comparisonDistributionLoading,
	} = useHomeLoadingState()

	const isActive = useCallback(
		(tileType: DashboardTileType) =>
			tileConfig?.find(tile => tile.tileType === tileType)?.visible ?? true,
		[tileConfig]
	)

	const realEstateView = useMemo(
		() => (
			<>
				{isActive(DashboardTileType.KeyNumbers) && <OverviewKeyNumbers />}
				{isActive(DashboardTileType.WasteTypesInUse) && <WasteTypesInUseCard />}
				{isActive(DashboardTileType.RecyclingRateOverTime) && <RecyclingRateOverTime />}
				{isActive(DashboardTileType.DevelopmentInWasteTypes) && <DevelopmentInWasteTypes />}
				{isActive(DashboardTileType.WasteContribution) && <PieWasteContribution />}
				{isActive(DashboardTileType.WasteTypeDistribution) && <WasteTypeDistribution />}
				{isActive(DashboardTileType.WastePerTenant) && <ComparisonWastePerTenant />}
				{isActive(DashboardTileType.ComparisonWasteTypeDistribution) && (
					<ComparisonWasteTypeDistribution />
				)}
				{isActive(DashboardTileType.InfrastructureSummary) && <InfrastructureSummary />}
			</>
		),
		[isActive]
	)

	const mwmView = useMemo(
		() => (
			<>
				{isActive(DashboardTileType.KeyNumbers) && <OverviewKeyNumbers />}
				{isActive(DashboardTileType.DevelopmentInWasteTypes) && <DevelopmentInWasteTypes />}
				{isActive(DashboardTileType.RecyclingRateOverTime) && <RecyclingRateOverTime />}
				{isActive(DashboardTileType.WasteTypeDistribution) && <WasteTypeDistribution />}
				{isActive(DashboardTileType.InfrastructureSummary) && <InfrastructureSummary />}
			</>
		),
		[isActive]
	)

	const view = useMemo(
		() => (isRealEstate ? realEstateView : mwmView),
		[isRealEstate, realEstateView, mwmView]
	)

	const generatePdf = useCallback(async () => {
		const el = document.getElementById("page-content")

		if (el) {
			const margin = 10
			const PDF_WIDTH = el.getBoundingClientRect().width
			const PDF_HEIGHT = el.getBoundingClientRect().height
			const opt = {
				filename: `${t("exportLabels:homeFileName")}_${moment().format("YYYYMMDD")}.pdf`,
				image: { type: "jpeg", quality: 0.98 },
				margin: 10,
				html2canvas: {
					width: PDF_WIDTH,
					height: PDF_HEIGHT,
					windowWidth: window.innerWidth,
					windowHeight: PDF_HEIGHT,
					letterRendering: true,
					useCORS: true,
					backgroundColor: "#F7F2F2",
				},
				jsPDF: {
					unit: "px",
					format: [PDF_WIDTH, PDF_HEIGHT + margin * 2],
					orientation: PDF_WIDTH > PDF_HEIGHT ? "landscape" : "portrait",
					hotfixes: ["px_scaling"],
				},
			}
			await html2pdf().set(opt).from(el).save()
		}
	}, [t])

	const dataLoading = useMemo(
		() =>
			keyNumbersLoading ||
			wasteTypesInUseLoading ||
			recyclingRateLoading ||
			developmentLoading ||
			wasteContributionLoading ||
			wasteDistributionLoading ||
			comparisonWasteLoading ||
			comparisonDistributionLoading,
		[
			keyNumbersLoading,
			wasteTypesInUseLoading,
			recyclingRateLoading,
			developmentLoading,
			wasteContributionLoading,
			wasteDistributionLoading,
			comparisonWasteLoading,
			comparisonDistributionLoading,
		]
	)

	return (
		<Page
			title="sidebarLabels:home"
			button={{
				label: "actions:previewPDF",
				onClick: () => {
					setPdfPreviewOpen(true)
					showModal(
						<PdfPreview
							generatePdf={generatePdf}
							close={() => {
								setPdfPreviewOpen(false)
								hideModal()
							}}
							view={view}
						/>
					)
				},
				type: "button",
				color: "primary",
				loading: dataLoading,
				className: "disabled:cursor-not-allowed disabled:bg-lime",
			}}
		>
			{isRealEstate && !currentTerminal?.id ? (
				<NoTerminals />
			) : isLoading ? (
				<div className="flex justify-center">
					<LoadingIndicator />
				</div>
			) : (
				<div className="grid grid-cols-1 gap-2 xl:grid-cols-2">{view}</div>
			)}
		</Page>
	)
}
