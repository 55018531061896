import { trpc } from "Utils/trpc"
import { AccessManagementCard } from "./AccessManagementCard"
import { CustomerAdminCard } from "./AdminCard"
import { CustomerCommunicationCard } from "./CommunicationCard"
import { GeneralCustomerInfoCard } from "./GeneralInfoCard"
import { CustomerHistoryCard } from "./HistoryCard"
import { InfrastructureCard } from "./InfrastructureCard"
import { PeopleCard } from "./PeopleCard"
import { CustomerPrefilteringCard } from "./PreFilteringCard"
import Skeleton from "react-loading-skeleton"
import { useTrans } from "translations"
import { useConfig } from "api/hooks/useConfig"
import { useCallback } from "react"
import { CustomerList, useCustomers } from "api/hooks/useCustomers/useCustomers"
import { ExportDataCard } from "./ExportDataCard"

type SingleCustomerViewProps = {
	customerId: string
	selectedCustomers: CustomerList
}

export const SingleCustomerView = ({ customerId, selectedCustomers }: SingleCustomerViewProps) => {
	const { isRealEstate, isMWM } = useConfig()
	const {
		data: customerWithCode,
		isError,
		isLoading,
		refetch,
	} = trpc.customers.get.useQuery({ id: customerId })
	const { t } = useTrans()
	const { refetchCustomers } = useCustomers()

	const onUpdate: () => Promise<any> = useCallback(async () => {
		await refetch()
		await refetchCustomers()
	}, [refetch, refetchCustomers])

	if (isLoading) return <Skeleton height={300} count={3} />

	const customer = customerWithCode

	if (isError || !customer) return <>{t("errors:failedToLoadCustomer")}</>

	return (
		<>
			<h4 className="font-signifier text-3xl font-light text-black">{customer.name}</h4>
			<GeneralCustomerInfoCard customer={customer} onUpdate={onUpdate} />
			<CustomerPrefilteringCard customerId={customerId} />
			{isRealEstate && customer && <PeopleCard customer={customer} onUpdate={onUpdate} />}
			{(!!customer?.accessParents?.length || isMWM) && (
				<InfrastructureCard customer={customer} refetch={onUpdate} />
			)}
			{customer && <AccessManagementCard customer={customer} refetch={onUpdate} />}
			{isRealEstate && customer && <CustomerCommunicationCard customer={customer} />}
			<CustomerHistoryCard customerId={customerId} customerName={customer?.name} />
			<ExportDataCard selectedCustomers={selectedCustomers} />
			<CustomerAdminCard customerId={customerId} customerName={customer?.name} />
		</>
	)
}
