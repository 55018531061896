import create from "zustand"
import { combine } from "zustand/middleware"
import {
	FetchedWasteTypes,
	FetchedWasteTypeClassificationSystems,
	FetchedUpgradeTypes,
	FetchedDownstreamHandlingTypes,
	FetchedTenantTypes,
	FetchedManufacturersTypes,
	FetchedLanguages,
	SmartIntegrations,
	FetchedHelpSections,
} from "../Utils/api/sanity/types"

type StoreState = {
	wasteTypes: FetchedWasteTypes | null
	wasteTypeClassificationSystems: FetchedWasteTypeClassificationSystems | null
	upgrades: FetchedUpgradeTypes | null
	downstreamHandling: FetchedDownstreamHandlingTypes | null
	tenantCategories: FetchedTenantTypes | null
	manufacturers: FetchedManufacturersTypes | null
	languages: FetchedLanguages | null
	smartIntegrations: SmartIntegrations | null
	helpSections: FetchedHelpSections | null
}

type StoreActions = {
	setWasteTypes: (wasteTypes: FetchedWasteTypes) => void
	setWasteTypeClassificationSystems: (
		wasteTypeClassificationSystems: FetchedWasteTypeClassificationSystems
	) => void
	setUpgrades: (upgrades: FetchedUpgradeTypes) => void
	setDownstreamHandling: (downstreamHandling: FetchedDownstreamHandlingTypes) => void
	setTenantCategories: (tenantCategories: FetchedTenantTypes) => void
	setManufacturers: (manufacturers: FetchedManufacturersTypes) => void
	setLanguages: (languages: FetchedLanguages) => void
	setSmartIntegrations: (smartIntegrations: SmartIntegrations) => void
	setHelpSections: (helpSections: FetchedHelpSections) => void
}

export const useCommonEntitiesStore = create<StoreState & StoreActions>(
	combine(
		{
			wasteTypes: null,
			wasteTypeClassificationSystems: null,
			upgrades: null,
			downstreamHandling: null,
			tenantCategories: null,
			manufacturers: null,
			languages: null,
			smartIntegrations: null,
			helpSections: null,
		} as any,
		set =>
			({
				setWasteTypes: (wasteTypes: FetchedWasteTypes) => set({ wasteTypes }),
				setWasteTypeClassificationSystems: (
					wasteTypeClassificationSystems: FetchedWasteTypeClassificationSystems
				) => set({ wasteTypeClassificationSystems }),
				setUpgrades: (upgrades: FetchedUpgradeTypes) => set({ upgrades }),
				setDownstreamHandling: (downstreamHandling: FetchedDownstreamHandlingTypes) =>
					set({ downstreamHandling }),
				setTenantCategories: (tenantCategories: FetchedTenantTypes) => set({ tenantCategories }),
				setManufacturers: (manufacturers: FetchedManufacturersTypes) => set({ manufacturers }),
				setLanguages: (languages: FetchedLanguages) => set({ languages }),
				setSmartIntegrations: (smartIntegrations: SmartIntegrations) => set({ smartIntegrations }),
				setHelpSections: (helpSections: FetchedHelpSections) => set({ helpSections }),
			}) as any
	)
)
