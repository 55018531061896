import React, { useMemo } from "react"
import { TableHistoryEntry } from "./types"
import { useTrans } from "translations"
import { get } from "lodash"

const ROW_STYLE = "flex w-full py-2.5 text-sm border-b items-center"

type Props = {
	entries: TableHistoryEntry[]
}

export const HistoryTable: React.FC<Props> = ({ entries }) => {
	const { t } = useTrans()
	const columns = useMemo(
		() => [
			{
				label: "historyLabels:dateOfChange",
				name: "date",
				className: "w-1/5",
			},
			{
				label: "historyLabels:changesMadeBy",
				name: "user",
				className: "w-1/3",
			},
			{
				label: "historyLabels:changes",
				name: "change",
				className: "w-1/2",
			},
		],
		[]
	)

	return (
		<div className="h-full">
			<div className={`${ROW_STYLE} border-b-grey4 font-medium h-12`}>
				{columns.map(el => (
					<div className={el.className} key={el.name}>
						{t(el.label)}
					</div>
				))}
			</div>
			{entries.map((entry, index) => (
				<div className={`${ROW_STYLE} border-b-grey3 font-normal last:border-none`} key={index}>
					{columns.map(el => (
						<div className={el.className} key={el.name}>
							{get(entry, el.name)}
						</div>
					))}
				</div>
			))}
		</div>
	)
}
