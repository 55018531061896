import { IdentityKey } from "admin-client-server/src/utils/identitiesUtils"
import { useModal } from "Contexts"
import { useCallback, useRef } from "react"
import { FieldType } from "./types"
import { throttle } from "lodash"
import { StyledSelect } from "components/StyledSelect"
import { useTrans } from "translations"
import ErrorTooltip from "components/Form/ErrorTooltip"

type Props = {
	value: string
	columnId: FieldType
	setCellError: (error: string) => void
	errorMessage?: string
	getAutocompleteOptions: (key: IdentityKey, value: string) => Promise<any>
	onAutocompleteOptionSelected: (option: any) => void
	disabled?: boolean
	hidePlaceholder?: boolean
}

export function SelectWithAutocomplete({
	value,
	columnId,
	errorMessage,
	setCellError,
	getAutocompleteOptions,
	onAutocompleteOptionSelected,
	disabled,
	hidePlaceholder,
}: Props) {
	const { t } = useTrans()
	const { ref: modalRef } = useModal()

	const updateWithAutocompleteDebounced = useRef(
		throttle(async (val: string, columnId: any, callback: (options: any) => void) => {
			const { options, hasError } = await getAutocompleteOptions(columnId, val)

			const mapped = options.map((option: any) => ({
				label: option[columnId],
				value: option[columnId],
				...option,
				error: hasError ? "errors:mustBeUnique" : "",
			}))

			callback(mapped)
		}, 500)
	)

	const loadOptions = useCallback(
		(inputValue: string, callback: (options: any) => void) => {
			updateWithAutocompleteDebounced.current(inputValue, columnId, options => {
				callback(options)
			})
		},
		[columnId]
	)

	const onChange = useCallback(
		(option: any) => {
			onAutocompleteOptionSelected(option)
			if (option.error) {
				setCellError(option.error)
			}
		},
		[onAutocompleteOptionSelected, setCellError]
	)

	return (
		<div className="relative flex-col">
			<StyledSelect
				async
				className="m-1"
				loadOptions={loadOptions}
				value={value ? { label: value, value } : undefined}
				onChange={onChange}
				showErrorState={!!errorMessage}
				isDisabled={disabled}
				menuPortalTarget={modalRef?.current}
				noOptionsMessage={() => (
					<span className="float-left p-2 text-sm">{t("errors:noResults")}</span>
				)}
				placeholder={hidePlaceholder ? "" : t("genericLabels:selectPlaceholder")}
			/>
			{errorMessage && (
				<ErrorTooltip error={{ type: "error", message: t(errorMessage) }} className="mr-5 mt-1" />
			)}
		</div>
	)
}
