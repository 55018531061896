import { sortBy } from "lodash"
import { useMemo } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { getWasteTypeIcon } from "Utils/getWasteTypeIcon"
import { getWasteTypeLabels } from "Utils/getWasteTypeLabels"
import { trpc } from "Utils/trpc"

export const useQRData = () => {
	const { currentTerminal } = useTerminalsState()
	const { wasteTypes } = useCommonEntitiesStore()

	const {
		data: accessParentData,
		isFetching: isFetchingAccessParents,
		isError: isErrorAccessParents,
	} = trpc.accessParents.getAllWithContainers.useQuery({ terminalId: currentTerminal.id })

	const stationUuids = useMemo(() => accessParentData?.map(ap => ap.id) || [], [accessParentData])

	const {
		data: containerData,
		isFetching: isFetchingContainers,
		isError: isErrorContainers,
	} = trpc.getContainersFromStationIds.useQuery(
		{
			stationUuids,
		},
		{
			enabled: stationUuids.length > 0,
		}
	)

	const isFetching = isFetchingAccessParents || isFetchingContainers
	const isError = isErrorAccessParents || isErrorContainers

	const containers = useMemo(
		() =>
			sortBy(
				containerData
					?.flatMap(
						station =>
							station?.containers.map(container => ({
								...container,
								station_id: station.id,
								station_wiq_uuid: station.wiq_uuid,
							}))
					)
					.filter(container => {
						if (container) {
							const station = accessParentData?.find(s => s.id === container.station_wiq_uuid)

							if (station) {
								return !!station.containers?.find(c => c.id === container.wiq_uuid)
							}
						}

						return false
					})
					.map(
						container =>
							container && {
								...container,
								imgSrc: getWasteTypeIcon(container.fraction_id, wasteTypes || []),
								primaryLabel: getWasteTypeLabels(container.fraction_id, wasteTypes || [])
									.primaryLabel,
								englishLabel: getWasteTypeLabels(container.fraction_id, wasteTypes || [])
									.englishLabel,
							}
					),
				"primaryLabel"
			),
		[containerData, wasteTypes, accessParentData]
	)

	return {
		containers,
		isFetching,
		isError,
	}
}
