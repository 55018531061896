import create from "zustand"

export type AlertType = "warning" | "success" | "error" | "info"

export type GlobalAlert = {
	type: AlertType
	message: string
	messageVariables?: { [key: string]: string }
	instructions?: string
	instructionVariables?: { [key: string]: string }
	shouldNotDisappear?: boolean
	canClose?: boolean
}

type UseGlobalAlert = {
	globalAlert: GlobalAlert | null
	setGlobalAlert: (obj: GlobalAlert | null) => void
}

/** Global notification popup alert */
export const useGlobalAlert = create<UseGlobalAlert>(set => ({
	globalAlert: null,
	setGlobalAlert: _globalAlert => set(() => ({ globalAlert: _globalAlert })),
}))
