import InfoPopup from "components/InfoPopup/InfoPopup"
import { ComponentProps } from "react"
export interface CardInfoProps {
	heading: string
	value?: string
	tooltip?: Omit<ComponentProps<typeof InfoPopup>, "width">
}

export const CardInfo: React.FC<CardInfoProps> = ({ heading, value, tooltip }) => {
	return (
		<div>
			<div className="mb-0.5 font-dmSans text-black">
				{heading}
				{tooltip && (
					<InfoPopup
						width="350px"
						size={15}
						className="ml-1 inline-block align-baseline"
						{...tooltip}
					/>
				)}
			</div>
			<p title={value} className="group truncate font-dmSans text-xl font-medium text-black">
				{value || "---"}
			</p>
		</div>
	)
}
