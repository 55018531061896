import React, { useContext } from "react"
import { ImportContext } from "../../ImportContext"
import { MappingColumn } from "./MappingColumn"

export const MapSheetsAndColumns: React.FC = () => {
	const { selectedTemplate } = useContext(ImportContext)

	return (
		<div className="w-full flex gap-5">
			{selectedTemplate?.mappingSections.map((section, _, sections) => (
				<div
					key={section.key}
					className="p-4 bg-grey1 flex flex-col h-fit"
					style={{ width: `${100 / sections.length}%`, maxWidth: "50%" }}
				>
					<MappingColumn section={section} />
				</div>
			))}
		</div>
	)
}
