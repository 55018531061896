type DataTableHeaderType = {
	key: string
	title: string
	/** Defaults to true */
	translate?: boolean
	defaultSize?: number
	minSize?: number
	sortable?: boolean
}

export const REEventTableHeaders: DataTableHeaderType[] = [
	{ key: "timestamp", title: "genericLabels:timestamp", defaultSize: 170, sortable: true },
	{ key: "type", title: "filterLabels:eventType", defaultSize: 110, sortable: false },
	{ key: "customer.name", title: "entities:customer", defaultSize: 120, sortable: false },
	{ key: "wasteType.code", title: "genericLabels:wasteType", defaultSize: 140, sortable: false },
	{ key: "weight.quantity", title: "genericLabels:weight", defaultSize: 70, sortable: true },
	{ key: "weight.unit", title: "genericLabels:unit", defaultSize: 50, sortable: false },
	{ key: "accessParent.name", title: "entities:accessParent", defaultSize: 50, sortable: false },
	{ key: "container.name", title: "entities:container", sortable: false },
	{ key: "result.code", title: "genericLabels:result", sortable: false },
	{
		key: "event.result.reason.code",
		title: "genericLabels:reason",
		defaultSize: 80,
		sortable: false,
	},
]

export const MWMEventTableHeaders = ({
	useWasteSuctionSystem,
	usesCRM,
}: {
	useWasteSuctionSystem?: boolean
	usesCRM?: boolean
}) =>
	(
		[
			{ key: "timestamp", title: "genericLabels:timestamp", defaultSize: 150, sortable: true },
			{ key: "type", title: "filterLabels:eventType", defaultSize: 110, sortable: false },
			{
				key: "customer.name",
				title: "genericLabels:customerReference",
				defaultSize: 120,
				sortable: false,
			},
			usesCRM && {
				key: "customer.crmId",
				title: "drawerLabels:crmId",
				defaultSize: 120,
				sortable: false,
			},
			{
				key: "wasteType.code",
				title: "genericLabels:wasteType",
				defaultSize: 140,
				sortable: false,
			},
			useWasteSuctionSystem && {
				key: "weight.quantity",
				title: "genericLabels:weight",
				defaultSize: 70,
				sortable: true,
			},
			useWasteSuctionSystem && {
				key: "weight.unit",
				title: "genericLabels:unit",
				defaultSize: 50,
				sortable: false,
			},
			{ key: "terminal.name", title: "entities:terminal", sortable: false },
			{
				key: "accessParent.name",
				title: "entities:accessParent",
				defaultSize: 130,
				sortable: false,
			},
			useWasteSuctionSystem && { key: "depot.name", title: "entities:depot", sortable: false },
			{ key: "container.name", title: "entities:container", sortable: false },
			{ key: "identity.printed", title: "formLabels:label", sortable: false },
			{ key: "identity.iso", title: "formLabels:ISO", defaultSize: 160, sortable: false },
			{ key: "result.code", title: "genericLabels:result", sortable: false },
			{
				key: "event.result.reason.code",
				title: "genericLabels:reason",
				defaultSize: 120,
				sortable: false,
			},
		] as DataTableHeaderType[]
	).filter(Boolean)
