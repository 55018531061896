import { ImportContext } from "components/ImportComponents/ImportContext"
import { Tabs } from "components/Tabs"
import { sum } from "lodash"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTrans } from "translations"
import { ValidateTable } from "./ValidateTable"

export const ValidateImportedData = () => {
	const { t } = useTrans()
	const [activeTab, setActiveTab] = useState<number>()
	const { selectedTemplate, validationState, mappingState } = useContext(ImportContext)

	const validatingSections = useMemo(
		() =>
			selectedTemplate?.mappingSections?.filter(
				s => !s.isOptional || (s.isOptional && mappingState?.[s.key]?.selected)
			),
		[selectedTemplate, mappingState]
	)

	const tabs = useMemo(
		() =>
			validatingSections?.map(s => {
				const tabData = validationState?.[s.key]
				const nrErrors = sum(
					Object.keys(tabData || {}).map(key => tabData?.[key].filter(el => el.error).length)
				)

				const newTab = {
					key: s.key,
					title: t(s.title),
					nrErrors,
				}

				return newTab
			}) || [],
		[t, validationState, validatingSections]
	)

	useEffect(() => {
		if (activeTab === undefined) {
			const tabWithErrors = tabs.findIndex(tab => tab.nrErrors! > 0)

			if (tabWithErrors >= 0) {
				setActiveTab(tabWithErrors)
			} else {
				setActiveTab(0)
			}
		}
	}, [tabs, activeTab, setActiveTab])

	const sectionKey = useMemo(() => tabs?.[activeTab!]?.key || "", [tabs, activeTab])

	return (
		<div style={{ height: "calc(100% - 150px)" }}>
			<div className="w-full border-b-2 border-grey3 mb-5">
				<Tabs tabs={tabs} activeTab={activeTab!} setActiveTab={setActiveTab} />
			</div>

			{validatingSections?.map(s => (
				<div
					key={s.key}
					className="w-full h-full overflow-scroll"
					style={{ display: s.key === sectionKey ? "block" : "none", height: "calc(100% - 60px)" }}
				>
					<ValidateTable sectionKey={s.key} />
				</div>
			))}
		</div>
	)
}
