import { useTrans } from "translations"
import { ExportDataCard } from "./ExportDataCard"
import { CustomerList } from "api/hooks/useCustomers/useCustomers"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { SortingState } from "@tanstack/react-table"

type MultipleCustomerViewProps = {
	selectedCustomers: CustomerList
	areAllSelected?: boolean
	filterState: ActiveOptions<any>
	sortingState?: SortingState
	nameFilter?: string
}

export const MultipleCustomerView = ({
	selectedCustomers,
	areAllSelected,
	filterState,
	sortingState,
	nameFilter,
}: MultipleCustomerViewProps) => {
	const { t } = useTrans()

	return (
		<>
			<h2 className="mb-1 text-3xl">{t("drawerLabels:multipleCustomersSelected")}</h2>
			<ExportDataCard
				selectedCustomers={selectedCustomers}
				areAllSelected={areAllSelected}
				filterState={filterState}
				sortingState={sortingState}
				nameFilter={nameFilter}
			/>
		</>
	)
}
