import TableHeader from "components/TableHeader"
import { FC } from "react"
import Skeleton from "react-loading-skeleton"
import { useTrans } from "translations"
import { formatNumberForTable } from "Utils/formatFunctions"
import useSorting, { orderByWithWasteCodeSpecialCase } from "Utils/useSorting"
import { TCostForecastData, TCostForecastHandler } from "../functions/useCostForecast"
import { useConfigService } from "pages/configuration/useConfigService"

const dummyArray = Array.from(Array(10))

export const CostsTable: FC<{
	data: TCostForecastData
	inputChangeHandler: TCostForecastHandler
}> = ({ data: unsortedData, inputChangeHandler }) => {
	const { t } = useTrans()
	const { wasteTypeConfig } = useConfigService()
	const { field, direction, changeSorting } = useSorting("wasteType")

	const data = orderByWithWasteCodeSpecialCase(unsortedData || [], field, direction)
	const isData = !!data.length

	return (
		<div>
			<div className="sticky top-0 grid min-h-[48px] grid-cols-6 border-b border-grey3 bg-white text-end">
				<TableHeader
					label="genericLabels:wasteType"
					field="wasteType"
					setSort={changeSorting}
					activeField={field}
					direction={direction}
					className="text-start"
				/>
				<TableHeader
					label="circularityLabels:wasteCode"
					field="wasteCode"
					setSort={changeSorting}
					activeField={field}
					direction={direction}
					className="justify-self-end"
				/>
				<TableHeader label="circularityLabels:currentAnnualAmount" className="justify-self-end" />
				<TableHeader label="circularityLabels:currentPriceKg" className="justify-self-end" />
				<TableHeader label="statisticsLabels:futureAnnualAmount" className="justify-self-end" />
				<TableHeader label="circularityLabels:futurePricKg" className="justify-self-end" />
			</div>
			{isData &&
				data.map(
					(
						{ wasteType, wasteCode, currentAmount, currentPrice, futureAmount, futurePrice },
						rowIndex
					) => {
						const roundingThreshold = Infinity
						const numDecimals = 3
						const options = { roundingThreshold, numDecimals }
						const currentAmountFormatted = formatNumberForTable(currentAmount, { suffix: " kg" })
						// Allow extra decimals for input fields to give users more freedom and precise forecasts
						const currentPriceFormatted = formatNumberForTable(currentPrice, { ...options })
						const futureAmountFormatted = formatNumberForTable(futureAmount, { ...options })
						const futurePriceFormatted = formatNumberForTable(futurePrice, { ...options })

						const wasteTypeName =
							wasteTypeConfig.find(({ wasteTypeCode }) => wasteTypeCode === wasteCode)?.name ||
							wasteType

						return (
							<div
								className="grid min-h-[48px] grid-cols-6 items-center py-0.5 text-end border-b border-grey2 hover:bg-hoverColor"
								key={`CostForecastTableEntry${rowIndex}`}
							>
								<span className="justify-self-start px-3 text-start font-dmSans text-sm text-black">
									{wasteTypeName}
								</span>
								<span className="px-3 pr-8 font-dmSans text-sm text-black">{wasteCode}</span>
								<span className="px-3 pr-8 font-dmSans text-sm text-black">
									{currentAmountFormatted}
								</span>
								<div className="flex items-center justify-end gap-1 px-3 pr-8 font-dmSans text-sm text-black">
									<TableInput
										value={currentPriceFormatted}
										handleInputChange={inputChangeHandler(wasteCode, "currentPrice")}
									/>
									{` ${t("circularityLabels:localCurrency")}/kg`}
									<PayOrSave price={currentPriceFormatted} />
								</div>
								<span className="px-3 pr-8 font-dmSans text-sm text-black">
									<TableInput
										value={futureAmountFormatted}
										handleInputChange={inputChangeHandler(wasteCode, "futureAmount")}
									/>
									{" kg"}
								</span>
								<div className="flex items-center justify-end gap-1 px-3 pr-8 font-dmSans text-sm  text-black">
									<TableInput
										value={futurePriceFormatted}
										handleInputChange={inputChangeHandler(wasteCode, "futurePrice")}
									/>
									{` ${t("circularityLabels:localCurrency")}/kg`}
									<PayOrSave price={futurePriceFormatted} />
								</div>
							</div>
						)
					}
				)}
			{!isData &&
				dummyArray.map((_, index) => (
					<Skeleton className="h-10" key={`CostFforecastTableSkeleton${index}`} />
				))}
		</div>
	)
}

const TableInput: FC<{
	value: string | undefined
	handleInputChange: ReturnType<TCostForecastHandler>
}> = ({ value, handleInputChange }) => {
	return (
		<input
			className="h-8 w-[100px] border-black py-2 px-3 text-right text-sm leading-10"
			onChange={handleInputChange}
			value={value ?? ""}
			placeholder="0"
		/>
	)
}

const PayOrSave: FC<{ price: string }> = ({ price }) => {
	const { t } = useTrans()
	const parsedPrice = parseFloat(price?.toString().replaceAll(" ", ""))
	return (
		<div className="w-8 text-left">
			{parsedPrice > 0 && <span className="text-carrotRed">{t("circularityLabels:pay")}</span>}
			{parsedPrice < 0 && <span className="text-carrotGreen">{t("actions:save")}</span>}
		</div>
	)
}
