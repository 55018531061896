import { IInputOption } from "components/GenericComponents/input"
import { TemplateColumn } from "components/ImportComponents/types"

export const REAL_ESTATE_SPACE_NAME = "REAL_ESTATE"
export const MWM_SPACE_NAME = "MWM"

export const filterBySpaceName = (c: TemplateColumn, isMWM: boolean) =>
	!c.contextName || c.contextName === (isMWM ? MWM_SPACE_NAME : REAL_ESTATE_SPACE_NAME)

export const dropdownValueTransform = (value: string, options?: IInputOption[]) => {
	const normalized = value?.toLowerCase().replaceAll(" ", "")
	const option = options?.find(o => o.option.toLowerCase().replaceAll(" ", "") === normalized)
	return option?.value || normalized
}
