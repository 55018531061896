import { timezoneStore } from "api/hooks/useConfig"
import moment from "moment"
import { match } from "ts-pattern"
import { FilterTimeFrameValue } from "./filterCreators"

export type CardFilterTimeFrameIso = { startTimeISO: string; endTimeISO?: string }

/** Generates a timeframe object for DWH api request */
export const getTimeFrame = (timeFrame: FilterTimeFrameValue): CardFilterTimeFrameIso => {
	const { timezone } = timezoneStore.getState()

	return match(timeFrame)
		.with(FilterTimeFrameValue.TODAY, () => ({
			startTimeISO: moment().tz(timezone, true).startOf("day").toISOString(),
		}))
		.with(FilterTimeFrameValue.YESTERDAY, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(1, "days").startOf("day").toISOString(),
			endTimeISO: moment().tz(timezone, true).subtract(1, "days").endOf("day").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_WEEK, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(1, "week").startOf("day").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_30_DAYS, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(30, "days").startOf("day").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_3_MONTHS, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(3, "month").startOf("month").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_6_MONTHS, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(6, "month").startOf("month").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_12_MONTHS, () => ({
			startTimeISO: moment()
				.tz(timezone, true)
				.subtract(12, "month")
				.startOf("month")
				.toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_MONTH, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(1, "month").startOf("month").toISOString(),
			endTimeISO: moment().tz(timezone, true).subtract(1, "month").endOf("month").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_YEAR, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(1, "year").startOf("year").toISOString(),
			endTimeISO: moment().tz(timezone, true).subtract(1, "year").endOf("year").toISOString(),
		}))
		.with(FilterTimeFrameValue.YEAR_TO_DATE, () => ({
			startTimeISO: moment().tz(timezone, true).startOf("year").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_6_FULL_MONTHS, () => ({
			startTimeISO: moment().tz(timezone, true).subtract(6, "month").startOf("month").toISOString(),
			endTimeISO: moment().tz(timezone, true).subtract(1, "month").endOf("month").toISOString(),
		}))
		.with(FilterTimeFrameValue.LAST_FULL_YEAR, () => ({
			startTimeISO: moment()
				.tz(timezone, true)
				.subtract(12, "month")
				.startOf("month")
				.toISOString(),
			endTimeISO: moment().tz(timezone, true).subtract(1, "month").endOf("month").toISOString(),
		}))
		.with(FilterTimeFrameValue.SINCE_BEGINNING, () => ({
			startTimeISO: "2017-01-01T00:00:00Z", // Carrot (WasteIQ) was founded in 2017
		}))
		.exhaustive()
}

export type TimestampISO = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`
export type TimeFilterCustomDate = `${TimestampISO}&${TimestampISO}`

export const getCustomTimeFrame = (
	customTimeframe: TimeFilterCustomDate
): CardFilterTimeFrameIso => {
	const [startTimeISO = "", endTimeISO = ""] = customTimeframe.split("&") as [
		TimestampISO,
		TimestampISO,
	]

	return { startTimeISO, endTimeISO }
}
