export const Infrastructure = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_23_2253)">
			<path
				d="M15.75 14.25H17.25V15.75H0.75V14.25H2.25V3C2.25 2.80109 2.32902 2.61032 2.46967 2.46967C2.61032 2.32902 2.80109 2.25 3 2.25H10.5C10.6989 2.25 10.8897 2.32902 11.0303 2.46967C11.171 2.61032 11.25 2.80109 11.25 3V14.25H12.75V6.75H15C15.1989 6.75 15.3897 6.82902 15.5303 6.96967C15.671 7.11032 15.75 7.30109 15.75 7.5V14.25ZM5.25 8.25V9.75H8.25V8.25H5.25ZM5.25 5.25V6.75H8.25V5.25H5.25Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_23_2253">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
)
