import create from "zustand"
import { IAuthJwt } from "../types"

export type IUseGeneralConfig = {
	authJwt: IAuthJwt | null
	setAuthJwt: (_authJwt: IAuthJwt) => void
}

export const useGeneralConfig = create<IUseGeneralConfig>(set => ({
	authJwt: null,
	setAuthJwt: _authJwt => set(() => ({ authJwt: _authJwt })),
}))
