import create from "zustand"

type UseHomeLoadingState = {
	keyNumbersLoading: boolean
	setKeyNumbersLoading: (value: boolean) => void

	wasteTypesInUseLoading: boolean
	setWasteTypesInUseLoading: (value: boolean) => void

	recyclingRateLoading: boolean
	setRecyclingRateLoading: (value: boolean) => void

	developmentLoading: boolean
	setDevelopmentLoading: (value: boolean) => void

	wasteContributionLoading: boolean
	setWasteContributionLoading: (value: boolean) => void

	wasteDistributionLoading: boolean
	setWasteDistributionLoading: (value: boolean) => void

	comparisonWasteLoading: boolean
	setComparisonWasteLoading: (value: boolean) => void

	comparisonDistributionLoading: boolean
	setComparisonDistributionLoading: (value: boolean) => void
}

export const useHomeLoadingState = create<UseHomeLoadingState>(set => ({
	keyNumbersLoading: false,
	setKeyNumbersLoading: _keyNumbersLoading =>
		set(() => ({ keyNumbersLoading: _keyNumbersLoading })),

	wasteTypesInUseLoading: false,
	setWasteTypesInUseLoading: _wasteTypesInUseLoading =>
		set(() => ({ wasteTypesInUseLoading: _wasteTypesInUseLoading })),

	recyclingRateLoading: false,
	setRecyclingRateLoading: _recyclingRateLoading =>
		set(() => ({ recyclingRateLoading: _recyclingRateLoading })),

	developmentLoading: false,
	setDevelopmentLoading: _developmentLoading =>
		set(() => ({ developmentLoading: _developmentLoading })),

	wasteContributionLoading: false,
	setWasteContributionLoading: _wasteContributionLoading =>
		set(() => ({ wasteContributionLoading: _wasteContributionLoading })),

	wasteDistributionLoading: false,
	setWasteDistributionLoading: _wasteDistributionLoading =>
		set(() => ({ wasteDistributionLoading: _wasteDistributionLoading })),

	comparisonWasteLoading: false,
	setComparisonWasteLoading: _comparisonWasteLoading =>
		set(() => ({ comparisonWasteLoading: _comparisonWasteLoading })),

	comparisonDistributionLoading: false,
	setComparisonDistributionLoading: _comparisonDistributionLoading =>
		set(() => ({ comparisonDistributionLoading: _comparisonDistributionLoading })),
}))
