import { orderBy } from "lodash"
import { trpc } from "Utils/trpc"

export const useCustomerParents = () => {
	const {
		data: customerParents,
		isFetching: isLoadingCustomerParents,
		refetch: refetchParents,
		isError,
	} = trpc.getRealEstateBusinessChains.useQuery()

	const isLoading = isLoadingCustomerParents

	const sortedCustomerParents = orderBy(customerParents, "name")

	return {
		data: sortedCustomerParents || [],
		isLoading,
		isError,
		refetchParents,
	}
}
