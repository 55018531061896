import React, { useMemo } from "react"
import { CloseIcon } from "Icons/Close"
import { AlertType } from "States/globalAlert"
import { alertConfig } from "./alertLineConfig"
import { Translate, useTrans } from "translations"
import { twMerge } from "tailwind-merge"

export const AlertLine = ({
	type,
	message,
	messageVariables,
	instructions,
	instructionVariables,
	instructionComponents,
	close,
	canClose = true,
	isStatic = false,
}: {
	type: AlertType
	message: string
	messageVariables?: { [key: string]: string }
	instructions?: string
	instructionVariables?: { [key: string]: string }
	instructionComponents?: React.ReactElement[]
	close?: () => void
	canClose?: boolean
	isStatic?: boolean
}) => {
	const { t } = useTrans()
	const config = useMemo(() => alertConfig[type], [type])

	return (
		<div
			className={twMerge(
				"flex w-full p-4 items-center shadow-md relative",
				isStatic ? "shadow-none" : "border-2",
				config.className
			)}
		>
			<div className="absolute top-5">{config.icon}</div>
			<div className="text-base pl-10 pr-3">
				<div className={isStatic ? "text-sm text-black font-normal" : "font-medium"}>
					{t(message, messageVariables)}
				</div>
				{instructions && (
					<div className="font-normal mt-2">
						<Translate
							i18nKey={instructions}
							components={instructionComponents}
							values={instructionVariables}
						/>
					</div>
				)}
			</div>
			{canClose && (
				<div className="absolute right-4 top-4 pointer" onClick={close}>
					<CloseIcon />
				</div>
			)}
		</div>
	)
}
