import { Popover } from "@headlessui/react"
import React, { useCallback } from "react"
import { useTrans } from "translations"
import { SketchPicker } from "react-color"
import { Button } from "components/button"

export type COLOR_KEY =
	| "primaryColor"
	| "secondaryColor"
	| "unsortedColor"
	| "sortedColor"
	| "otherColor"

type COLOR = {
	key: COLOR_KEY
	label: string
}

export type COLOR_CONFIG = {
	primaryColor?: string | null
	secondaryColor?: string | null
	unsortedColor?: string | null
	sortedColor?: string | null
	otherColor?: string | null
}

export const CUSTOM_COLORS: COLOR[] = [
	{
		key: "primaryColor",
		label: "configLabels:primaryColor",
	},
	{
		key: "secondaryColor",
		label: "configLabels:secondaryColor",
	},
	{
		key: "unsortedColor",
		label: "configLabels:unsortedColor",
	},
	{
		key: "sortedColor",
		label: "configLabels:sortedColor",
	},
	{
		key: "otherColor",
		label: "configLabels:otherColor",
	},
]

export const DEFAULT_CONFIG = {
	primaryColor: "",
	secondaryColor: "",
	unsortedColor: "",
	sortedColor: "",
	otherColor: "",
}

type Props = {
	updatedColors: COLOR_CONFIG
	setUpdatedColors: React.Dispatch<React.SetStateAction<COLOR_CONFIG>>
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const ColorsTable: React.FC<Props> = ({
	updatedColors,
	setUpdatedColors,
	setHasUnsavedChanges,
}: Props) => {
	const { t } = useTrans()

	const onColorChange = useCallback(
		(colorKey: string, color: string) => {
			setUpdatedColors(prev => ({
				...prev,
				[colorKey]: color,
			}))
			setHasUnsavedChanges(true)
		},
		[setUpdatedColors, setHasUnsavedChanges]
	)

	return (
		<table className="table-auto w-full text-sm mt-6 mb-10">
			<thead>
				<tr className="border-b h-12 text-left">
					<th>
						<div className="mr-4">{t("configLabels:labelToRepresent")}</div>
					</th>
					<th>
						<div className="ml-4">{t("configLabels:color")}</div>
					</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{CUSTOM_COLORS?.map(({ key, label }, index) => {
					return (
						<tr key={index} className="border-b last-of-type:border-none h-12">
							<td>{t(label)}</td>

							<td>
								<Popover>
									{({ close }) => (
										<>
											<Popover.Button>
												<div className="mx-4 min-w-[80px] mt-2 flex justify-center items-center">
													{updatedColors[key] ? (
														<Square color={updatedColors[key] || ""} />
													) : (
														<div className="underline cursor-pointer -mt-2">
															{t("actions:addColor")}
														</div>
													)}
												</div>
											</Popover.Button>
											<Popover.Panel className="absolute z-10 bg-grey1 p-4 border border-grey3 shadow">
												<SketchPicker
													color={updatedColors[key] ?? undefined}
													onChange={color => onColorChange(key, color.hex)}
													styles={{
														default: {
															picker: {
																boxShadow: "none",
															},
														},
													}}
												/>
												<Button className="w-full mt-2" label={t("common:done")} onClick={close} />
											</Popover.Panel>
										</>
									)}
								</Popover>
							</td>
							<td>
								{updatedColors[key] && (
									<div
										className="underline ml-4 cursor-pointer"
										onClick={() => {
											setUpdatedColors(prev => ({
												...prev,
												[key]: "",
											}))
										}}
									>
										{t("actions:reset")}
									</div>
								)}
							</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

const Square: React.FC<{ color: string }> = ({ color }) => {
	return (
		<div
			style={{
				width: 28,
				height: 28,
				backgroundColor: color,
			}}
		/>
	)
}
