import { DropdownArrowIcon } from "Icons/DropdownArrow"
import { FC } from "react"
import { Link } from "react-router-dom"
import { Card, ICardComponentProps } from "./card"

export const LinksCard: FC<{ links: ILinkEntryProps[] } & ICardComponentProps> = ({
	title,
	links,
}) => {
	return (
		<Card title={title} halfCard>
			<div className="flex-col gap-4">
				{links.map((link, index) => (
					<LinkEntry key={`linkCard-entry-${index}`} {...link} />
				))}
			</div>
		</Card>
	)
}

export interface ILinkEntryProps {
	heading: string
	description?: string
	linkTo: string
}

const LinkEntry: FC<ILinkEntryProps> = ({ heading, linkTo, description }) => {
	return (
		<Link to={linkTo}>
			<div className="flex w-full items-center gap-6 bg-grey2 px-6 py-4">
				<p className="whitespace-nowrap font-bold">{heading}</p>
				<p className="w-full text-sm text-grey6">{description}</p>
				<div className="w-2.5">
					<DropdownArrowIcon className="h-2.5 w-2.5 -rotate-90" />
				</div>
			</div>
		</Link>
	)
}
