import { MultiListSectionEntry, MultiListSectionEntryProps } from "./multiListSectionEntry"

import { FC, useEffect, useMemo, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { useTrans } from "translations"
import { isMatch } from "lodash"
import { EmptyDataType } from "components/EmptyView/emptyViewTypes"
import { EmptyView } from "components/EmptyView/EmptyView"
import { CardComponentFilterLocation, ICardComponentProps } from "../card"

export type DataListProps<DataType extends Record<string, any>[] = Record<string, any>[]> = {
	data: DataType | undefined
	getInitiallySelectedRows?: (entries: DataType) => DataType
	entryRender: (entry: DataType[number]) => MultiListSectionEntryProps
	onRowClick?: (entry: DataType[number], isMultiSelect?: boolean) => void
	onMultipleSelect?: (entries: DataType) => void
	emptyViewType?: EmptyDataType
	isLoading?: boolean
	withMultiSelect?: boolean
	labelSingle: string
	labelMultiple: string
	heightOffset?: number
} & Pick<ICardComponentProps, "filterLocation">

export const DataList: React.FC<DataListProps> = props => {
	const {
		data = [],
		isLoading,
		getInitiallySelectedRows,
		entryRender,
		onRowClick,
		onMultipleSelect,
		emptyViewType,
		withMultiSelect = false,
		labelSingle,
		labelMultiple,
		heightOffset,
	} = props

	const [selectedEntries, setSelectedEntries] = useState<typeof data>([])

	useEffect(() => {
		// Set initially selected rows on mount
		const initiallySelectedRows = getInitiallySelectedRows?.(data) || []
		setSelectedEntries(initiallySelectedRows)

		const elem = document.getElementById(`list-item-${initiallySelectedRows[0]?.id}`)
		if (elem) {
			elem.scrollIntoView({ behavior: "smooth", block: "center" })
		}
	}, [data, getInitiallySelectedRows])

	// Handle single and multiple entry selection
	const onEntryClick = (
		event: React.MouseEvent,
		entry: Record<string, any>,
		checkboxEvent: boolean = false
	) => {
		let newSelectedEntries = [entry]
		if (checkboxEvent) {
			const alreadySelected = selectedEntries.some(selectedEntry => isMatch(selectedEntry, entry))
			newSelectedEntries = alreadySelected
				? selectedEntries.filter(selectedEntry => !isMatch(selectedEntry, entry))
				: [...selectedEntries, entry]
		} else {
			onRowClick?.(entry)
		}

		if (onMultipleSelect) {
			onMultipleSelect(newSelectedEntries)
		}
		setSelectedEntries(newSelectedEntries)
	}

	const showList = useMemo(
		() => isLoading || (!!entryRender && data?.length > 0),
		[isLoading, entryRender, data]
	)

	const showEmpty = useMemo(() => !isLoading && !data?.length, [isLoading, data])

	const containerHeightOffset = useMemo(() => {
		let offset = 0
		if (heightOffset) offset += heightOffset
		if (props.filterLocation === CardComponentFilterLocation.INSIDE) offset += 68
		return offset
	}, [heightOffset, props.filterLocation])

	return (
		<div className="flex-col" style={{ height: `calc(100% - ${containerHeightOffset}px)` }}>
			<div className="h-full flex-1 overflow-auto">
				{showList && (
					<div className="px-5 py-6 pt-2">
						{!isLoading &&
							entryRender &&
							data?.map((entry, entryIndex) => (
								<div id={`list-item-${entry.id}`} key={entry.id}>
									<MultiListSectionEntry
										key={`DataListCardEntry_${entryIndex}`}
										isSelected={selectedEntries.some(selectedEntry =>
											isMatch(selectedEntry, entry)
										)}
										{...entryRender?.(entry)}
										onClick={(event, checkboxEvent) => onEntryClick(event, entry, checkboxEvent)}
										withMultiSelect={withMultiSelect}
									/>
								</div>
							))}
						{isLoading && <MultiListSectionSkeleton />}
					</div>
				)}
				{emptyViewType && showEmpty && <EmptyView type={emptyViewType} className="px-4" />}
			</div>
			<MultiListSectionFooter
				numberOfRows={data?.length ?? 0}
				withMultiSelect={withMultiSelect}
				selectedRows={selectedEntries.length ?? 0}
				labelSingle={labelSingle}
				labelMultiple={labelMultiple}
			/>
		</div>
	)
}

const MultiListSectionSkeleton: FC<{}> = () => {
	return <Skeleton count={5} height={60} borderRadius={0} />
}

type FooterProps = {
	numberOfRows: number
	withMultiSelect: boolean
	selectedRows: number
	labelSingle: string
	labelMultiple: string
}

const MultiListSectionFooter: FC<FooterProps> = ({
	numberOfRows,
	withMultiSelect,
	selectedRows,
	labelSingle,
	labelMultiple,
}) => {
	const { t } = useTrans()
	const rowCountLabel = numberOfRows === 1 ? labelSingle : labelMultiple
	return (
		<div className="flex h-16 items-center justify-between border-t-2 border-grey1 bg-white pl-4 pr-5 text-sm">
			<div className="text-grey6">
				{selectedRows === 0 || !withMultiSelect
					? `${numberOfRows} ${t(rowCountLabel).toLowerCase()}`
					: `${selectedRows}/${numberOfRows} ${t(labelMultiple).toLowerCase()} ${t(
							"genericLabels:selected"
					  )}`}
			</div>
			{withMultiSelect && <div className="text-grey6">{t("actions:selectMultipleRows")}</div>}
		</div>
	)
}
