import { ColumnType, textView } from "./functions"

export const accessManagementColumns: ColumnType[] = [
	{
		label: "formLabels:label",
		name: "printed",
		getView: textView,
	},
	{
		label: "formLabels:ISO",
		name: "iso",
		getView: textView,
	},
	{
		label: "formLabels:EM",
		name: "em",
		getView: textView,
	},
	{
		label: "formLabels:status",
		name: "status",
		getView: textView,
	},
]
