import classNames from "classnames"
import InfoPopup from "components/InfoPopup/InfoPopup"
import ArrowUp from "Icons/ArrowUp"
import { TableHeaderInterface } from "pages/CustomerDetails/customerDetails.interface"
import React from "react"
import { useTrans } from "translations"

export const TableHeader: React.FC<TableHeaderInterface> = ({
	field,
	label,
	activeField,
	setSort,
	direction,
	className,
	tooltipText,
	tooltipComponent,
}) => {
	const { t } = useTrans()
	const isActive = activeField === field
	return (
		<div
			className={classNames(
				"w-full flex items-center pl-3 text-sm font-medium text-black text-start",
				setSort && "cursor-pointer",
				className
			)}
			onClick={() => setSort && setSort(field)}
		>
			{t(label)}
			{setSort && (
				<ArrowUp
					className={classNames(
						"ml-2",
						isActive ? "text-black" : "text-grey4",
						isActive && direction !== "desc" && "rotate-180"
					)}
				/>
			)}
			{tooltipText && (
				<InfoPopup text={t(tooltipText)} id={label} size={14} width="250px" className="ml-1 mb-1" />
			)}
			{!tooltipText && tooltipComponent && (
				<InfoPopup id={label} size={14} width="250px" className="ml-1 mb-1">
					{tooltipComponent}
				</InfoPopup>
			)}
		</div>
	)
}

export default TableHeader
