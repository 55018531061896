import { QR_OPTIONS } from "constants/general"
import { QRCodeSVG } from "qrcode.react"

export interface PrintableQRCodeProps {
	container: {
		id: string
		fraction_id: string
		imgSrc: string
		primaryLabel: string
		englishLabel: string
		customColor?: string
	}
	qrUrl: string
	stationId: string
	qrContainerHeightPercentage: number
	qrLabelHeightPercentage: number
	iconSize: number
	labelFontSize: string
	gridCellheight: string
}

const PrintableQRCode: React.FC<PrintableQRCodeProps> = ({
	container,
	qrUrl,
	stationId,
	qrContainerHeightPercentage,
	qrLabelHeightPercentage,
	iconSize,
	labelFontSize,
	gridCellheight,
}) => {
	return (
		<div key={container.id} className={`${gridCellheight} mb-8 text-center print:mb-0`}>
			<div className="relative flex">
				<QRCodeSVG
					value={`${qrUrl}?st=${stationId}&cid=${container.id}`}
					width="100%"
					height={`${qrContainerHeightPercentage}%`}
					imageSettings={
						container.imgSrc
							? {
									src: container.imgSrc,
									height: iconSize,
									width: iconSize,
									excavate: true,
							  }
							: undefined
					}
					{...QR_OPTIONS}
					className="mx-auto border p-[10%] print:border-black"
				/>
				{container.customColor && (
					<div className="absolute flex w-1/4 h-1/4 p-0.5 align-center justify-center bg-white left-[37.5%] top-[37.5%]">
						<div className="w-full h-full" style={{ backgroundColor: container.customColor }} />
					</div>
				)}
			</div>
			<div
				className={`flex h-[${qrLabelHeightPercentage}%] flex-col items-start justify-start px-[5%]`}
			>
				<div
					className={`mt-[3%] w-full break-words p-0 font-semibold leading-none ${labelFontSize}`}
				>
					{container?.primaryLabel || ""}
				</div>
			</div>
		</div>
	)
}

export default PrintableQRCode
