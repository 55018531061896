import { EXCEL_FORMAT } from "constants/general"
import { TemplateColumn } from "components/ImportComponents/types"
import { saveAs } from "file-saver"
import { Workbook, Worksheet } from "exceljs"
import { translate } from "translations/functions/hook"

export const downloadExcel = async (title: string, workbook: Workbook) => {
	const xls = await workbook.xlsx.writeBuffer()
	saveAs(
		new Blob([xls], {
			type: EXCEL_FORMAT,
		}),
		translate(title)
	)
}

export const filterLinking = (columns: TemplateColumn[]) =>
	columns.filter(column => !column.hasLinkedParent)

export const addColumns = (sheet: Worksheet, columns: TemplateColumn[]) => {
	sheet.columns = columns.map(header => ({
		header: translate(header.title),
		key: header.key,
		width: 20,
	}))

	sheet.getRow(1).font = { bold: true }
}
