import { NavItemInterface } from "components/Sidebar/Sidebar.interface"
import React from "react"
import { NavLink } from "react-router-dom"
import { useTrans } from "translations"

export const NavItem: React.FC<NavItemInterface> = ({ to, name }) => {
	const { t } = useTrans()

	return (
		<NavLink
			to={to}
			className={({ isActive }) =>
				`flex h-10 items-center pl-[50px] transition-colors hover:bg-butteryLime ${
					isActive ? "bg-limeLight" : ""
				}`
			}
			end={to === "/"}
		>
			{t(name)}
		</NavLink>
	)
}
