export const A4_WIDTH = 210
export const A4_HEIGHT = 297

export interface QrPrintStyles {
	name: string
	title: string
	description: string
	pageInfo: string
	previewText: string
	columns: number
	maxCellsPerPage: number
	buttonText: string
	iconSizeFactor: number
	templateWidth: string
	templateHeight: string
	labelFontSize: string
	gridCellheight: string
	qrContainerMarginBottom: string
	qrContainerHeightPercentage: number
	qrLabelHeightPercentage: number
	pageOrientation: string
	gridTemplateRows: string
	allowDuplicateWasteTypes: boolean
}

export const qrPrintStyles: QrPrintStyles[] = [
	{
		name: "WEIGHT",
		title: "genericLabels:wasteRoomQRTitle",
		description: "genericLabels:wasteRoomQRDescription",
		pageInfo: "genericLabels:wasteRoomQRPageInfo",
		previewText: "genericLabels:wasteRoomQRPreview",
		columns: 2,
		maxCellsPerPage: 2,
		buttonText: "actions:printQRCodesForWasteRoom",
		iconSizeFactor: 0.6,
		templateWidth: `${A4_HEIGHT}mm`,
		templateHeight: `${A4_WIDTH}mm`,
		labelFontSize: "text-2xl",
		gridCellheight: "print:h-[165mm]",
		qrContainerMarginBottom: "mb-16 print:mb-0",
		qrContainerHeightPercentage: 80,
		qrLabelHeightPercentage: 20,
		pageOrientation: "landscape",
		gridTemplateRows: "1fr",
		allowDuplicateWasteTypes: false,
	},
	{
		name: "STORE",
		title: "genericLabels:storeQRTitle",
		description: "genericLabels:storeQRDescription",
		pageInfo: "genericLabels:storeQRPageInfo",
		previewText: "genericLabels:storeQRPreview",
		columns: 5,
		maxCellsPerPage: 20,
		buttonText: "actions:printQRCodesForTenant",
		iconSizeFactor: 1.5,
		templateWidth: `${A4_WIDTH - 20}mm`,
		templateHeight: `${A4_HEIGHT}mm`,
		labelFontSize: "text-[10px] print:text-xs",
		gridCellheight: "print:h-[63mm]",
		qrContainerMarginBottom: "mb-0",
		qrContainerHeightPercentage: 65,
		qrLabelHeightPercentage: 35,
		pageOrientation: "portrait",
		gridTemplateRows: "repeat(4, 1fr)", // 4 rows per A4 page
		allowDuplicateWasteTypes: false,
	},
]

export function createPrintStyles(printStyles: QrPrintStyles) {
	const { pageOrientation } = printStyles

	const styles = `
		@media print {
			@page {
				size: ${pageOrientation};
				margin: 0;
			}
		}
	`

	const styleElement = document.createElement("style")
	styleElement.type = "text/css"
	styleElement.appendChild(document.createTextNode(styles))
	document.head.appendChild(styleElement)

	return () => {
		document.head.removeChild(styleElement)
	}
}
