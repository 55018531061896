export const EmptyState = ({ className }: { className?: string }) => (
	<svg width="64" height="64" viewBox="0 0 64 64" fill="none" className={className}>
		<path
			d="M17.8482 32.0762L12.2148 34.8926L11.8926 35.2148L9.0762 40.85L9 41.1698V43.8302L9.0762 44.1518L11.8926 49.7852L12.2148 50.1074L17.8482 52.9238L18.1698 53H20.8302L21.1518 52.9238L26.7852 50.1074L27.1074 49.7852L29.9238 44.15L30 43.8284V41.1698L29.9238 40.85L27.447 35.8952L26.2938 35.708L18.7656 43.2344L18.2628 42.95L20.7762 32.8952L20.0778 32H18.1698L17.8482 32.0762Z"
			fill="#B2ABAB"
		/>
		<path
			d="M33.72 53H41.28L42 52.28V23.72L41.28 23H33.72L33 23.72V52.28L33.72 53Z"
			fill="#B2ABAB"
		/>
		<path
			d="M45.72 53H53.28L54 52.281V11.719L53.28 11H45.72L45 11.719V52.281L45.72 53Z"
			fill="#B2ABAB"
		/>
	</svg>
)
