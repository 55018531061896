import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useModal } from "Contexts"
import HistoryModal from "Modals/HistoryModal/HistoryModal"
import { CUSTOMER_HISTORY_TYPE } from "Modals/HistoryModal/constants"
import { FC } from "react"
import { useTrans } from "translations"

interface CustomerHistoryCardProps {
	customerId: string
	customerName?: string
}

export const CustomerHistoryCard: FC<CustomerHistoryCardProps> = ({
	customerId,
	customerName = "",
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()

	const showHistoryModal = () =>
		showModal(
			<HistoryModal
				key={`historyModal_${customerId}`}
				id={customerId}
				type={CUSTOMER_HISTORY_TYPE}
			/>
		)

	return (
		<Card title={"drawerLabels:changeHistory"} variant={CardComponentVariant.DRAWER}>
			<p className="mb-10 font-dmSans text-sm text-black">
				{t("hints:historyLinkDescriptionEntity", {
					entity: t("entities:definitiveCustomer").toLowerCase(),
				})}
			</p>
			<Button
				label={"actions:seeChangeHistory"}
				color="secondary"
				className="float-right"
				onClick={showHistoryModal}
			/>
		</Card>
	)
}
