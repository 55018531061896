import { isEmpty, isUndefined, noop } from "lodash"
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { FilterSort, Option } from "./types"
import {
	mapSearchParamOptions,
	parseSearchParamsForFilterOptions,
	stringifySearchParamOptions,
} from "./searchParams"
import { OPTION_ALL } from "./constants"
import { useGlobalAlert } from "States/globalAlert"
import { useTrans } from "translations"

export type ActiveOptions<T extends string> = {
	[id in T]: (Partial<Option> & { value: string })[]
}

type FilterSortContextType<T extends string> = {
	activeOptions: ActiveOptions<T>
	onOptionSelect: (filterSort: FilterSort & { id: keyof ActiveOptions<T> }, option: Option) => void
	updateActiveOptions: (options: ActiveOptions<T>) => void
	resetAll: () => void
	reset: (id: keyof ActiveOptions<T>) => void
}

const FilterSortContext = createContext<FilterSortContextType<any>>({
	activeOptions: {},
	onOptionSelect: noop,
	updateActiveOptions: noop,
	resetAll: noop,
	reset: noop,
})

interface FilterSortProviderProps<T extends string> {
	children: ReactNode
	filterSorts: FilterSort[]
	onOptionChange?: (activeOptions: ActiveOptions<T>) => void
	useSearchParams?: boolean
}

export const FilterSortProvider = <T extends string>({
	filterSorts,
	onOptionChange,
	useSearchParams = false,
	children,
}: FilterSortProviderProps<T>) => {
	const [activeOptions, setActiveOptions] = useState<ActiveOptions<T>>({} as ActiveOptions<T>)
	const navigate = useNavigate()
	const { t } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()

	useEffect(() => {
		// Update active options from URL search params
		if (useSearchParams) {
			const searchParams = window.location.search
			const urlFilters = parseSearchParamsForFilterOptions(searchParams)
			const activeOptionsInSearchParams = mapSearchParamOptions(urlFilters, filterSorts)

			setActiveOptions({ ...activeOptionsInSearchParams } as ActiveOptions<T>)
		}
		// Only makes sense to when search params changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.search])

	useEffect(() => {
		// Initialize active options based on default values
		if (!useSearchParams || !window.location.search) {
			const defaultOptions = filterSorts.reduce((acc, filterSort) => {
				if (filterSort.defaultValue !== undefined) {
					acc[filterSort.id as keyof ActiveOptions<T>] = [
						filterSort.options.find(({ value }) => value === filterSort.defaultValue) || {
							value: "",
						},
					]
				}
				return acc
			}, {} as ActiveOptions<T>)

			setActiveOptions({ ...defaultOptions })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		// Callback on active options change
		if (onOptionChange) {
			onOptionChange(activeOptions)
		}
	}, [activeOptions, onOptionChange])

	const updateSearchParams = useCallback(
		(newActiveOptions: ActiveOptions<T>) => {
			// Update URL search params from active options
			if (useSearchParams) {
				const filterValuesForURL = Object.entries(newActiveOptions).reduce(
					(acc, [filterId, options]) => {
						const filteredOptionValues = (options as Option[])
							.filter(
								option =>
									!isUndefined(option.value) &&
									!isEmpty(option.value) &&
									option.value !== OPTION_ALL
							)
							.map(({ value }) => value)
							.join("&")

						if (!isEmpty(filteredOptionValues)) {
							acc[filterId] = filteredOptionValues
						}

						return acc
					},
					{} as Record<string, string>
				)

				const searchParams = stringifySearchParamOptions(filterValuesForURL)
				navigate(searchParams)
			}
		},
		[navigate, useSearchParams]
	)

	const onOptionSelect = useCallback(
		({ id, isMultiselect }: FilterSort & { id: keyof ActiveOptions<T> }, option: Option) => {
			let newActiveOptions: typeof activeOptions
			if (option.value === OPTION_ALL) {
				newActiveOptions = { ...activeOptions, [id]: [option] }
			} else {
				// Handle option selection
				const selectOption = () => {
					const newSelection = isMultiselect ? [...(activeOptions[id] ?? []), option] : [option]

					return { ...activeOptions, [id]: newSelection }
				}
				// Handle option deselection
				const deselectOption = () => {
					const filteredArray = activeOptions[id]?.filter(
						selectedOption => !(option.value === selectedOption.value)
					)
					const newSelection = filteredArray && filteredArray.length ? filteredArray : []

					return newSelection.length
						? { ...activeOptions, [id]: newSelection }
						: (Object.fromEntries(
								Object.entries(activeOptions).filter(([key]) => key !== id)
						  ) as ActiveOptions<T>)
				}

				newActiveOptions =
					isMultiselect &&
					activeOptions[id]?.some(activeOption => activeOption.value === option.value)
						? deselectOption()
						: selectOption()
			}

			const filterSort = filterSorts.find(filterSort => filterSort.id === id)
			if (filterSort?.unsetsFilters) {
				const unsetFilterSorts = filterSort.unsetsFilters
					.map(filterId => filterSorts.find(filterSort => filterSort.id === filterId))
					.filter(Boolean) as FilterSort[]

				let changedFilterSorts: FilterSort[] = []

				unsetFilterSorts.forEach(fs => {
					const defaultValue = [fs?.options.find(({ value }) => value === fs.defaultValue)].filter(
						Boolean
					)
					if ((newActiveOptions as any)[fs.id]?.[0]?.value !== fs.defaultValue) {
						changedFilterSorts.push(fs)
						newActiveOptions = {
							...newActiveOptions,
							[fs.id]: defaultValue,
						}
					}
				})

				if (changedFilterSorts.length) {
					setGlobalAlert({
						type: "info",
						message: "systemMessages:filtersUnset",
						instructions: changedFilterSorts.map(fs => t(fs.title)).join(", "),
					})
				}
			}

			updateSearchParams(newActiveOptions)
			setActiveOptions(newActiveOptions)
		},
		[activeOptions, updateSearchParams, filterSorts, t, setGlobalAlert]
	)

	const updateActiveOptions = useCallback((options: ActiveOptions<T>) => {
		setActiveOptions(prev => ({ ...prev, ...options }))
	}, [])

	const reset = useCallback(
		(id: keyof ActiveOptions<T>) => {
			// Reset a specific filter/sort
			const newActiveOptions = Object.fromEntries(
				Object.entries(activeOptions).filter(([key]) => key !== id)
			) as ActiveOptions<T>

			updateSearchParams(newActiveOptions)
			setActiveOptions(newActiveOptions)
		},
		[updateSearchParams, activeOptions]
	)

	const resetAll = useCallback(() => {
		updateSearchParams({} as ActiveOptions<T>)
		setActiveOptions({} as ActiveOptions<T>)
	}, [updateSearchParams])

	return (
		<FilterSortContext.Provider
			value={{ activeOptions, reset, resetAll, onOptionSelect, updateActiveOptions }}
		>
			{children}
		</FilterSortContext.Provider>
	)
}

export const useFilterSort = () => {
	const context = useContext(FilterSortContext)
	if (!context) {
		throw new Error("useFilters must be used within a FiltersProvider")
	}
	return context
}
