import React, { useCallback, useEffect, useMemo } from "react"
import { trpc } from "Utils/trpc"
import { WasteStreamsTable } from "./wasteStreamsTable"
import { sortBy, uniqBy } from "lodash"
import { ACCESS_POINT } from "admin-client-server/src/coreApi/models/Common"
import { WasteTypeData, useConfigService } from "./useConfigService"
import { Translate, useTrans } from "translations"
import { useTerminalsState } from "States/Terminals"
import { useAccessParents } from "pages/infrastructure/functions"
import { COLOR_CONFIG, COLOR_KEY, CUSTOM_COLORS, ColorsTable, DEFAULT_CONFIG } from "./colorsTable"
import { Button } from "components/button"

type Props = {
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const WasteStreamsBuilding: React.FC<Props> = ({ setHasUnsavedChanges }: Props) => {
	const { t, language } = useTrans()
	const { accessParents } = useAccessParents()
	const { currentTerminal } = useTerminalsState()
	const {
		terminalWasteTypeConfig: data,
		refetchTerminalWasteTypeConfig: refetch,
		terminalConfig,
		updateTerminalConfig,
	} = useConfigService()

	const [updatedWasteTypes, setUpdatedWasteTypes] = React.useState<WasteTypeData[]>([])
	const [updatedColors, setUpdatedColors] = React.useState<COLOR_CONFIG>(DEFAULT_CONFIG)

	useEffect(() => {
		let updatedColors: { [k in COLOR_KEY]: string } = { ...DEFAULT_CONFIG }

		CUSTOM_COLORS.forEach(color => {
			updatedColors[color.key] = terminalConfig?.[color.key] || ""
		})

		setUpdatedColors(updatedColors)
	}, [terminalConfig, setUpdatedColors])

	const { mutate: updateTerminalWasteTypeConfig, isLoading } =
		trpc.config.updateTerminalWasteTypeConfig.useMutation({
			onSuccess: () => {
				refetch()
				setHasUnsavedChanges(false)
			},
		})

	const containers = useMemo(() => {
		return sortBy(
			uniqBy(
				accessParents?.flatMap(ap => ap.containers as ACCESS_POINT[]),
				"wasteType.code"
			),
			"wasteType.code"
		)
	}, [accessParents])

	const onSave = useCallback(() => {
		if (updatedWasteTypes.length) {
			updateTerminalWasteTypeConfig({
				updates: updatedWasteTypes.map(uwt => ({ ...uwt, locale: language })),
				terminalId: currentTerminal?.id,
			})
		}

		if (
			Object.values(updatedColors).filter(Boolean).length ||
			(!!terminalConfig && Object.values(terminalConfig).filter(Boolean).length)
		) {
			updateTerminalConfig({
				...updatedColors,
				terminalId: currentTerminal?.id,
			})
		}
	}, [
		updateTerminalWasteTypeConfig,
		language,
		currentTerminal,
		updatedWasteTypes,
		updateTerminalConfig,
		updatedColors,
		terminalConfig,
	])

	const onResetAll = useCallback(() => {
		setUpdatedWasteTypes(
			data?.map(el => ({
				...el,
				names: el.names.map(n => ({ ...n, displayName: "" })),
				color: "",
			})) || []
		)
		setUpdatedColors(DEFAULT_CONFIG)
		setHasUnsavedChanges(true)
	}, [setUpdatedWasteTypes, setHasUnsavedChanges, data])

	return (
		<>
			<div className="mt-4">
				<ul className="list-disc list-inside ml-2">
					<Translate
						i18nKey="hints:wasteStreamBuildingConfig"
						children={[
							<li />,
							<li>
								{
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									<a
										href="/infrastructure/manage"
										style={{ textDecoration: "underline" }}
										rel="noreferrer"
									/>
								}
							</li>,
							<li />,
						]}
					/>
				</ul>
			</div>
			<ColorsTable
				updatedColors={updatedColors}
				setUpdatedColors={setUpdatedColors}
				setHasUnsavedChanges={setHasUnsavedChanges}
			/>
			<WasteStreamsTable
				containers={containers}
				data={data}
				setHasUnsavedChanges={setHasUnsavedChanges}
				onSave={onSave}
				updateLoading={isLoading}
				updatedWasteTypes={updatedWasteTypes}
				setUpdatedWasteTypes={setUpdatedWasteTypes}
			/>
			<div className="bg-white w-full sticky bottom-0 pb-4">
				<hr></hr>
				<div className="mt-4 text-sm flex justify-between">
					<Button label={t("actions:save")} onClick={onSave} loading={false} />
					<div className="mr-4 self-center underline cursor-pointer" onClick={onResetAll}>
						{t("actions:resetAll")}
					</div>
				</div>
			</div>
		</>
	)
}
