import { Workbook } from "exceljs"
import { translate } from "translations/functions/hook"
import { addColumns, downloadExcel, filterLinking } from "Utils/importCommon/downloadFunctions"
import {
	getAccessParentColumns,
	getContainerColumns,
	getDepotColumns,
	getTerminalColumns,
} from "./columnFunctions"

const addTerminalsSheet = (workbook: Workbook, isMWM: boolean) => {
	const sheet = workbook.addWorksheet(translate("importLabels:terminalsSheetTitle"))

	const columns = getTerminalColumns({ isMWM })
	addColumns(sheet, columns)
}

const addAccessParentsSheet = (
	workbook: Workbook,
	isMWM: boolean,
	includeLinking: boolean = true
) => {
	const sheet = workbook.addWorksheet(translate("importLabels:accessParentsSheetTitle"))

	const accessParentColumns = getAccessParentColumns({ isMWM })
	const columns = includeLinking ? accessParentColumns : filterLinking(accessParentColumns)
	addColumns(sheet, columns)
}

const addDepotsSheet = (workbook: Workbook, includeLinking: boolean = true) => {
	const sheet = workbook.addWorksheet(translate("importLabels:depotsSheetTitle"))
	const accessParentColumns = getDepotColumns()
	const columns = includeLinking ? accessParentColumns : filterLinking(accessParentColumns)
	addColumns(sheet, columns)
}

const addContainersSheet = ({
	workbook,
	isMWM,
	onDepot,
	onDepotOrSection,
	includeLinking = true,
}: {
	workbook: Workbook
	isMWM: boolean
	onDepot?: boolean
	onDepotOrSection?: boolean
	includeLinking?: boolean
}) => {
	const sheet = workbook.addWorksheet(translate("importLabels:containersSheetTitle"))

	const containerColumns = getContainerColumns({ isMWM, onDepot, onDepotOrSection })
	const columns = includeLinking ? containerColumns : filterLinking(containerColumns)
	addColumns(sheet, columns)
}

export const downloadForTerminals = (title: string, isMWM: boolean, hasDepots: boolean) => {
	const workbook = new Workbook()

	addTerminalsSheet(workbook, isMWM)
	addAccessParentsSheet(workbook, isMWM)

	if (hasDepots) {
		addDepotsSheet(workbook)
		addContainersSheet({ workbook, isMWM, onDepotOrSection: true })
	} else {
		addContainersSheet({ workbook, isMWM })
	}

	downloadExcel(title, workbook)
}

export const downloadForAccessParents = (title: string, isMWM: boolean, hasDepots: boolean) => {
	const workbook = new Workbook()

	addAccessParentsSheet(workbook, isMWM, false)

	if (hasDepots) {
		addDepotsSheet(workbook)
		addContainersSheet({ workbook, isMWM, onDepotOrSection: true })
	} else {
		addContainersSheet({ workbook, isMWM })
	}

	downloadExcel(title, workbook)
}

export const downloadForDepots = (title: string) => {
	const workbook = new Workbook()

	addDepotsSheet(workbook, false)
	addContainersSheet({ workbook, isMWM: true, onDepot: true })

	downloadExcel(title, workbook)
}

export const downloadForContainersOnDepot = (title: string) => {
	const workbook = new Workbook()

	addContainersSheet({ workbook, isMWM: true, onDepot: true, includeLinking: false })

	downloadExcel(title, workbook)
}

export const downloadForContainers = (title: string, isMWM: boolean) => {
	const workbook = new Workbook()

	addContainersSheet({ workbook, isMWM, includeLinking: false })

	downloadExcel(title, workbook)
}
