import { DocumentNode } from "@apollo/client"
import { postFunc } from "../Utils/api"
import { DELETE_POINT } from "./mutations/deletePoint"

export const deletePoint = ({
	id,
	refetchQuery,
	onStartCallBack,
	onFailCallBack,
}: {
	id: string
	refetchQuery?: DocumentNode
	onStartCallBack?: () => void
	onFailCallBack?: (err: any) => void
}) => {
	postFunc(
		DELETE_POINT,
		() => {
			onStartCallBack && onStartCallBack()
		},
		{ variables: { Id: id }, refetchQueries: refetchQuery ? [{ query: refetchQuery }] : [] },
		err => {
			onFailCallBack && onFailCallBack(err)
		}
	)
}
