import { SVGAttributes } from "react"

export const CheckCircleIcon = (props: SVGAttributes<SVGSVGElement>) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
			<rect width="20" height="20" rx="10" fill="#467432" />
			<path
				d="M8.97749 11.9952L14.7227 6.25L15.6066 7.13388L8.97749 13.763L5 9.78555L5.88388 8.90167L8.97749 11.9952Z"
				fill="white"
			/>
		</svg>
	)
}
