import { useConfig } from "api/hooks/useConfig"
import { getNavigationInterface } from "components/Sidebar/components/NavSection"
import { NavHeaderInterface } from "components/Sidebar/Sidebar.interface"
import React from "react"
import { useTrans } from "translations"

export const NavHeader: React.FC<NavHeaderInterface> = ({ type }) => {
	const { t } = useTrans()
	const { config, isRealEstate } = useConfig()

	if (!config) return null

	const navigation = getNavigationInterface(isRealEstate, config.useExternalCRM)
	const Icon = navigation[type].icon

	return (
		<h6 className="flex items-center pl-5 font-dmSans text-base	font-semibold uppercase">
			<span className="block pr-2.5">
				<Icon />
			</span>
			{t(`sidebarLabels:${type}`)}
		</h6>
	)
}
