import { Workbook } from "exceljs"
import { addColumns, downloadExcel } from "Utils/importCommon/downloadFunctions"
import { translate } from "translations/functions/hook"
import { getCustomerColumns, getContactsColumns, getStationsColumns } from "./columnFunctions"

const addCustomersSheet = (workbook: Workbook, isMWM: boolean) => {
	const sheet = workbook.addWorksheet(translate("importLabels:customersSheetTitle"))

	const customerColumns = getCustomerColumns({ isMWM }).filter(col => !col.isGenerated)
	addColumns(sheet, customerColumns)
}

const addContactsSheet = (workbook: Workbook) => {
	const sheet = workbook.addWorksheet(translate("importLabels:contactsSheetTitle"))

	const contactsColumns = getContactsColumns()
	addColumns(sheet, contactsColumns)
}

const addStationsSheet = (workbook: Workbook) => {
	const sheet = workbook.addWorksheet(translate("importLabels:accessParentsSheetTitle"))

	const stationsColumns = getStationsColumns()
	addColumns(sheet, stationsColumns)
}

export const downloadForCustomers = async (title: string, isMWM: boolean) => {
	const workbook = new Workbook()

	addCustomersSheet(workbook, isMWM)
	if (isMWM) {
		addStationsSheet(workbook)
	} else {
		addContactsSheet(workbook)
	}

	downloadExcel(title, workbook)
}
