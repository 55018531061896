import { HelpSection } from "./api/sanity/types"

export const mapImportSection = ({
	useWasteSuctionSystem,
	isMWM,
	section,
}: {
	useWasteSuctionSystem?: boolean
	isMWM: boolean
	section: HelpSection
}) => {
	let type = ""
	let step = ""
	switch (section.type) {
		case "infrastructure_re":
			isMWM ? (type = "not_in_use") : (type = "infrastructure")
			break
		case "infrastructure_mwm":
			isMWM && !useWasteSuctionSystem ? (type = "infrastructure") : (type = "not_in_use")
			break
		case "infrastructure_depots":
			useWasteSuctionSystem ? (type = "infrastructure") : (type = "not_in_use")
			break
		case "customers":
			type = "customers"
			break
		case "tenants":
			type = "customers"
			break
	}

	switch (section.step) {
		case "download":
			step = "downloadTemplate"
			break
		case "upload":
			step = "uploadFile"
			break
		case "mapping":
			step = "mapSheets"
			break
		case "validate":
			step = "validateFile"
			break
		case "adding":
			step = "addToSystem"
			break
	}

	return `${step}-${type}`
}
