import { Page } from "components/Page"
import { TenantWasteTypesCard } from "components/pageCards/tenantWasteTypesCard"
import { FC } from "react"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"

export const TenantWasteTypes: FC<{}> = () => {
	const { t } = useTrans()
	const { currentTerminal } = useTerminalsState()

	return (
		<Page
			title="statisticsLabels:tenantWasteTypes"
			fullHeight
			breadcrumbsPaths={[{ url: "/tenant/dashboard", name: t("sidebarLabels:tenantFollowUp") }]}
		>
			<TenantWasteTypesCard realEstateId={currentTerminal.id} />
		</Page>
	)
}

export default TenantWasteTypes
