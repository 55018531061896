import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { Translate } from "translations"

export const NoDepotsForContainersModal: React.FC = () => {
	const { hideModal } = useModal()

	return (
		<ModalContainer
			title={"errors:noDepots"}
			onConfirm={hideModal}
			onCancel={hideModal}
			onConfirmText="common:OK"
			disableCancelButton
		>
			<div className="pt-3 whitespace-pre">
				<Translate
					i18nKey={"hints:noDepotsForContainers"}
					components={[
						// eslint-disable-next-line jsx-a11y/anchor-has-content
						<a
							href="/infrastructure/manage"
							style={{ textDecoration: "underline" }}
							target="_blank"
							rel="noreferrer"
						/>,
					]}
				/>
			</div>
		</ModalContainer>
	)
}
