import { Translate } from "translations"
import { ColumnType, listView, textView } from "./functions"

export const infrastructureColumns: ColumnType[] = [
	{
		label: "formLabels:name",
		name: "name",
		getView: textView,
	},
]

export const infrastructureColumnsWithRedundancyPoints: ColumnType[] = [
	{
		label: "sidebarLabels:nameOfAccessParent",
		name: "name",
		getView: textView,
	},
	{
		label: "entities:redundancyPoints",
		name: "redundancyPointNames",
		getView: listView,
		sortable: false,
		headerTooltipComponent: (
			<Translate
				i18nKey="hints:redundancyPointDescription"
				components={[
					// Anchor content is added by the translation
					// eslint-disable-next-line jsx-a11y/anchor-has-content
					<a href="mailto:support@carrot.tech" className="underline text-white" />,
				]}
			/>
		),
	},
]
