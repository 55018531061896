import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useModal } from "Contexts"
import { EditSmartFunctionalityModal } from "pages/infrastructure/modals/editSmartFunctionalityModal"
import { SmartFunctionalityModal } from "pages/infrastructure/modals/smartFunctionalityModal"
import { FC, useMemo } from "react"
import { Translate, useTrans } from "translations"
import { SmartIntegration, SmartIntegrationWithTranslation } from "Utils/smartInfrastructure"
import { isCarrotAdmin } from "Utils/authUtils"
import { Table } from "components/Table"
import { smartCapabilitiesColumns } from "Utils/tableColumns/smartCapabilitiesColumns"
import { AlertLine } from "components/AlertLine/AlertLine"
import { ContainerDrawerProps } from "../containerDrawer"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import InfoPopup from "components/InfoPopup/InfoPopup"

export const ContainerSmartCapabilities: FC<ContainerDrawerProps> = ({
	accessParentId,
	containerId,
	depotId,
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()

	const { getContainer } = useAccessPoints({ accessParentId })
	const container = getContainer(containerId, depotId)

	const smartIntegrations = useMemo(
		() =>
			JSON.parse(container?.smartIntegrations ?? "[]").map((si: SmartIntegration) => ({
				...si,
				capabilityTypeTranslation: t(`entities:smart-tech-${si.capabilityType}`),
			})) as SmartIntegrationWithTranslation[],
		[container, t]
	)

	const canAddSmartFunctionality = useMemo(
		() =>
			smartIntegrations.length < 2 &&
			!smartIntegrations.map((si: SmartIntegration) => si.capabilityType).includes("integrated"),
		[smartIntegrations]
	)

	const openEditModal = () =>
		container && showModal(<EditSmartFunctionalityModal {...{ accessParentId, container }} />)

	const openSmartModal = () =>
		container && showModal(<SmartFunctionalityModal {...{ accessParentId, container }} />)

	return (
		<Card
			{...{
				title: "drawerLabels:smartCapabilities",
			}}
			overflow="auto"
			variant={CardComponentVariant.DRAWER}
		>
			<div className="mb-4 font-dmSans text-sm">
				{t("hints:manageSmartHardwareDescription")}
				<InfoPopup
					id={containerId}
					size={15}
					className="ml-1 inline-block align-baseline"
					width="350px"
				>
					<Translate
						i18nKey="hints:manageSmartHardwareDescriptionPopup"
						children={[
							<p className="my-2" />,
							<p className="my-2">
								{
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									<a href="mailto:support@carrot.tech" className="underline text-white" />
								}
							</p>,
							<p className="my-2" />,
						]}
					/>
				</InfoPopup>
			</div>
			<Table
				columns={smartCapabilitiesColumns}
				data={smartIntegrations}
				emptyViewType="noTechnologies"
				emptyViewClassName="bg-grey1 mt-0"
			/>

			{!canAddSmartFunctionality && (
				<div className="my-4">
					<AlertLine
						type="warning"
						message="hints:manageSmartHardwareLimitReached"
						canClose={false}
						isStatic
					/>
				</div>
			)}

			<div>
				{canAddSmartFunctionality && (
					<div className="float-right ml-4">
						<Button
							className=""
							label="actions:addNew"
							color="secondary"
							onClick={openSmartModal}
						/>
					</div>
				)}

				{isCarrotAdmin() && smartIntegrations.length > 0 && (
					<div className="float-right">
						<Button label="actions:edit" color="secondary" onClick={openEditModal} />
					</div>
				)}
			</div>
		</Card>
	)
}
