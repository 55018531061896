import React, { ChangeEvent, useCallback, useContext, useRef } from "react"
import ExcelJS from "exceljs"
import { Button } from "components/button"
import { CheckCircleIcon } from "Icons/CheckCircle"
import TrashIcon from "Icons/Trash"
import { useDropzone } from "react-dropzone"
import { useGlobalAlert } from "States/globalAlert"
import { useTrans } from "translations"
import { ImportContext } from "../../ImportContext"
import { UploadedFileColumn, UploadedFileSheet } from "../../types"
import { EXCEL_FORMAT } from "constants/general"
import { get } from "lodash"

const getTextValue = (value: any) =>
	get(value, "text.richText[0].text") || get(value, "result") || get(value, "text") || value

export const UploadZone: React.FC = () => {
	const { t } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()
	const inputRef = useRef<any>()
	const { uploadedFileName, setUploadedFileName, setUploadedFileStructure, reset } =
		useContext(ImportContext)

	const triggerFileUpload = () => {
		inputRef.current.click()
	}

	const removeDownloadedFile = useCallback(() => {
		setUploadedFileName("")
		reset()
	}, [setUploadedFileName, reset])

	const onAddFile = useCallback(
		(file: File) => {
			if (file.type === EXCEL_FORMAT) {
				const workbook = new ExcelJS.Workbook()
				const reader = new FileReader()

				reader.onload = () => {
					const buffer: any = reader.result || ""
					workbook.xlsx.load(buffer).then(wb => {
						const fileData: any[] = []

						let noData = true
						wb.eachSheet((sheet, id) => {
							const headerValues = (sheet.getRow(1).values as ExcelJS.CellValue[]).slice(1)

							if (sheet.actualRowCount > 1) {
								noData = false

								const data: UploadedFileSheet = {
									id: id.toString(),
									name: sheet.name,
									columns: headerValues.map(name => ({ name, values: [] })) as UploadedFileColumn[],
								}

								sheet.eachRow((row: any, rowIndex) => {
									if (rowIndex > 1) {
										const rowValues = row.values.slice(1)

										headerValues.forEach((_, i) => {
											data.columns[i].values.push(getTextValue(rowValues[i]))
										})
									}
								})

								data.columns = data.columns.filter(Boolean)

								fileData.push(data)
							}
						})

						if (noData) {
							setGlobalAlert({
								type: "error",
								message: "errors:importNoData",
								instructions: "errors:importNoDataInstructions",
							})
							inputRef.current.value = ""
						} else {
							setUploadedFileName(file.name)
							setUploadedFileStructure(fileData)
						}
					})
				}
				reader.readAsArrayBuffer(file)
			} else {
				setGlobalAlert({
					type: "error",
					message: "errors:importWrongFormat",
					instructions: "importLabels:wrongFormatError",
				})
			}
		},
		[setGlobalAlert, setUploadedFileName, setUploadedFileStructure]
	)

	const { getRootProps } = useDropzone({
		noClick: true,
		maxFiles: 1,
		onDrop: files => onAddFile(files[0]),
	})

	const onFileAddedWithButton = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.files?.length) {
				onAddFile(e.target.files[0])
			}
		},
		[onAddFile]
	)

	return uploadedFileName ? (
		<div className="w-full h-60 bg-grey1 flex flex-col justify-center items-center">
			<CheckCircleIcon className="mb-2" />
			<div className="font-medium text-sm leading-6 text-carrotGreen">
				{t("importLabels:fileUploaded")}
			</div>
			<div className="font-normal text-sm leading-6">{uploadedFileName}</div>
			<div className="flex items-center pointer" onClick={removeDownloadedFile}>
				<TrashIcon width={12} height={12} />
				<div className="font-medium underline text-sm">{t("importLabels:removeFile")}</div>
			</div>
		</div>
	) : (
		<div
			className="w-full h-60 bg-grey1 border-dashed border border-black flex flex-col justify-center items-center"
			{...getRootProps()}
		>
			<div className="font-medium text-sm leading-6">{t("importLabels:dragTitle")}</div>
			<div className="font-normal text-sm leading-6">{t("importLabels:typeRestriction")}</div>
			<Button
				label="importLabels:uploadFile"
				type="button"
				color="primary"
				onClick={triggerFileUpload}
				className="mt-5"
			/>
			<input
				type="file"
				ref={inputRef}
				className="hidden"
				onChange={onFileAddedWithButton}
				accept=".xlsx"
				multiple={false}
			/>
		</div>
	)
}
